import { useState } from 'react';

import { MemberTeamFragment } from '@/types/tanstack-query/generated';

const useInviteMembersTeam = () => {
  const [membersTeam, setMembersTeam] = useState<MemberTeamFragment[]>([]);
  const setItems = async (value: MemberTeamFragment[]) => {
    setMembersTeam(value);
  };

  const popItem = async (props: { inviteMember: MemberTeamFragment }) => {
    setMembersTeam(
      membersTeam.filter((item) => item.id !== props.inviteMember.id),
    );
  };

  const pushItem = async (props: { inviteMember: MemberTeamFragment }) => {
    const clone = Object.assign([], membersTeam);
    clone.push(props.inviteMember);
    setMembersTeam(clone);
  };

  const updateItem = async (props: { inviteMember: MemberTeamFragment }) => {
    const clone: MemberTeamFragment[] = Object.assign([], membersTeam);
    setMembersTeam(
      clone.map((item) => {
        if (item.id === props.inviteMember.id) return { ...props.inviteMember };
        return { ...item };
      }),
    );
  };

  return {
    membersTeam,
    event: {
      setItems,
      popItem,
      pushItem,
      updateItem,
    },
  };
};

export default useInviteMembersTeam;
