import { useContext, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import {
  TeamFragment,
  useSortableWorkflowMutation,
  WorkflowFragment,
} from '@/types/tanstack-query/generated';

const useWorkflows = () => {
  const [team, setTeam] = useState<TeamFragment>();
  const [workflows, setWorkflows] = useState<WorkflowFragment[]>([]);
  const sortWorkflows = useSortableWorkflowMutation({
    onMutate: () => {},
    onError: () => {},
    onSuccess: () => {},
    onSettled: () => {},
  });
  const params = useParams();
  const { getDataByTeam, workflows: workflowsCore } =
    useContext(WorkspaceContext);

  const fetchWorkflows = async (props: { team_identifier: string }) => {
    if (getDataByTeam) {
      const result = getDataByTeam({ team_identifier: props.team_identifier });
      setWorkflows(result.workflows);
      setTeam(result.team);
    }
  };

  const sortItems = async (props: { workflows: WorkflowFragment[] }) => {
    sortWorkflows.mutate({ ids: props.workflows.map((item) => item.id) });
  };

  useEffect(() => {
    if (params.team) {
      fetchWorkflows({ team_identifier: params.team });
    }
  }, [params, workflowsCore]);

  return {
    workflows,
    team,
    event: {
      fetchWorkflows,
      sortItems,
    },
  };
};

export default useWorkflows;
