/* eslint-disable import/no-extraneous-dependencies */

import { useContext, useState } from 'react';

import { Label } from '@radix-ui/react-dropdown-menu';
import { useParams } from 'react-router-dom';

import { Button } from '@/components/Button';
import { Card, CardContent } from '@/components/Card';
import { ScrollArea, ScrollBar } from '@/components/ScrollArea';
import { Separator } from '@/components/Separator';
import IssueServicesContext from '@/providers/issue/IssueServicesContext';
import { MemoizedRenderProject } from '@/renders/RenderProject';
import { IGroupingIssue } from '@/types/tanstack-query/core/core';
import { ProjectFragment } from '@/types/tanstack-query/generated';

import ProjectAnalyticsComponent from './components/ProjectAnalyticsComponent';
import ProjectNotFoundComponent from './components/ProjectNotFoundComponent';
import HeaderProjectComponent from './HeaderProjectComponent';
import useProjectIssues from './hooks/useProjectIssues';
import { IssuesGrouping } from '../issue/components/ListGrouping/ListGrouping';

const IssuesComponent = (props: { project: ProjectFragment }) => {
  const { showCreatePopup } = useContext(IssueServicesContext);
  const [grouping] = useState<IGroupingIssue>('status');

  const { initial, issues, teamData } = useProjectIssues({
    project: props.project,
  });

  return (
    <div className="relative flex grow flex-col overflow-hidden md:flex-row">
      <div className="z-20 flex w-full  flex-none flex-row bg-secondary py-2 md:relative md:order-last md:w-[300px] md:py-0">
        <Separator orientation="vertical" />
        <ProjectAnalyticsComponent project={props.project} />
      </div>
      <div className="relative z-10 flex h-full grow md:pt-0">
        {initial && issues.length > 0 && (
          <ScrollArea className="w-full" type="auto">
            <ScrollArea type="auto" style={{ maxHeight: '100%' }}>
              <IssuesGrouping
                grouping={grouping}
                issues={issues}
                team={teamData?.team}
                project={props.project}
              />
              <ScrollBar orientation="vertical" />
            </ScrollArea>
            <ScrollBar orientation="vertical" />
          </ScrollArea>
        )}
        {initial && issues.length === 0 && (
          <div className="flex grow flex-col items-center justify-center">
            <Card className="max-w-[500px] bg-secondary">
              <CardContent className="p-6">
                <div className="flex flex-col gap-2">
                  <Label className="text-xl font-semibold">All Issues</Label>
                  <Label className="opacity-50">
                    All Issues is the place where you can see all of your team's
                    work in one view.
                  </Label>
                  <Label className="opacity-50">
                    Once you have created some issues for your team, they will
                    show up here.
                  </Label>
                  <div className="pt-[20px]">
                    <Button
                      size={'sm'}
                      type="button"
                      onClick={() => {
                        showCreatePopup({
                          isOpen: true,
                          initTeamId: teamData?.team?.id,
                          initProjectId: props.project.id,
                        });
                      }}
                      variant={'default'}
                    >
                      Create a new issue
                    </Button>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        )}
      </div>
    </div>
  );
};

const ProjectIssuesPage = (props?: { code?: string; id?: string }) => {
  const params = useParams();

  return (
    <div className="relative flex w-full flex-col md:h-full md:overflow-hidden">
      <MemoizedRenderProject
        code={props?.code ?? params.projectCode}
        id={props?.id ?? params.id}
        renderItem={(project) => {
          return project ? (
            <>
              <HeaderProjectComponent data={project} />
              <Separator />
              <IssuesComponent project={project} />
            </>
          ) : (
            <ProjectNotFoundComponent />
          );
        }}
      />
    </div>
  );
};

export default ProjectIssuesPage;
