import { useContext, useEffect, useState } from 'react';

import { create } from 'zustand';

import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { IssueFragment } from '@/types/tanstack-query/generated';

import {
  IConditionFilter,
  ConditionFilterList,
} from '../pages/issue/constant/constant';

type IPageState = {
  issues: IssueFragment[];
  setIssues: (issues: IssueFragment[]) => void;
};

const createStore = () =>
  create<IPageState>((set) => ({
    issues: [],
    setIssues: (issues: IssueFragment[]) =>
      set({
        issues: issues,
      }),
  }));

const useIssuesMultiFilters = () => {
  const [useStore] = useState(createStore);
  const { issues, setIssues } = useStore();
  const { workflows } = useContext(WorkspaceContext);

  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [selectedConditionStatus, setSelectedConditionStatus] =
    useState<IConditionFilter>(ConditionFilterList[0]!);

  const [selectedPriorities, setSelectedPriorities] = useState<string[]>([]);
  const [selectedConditionPriority, setSelectedConditionPriority] =
    useState<IConditionFilter>(ConditionFilterList[0]!);

  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [selectedConditionLabel, setSelectedConditionLabel] =
    useState<IConditionFilter>(ConditionFilterList[0]!);

  const [selectedAssignees, setSelectedAssignees] = useState<string[]>([]);
  const [selectedConditionAssignee, setSelectedConditionAssignee] =
    useState<IConditionFilter>(ConditionFilterList[0]!);
  const [filterIssues, setFilterIssues] = useState<IssueFragment[]>([]);
  useEffect(() => {
    let filter = issues;

    // Filter Status
    if (selectedStatuses.length > 0) {
      const filterStatus = workflows.filter((item) =>
        selectedStatuses.includes(item.id),
      );

      const filterStatusSameType = filterStatus.flatMap((item) => {
        return workflows.filter(
          (child) => child.type === item.type && child.name === item.name,
        );
      });
      switch (selectedConditionStatus.value) {
        case 'or':
          filter = filter.filter(
            (item) =>
              item.workflow_id &&
              filterStatusSameType.find(
                (workflow) => item.workflow_id === workflow.id,
              ),
          );
          break;
        case 'or_not':
          filter = filter.filter(
            (item) =>
              item.workflow_id &&
              !filterStatusSameType.find(
                (workflow) => item.workflow_id === workflow.id,
              ),
          );

          break;
        default:
          break;
      }
    }

    // Filter Priority
    if (
      selectedConditionPriority.value === 'or' &&
      selectedPriorities.length > 0
    ) {
      filter = filter.filter(
        (item) => item.priority && selectedPriorities.includes(item.priority),
      );
    }
    if (
      selectedConditionPriority.value === 'or_not' &&
      selectedPriorities.length > 0
    ) {
      filter = filter.filter(
        (item) => item.priority && !selectedPriorities.includes(item.priority),
      );
    }
    // Filter Label
    if (selectedConditionLabel.value === 'or' && selectedLabels.length > 0) {
      filter = filter.filter((item) => {
        if (item.labels && item.labels.length > 0) {
          const verify = selectedLabels.filter((x) =>
            item.labels?.some((label) => label.label_id === x),
          );

          return verify.length > 0;
        }
        return false;
      });
    }
    if (
      selectedConditionLabel.value === 'or_not' &&
      selectedLabels.length > 0
    ) {
      filter = filter.filter((item) => {
        if (item.labels && item.labels.length > 0) {
          const verify = selectedLabels.filter((x) =>
            item.labels?.some((label) => label.label_id === x),
          );

          return verify.length === 0;
        }
        return false;
      });
    }
    // Filter Assignee
    if (
      selectedConditionAssignee.value === 'or' &&
      selectedAssignees.length > 0
    ) {
      filter = filter.filter(
        (item) =>
          item.assignee_id && selectedAssignees.includes(item.assignee_id),
      );
    }
    if (
      selectedConditionAssignee.value === 'or_not' &&
      selectedAssignees.length > 0
    ) {
      filter = filter.filter(
        (item) =>
          item.assignee_id && !selectedAssignees.includes(item.assignee_id),
      );
    }

    setFilterIssues(filter);
  }, [
    selectedStatuses,
    selectedConditionStatus,
    selectedPriorities,
    selectedConditionPriority,
    selectedLabels,
    selectedConditionLabel,
    selectedAssignees,
    selectedConditionAssignee,
    issues,
  ]);

  return {
    issues: filterIssues,
    selectedStatuses,
    selectedConditionStatus,
    selectedPriorities,
    selectedConditionPriority,
    selectedLabels,
    selectedConditionLabel,
    selectedAssignees,
    selectedConditionAssignee,
    event: {
      setIssues,
      setSelectedStatuses,
      setSelectedConditionStatus,
      setSelectedPriorities,
      setSelectedConditionPriority,
      setSelectedLabels,
      setSelectedConditionLabel,
      setSelectedAssignees,
      setSelectedConditionAssignee,
    },
  };
};

export default useIssuesMultiFilters;
