import type { SVGProps } from 'react';

export function UserCircle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 256 256"
      {...props}
    >
      <path
        fill="currentColor"
        d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24M74.08 197.5a64 64 0 0 1 107.84 0a87.83 87.83 0 0 1-107.84 0M96 120a32 32 0 1 1 32 32a32 32 0 0 1-32-32m97.76 66.41a79.66 79.66 0 0 0-36.06-28.75a48 48 0 1 0-59.4 0a79.66 79.66 0 0 0-36.06 28.75a88 88 0 1 1 131.52 0"
      ></path>
    </svg>
  );
}

export function UserCircleGear(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 256 256"
      {...props}
    >
      <path
        fill="currentColor"
        d="m228.25 63.07l-4.66-2.69a23.6 23.6 0 0 0 0-8.76l4.66-2.69a8 8 0 0 0-8-13.86l-4.67 2.7a23.92 23.92 0 0 0-7.58-4.39V28a8 8 0 0 0-16 0v5.38a23.92 23.92 0 0 0-7.58 4.39l-4.67-2.7a8 8 0 1 0-8 13.86l4.66 2.69a23.6 23.6 0 0 0 0 8.76l-4.66 2.69a8 8 0 0 0 4 14.93a7.92 7.92 0 0 0 4-1.07l4.67-2.7a23.92 23.92 0 0 0 7.58 4.39V84a8 8 0 0 0 16 0v-5.38a23.92 23.92 0 0 0 7.58-4.39l4.67 2.7a7.92 7.92 0 0 0 4 1.07a8 8 0 0 0 4-14.93M192 56a8 8 0 1 1 8 8a8 8 0 0 1-8-8m29.35 48.11a8 8 0 0 0-6.57 9.21A88.85 88.85 0 0 1 216 128a87.62 87.62 0 0 1-22.24 58.41a79.66 79.66 0 0 0-36.06-28.75a48 48 0 1 0-59.4 0a79.66 79.66 0 0 0-36.06 28.75A88 88 0 0 1 128 40a88.76 88.76 0 0 1 14.68 1.22a8 8 0 0 0 2.64-15.78a103.92 103.92 0 1 0 85.24 85.24a8 8 0 0 0-9.21-6.57M96 120a32 32 0 1 1 32 32a32 32 0 0 1-32-32m-21.92 77.5a64 64 0 0 1 107.84 0a87.83 87.83 0 0 1-107.84 0"
      ></path>
    </svg>
  );
}

export function Building(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 256 256"
      {...props}
    >
      <path
        fill="currentColor"
        d="M232 224h-24V32h8a8 8 0 0 0 0-16H40a8 8 0 0 0 0 16h8v192H24a8 8 0 0 0 0 16h208a8 8 0 0 0 0-16M64 32h128v192h-32v-40a8 8 0 0 0-8-8h-48a8 8 0 0 0-8 8v40H64Zm80 192h-32v-32h32ZM88 64a8 8 0 0 1 8-8h16a8 8 0 0 1 0 16H96a8 8 0 0 1-8-8m48 0a8 8 0 0 1 8-8h16a8 8 0 0 1 0 16h-16a8 8 0 0 1-8-8m-48 40a8 8 0 0 1 8-8h16a8 8 0 0 1 0 16H96a8 8 0 0 1-8-8m48 0a8 8 0 0 1 8-8h16a8 8 0 0 1 0 16h-16a8 8 0 0 1-8-8m-48 40a8 8 0 0 1 8-8h16a8 8 0 0 1 0 16H96a8 8 0 0 1-8-8m48 0a8 8 0 0 1 8-8h16a8 8 0 0 1 0 16h-16a8 8 0 0 1-8-8"
      ></path>
    </svg>
  );
}

export function Plus(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 256 256"
      {...props}
    >
      <path
        fill="currentColor"
        d="M224 128a8 8 0 0 1-8 8h-80v80a8 8 0 0 1-16 0v-80H40a8 8 0 0 1 0-16h80V40a8 8 0 0 1 16 0v80h80a8 8 0 0 1 8 8"
      ></path>
    </svg>
  );
}

export function X(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 256 256"
      {...props}
    >
      <path
        fill="currentColor"
        d="M205.66 194.34a8 8 0 0 1-11.32 11.32L128 139.31l-66.34 66.35a8 8 0 0 1-11.32-11.32L116.69 128L50.34 61.66a8 8 0 0 1 11.32-11.32L128 116.69l66.34-66.35a8 8 0 0 1 11.32 11.32L139.31 128Z"
      ></path>
    </svg>
  );
}

export function EditSquareOutlineIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M3 21V3h10.925l-2 2H5v14h14v-6.95l2-2V21zm6-6v-4.25L19.625.125L23.8 4.4L13.25 15zM21.025 4.4l-1.4-1.4zM11 13h1.4l5.8-5.8l-.7-.7l-.725-.7L11 11.575zm6.5-6.5l-.725-.7zl.7.7z"
      ></path>
    </svg>
  );
}

export function CarbonSearch(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        fill="currentColor"
        d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9"
      ></path>
    </svg>
  );
}

export function FilterIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        d="M1.75 3.75A.75.75 0 0 1 2.5 3h11a.75.75 0 0 1 0 1.5h-11a.75.75 0 0 1-.75-.75m2 4A.75.75 0 0 1 4.5 7h7a.75.75 0 0 1 0 1.5h-7a.75.75 0 0 1-.75-.75m2 4A.75.75 0 0 1 6.5 11h3a.75.75 0 0 1 0 1.5h-3a.75.75 0 0 1-.75-.75"
      ></path>
    </svg>
  );
}

export function FocusFillIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M20.228 9.06a.743.743 0 0 1-.743-.742V6.489a1.976 1.976 0 0 0-1.98-1.977h-2.752a.743.743 0 0 1-.743-.741a.74.74 0 0 1 .742-.741h2.753a3.518 3.518 0 0 1 2.466 1.027A3.506 3.506 0 0 1 21 6.52v1.829a.751.751 0 0 1-.772.711m-2.723 11.89h-2.752a.743.743 0 0 1-.526-1.265a.743.743 0 0 1 .525-.217h2.753a1.982 1.982 0 0 0 1.98-1.977v-1.809a.741.741 0 0 1 1.268-.524a.74.74 0 0 1 .217.524v1.829a3.506 3.506 0 0 1-1.03 2.461A3.518 3.518 0 0 1 17.476 21zm-8.267 0H6.485a3.498 3.498 0 0 1-2.465-1.025A3.486 3.486 0 0 1 3 17.461v-1.779a.74.74 0 0 1 1.268-.524a.74.74 0 0 1 .217.524v1.829a1.975 1.975 0 0 0 1.98 1.977h2.753a.743.743 0 0 1 .742.741a.74.74 0 0 1-.742.741zM3.743 9.06A.743.743 0 0 1 3 8.317V6.489c0-.923.367-1.81 1.02-2.464A3.498 3.498 0 0 1 6.485 3h2.753a.743.743 0 0 1 .742.741a.74.74 0 0 1-.742.742H6.485a1.982 1.982 0 0 0-1.98 1.977v1.828a.75.75 0 0 1-.762.771m12.94 2.916a4.69 4.69 0 0 1-1.069 2.978a4.704 4.704 0 0 1-5.853 1.16a4.688 4.688 0 0 1-.392-8.045a4.709 4.709 0 0 1 6.96 2.109c.236.57.357 1.181.354 1.798"
      ></path>
    </svg>
  );
}

export function InboxIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162q0-.338-.1-.661l-2.41-7.839a2.25 2.25 0 0 0-2.15-1.588H6.911a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.3 2.3 0 0 0-.1.661"
      ></path>
    </svg>
  );
}

export function ArrowRight2FillIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9 17a1 1 0 0 0 1.707.707l5-5a1 1 0 0 0 0-1.414l-5-5A1 1 0 0 0 9 7z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export function LinkOutlineIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M13.213 9.787a3.39 3.39 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961"
      ></path>
    </svg>
  );
}

export function CopyIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 512 512"
      {...props}
    >
      <rect
        width={336}
        height={336}
        x={128}
        y={128}
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth={32}
        rx={57}
        ry={57}
      ></rect>
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={32}
        d="m383.5 128l.5-24a56.16 56.16 0 0 0-56-56H112a64.19 64.19 0 0 0-64 64v216a56.16 56.16 0 0 0 56 56h24"
      ></path>
    </svg>
  );
}

export function ClockAnimationIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,12,21Z"
      ></path>
      <rect width={2} height={7} x={11} y={6} fill="currentColor" rx={1}>
        <animateTransform
          attributeName="transform"
          dur="9s"
          repeatCount="indefinite"
          type="rotate"
          values="0 12 12;360 12 12"
        ></animateTransform>
      </rect>
      <rect width={2} height={9} x={11} y={11} fill="currentColor" rx={1}>
        <animateTransform
          attributeName="transform"
          dur="0.75s"
          repeatCount="indefinite"
          type="rotate"
          values="0 12 12;360 12 12"
        ></animateTransform>
      </rect>
    </svg>
  );
}

export function SpinnersPulseAnimationIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 24 24"
      {...props}
    >
      <circle cx={12} cy={12} r={0} fill="currentColor">
        <animate
          id="svgSpinnersPulse30"
          fill="freeze"
          attributeName="r"
          begin="0;svgSpinnersPulse32.begin+0.4s"
          calcMode="spline"
          dur="1.2s"
          keySplines=".52,.6,.25,.99"
          values="0;11"
        ></animate>
        <animate
          fill="freeze"
          attributeName="opacity"
          begin="0;svgSpinnersPulse32.begin+0.4s"
          calcMode="spline"
          dur="1.2s"
          keySplines=".52,.6,.25,.99"
          values="1;0"
        ></animate>
      </circle>
      <circle cx={12} cy={12} r={0} fill="currentColor">
        <animate
          id="svgSpinnersPulse31"
          fill="freeze"
          attributeName="r"
          begin="svgSpinnersPulse30.begin+0.4s"
          calcMode="spline"
          dur="1.2s"
          keySplines=".52,.6,.25,.99"
          values="0;11"
        ></animate>
        <animate
          fill="freeze"
          attributeName="opacity"
          begin="svgSpinnersPulse30.begin+0.4s"
          calcMode="spline"
          dur="1.2s"
          keySplines=".52,.6,.25,.99"
          values="1;0"
        ></animate>
      </circle>
      <circle cx={12} cy={12} r={0} fill="currentColor">
        <animate
          id="svgSpinnersPulse32"
          fill="freeze"
          attributeName="r"
          begin="svgSpinnersPulse30.begin+0.8s"
          calcMode="spline"
          dur="1.2s"
          keySplines=".52,.6,.25,.99"
          values="0;11"
        ></animate>
        <animate
          fill="freeze"
          attributeName="opacity"
          begin="svgSpinnersPulse30.begin+0.8s"
          calcMode="spline"
          dur="1.2s"
          keySplines=".52,.6,.25,.99"
          values="1;0"
        ></animate>
      </circle>
    </svg>
  );
}

export function PencilIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        fill="currentColor"
        d="m410.3 231l11.3-11.3l-33.9-33.9l-62.1-62.1l-33.9-33.9l-11.3 11.3l-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2l199.2-199.2zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9l-78.2 23l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7l-14.4 14.5l-22.6 22.6l-11.4 11.3l33.9 33.9l62.1 62.1l33.9 33.9l11.3-11.3l22.6-22.6l14.5-14.5c25-25 25-65.5 0-90.5l-39.3-39.4c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6"
      ></path>
    </svg>
  );
}

export function PlusIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeDasharray={18}
        strokeDashoffset={18}
        strokeLinecap="round"
        strokeWidth={2}
      >
        <path d="M12 5V19">
          <animate
            fill="freeze"
            attributeName="stroke-dashoffset"
            begin="0.4s"
            dur="0.3s"
            values="18;0"
          ></animate>
        </path>
        <path d="M5 12H19">
          <animate
            fill="freeze"
            attributeName="stroke-dashoffset"
            dur="0.3s"
            values="18;0"
          ></animate>
        </path>
      </g>
    </svg>
  );
}

export function LightModeIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={128}
      height={128}
      viewBox="0 0 512 512"
      {...props}
    >
      <defs>
        <linearGradient
          id="meteoconsOvercastDayRainFill0"
          x1={99.5}
          x2={232.6}
          y1={30.7}
          y2={261.4}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#f3f7fe"></stop>
          <stop offset={0.5} stopColor="#f3f7fe"></stop>
          <stop offset={1} stopColor="#deeafb"></stop>
        </linearGradient>
        <linearGradient
          id="meteoconsOvercastDayRainFill1"
          x1={52.7}
          x2={133.4}
          y1={9.6}
          y2={149.3}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#9ca3af"></stop>
          <stop offset={0.5} stopColor="#9ca3af"></stop>
          <stop offset={1} stopColor="#6b7280"></stop>
        </linearGradient>
        <linearGradient
          id="meteoconsOvercastDayRainFill2"
          x1={1381.3}
          x2={1399.5}
          y1={-1144.7}
          y2={-1097.4}
          gradientTransform="rotate(-9 8002.567 8233.063)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#0b65ed"></stop>
          <stop offset={0.5} stopColor="#0a5ad4"></stop>
          <stop offset={1} stopColor="#0950bc"></stop>
        </linearGradient>
        <linearGradient
          id="meteoconsOvercastDayRainFill3"
          x1={1436.7}
          x2={1454.9}
          y1={-1137}
          y2={-1089.7}
          gradientTransform="rotate(-9 8009.537 8233.037)"
          href="#meteoconsOvercastDayRainFill2"
        ></linearGradient>
        <linearGradient
          id="meteoconsOvercastDayRainFill4"
          x1={1492.1}
          x2={1510.3}
          y1={-1129.3}
          y2={-1082.1}
          gradientTransform="rotate(-9 8016.566 8233.078)"
          href="#meteoconsOvercastDayRainFill2"
        ></linearGradient>
        <linearGradient
          id="meteoconsOvercastDayRainFill5"
          x1={78}
          x2={118}
          y1={63.4}
          y2={132.7}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fbbf24"></stop>
          <stop offset={0.5} stopColor="#fbbf24"></stop>
          <stop offset={1} stopColor="#f59e0b"></stop>
        </linearGradient>
        <symbol id="meteoconsOvercastDayRainFill6" viewBox="0 0 196 196">
          <circle
            cx={98}
            cy={98}
            r={40}
            fill="url(#meteoconsOvercastDayRainFill5)"
            stroke="#f8af18"
            strokeMiterlimit={10}
            strokeWidth={4}
          ></circle>
          <path
            fill="none"
            stroke="#fbbf24"
            strokeLinecap="round"
            strokeMiterlimit={10}
            strokeWidth={12}
            d="M98 31.4V6m0 184v-25.4M145.1 51l18-17.9M33 163l18-17.9M51 51L33 33m130.1 130.1l-18-18M6 98h25.4M190 98h-25.4"
          >
            <animateTransform
              additive="sum"
              attributeName="transform"
              dur="6s"
              repeatCount="indefinite"
              type="rotate"
              values="0 98 98; 45 98 98"
            ></animateTransform>
          </path>
        </symbol>
        <symbol id="meteoconsOvercastDayRainFill7" viewBox="0 0 200.3 126.1">
          <path
            fill="url(#meteoconsOvercastDayRainFill1)"
            stroke="#848b98"
            strokeMiterlimit={10}
            d="M.5 93.2a32.4 32.4 0 0 0 32.4 32.4h129.8v-.1l2.3.1a34.8 34.8 0 0 0 6.5-68.9a32.4 32.4 0 0 0-48.5-33a48.6 48.6 0 0 0-88.6 37.1h-1.5A32.4 32.4 0 0 0 .5 93.1Z"
          ></path>
        </symbol>
        <symbol id="meteoconsOvercastDayRainFill8" viewBox="0 0 350 222">
          <path
            fill="url(#meteoconsOvercastDayRainFill0)"
            stroke="#e6effc"
            strokeMiterlimit={10}
            strokeWidth={6}
            d="m291 107l-2.5.1A83.9 83.9 0 0 0 135.6 43A56 56 0 0 0 51 91a56.6 56.6 0 0 0 .8 9A60 60 0 0 0 63 219l4-.2v.2h224a56 56 0 0 0 0-112Z"
          ></path>
        </symbol>
        <symbol id="meteoconsOvercastDayRainFill9" viewBox="0 0 398 222">
          <use
            width={200.3}
            height={126.1}
            href="#meteoconsOvercastDayRainFill7"
            transform="translate(198 27)"
          >
            <animateTransform
              additive="sum"
              attributeName="transform"
              dur="6s"
              repeatCount="indefinite"
              type="translate"
              values="-9 0; 9 0; -9 0"
            ></animateTransform>
          </use>
          <use width={350} height={222} href="#meteoconsOvercastDayRainFill8">
            <animateTransform
              additive="sum"
              attributeName="transform"
              dur="6s"
              repeatCount="indefinite"
              type="translate"
              values="-18 0; 18 0; -18 0"
            ></animateTransform>
          </use>
        </symbol>
        <symbol id="meteoconsOvercastDayRainFilla" viewBox="0 0 410.8 258">
          <use
            width={196}
            height={196}
            href="#meteoconsOvercastDayRainFill6"
          ></use>
          <use
            width={398}
            height={222}
            href="#meteoconsOvercastDayRainFill9"
            transform="translate(12.84 36)"
          ></use>
        </symbol>
        <symbol id="meteoconsOvercastDayRainFillb" viewBox="0 0 129 57">
          <path
            fill="url(#meteoconsOvercastDayRainFill2)"
            stroke="#0a5ad4"
            strokeMiterlimit={10}
            d="M8.5 56.5a8 8 0 0 1-8-8v-40a8 8 0 0 1 16 0v40a8 8 0 0 1-8 8Z"
            opacity={0}
          >
            <animateTransform
              id="meteoconsOvercastDayRainFillc"
              additive="sum"
              attributeName="transform"
              begin="0s; x1.end+.33s"
              dur=".67s"
              type="translate"
              values="0 -60; 0 60"
            ></animateTransform>
            <animate
              id="meteoconsOvercastDayRainFilld"
              attributeName="opacity"
              begin="0s; y1.end+.33s"
              dur=".67s"
              keyTimes="0; .25; 1"
              values="0; 1; 0"
            ></animate>
          </path>
          <path
            fill="url(#meteoconsOvercastDayRainFill3)"
            stroke="#0a5ad4"
            strokeMiterlimit={10}
            d="M64.5 56.5a8 8 0 0 1-8-8v-40a8 8 0 0 1 16 0v40a8 8 0 0 1-8 8Z"
            opacity={0}
          >
            <animateTransform
              id="meteoconsOvercastDayRainFille"
              additive="sum"
              attributeName="transform"
              begin=".33s; x2.end+.33s"
              dur=".67s"
              type="translate"
              values="0 -60; 0 60"
            ></animateTransform>
            <animate
              id="meteoconsOvercastDayRainFillf"
              attributeName="opacity"
              begin=".33s; y2.end+.33s"
              dur=".67s"
              keyTimes="0; .25; 1"
              values="0; 1; 0"
            ></animate>
          </path>
          <path
            fill="url(#meteoconsOvercastDayRainFill4)"
            stroke="#0a5ad4"
            strokeMiterlimit={10}
            d="M120.5 56.5a8 8 0 0 1-8-8v-40a8 8 0 0 1 16 0v40a8 8 0 0 1-8 8Z"
            opacity={0}
          >
            <animateTransform
              id="meteoconsOvercastDayRainFillg"
              additive="sum"
              attributeName="transform"
              begin="-.33s; x3.end+.33s"
              dur=".67s"
              type="translate"
              values="0 -60; 0 60"
            ></animateTransform>
            <animate
              id="meteoconsOvercastDayRainFillh"
              attributeName="opacity"
              begin="-.33s; y3.end+.33s"
              dur=".67s"
              keyTimes="0; .25; 1"
              values="0; 1; 0"
            ></animate>
          </path>
        </symbol>
      </defs>
      <use
        width={410.8}
        height={258}
        href="#meteoconsOvercastDayRainFilla"
        transform="translate(56 109)"
      ></use>
      <use
        width={129}
        height={57}
        href="#meteoconsOvercastDayRainFillb"
        transform="translate(191.5 343.5)"
      ></use>
    </svg>
  );
}

export function DarkModeIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={128}
      height={128}
      viewBox="0 0 512 512"
      {...props}
    >
      <defs>
        <linearGradient
          id="meteoconsStarryNightFill0"
          x1={54.3}
          x2={187.2}
          y1={29}
          y2={259.1}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#86c3db"></stop>
          <stop offset={0.5} stopColor="#86c3db"></stop>
          <stop offset={1} stopColor="#5eafcf"></stop>
        </linearGradient>
        <linearGradient
          id="meteoconsStarryNightFill1"
          x1={294}
          x2={330}
          y1={112.8}
          y2={175.2}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fcd966"></stop>
          <stop offset={0.5} stopColor="#fcd966"></stop>
          <stop offset={1} stopColor="#fccd34"></stop>
        </linearGradient>
        <linearGradient
          id="meteoconsStarryNightFill2"
          x1={295.5}
          x2={316.5}
          y1={185.9}
          y2={222.1}
          href="#meteoconsStarryNightFill1"
        ></linearGradient>
        <linearGradient
          id="meteoconsStarryNightFill3"
          x1={356.3}
          x2={387.7}
          y1={194.8}
          y2={249.2}
          href="#meteoconsStarryNightFill1"
        ></linearGradient>
        <symbol id="meteoconsStarryNightFill4" viewBox="0 0 270 270">
          <path
            fill="url(#meteoconsStarryNightFill0)"
            stroke="#72b9d5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={6}
            d="M252.3 168.6A133.4 133.4 0 0 1 118 36.2A130.5 130.5 0 0 1 122.5 3A133 133 0 0 0 3 134.6C3 207.7 63 267 137.2 267c62.5 0 114.8-42.2 129.8-99.2a135.6 135.6 0 0 1-14.8.8Z"
          >
            <animateTransform
              additive="sum"
              attributeName="transform"
              dur="6s"
              repeatCount="indefinite"
              type="rotate"
              values="-15 135 135; 9 135 135; -15 135 135"
            ></animateTransform>
          </path>
        </symbol>
      </defs>
      <path
        fill="url(#meteoconsStarryNightFill1)"
        stroke="#fcd34d"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m282.8 162.8l25-6.4a1.8 1.8 0 0 1 1.7.5l18.3 18a1.8 1.8 0 0 0 3-1.7l-6.4-25a1.8 1.8 0 0 1 .5-1.7l18-18.4a1.8 1.8 0 0 0-1.8-3l-24.9 6.5a1.8 1.8 0 0 1-1.7-.5l-18.4-18a1.8 1.8 0 0 0-3 1.7l6.5 25a1.8 1.8 0 0 1-.5 1.7l-18 18.3a1.8 1.8 0 0 0 1.7 3Z"
      >
        <animateTransform
          additive="sum"
          attributeName="transform"
          calcMode="spline"
          dur="6s"
          keySplines=".42, 0, .58, 1; .42, 0, .58, 1"
          repeatCount="indefinite"
          type="rotate"
          values="-15 312 144; 15 312 144; -15 312 144"
        ></animateTransform>
        <animate
          attributeName="opacity"
          dur="6s"
          values="1; .75; 1; .75; 1; .75; 1"
        ></animate>
      </path>
      <path
        fill="url(#meteoconsStarryNightFill2)"
        stroke="#fcd34d"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m285.4 193.4l12 12.3a1.2 1.2 0 0 1 .3 1.1l-4.3 16.6a1.2 1.2 0 0 0 2 1.2l12.3-12a1.2 1.2 0 0 1 1.1-.3l16.6 4.3a1.2 1.2 0 0 0 1.2-2l-12-12.3a1.2 1.2 0 0 1-.3-1.1l4.3-16.6a1.2 1.2 0 0 0-2-1.2l-12.3 12a1.2 1.2 0 0 1-1.1.3l-16.7-4.3a1.2 1.2 0 0 0-1.1 2Z"
      >
        <animateTransform
          additive="sum"
          attributeName="transform"
          begin="-.33s"
          calcMode="spline"
          dur="6s"
          keySplines=".42, 0, .58, 1; .42, 0, .58, 1"
          repeatCount="indefinite"
          type="rotate"
          values="-15 306 204; 15 306 204; -15 306 204"
        ></animateTransform>
        <animate
          attributeName="opacity"
          begin="-.33s"
          dur="6s"
          values="1; .75; 1; .75; 1; .75; 1"
        ></animate>
      </path>
      <path
        fill="url(#meteoconsStarryNightFill3)"
        stroke="#fcd34d"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m337.3 223.7l24.8 7a1.8 1.8 0 0 1 1.3 1.2l6.9 24.8a1.8 1.8 0 0 0 3.4 0l7-24.8a1.8 1.8 0 0 1 1.2-1.3l24.8-6.9a1.8 1.8 0 0 0 0-3.4l-24.8-7a1.8 1.8 0 0 1-1.3-1.2l-6.9-24.8a1.8 1.8 0 0 0-3.4 0l-7 24.8a1.8 1.8 0 0 1-1.2 1.3l-24.8 6.9a1.8 1.8 0 0 0 0 3.4Z"
      >
        <animateTransform
          additive="sum"
          attributeName="transform"
          begin="-.67s"
          calcMode="spline"
          dur="6s"
          keySplines=".42, 0, .58, 1; .42, 0, .58, 1"
          repeatCount="indefinite"
          type="rotate"
          values="-15 372 222; 15 372 222; -15 372 222"
        ></animateTransform>
        <animate
          attributeName="opacity"
          begin="-.67s"
          dur="6s"
          values="1; .75; 1; .75; 1; .75; 1"
        ></animate>
      </path>
      <use
        width={270}
        height={270}
        href="#meteoconsStarryNightFill4"
        transform="translate(121 121)"
      ></use>
    </svg>
  );
}

export function PeopleTeamIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 48 48"
      {...props}
    >
      <path
        fill="currentColor"
        d="M18 11a6 6 0 1 1 12 0a6 6 0 0 1-12 0m19-4a5 5 0 1 0 0 10a5 5 0 0 0 0-10M11 7a5 5 0 1 0 0 10a5 5 0 0 0 0-10m8.25 12A4.25 4.25 0 0 0 15 23.25V34a9 9 0 1 0 18 0V23.25A4.25 4.25 0 0 0 28.75 19zM13 23.25c0-1.6.602-3.061 1.592-4.167A4.3 4.3 0 0 0 13.75 19h-5.5A4.25 4.25 0 0 0 4 23.25V33a7 7 0 0 0 10.293 6.179A10.95 10.95 0 0 1 13 34zM35 34c0 1.872-.468 3.635-1.293 5.179A7 7 0 0 0 44 33v-9.75A4.25 4.25 0 0 0 39.75 19h-5.5q-.433.001-.842.083A6.23 6.23 0 0 1 35 23.25z"
      ></path>
    </svg>
  );
}

export function AttachmentIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={128}
      height={128}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="m14.829 7.757l-5.657 5.657a1 1 0 1 0 1.414 1.414l5.657-5.656A3 3 0 0 0 12 4.929l-5.657 5.657a5 5 0 0 0 7.071 7.07L19.071 12l1.414 1.414l-5.656 5.657a7 7 0 0 1-9.9-9.9l5.657-5.656a5 5 0 0 1 7.071 7.07L12 16.244A3 3 0 0 1 7.758 12l5.656-5.657z"
      ></path>
    </svg>
  );
}

export function ImageIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={128}
      height={128}
      viewBox="0 0 32 32"
      {...props}
    >
      <path fill="#2dcc9f" d="M30 5.851v20.298H2V5.851z"></path>
      <path
        fill="#fff"
        d="M24.232 8.541a2.2 2.2 0 1 0 1.127.623a2.2 2.2 0 0 0-1.127-.623M18.111 20.1q-2.724-3.788-5.45-7.575L4.579 23.766h10.9q1.316-1.832 2.634-3.663M22.057 16q-2.793 3.882-5.584 7.765h11.169Q24.851 19.882 22.057 16"
      ></path>
    </svg>
  );
}

export function WordIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={128}
      height={128}
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        fill="#283c82"
        d="M18.536 2.323v2.545c3.4.019 7.12-.035 10.521.019a.783.783 0 0 1 .912.861c.054 6.266-.013 12.89.032 19.157c-.02.4.009 1.118-.053 1.517c-.079.509-.306.607-.817.676c-.286.039-.764.034-1.045.047c-2.792-.014-5.582-.011-8.374-.01h-1.175v2.547L2 27.133V4.873z"
      ></path>
      <path
        fill="#fff"
        d="M18.536 5.822h10.5V26.18h-10.5v-2.545h8.27v-1.272h-8.27v-1.59h8.27V19.5h-8.27v-1.59h8.27v-1.273h-8.27v-1.59h8.27v-1.273h-8.27v-1.59h8.27v-1.273h-8.27v-1.59h8.27V8.048h-8.27zm-9.963 5.621c.6-.035 1.209-.06 1.813-.092c.423 2.147.856 4.291 1.314 6.429c.359-2.208.757-4.409 1.142-6.613a57 57 0 0 0 1.905-.1c-.719 3.082-1.349 6.19-2.134 9.254c-.531.277-1.326-.013-1.956.032c-.423-2.106-.916-4.2-1.295-6.314c-.372 2.061-.856 4.094-1.282 6.136q-.916-.048-1.839-.111c-.528-2.8-1.148-5.579-1.641-8.385q.818-.038 1.635-.067c.328 2.026.7 4.043.986 6.072c.448-2.08.907-4.161 1.352-6.241"
      ></path>
    </svg>
  );
}

export function ExcelIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={128}
      height={128}
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        fill="#20744a"
        fillRule="evenodd"
        d="M28.781 4.405h-10.13V2.018L2 4.588v22.527l16.651 2.868v-3.538h10.13A1.16 1.16 0 0 0 30 25.349V5.5a1.16 1.16 0 0 0-1.219-1.095m.16 21.126H18.617l-.017-1.889h2.487v-2.2h-2.506l-.012-1.3h2.518v-2.2H18.55l-.012-1.3h2.549v-2.2H18.53v-1.3h2.557v-2.2H18.53v-1.3h2.557v-2.2H18.53v-2h10.411Z"
      ></path>
      <path
        fill="#20744a"
        d="M22.487 7.439h4.323v2.2h-4.323zm0 3.501h4.323v2.2h-4.323zm0 3.501h4.323v2.2h-4.323zm0 3.501h4.323v2.2h-4.323zm0 3.501h4.323v2.2h-4.323z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="m6.347 10.673l2.146-.123l1.349 3.709l1.594-3.862l2.146-.123l-2.606 5.266l2.606 5.279l-2.269-.153l-1.532-4.024l-1.533 3.871l-2.085-.184l2.422-4.663z"
      ></path>
    </svg>
  );
}

export function PdfIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={128}
      height={128}
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        fill="#909090"
        d="m24.1 2.072l5.564 5.8v22.056H8.879V30h20.856V7.945z"
      ></path>
      <path fill="#f4f4f4" d="M24.031 2H8.808v27.928h20.856V7.873z"></path>
      <path fill="#7a7b7c" d="M8.655 3.5h-6.39v6.827h20.1V3.5z"></path>
      <path fill="#dd2025" d="M22.472 10.211H2.395V3.379h20.077z"></path>
      <path
        fill="#464648"
        d="M9.052 4.534H7.745v4.8h1.028V7.715L9 7.728a2 2 0 0 0 .647-.117a1.4 1.4 0 0 0 .493-.291a1.2 1.2 0 0 0 .335-.454a2.1 2.1 0 0 0 .105-.908a2.2 2.2 0 0 0-.114-.644a1.17 1.17 0 0 0-.687-.65a2 2 0 0 0-.409-.104a2 2 0 0 0-.319-.026m-.189 2.294h-.089v-1.48h.193a.57.57 0 0 1 .459.181a.92.92 0 0 1 .183.558c0 .246 0 .469-.222.626a.94.94 0 0 1-.524.114m3.671-2.306c-.111 0-.219.008-.295.011L12 4.538h-.78v4.8h.918a2.7 2.7 0 0 0 1.028-.175a1.7 1.7 0 0 0 .68-.491a1.9 1.9 0 0 0 .373-.749a3.7 3.7 0 0 0 .114-.949a4.4 4.4 0 0 0-.087-1.127a1.8 1.8 0 0 0-.4-.733a1.6 1.6 0 0 0-.535-.4a2.4 2.4 0 0 0-.549-.178a1.3 1.3 0 0 0-.228-.017m-.182 3.937h-.1V5.392h.013a1.06 1.06 0 0 1 .6.107a1.2 1.2 0 0 1 .324.4a1.3 1.3 0 0 1 .142.526c.009.22 0 .4 0 .549a3 3 0 0 1-.033.513a1.8 1.8 0 0 1-.169.5a1.1 1.1 0 0 1-.363.36a.67.67 0 0 1-.416.106m5.08-3.915H15v4.8h1.028V7.434h1.3v-.892h-1.3V5.43h1.4v-.892"
      ></path>
      <path
        fill="#dd2025"
        d="M21.781 20.255s3.188-.578 3.188.511s-1.975.646-3.188-.511m-2.357.083a7.5 7.5 0 0 0-1.473.489l.4-.9c.4-.9.815-2.127.815-2.127a14 14 0 0 0 1.658 2.252a13 13 0 0 0-1.4.288Zm-1.262-6.5c0-.949.307-1.208.546-1.208s.508.115.517.939a10.8 10.8 0 0 1-.517 2.434a4.4 4.4 0 0 1-.547-2.162Zm-4.649 10.516c-.978-.585 2.051-2.386 2.6-2.444c-.003.001-1.576 3.056-2.6 2.444M25.9 20.895c-.01-.1-.1-1.207-2.07-1.16a14 14 0 0 0-2.453.173a12.5 12.5 0 0 1-2.012-2.655a11.8 11.8 0 0 0 .623-3.1c-.029-1.2-.316-1.888-1.236-1.878s-1.054.815-.933 2.013a9.3 9.3 0 0 0 .665 2.338s-.425 1.323-.987 2.639s-.946 2.006-.946 2.006a9.6 9.6 0 0 0-2.725 1.4c-.824.767-1.159 1.356-.725 1.945c.374.508 1.683.623 2.853-.91a23 23 0 0 0 1.7-2.492s1.784-.489 2.339-.623s1.226-.24 1.226-.24s1.629 1.639 3.2 1.581s1.495-.939 1.485-1.035"
      ></path>
      <path fill="#909090" d="M23.954 2.077V7.95h5.633z"></path>
      <path fill="#f4f4f4" d="M24.031 2v5.873h5.633z"></path>
      <path
        fill="#fff"
        d="M8.975 4.457H7.668v4.8H8.7V7.639l.228.013a2 2 0 0 0 .647-.117a1.4 1.4 0 0 0 .493-.291a1.2 1.2 0 0 0 .332-.454a2.1 2.1 0 0 0 .105-.908a2.2 2.2 0 0 0-.114-.644a1.17 1.17 0 0 0-.687-.65a2 2 0 0 0-.411-.105a2 2 0 0 0-.319-.026m-.189 2.294h-.089v-1.48h.194a.57.57 0 0 1 .459.181a.92.92 0 0 1 .183.558c0 .246 0 .469-.222.626a.94.94 0 0 1-.524.114m3.67-2.306c-.111 0-.219.008-.295.011l-.235.006h-.78v4.8h.918a2.7 2.7 0 0 0 1.028-.175a1.7 1.7 0 0 0 .68-.491a1.9 1.9 0 0 0 .373-.749a3.7 3.7 0 0 0 .114-.949a4.4 4.4 0 0 0-.087-1.127a1.8 1.8 0 0 0-.4-.733a1.6 1.6 0 0 0-.535-.4a2.4 2.4 0 0 0-.549-.178a1.3 1.3 0 0 0-.228-.017m-.182 3.937h-.1V5.315h.013a1.06 1.06 0 0 1 .6.107a1.2 1.2 0 0 1 .324.4a1.3 1.3 0 0 1 .142.526c.009.22 0 .4 0 .549a3 3 0 0 1-.033.513a1.8 1.8 0 0 1-.169.5a1.1 1.1 0 0 1-.363.36a.67.67 0 0 1-.416.106m5.077-3.915h-2.43v4.8h1.028V7.357h1.3v-.892h-1.3V5.353h1.4v-.892"
      ></path>
    </svg>
  );
}

export function PowerpointIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={128}
      height={128}
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        fill="#d33922"
        d="M18.536 2.321v2.863c3.4.019 7.357-.035 10.754.016c.642 0 .67.568.678 1.064c.054 5.942-.013 12.055.032 18c-.012.234-.006 1.1-.013 1.346c-.022.823-.434.859-1.257.884c-.132 0-.52.006-.648.012c-3.181-.016-6.362-.009-9.546-.009v3.182L2 27.134V4.873z"
      ></path>
      <path
        fill="#fff"
        d="M18.536 6.138h10.5v19.4h-10.5V23h7.634v-1.275h-7.634v-1.59h7.634v-1.272h-7.631q.002-.936-.006-1.87a4.47 4.47 0 0 0 3.82-.375a4.35 4.35 0 0 0 1.959-3.474c-1.4-.01-2.793-.006-4.186-.006c0-1.384.016-2.767-.029-4.148c-.522.1-1.043.21-1.562.321V6.139"
      ></path>
      <path
        fill="#d33922"
        d="M20.766 8.324a4.476 4.476 0 0 1 4.186 4.167c-1.4.016-2.793.01-4.189.01V8.324"
      ></path>
      <path
        fill="#fff"
        d="M7.1 10.726c1.727.083 3.82-.684 5.252.611c1.371 1.664 1.008 4.724-1.024 5.719A4.7 4.7 0 0 1 9 17.348c0 1.244-.006 2.488 0 3.731q-.947-.082-1.893-.159c-.029-3.4-.035-6.8 0-10.2"
      ></path>
      <path
        fill="#d33922"
        d="M8.993 12.446c.627-.029 1.4-.143 1.826.445a2.3 2.3 0 0 1 .041 2.087c-.363.655-1.183.592-1.816.668c-.067-1.066-.06-2.131-.051-3.2"
      ></path>
    </svg>
  );
}

export function PhFolderOpenFill(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={128}
      height={128}
      viewBox="0 0 256 256"
      {...props}
    >
      <path
        fill="currentColor"
        d="M245 110.64a16 16 0 0 0-13-6.64h-16V88a16 16 0 0 0-16-16h-69.33l-27.73-20.8a16.14 16.14 0 0 0-9.6-3.2H40a16 16 0 0 0-16 16v144a8 8 0 0 0 8 8h179.1a8 8 0 0 0 7.59-5.47l28.49-85.47a16.05 16.05 0 0 0-2.18-14.42M93.34 64l29.86 22.4A8 8 0 0 0 128 88h72v16H69.77a16 16 0 0 0-15.18 10.94L40 158.7V64Z"
      ></path>
    </svg>
  );
}

export function DocumentTextSolid(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={128}
      height={128}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor">
        <path
          fillRule="evenodd"
          d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5zM7.5 15a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5h-7.5A.75.75 0 0 1 7.5 15m.75 2.25a.75.75 0 0 0 0 1.5H12a.75.75 0 0 0 0-1.5z"
          clipRule="evenodd"
        ></path>
        <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279a9.77 9.77 0 0 0-6.963-6.963"></path>
      </g>
    </svg>
  );
}

export function TextCaseTitle16Filled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={128}
      height={128}
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        d="M5.704 2.74a.75.75 0 0 0-1.408 0l-3.5 9.5a.75.75 0 0 0 1.408.52l.924-2.51h3.744l.924 2.51a.75.75 0 1 0 1.408-.52zm.615 6.01H3.681l1.32-3.58zm4.181-6.5a.75.75 0 0 1 .75.75v4.294A2.24 2.24 0 0 1 12.354 7c1.438 0 2.604 1.4 2.604 3.125s-1.166 3.125-2.604 3.125c-.438 0-.851-.13-1.214-.36a.75.75 0 0 1-1.39-.39V3a.75.75 0 0 1 .75-.75m.75 7.875c0 1.165.739 1.625 1.104 1.625s1.104-.46 1.104-1.625S12.72 8.5 12.354 8.5s-1.104.46-1.104 1.625"
      ></path>
    </svg>
  );
}

export function DeleteIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={128}
      height={128}
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        fill="currentColor"
        d="M13.5 6.5V7h5v-.5a2.5 2.5 0 0 0-5 0m-2 .5v-.5a4.5 4.5 0 1 1 9 0V7H28a1 1 0 1 1 0 2h-1.508L24.6 25.568A5 5 0 0 1 19.63 30h-7.26a5 5 0 0 1-4.97-4.432L5.508 9H4a1 1 0 0 1 0-2zm2.5 6.5a1 1 0 1 0-2 0v10a1 1 0 1 0 2 0zm5-1a1 1 0 0 0-1 1v10a1 1 0 1 0 2 0v-10a1 1 0 0 0-1-1"
      ></path>
    </svg>
  );
}

export function DocumentsIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M16.519 16.501c.175-.136.334-.295.651-.612l3.957-3.958c.096-.095.052-.26-.075-.305a4.332 4.332 0 0 1-1.644-1.034a4.332 4.332 0 0 1-1.034-1.644c-.045-.127-.21-.171-.305-.075L14.11 12.83c-.317.317-.476.476-.612.651c-.161.207-.3.43-.412.666c-.095.2-.166.414-.308.84l-.184.55l-.292.875l-.273.82a.584.584 0 0 0 .738.738l.82-.273l.875-.292l.55-.184c.426-.142.64-.212.84-.308c.236-.113.46-.25.666-.412m5.849-5.809a2.163 2.163 0 1 0-3.06-3.059l-.126.128a.524.524 0 0 0-.148.465c.02.107.055.265.12.452c.13.375.376.867.839 1.33a3.5 3.5 0 0 0 1.33.839c.188.065.345.1.452.12a.525.525 0 0 0 .465-.148z"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.172 3.172C3 4.343 3 6.229 3 10v4c0 3.771 0 5.657 1.172 6.828C5.343 22 7.229 22 11 22h2c3.771 0 5.657 0 6.828-1.172C20.981 19.676 21 17.832 21 14.18l-2.818 2.818c-.27.27-.491.491-.74.686a5.107 5.107 0 0 1-.944.583a8.163 8.163 0 0 1-.944.355l-2.312.771a2.083 2.083 0 0 1-2.635-2.635l.274-.82l.475-1.426l.021-.066c.121-.362.22-.658.356-.944c.16-.335.355-.651.583-.943c.195-.25.416-.47.686-.74l4.006-4.007L18.12 6.7l.127-.127A3.651 3.651 0 0 1 20.838 5.5c-.151-1.03-.444-1.763-1.01-2.328C18.657 2 16.771 2 13 2h-2C7.229 2 5.343 2 4.172 3.172M7.25 9A.75.75 0 0 1 8 8.25h6.5a.75.75 0 0 1 0 1.5H8A.75.75 0 0 1 7.25 9m0 4a.75.75 0 0 1 .75-.75h2.5a.75.75 0 0 1 0 1.5H8a.75.75 0 0 1-.75-.75m0 4a.75.75 0 0 1 .75-.75h1.5a.75.75 0 0 1 0 1.5H8a.75.75 0 0 1-.75-.75"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export function SpinnerIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={128}
      height={128}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
        opacity={0.25}
      ></path>
      <path
        fill="currentColor"
        d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"
      >
        <animateTransform
          attributeName="transform"
          dur="0.75s"
          repeatCount="indefinite"
          type="rotate"
          values="0 12 12;360 12 12"
        ></animateTransform>
      </path>
    </svg>
  );
}

export function SpinnerDotIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={128}
      height={128}
      viewBox="0 0 24 24"
      {...props}
    >
      <circle cx={4} cy={12} r={3} fill="currentColor">
        <animate
          id="svgSpinners3DotsFade0"
          fill="freeze"
          attributeName="opacity"
          begin="0;svgSpinners3DotsFade1.end-0.25s"
          dur="0.75s"
          values="1;0.2"
        ></animate>
      </circle>
      <circle cx={12} cy={12} r={3} fill="currentColor" opacity={0.4}>
        <animate
          fill="freeze"
          attributeName="opacity"
          begin="svgSpinners3DotsFade0.begin+0.15s"
          dur="0.75s"
          values="1;0.2"
        ></animate>
      </circle>
      <circle cx={20} cy={12} r={3} fill="currentColor" opacity={0.3}>
        <animate
          id="svgSpinners3DotsFade1"
          fill="freeze"
          attributeName="opacity"
          begin="svgSpinners3DotsFade0.begin+0.3s"
          dur="0.75s"
          values="1;0.2"
        ></animate>
      </circle>
    </svg>
  );
}

export function CloseCircleFillIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={128}
      height={128}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12m7.293-2.707a1 1 0 0 1 1.414 0L12 10.586l1.293-1.293a1 1 0 1 1 1.414 1.414L13.414 12l1.293 1.293a1 1 0 0 1-1.414 1.414L12 13.414l-1.293 1.293a1 1 0 0 1-1.414-1.414L10.586 12l-1.293-1.293a1 1 0 0 1 0-1.414"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export function DeniedIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={128}
      height={128}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M12 2c5.5 0 10 4.5 10 10s-4.5 10-10 10S2 17.5 2 12S6.5 2 12 2m0 2c-1.9 0-3.6.6-4.9 1.7l11.2 11.2c1-1.4 1.7-3.1 1.7-4.9c0-4.4-3.6-8-8-8m4.9 14.3L5.7 7.1C4.6 8.4 4 10.1 4 12c0 4.4 3.6 8 8 8c1.9 0 3.6-.6 4.9-1.7"
      ></path>
    </svg>
  );
}

export function CloseFillIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={128}
      height={128}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
        <path
          fill="currentColor"
          d="m12 14.122l5.303 5.303a1.5 1.5 0 0 0 2.122-2.122L14.12 12l5.304-5.303a1.5 1.5 0 1 0-2.122-2.121L12 9.879L6.697 4.576a1.5 1.5 0 1 0-2.122 2.12L9.88 12l-5.304 5.304a1.5 1.5 0 1 0 2.122 2.12z"
        ></path>
      </g>
    </svg>
  );
}

export function FavoriteIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={128}
      height={128}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M12 2.5a1 1 0 0 1 .894.553l2.58 5.158l5.67.824a1 1 0 0 1 .554 1.706l-4.127 4.024l.928 5.674a1 1 0 0 1-1.455 1.044L12 18.807l-5.044 2.676a1 1 0 0 1-1.455-1.044l.928-5.674l-4.127-4.024a1 1 0 0 1 .554-1.706l5.67-.824l2.58-5.158A1 1 0 0 1 12 2.5m0 3.236l-1.918 3.836a1 1 0 0 1-.75.543l-4.184.608l3.05 2.973a1 1 0 0 1 .289.878L7.8 18.771l3.731-1.98a1 1 0 0 1 .938 0l3.731 1.98l-.687-4.197a1 1 0 0 1 .289-.877l3.05-2.974l-4.183-.608a1 1 0 0 1-.75-.543z"
      ></path>
    </svg>
  );
}

export function FavoriteFilledIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={128}
      height={128}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M22 10.1c.1-.5-.3-1.1-.8-1.1l-5.7-.8L12.9 3c-.1-.2-.2-.3-.4-.4c-.5-.3-1.1-.1-1.4.4L8.6 8.2L2.9 9c-.3 0-.5.1-.6.3c-.4.4-.4 1 0 1.4l4.1 4l-1 5.7c0 .2 0 .4.1.6c.3.5.9.7 1.4.4l5.1-2.7l5.1 2.7c.1.1.3.1.5.1h.2c.5-.1.9-.6.8-1.2l-1-5.7l4.1-4c.2-.1.3-.3.3-.5"
      ></path>
    </svg>
  );
}

export function DocumentTextOutlineIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={128}
      height={128}
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth={32}
        d="M416 221.25V416a48 48 0 0 1-48 48H144a48 48 0 0 1-48-48V96a48 48 0 0 1 48-48h98.75a32 32 0 0 1 22.62 9.37l141.26 141.26a32 32 0 0 1 9.37 22.62Z"
      ></path>
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={32}
        d="M256 56v120a32 32 0 0 0 32 32h120m-232 80h160m-160 80h160"
      ></path>
    </svg>
  );
}

export function ArrowRightIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={128}
      height={128}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m10 17l5-5m0 0l-5-5"
      ></path>
    </svg>
  );
}

export function TablerListIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M13 5h8m-8 4h5m-5 6h8m-8 4h5M3 5a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1zm0 10a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1z"
      ></path>
    </svg>
  );
}

export function AlertLoopAnimationIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={2}
      >
        <g>
          <path strokeDasharray={4} strokeDashoffset={4} d="M12 3V5">
            <animate
              fill="freeze"
              attributeName="stroke-dashoffset"
              dur="0.2s"
              values="4;0"
            ></animate>
          </path>
          <path
            strokeDasharray={28}
            strokeDashoffset={28}
            d="M12 5C8.68629 5 6 7.68629 6 11L6 17C5 17 4 18 4 19H12M12 5C15.3137 5 18 7.68629 18 11L18 17C19 17 20 18 20 19H12"
          >
            <animate
              fill="freeze"
              attributeName="stroke-dashoffset"
              begin="0.2s"
              dur="0.4s"
              values="28;0"
            ></animate>
          </path>
          <animateTransform
            attributeName="transform"
            begin="0.8s"
            dur="6s"
            keyTimes="0;0.05;0.15;0.2;1"
            repeatCount="indefinite"
            type="rotate"
            values="0 12 3;3 12 3;-3 12 3;0 12 3;0 12 3"
          ></animateTransform>
        </g>
        <path
          strokeDasharray={8}
          strokeDashoffset={8}
          d="M10 20C10 21.1046 10.8954 22 12 22C13.1046 22 14 21.1046 14 20"
        >
          <animate
            fill="freeze"
            attributeName="stroke-dashoffset"
            begin="0.6s"
            dur="0.2s"
            values="8;0"
          ></animate>
          <animateTransform
            attributeName="transform"
            begin="1s"
            dur="6s"
            keyTimes="0;0.05;0.15;0.2;1"
            repeatCount="indefinite"
            type="rotate"
            values="0 12 8;6 12 8;-6 12 8;0 12 8;0 12 8"
          ></animateTransform>
        </path>
        <path strokeDasharray={8} strokeDashoffset={8} d="M22 6v4" opacity={0}>
          <set attributeName="opacity" begin="1s" to={1}></set>
          <animate
            fill="freeze"
            attributeName="stroke-dashoffset"
            begin="1s"
            dur="0.2s"
            values="8;0"
          ></animate>
          <animate
            attributeName="stroke-width"
            begin="1.7s"
            dur="3s"
            keyTimes="0;0.1;0.2;0.3;1"
            repeatCount="indefinite"
            values="2;3;3;2;2"
          ></animate>
        </path>
      </g>
      <circle cx={22} cy={14} r={1} fill="currentColor" fillOpacity={0}>
        <animate
          fill="freeze"
          attributeName="fill-opacity"
          begin="1s"
          dur="0.4s"
          values="0;1"
        ></animate>
        <animate
          attributeName="r"
          begin="2s"
          dur="3s"
          keyTimes="0;0.1;0.2;0.3;1"
          repeatCount="indefinite"
          values="1;2;2;1;1"
        ></animate>
      </circle>
    </svg>
  );
}

export function CircleProgressIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="45"
      height="45"
      viewBox="-5.625 -5.625 56.25 56.25"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: 'rotate(-90deg)' }}
      {...props}
    >
      <circle
        r="12.5"
        cx="22.5"
        cy="22.5"
        fill="transparent"
        stroke="#e0e0e0"
        strokeWidth="1"
        strokeDasharray="78.5px"
        strokeDashoffset="0"
      ></circle>
      <circle
        r="12.5"
        cx="22.5"
        cy="22.5"
        stroke="#76e5b1"
        strokeWidth="3"
        strokeLinecap="round"
        strokeDashoffset="38px"
        fill="transparent"
        strokeDasharray="78.5px"
      ></circle>
    </svg>
  );
}
