import React, { useState } from 'react';

import classNames from 'classnames';

import { Button } from '@/components/Button';
import { Label } from '@/components/Label';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/Popover';
import { ScrollArea } from '@/components/ScrollArea';
import {
  LevelHighIcon,
  LevelLowIcon,
  LevelMediumIcon,
  LevelUrgentIcon,
  NoneSelectedIcon,
} from '@/icons/core/issue';
import {
  BasePriorities,
  getNameByPriority,
} from '@/services/issue/issue-utils';
import { PriorityType } from '@/types/tanstack-query/generated';
import { IPriority } from '@/types/tanstack-query/issue/priority.interface';
import { mayBeMatch } from '@/utils/custom-matcher';

import CheckedIcon from '../../../../icons/checked';

const RenderPriorityIcon = (props: {
  type: PriorityType | undefined | null;
}) => {
  return mayBeMatch([props.type ?? ''])({
    [PriorityType.High]: () => <LevelHighIcon width={20} height={20} />,
    [PriorityType.Medium]: () => <LevelMediumIcon width={20} height={20} />,
    [PriorityType.Low]: () => <LevelLowIcon width={20} height={20} />,
    [PriorityType.NoPriority]: () => (
      <NoneSelectedIcon width={20} height={20} />
    ),
    [PriorityType.Urgent]: () => <LevelUrgentIcon width={20} height={20} />,
    orElse: () => (
      <NoneSelectedIcon width={20} height={20} style={{ color: 'black' }} />
    ),
  });
};

const OrderButton = (props: {
  data: IPriority[];
  selected: PriorityType | undefined;
  onSelected: (value: PriorityType) => void;
}) => {
  return (
    <div>
      {BasePriorities.map((item) => {
        return (
          <Button
            key={item.type}
            type="button"
            variant={item.type === props.selected ? 'secondary' : 'ghost'}
            className="flex h-10 w-full flex-row items-center gap-2 px-[5px] text-left"
            onClick={() => props.onSelected(item.type)}
          >
            <div className="flex w-[24px] flex-none items-center justify-center">
              {<RenderPriorityIcon type={item.type} />}
            </div>
            <div className="grow">
              <Label>{getNameByPriority(item.type)}</Label>
            </div>
            <div
              className={classNames(
                'w-[16px] flex-none text-center opacity-0',
                {
                  'opacity-100': item.type === props.selected,
                },
              )}
            >
              <CheckedIcon />
            </div>
          </Button>
        );
      })}
    </div>
  );
};

const PriorityButton = (props: {
  title?: string;
  selected: PriorityType | undefined;
  onSelected: (value: PriorityType) => void;
  renderItem: (value: IPriority | undefined) => React.ReactNode;
}) => {
  const selectedPriority = props.selected
    ? BasePriorities.find((item) => item.type === props.selected)
    : BasePriorities[0];
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Popover modal={true} open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        {props.renderItem(selectedPriority)}
      </PopoverTrigger>

      <PopoverContent className="p-[5px]">
        {props.title && (
          <div className="w-full px-[8px] pt-[4px]">
            <Label className="line-clamp-1 max-w-[250px]">{props.title}</Label>
          </div>
        )}
        <ScrollArea className="h-full max-h-[300px] w-full">
          <OrderButton
            data={BasePriorities}
            onSelected={(data) => {
              props.onSelected(data);
              setIsOpen(false);
            }}
            selected={props.selected}
          />
        </ScrollArea>
      </PopoverContent>
    </Popover>
  );
};
const MemoizedPriorityButton = React.memo(PriorityButton);
const MemoizedRenderPriorityIcon = React.memo(RenderPriorityIcon);
export { MemoizedPriorityButton, MemoizedRenderPriorityIcon };
