export const CalcPercent = (props: { value: number; total: number }) => {
  try {
    if (props.total === 0) {
      return 0;
    }
    return Math.round((props.value / props.total) * 100);
  } catch (error) {
    return 0;
  }
};

export const CalcPercentOfProject = (props: {
  started: number;
  completed: number;
  total: number;
}) => {
  if (props.total === 0) {
    return 0;
  }
  const percentStarted = Math.round((props.started / props.total) * 100);
  const percentCompleted = Math.round((props.completed / props.total) * 100);

  return Math.round(percentStarted / 2) + percentCompleted;
};
