import { useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { useFilePicker } from 'use-file-picker';
import {
  FileAmountLimitValidator,
  FileSizeValidator,
} from 'use-file-picker/validators';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/Accordion';
import { Button } from '@/components/Button';
import { Card } from '@/components/Card';
import { Label } from '@/components/Label';
import FileMimeType from '@/components/MimeType';
import { Table, TableBody, TableCell, TableRow } from '@/components/Table';
import { TooltipRoot } from '@/components/Tooltip';
import { UserAvatar } from '@/components/UserAvatar';
import { useErrorHandler } from '@/hooks/useToasterHandler';
import { DeleteIcon, SpinnerDotIcon } from '@/icons/core';
import { MemoizedRenderMember } from '@/renders/RenderMember';
import { InvalidInputError } from '@/services/core/error-type';
import { CreateIssueAttachmentDocument } from '@/types/apollo-client/issue-attachments/graphql/mutations.graphql.generated';
import {
  useDeleteIssueAttachmentMutation,
  useGetIssueAttachmentsQuery,
  IssueAttachmentFragment,
} from '@/types/tanstack-query/generated';
import { formatBytes } from '@/utils/format-bytes';
import CustomDateTime from '@/utils/format-datetime';

import useAttachments from './useAttachments';

const AttachmentItem = (props: { attachment: IssueAttachmentFragment }) => {
  const { showErrorToast } = useErrorHandler();
  const deleteItem = useDeleteIssueAttachmentMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {},
    onSettled: () => {},
  });
  return (
    <>
      <TableRow key={props.attachment.id}>
        <TableCell>
          <a target="_blank" href={props.attachment.file?.path}>
            <div className="flex flex-row items-center gap-1">
              <div className="w-[20px]">
                <FileMimeType type={props.attachment.file?.mime_type ?? ''} />
              </div>
              {props.attachment.file?.name}
              <div>
                <Label className="opacity-50">
                  ({formatBytes(props.attachment.file?.size ?? 0)})
                </Label>
              </div>
            </div>
          </a>
        </TableCell>
        <TableCell width={55}>
          {props.attachment.created_at && (
            <TooltipRoot
              content={`Created at ${CustomDateTime.convertStringToDateTime(
                props.attachment.created_at,
              )}`}
            >
              <Label className="block w-full text-center text-xs">
                {CustomDateTime.convertStringToDateTime(
                  props.attachment.created_at,
                  'LLL dd',
                )}
              </Label>
            </TooltipRoot>
          )}
        </TableCell>

        <TableCell width={40}>
          <MemoizedRenderMember
            id={props.attachment.created_by}
            renderItem={(member) => (
              <UserAvatar size={'sm'} fallback={member?.username} />
            )}
          />
        </TableCell>
        <TableCell width={40}>
          {deleteItem.isPending ? (
            <SpinnerDotIcon width={18} height={18} />
          ) : (
            <DeleteIcon
              onClick={() => deleteItem.mutate({ id: props.attachment.id })}
              width={16}
              height={16}
              className={'cursor-pointer opacity-70'}
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

const AttachmentList = (props: { attachments: IssueAttachmentFragment[] }) => {
  return (
    <Table>
      <TableBody>
        {props.attachments.map((item) => (
          <AttachmentItem key={item.id} attachment={item} />
        ))}
      </TableBody>
    </Table>
  );
};

const AttachmentsComponent = (props: {
  issue_id: string;
  workspace_id: string;
  disabled: boolean;
}) => {
  const { showErrorToast } = useErrorHandler();
  const { event, attachments } = useAttachments();
  const getAttachments = useGetIssueAttachmentsQuery(
    {
      getInput: {
        workspace_id: props.workspace_id,
        issue_id: props.issue_id,
      },
    },
    { enabled: !!props.issue_id && !!props.workspace_id },
  );
  const [createAttachment, { loading }] = useMutation(
    CreateIssueAttachmentDocument,
  );
  // const createAttachment = useCreateIssueAttachmentMutation({
  //   onMutate: () => {},
  //   onError: (res) => {
  //     showErrorToast({ error: res });
  //   },
  //   onSuccess: () => {},
  //   onSettled: () => {},
  // });

  const onAttachment = async (file: File) => {
    if (file) {
      createAttachment({
        variables: {
          createInput: {
            issue_id: props.issue_id,
            file: file,
            workspace_id: props.workspace_id,
          },
        },
      });
    }
  };

  const { openFilePicker } = useFilePicker({
    readAs: 'DataURL',
    multiple: false,
    validators: [
      new FileAmountLimitValidator({ max: 1 }),

      new FileSizeValidator({ maxFileSize: 50 * 1024 * 1024 /* 50 MB */ }),
    ],

    onFilesRejected: () => {
      showErrorToast({
        error: new InvalidInputError('Input size invalid'),
      });
    },
    onFilesSuccessfullySelected: ({ plainFiles }) => {
      if (plainFiles[0]) {
        onAttachment(plainFiles[0]);
      }
    },
  });

  useEffect(() => {
    event.storeAttachments(getAttachments.data?.issueAttachments ?? []);
  }, [getAttachments.data?.issueAttachments]);

  return (
    <div className="flex w-full flex-col">
      <Card className="mt-[10px] w-full p-0">
        <Accordion type="single" collapsible={true} defaultValue={'team'}>
          <AccordionItem value={'team'}>
            <Button
              disabled={props.disabled}
              size={'sm'}
              type="button"
              variant={'ghost'}
              className="flex  h-[30px] w-full flex-row justify-start px-[10px] text-left"
            >
              <AccordionTrigger className="flex-none">
                <Label className="text-xs opacity-50">Attachments</Label>
              </AccordionTrigger>
              <Button
                type="button"
                size={'sm'}
                variant={'ghost'}
                disabled={loading || props.disabled}
                onClick={() => openFilePicker()}
              >
                <div className="flex flex-row items-center gap-2">
                  {loading && <SpinnerDotIcon width={18} height={18} />}
                  upload
                </div>
              </Button>
            </Button>
            <AccordionContent>
              <div className="flex w-full flex-col">
                <AttachmentList attachments={attachments} />
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </Card>
    </div>
  );
};

export default AttachmentsComponent;
