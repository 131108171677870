import { useState } from 'react';

import { useSubscription } from '@apollo/client';

import {
  OnSubscriptionsDocument,
  OnSubscriptionsSubscriptionVariables,
} from '@/types/apollo-client/subscription/graphql/subscriptions.graphql.generated';

import SubscriptionContext from './SubscriptionContext';

const Subscriptions = (props: { children?: React.ReactNode }) => {
  const [workspaceId, setWorkspaceId] = useState<string>();
  const { data: dataSub } = useSubscription(OnSubscriptionsDocument, {
    skip: !workspaceId,
    variables: {
      workspace_id: workspaceId,
    } as OnSubscriptionsSubscriptionVariables,
  });

  return (
    <SubscriptionContext.Provider
      value={{
        pubsub: dataSub?.subscribes,
        onConnect: (value) => setWorkspaceId(value),
      }}
    >
      {props.children}
    </SubscriptionContext.Provider>
  );
};

const SubscriptionWrapper = (props: { children?: React.ReactNode }) => {
  return <Subscriptions>{props.children}</Subscriptions>;
};

export default SubscriptionWrapper;
