import * as Types from '../../types';

import { gql } from '@apollo/client';
import { DocumentAttachmentFragmentDoc } from './fragments.graphql.generated';
import * as Apollo from '@apollo/client';
export type CreateDocumentAttachmentMutationVariables = Types.Exact<{
  createInput: Types.CreateUploadDocumentAttachmentInput;
}>;


export type CreateDocumentAttachmentMutation = { __typename?: 'Mutation', createDocumentAttachment: { __typename?: 'DocumentAttachmentEntity', id: string, document_id: string, workspace_id?: string | null, file_id: string, created_by: string, created_at?: string | null, file?: { __typename?: 'FileEntity', id: string, name: string, path: string, mime_type: string, size: number, workspace_id?: string | null, created_at?: string | null } | null, user?: { __typename?: 'UserEntity', email: string, username?: string | null, id: string, image?: string | null, full_name?: string | null, theme?: Types.ThemeType | null } | null } };


export const CreateDocumentAttachmentDocument = gql`
    mutation createDocumentAttachment($createInput: CreateUploadDocumentAttachmentInput!) {
  createDocumentAttachment(createInput: $createInput) {
    ...DocumentAttachment
  }
}
    ${DocumentAttachmentFragmentDoc}`;
export type CreateDocumentAttachmentMutationFn = Apollo.MutationFunction<CreateDocumentAttachmentMutation, CreateDocumentAttachmentMutationVariables>;
export type CreateDocumentAttachmentMutationResult = Apollo.MutationResult<CreateDocumentAttachmentMutation>;
export type CreateDocumentAttachmentMutationOptions = Apollo.BaseMutationOptions<CreateDocumentAttachmentMutation, CreateDocumentAttachmentMutationVariables>;