import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/Accordion';
import { Button } from '@/components/Button';
import { Card } from '@/components/Card';
import { Label } from '@/components/Label';
import { IDocumentFragment } from '@/types/tanstack-query/generated';

import DocumentTableList from '../DocumentTableList/DocumentTableList';

const SubDocumentsComponent = (props: { data: IDocumentFragment[] }) => {
  return (
    <Card className="mt-[10px] p-0">
      <Accordion type="single" collapsible={true} defaultValue={'team'}>
        <AccordionItem value={'team'}>
          <Button
            size={'sm'}
            type="button"
            variant={'ghost'}
            className="flex  h-[30px] w-full flex-row justify-start px-[10px] text-left"
          >
            <AccordionTrigger className="flex-none">
              <Label className="text-xs opacity-50">Sub-documents</Label>
            </AccordionTrigger>
          </Button>
          <AccordionContent>
            <div className="flex w-full flex-col">
              <DocumentTableList documents={props.data} isSubDocuments={true} />
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </Card>
  );
};

export default SubDocumentsComponent;
