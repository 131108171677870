import { useContext, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { create } from 'zustand';

import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { ITeamData } from '@/types/tanstack-query/team/team.interface';

type IPageState = {
  team: ITeamData | undefined;
  setTeamData: (value: ITeamData) => void;
};

const createStore = () =>
  create<IPageState>((set) => ({
    team: undefined,
    setTeamData: (value: ITeamData) =>
      set({
        team: value,
      }),
  }));

const useTeam = () => {
  const params = useParams();
  const [useStore] = useState(createStore);
  const { getDataByTeam } = useContext(WorkspaceContext);
  const { team, setTeamData } = useStore();

  useEffect(() => {
    if (params.team && getDataByTeam) {
      const getTeam = getDataByTeam({ team_identifier: params.team });
      setTeamData(getTeam);
    }
  }, [params.team]);

  return {
    team,
  };
};

export default useTeam;
