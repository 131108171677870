import { useContext, useEffect, useState } from 'react';

import orderBy from 'lodash/orderBy';

import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { IssueFragment, TeamFragment } from '@/types/tanstack-query/generated';

const useIssueNavigation = (props: {
  team: TeamFragment;
  issue: IssueFragment;
  onNavigation: (issue: IssueFragment) => void;
}) => {
  const { getIssuesByTeam, issues } = useContext(WorkspaceContext);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [issuesByTeam, setIssueByTeam] = useState<IssueFragment[]>([]);

  const getIssueByIndex = (index: number) => {
    const getIssue = issuesByTeam[index];
    if (getIssue) {
      setCurrentIndex(index);
      props.onNavigation(getIssue);
    }
  };

  useEffect(() => {
    const getIssues = orderBy(getIssuesByTeam?.({ team_id: props.team.id }), [
      'code',
    ]);
    const findIndex = getIssues?.findIndex(
      (item) => item.code === props.issue.code,
    );
    setIssueByTeam(getIssues);
    setTotal(getIssues?.length ?? 0);
    if (findIndex && findIndex >= 0) {
      setCurrentIndex(findIndex);
    }
  }, [props.issue, props.team, issues]);

  return {
    currentIndex,
    total: total,
    event: {
      getIssueByIndex,
    },
  };
};

export default useIssueNavigation;
