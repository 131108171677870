import { useEffect, useState } from 'react';

import { IssueFragment } from '@/types/tanstack-query/generated';

import {
  IConditionFilter,
  ConditionFilterList,
} from '../pages/issue/constant/constant';

const useIssuesFilters = () => {
  const [issues, setIssues] = useState<IssueFragment[]>([]);

  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [selectedConditionStatus, setSelectedConditionStatus] =
    useState<IConditionFilter>(ConditionFilterList[0]!);

  const [selectedPriorities, setSelectedPriorities] = useState<string[]>([]);
  const [selectedConditionPriority, setSelectedConditionPriority] =
    useState<IConditionFilter>(ConditionFilterList[0]!);

  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [selectedConditionLabel, setSelectedConditionLabel] =
    useState<IConditionFilter>(ConditionFilterList[0]!);

  const [selectedAssignees, setSelectedAssignees] = useState<string[]>([]);
  const [selectedConditionAssignee, setSelectedConditionAssignee] =
    useState<IConditionFilter>(ConditionFilterList[0]!);
  const [filterIssues, setFilterIssues] = useState<IssueFragment[]>([]);
  useEffect(() => {
    let filter = issues;

    // Filter Status
    if (selectedConditionStatus.value === 'or' && selectedStatuses.length > 0) {
      filter = filter.filter(
        (item) =>
          item.workflow_id && selectedStatuses.includes(item.workflow_id),
      );
    }
    if (
      selectedConditionStatus.value === 'or_not' &&
      selectedStatuses.length > 0
    ) {
      filter = filter.filter(
        (item) =>
          item.workflow_id && !selectedStatuses.includes(item.workflow_id),
      );
    }
    // Filter Priority
    if (
      selectedConditionPriority.value === 'or' &&
      selectedPriorities.length > 0
    ) {
      filter = filter.filter(
        (item) => item.priority && selectedPriorities.includes(item.priority),
      );
    }
    if (
      selectedConditionPriority.value === 'or_not' &&
      selectedPriorities.length > 0
    ) {
      filter = filter.filter(
        (item) => item.priority && !selectedPriorities.includes(item.priority),
      );
    }
    // Filter Label
    if (selectedConditionLabel.value === 'or' && selectedLabels.length > 0) {
      filter = filter.filter((item) => {
        if (item.labels && item.labels.length > 0) {
          const verify = selectedLabels.filter((x) =>
            item.labels?.some((label) => label.label_id === x),
          );

          return verify.length > 0;
        }
        return false;
      });
    }
    if (
      selectedConditionLabel.value === 'or_not' &&
      selectedLabels.length > 0
    ) {
      filter = filter.filter((item) => {
        if (item.labels && item.labels.length > 0) {
          const verify = selectedLabels.filter((x) =>
            item.labels?.some((label) => label.label_id === x),
          );

          return verify.length === 0;
        }
        return false;
      });
    }
    // Filter Assignee
    if (
      selectedConditionAssignee.value === 'or' &&
      selectedAssignees.length > 0
    ) {
      filter = filter.filter(
        (item) =>
          item.assignee_id && selectedAssignees.includes(item.assignee_id),
      );
    }
    if (
      selectedConditionAssignee.value === 'or_not' &&
      selectedAssignees.length > 0
    ) {
      filter = filter.filter(
        (item) =>
          item.assignee_id && !selectedAssignees.includes(item.assignee_id),
      );
    }

    setFilterIssues(filter);
  }, [
    selectedStatuses,
    selectedConditionStatus,
    selectedPriorities,
    selectedConditionPriority,
    selectedLabels,
    selectedConditionLabel,
    selectedAssignees,
    selectedConditionAssignee,
    issues,
  ]);

  return {
    issues: filterIssues,
    selectedStatuses,
    selectedConditionStatus,
    selectedPriorities,
    selectedConditionPriority,
    selectedLabels,
    selectedConditionLabel,
    selectedAssignees,
    selectedConditionAssignee,
    event: {
      setIssues,
      setSelectedStatuses,
      setSelectedConditionStatus,
      setSelectedPriorities,
      setSelectedConditionPriority,
      setSelectedLabels,
      setSelectedConditionLabel,
      setSelectedAssignees,
      setSelectedConditionAssignee,
    },
  };
};

export default useIssuesFilters;
