import { useContext, useEffect, useState } from 'react';

import orderBy from 'lodash/orderBy';
import { create } from 'zustand';

import { ErrorType } from '@/services/core/error-type';
import {
  PushEventType,
  RoleType,
  TeamFragment,
  LabelFragment,
  WorkflowFragment,
  UserFragment,
  WorkspaceCoreFragment,
  WorkspaceFragment,
  ProjectStatusFragment,
  MemberTeamFragment,
  MemberWorkspaceFragment,
  SubscriberFragment,
  FavoriteFragment,
} from '@/types/tanstack-query/generated';
import { ITeamData } from '@/types/tanstack-query/team/team.interface';
import CustomWorkflows from '@/utils/custom-workflow';

import AuthenticatedUserContext from '../auth/AuthenticatedUserContext';
import SubscriptionContext from '../subscriptions/SubscriptionContext';

type IDataTeam = {
  team: TeamFragment | undefined;
  labels: LabelFragment[];
  workflows: WorkflowFragment[];
  members: UserFragment[];
};
type IPageState = {
  state:
    | { type: 'initial' }
    | { type: 'loading' }
    | { type: 'fetchFailed'; error: ErrorType }
    | { type: 'fetchSuccess'; data: WorkspaceCoreFragment | undefined };
  workspace: WorkspaceFragment | undefined;
  workspaces: WorkspaceFragment[];
  selected: WorkspaceFragment | undefined;
  labels: LabelFragment[];
  teams: TeamFragment[];
  members: UserFragment[];
  workflows: WorkflowFragment[];
  projectStatuses: ProjectStatusFragment[];
  memberTeams: MemberTeamFragment[];
  memberWorkspaces: MemberWorkspaceFragment[];
  subscribers: SubscriberFragment[];
  favorites: FavoriteFragment[];
  loading: boolean;
  role: RoleType;
  setTeams: (value: TeamFragment[]) => void;
  setWorkflows: (value: WorkflowFragment[]) => void;
  setProjectStatuses: (value: ProjectStatusFragment[]) => void;
  setLabels: (value: LabelFragment[]) => void;
  setSubscribers: (value: SubscriberFragment[]) => void;
  setFavorites: (value: FavoriteFragment[]) => void;
  setMemberWorkspaces: (value: MemberWorkspaceFragment[]) => void;
  setLoading: (value: boolean) => void;
  fetchFailed: (value: ErrorType) => void;
  fetchSuccess: (props: { core: WorkspaceCoreFragment | undefined }) => void;
};

const createStore = () =>
  create<IPageState>((set) => ({
    state: { type: 'initial' },
    loading: true,
    role: RoleType.Member,
    selected: undefined,
    workspace: undefined,
    workspaces: [],
    labels: [],
    teams: [],
    workflows: [],
    members: [],
    memberWorkspaces: [],
    projectStatuses: [],
    memberTeams: [],
    subscribers: [],
    favorites: [],
    setTeams: (value: TeamFragment[]) =>
      set({ teams: orderBy(value, ['created_at', ['asc']]) }),
    setWorkflows: (value: WorkflowFragment[]) =>
      set({ workflows: orderBy(value, ['type', 'order'], ['asc', 'asc']) }),
    setProjectStatuses: (value: ProjectStatusFragment[]) =>
      set({ projectStatuses: orderBy(value, ['created_at', ['asc']]) }),
    setLabels: (value: LabelFragment[]) =>
      set({ labels: orderBy(value, ['created_at', ['asc']]) }),
    setSubscribers: (value: SubscriberFragment[]) =>
      set({ subscribers: orderBy(value, ['created_at', ['asc']]) }),
    setFavorites: (value: FavoriteFragment[]) =>
      set({ favorites: orderBy(value, ['created_at', ['asc']]) }),
    setMemberWorkspaces: (value: MemberWorkspaceFragment[]) =>
      set({ memberWorkspaces: orderBy(value, ['created_at', ['asc']]) }),
    setLoading: (value: boolean) =>
      set({ state: { type: 'loading' }, loading: value }),

    fetchFailed: (value: ErrorType) =>
      set({ state: { type: 'fetchFailed', error: value } }),
    fetchSuccess: (props: { core: WorkspaceCoreFragment | undefined }) =>
      set({
        state: { type: 'fetchSuccess', data: props.core },
        selected: props.core?.workspace,
        workspace: props.core?.workspace,
        workspaces: props.core?.workspaces,
        projectStatuses: props.core?.project_statuses,
        labels: orderBy(props.core?.labels, ['created_at', ['asc']]),
        teams: orderBy(props.core?.teams, ['created_at', ['asc']]),
        workflows: CustomWorkflows.sortWorkflows(props.core?.workflows ?? []),
        members: props.core?.members,
        memberTeams: props.core?.member_teams,
        memberWorkspaces: props.core?.member_workspaces,
        subscribers: props.core?.subscribers,
        favorites: props.core?.favorites,
        role: props.core?.role,
      }),
  }));

const useWorkspace = () => {
  const [useStore] = useState(createStore);
  const { pubsub } = useContext(SubscriptionContext);
  const { refetch: updateUser } = useContext(AuthenticatedUserContext);
  const {
    loading,
    selected,
    workspace,
    workspaces,
    labels,
    teams,
    workflows,
    projectStatuses,
    memberTeams,
    memberWorkspaces,
    members,
    subscribers,
    favorites,
    role,
    setTeams,
    setWorkflows,
    setProjectStatuses,
    setLabels,
    setSubscribers,
    setFavorites,
    setMemberWorkspaces,
    setLoading,
    fetchSuccess,
  } = useStore();

  const storeWorkspace = async (value: WorkspaceCoreFragment) => {
    if (value) {
      fetchSuccess({
        core: value,
      });
    }
  };

  const getDataByTeam = (props: {
    team_id?: string;
    team_identifier?: string;
  }): IDataTeam => {
    const getTeam = teams.find(
      (item) =>
        item.id === props.team_id || item.identifier === props.team_identifier,
    );

    const getLabels = labels.filter(
      (item) => item.team_id === getTeam?.id || item.team_id === null,
    );

    const getWorkflows = workflows.filter(
      (item) => item.team_id === getTeam?.id,
    );
    const getMemberTeams = memberTeams.filter(
      (item) => item.team_id === getTeam?.id,
    );
    const getMembers = getMemberTeams
      .filter((item) => item.member !== null)
      .map((item) => item.member!);

    return {
      team: getTeam,
      labels: getLabels,
      workflows: CustomWorkflows.sortWorkflows(getWorkflows ?? []),
      members: getMembers,
    } as ITeamData;
  };

  // Project Status
  const pushProjectStatusItem = async (props: {
    projectStatus: ProjectStatusFragment;
  }) => {
    setLoading(false);
    const clone = Object.assign([], projectStatuses);
    clone.push(props.projectStatus);
    setProjectStatuses(clone);
  };

  const updateProjectStatusItem = async (props: {
    projectStatus: ProjectStatusFragment;
  }) => {
    setLoading(false);
    const clone: ProjectStatusFragment[] = Object.assign([], projectStatuses);
    setProjectStatuses(
      clone.map((item) => {
        if (item.id === props.projectStatus.id)
          return { ...props.projectStatus };
        return { ...item };
      }),
    );
  };
  const popProjectStatusItem = async (props: { projectStatus_id: string }) => {
    setLoading(false);
    const clone: ProjectStatusFragment[] = Object.assign(
      [],
      projectStatuses.filter((item) => item.id !== props.projectStatus_id),
    );
    setProjectStatuses(clone);
  };

  // Team
  const pushTeamItem = async (props: { team: TeamFragment }) => {
    setLoading(false);
    const clone = Object.assign([], teams);
    clone.push(props.team);
    setTeams(clone);
  };

  const updateTeamItem = async (props: { team: TeamFragment }) => {
    setLoading(false);
    const clone: TeamFragment[] = Object.assign([], teams);
    setTeams(
      clone.map((item) => {
        if (item.id === props.team.id) return { ...props.team };
        return { ...item };
      }),
    );
  };

  // Workflow
  const pushWorkflowItem = async (props: { workflow: WorkflowFragment }) => {
    setLoading(false);
    const clone = Object.assign([], workflows);
    clone.push(props.workflow);
    setWorkflows(clone);
  };

  const updateWorkflowItem = async (props: { workflow: WorkflowFragment }) => {
    setLoading(false);
    const clone: WorkflowFragment[] = Object.assign([], workflows);
    setWorkflows(
      clone.map((item) => {
        if (item.id === props.workflow.id) return { ...props.workflow };
        return { ...item };
      }),
    );
  };
  const popWorkflowItem = async (props: { workflow_id: string }) => {
    setLoading(false);
    const clone: WorkflowFragment[] = Object.assign(
      [],
      workflows.filter((item) => item.id !== props.workflow_id),
    );
    setWorkflows(clone);
  };
  // Label
  const pushLabelItem = async (props: { label: LabelFragment }) => {
    setLoading(false);
    const clone = Object.assign([], labels);
    clone.push(props.label);
    setLabels(clone);
  };

  const updateLabelItem = async (props: { label: LabelFragment }) => {
    setLoading(false);
    const clone: LabelFragment[] = Object.assign([], labels);
    setLabels(
      clone.map((item) => {
        if (item.id === props.label.id) return { ...props.label };
        return { ...item };
      }),
    );
  };
  const popLabelItem = async (props: { label_id: string }) => {
    setLoading(false);
    const clone: LabelFragment[] = Object.assign(
      [],
      labels.filter((item) => item.id !== props.label_id),
    );
    setLabels(clone);
  };

  // Subscriber
  const pushSubscriberItem = async (props: {
    subscriber: SubscriberFragment;
  }) => {
    setLoading(false);
    const clone = Object.assign([], subscribers);
    clone.push(props.subscriber);
    setSubscribers(clone);
  };
  const popSubscriberItem = async (props: { subscriber_id: string }) => {
    setLoading(false);
    const clone: SubscriberFragment[] = Object.assign(
      [],
      subscribers.filter((item) => item.id !== props.subscriber_id),
    );
    setSubscribers(clone);
  };

  // Favorite
  const pushFavoriteItem = async (props: { favorite: FavoriteFragment }) => {
    setLoading(false);
    const clone = Object.assign([], favorites);
    clone.push(props.favorite);
    setFavorites(clone);
  };
  const popFavoriteItem = async (props: { favorite_id: string }) => {
    setLoading(false);
    const clone: FavoriteFragment[] = Object.assign(
      [],
      favorites.filter((item) => item.id !== props.favorite_id),
    );
    setFavorites(clone);
  };

  // Workflow
  const pushMemberWorkspaceItem = async (props: {
    memberWorkspace: MemberWorkspaceFragment;
  }) => {
    setLoading(false);
    const clone = Object.assign([], memberWorkspaces);
    clone.push(props.memberWorkspace);
    setMemberWorkspaces(clone);
  };

  const popMemberWorkspaceItem = async (props: {
    member_workspace_id: string;
  }) => {
    setLoading(false);
    const clone: MemberWorkspaceFragment[] = Object.assign(
      [],
      memberWorkspaces.filter((item) => item.id !== props.member_workspace_id),
    );
    setMemberWorkspaces(clone);
  };

  useEffect(() => {
    if (pubsub) {
      switch (pubsub.event) {
        case PushEventType.TeamAdded:
          if (!pubsub.data) break;
          pushTeamItem({ team: pubsub.data as TeamFragment });

          break;
        case PushEventType.TeamUpdated:
          if (!pubsub.data) break;
          updateTeamItem({ team: pubsub.data as TeamFragment });

          break;
        case PushEventType.ProjectStatusAdded:
          if (!pubsub.data) break;
          pushProjectStatusItem({
            projectStatus: pubsub.data as ProjectStatusFragment,
          });

          break;
        case PushEventType.ProjectStatusUpdated:
          if (!pubsub.data) break;
          updateProjectStatusItem({
            projectStatus: pubsub.data as ProjectStatusFragment,
          });

          break;
        case PushEventType.ProjectStatusDeleted:
          if (!pubsub.id) break;
          popProjectStatusItem({ projectStatus_id: pubsub.id });

          break;
        case PushEventType.WorkflowAdded:
          if (!pubsub.data) break;
          pushWorkflowItem({ workflow: pubsub.data as WorkflowFragment });

          break;
        case PushEventType.WorkflowUpdated:
          if (!pubsub.data) break;
          updateWorkflowItem({ workflow: pubsub.data as WorkflowFragment });

          break;
        case PushEventType.WorkflowDeleted:
          if (!pubsub.id) break;
          popWorkflowItem({ workflow_id: pubsub.id });

          break;
        case PushEventType.LabelAdded:
          if (!pubsub.data) break;
          pushLabelItem({ label: pubsub.data as LabelFragment });

          break;
        case PushEventType.LabelUpdated:
          if (!pubsub.data) break;
          updateLabelItem({ label: pubsub.data as LabelFragment });

          break;
        case PushEventType.LabelDeleted:
          if (!pubsub.id) break;
          popLabelItem({ label_id: pubsub.id });

          break;
        case PushEventType.UserUpdated:
          if (!pubsub.data) break;
          updateUser();

          break;
        case PushEventType.IssueSubscribed:
        case PushEventType.DocumentSubscribed:
          if (!pubsub.data) break;
          pushSubscriberItem({
            subscriber: pubsub.data as SubscriberFragment,
          });

          break;
        case PushEventType.IssueUnsubscribed:
        case PushEventType.DocumentUnsubscribed:
          if (!pubsub.id) break;
          popSubscriberItem({ subscriber_id: pubsub.id });

          break;
        case PushEventType.FavoriteAdded:
          if (!pubsub.data) break;
          pushFavoriteItem({
            favorite: pubsub.data as FavoriteFragment,
          });

          break;
        case PushEventType.FavoriteDeleted:
          if (!pubsub.id) break;
          popFavoriteItem({ favorite_id: pubsub.id });

          break;
        case PushEventType.MemberWorkspaceAdded:
          if (!pubsub.data) break;
          pushMemberWorkspaceItem({
            memberWorkspace: pubsub.data as MemberWorkspaceFragment,
          });

          break;
        case PushEventType.MemberWorkspaceDeleted:
          if (!pubsub.id) break;
          popMemberWorkspaceItem({ member_workspace_id: pubsub.id });

          break;
        default:
          break;
      }
    }
  }, [pubsub]);

  return {
    state: useStore((store) => store.state),
    loading,
    selected,
    workspace,
    workspaces,
    labels,
    teams,
    workflows,
    projectStatuses,
    memberTeams,
    memberWorkspaces,
    members,
    subscribers,
    favorites,
    role,
    event: {
      storeWorkspace,
      getDataByTeam,
    },
  };
};

export default useWorkspace;
