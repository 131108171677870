import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogTitle,
} from '@/components/AlertDialog';
import { useErrorHandler } from '@/hooks/useToasterHandler';
import {
  ProjectStatusFragment,
  useRemoveProjectStatusMutation,
} from '@/types/tanstack-query/generated';

const DeleteProjectStatusDialog = (props: {
  projectStatus: ProjectStatusFragment | undefined;
  onCancel: () => void;
  onDeleteSuccess: (projectStatus: ProjectStatusFragment) => void;
}) => {
  const { showErrorToast } = useErrorHandler();

  const removeProjectStatus = useRemoveProjectStatusMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: (res) => {
      props.onDeleteSuccess(res.removeProjectStatus);
    },
    onSettled: () => {},
  });

  return (
    <AlertDialog open={!!props.projectStatus}>
      <AlertDialogContent>
        <AlertDialogTitle className="text-xl">
          Are you sure you want to delete the "{props.projectStatus?.name}"
          project status?
        </AlertDialogTitle>
        <AlertDialogDescription>
          The project status will be removed. This might also affect issues and
          drafts you do not have access to. This action cannot be undone.
        </AlertDialogDescription>

        <div className="flex flex-row items-center justify-end gap-4">
          <AlertDialogCancel onClick={() => props.onCancel()}>
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction
            onClick={() =>
              props.projectStatus &&
              removeProjectStatus.mutate({ id: props.projectStatus.id })
            }
          >
            Delete
          </AlertDialogAction>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DeleteProjectStatusDialog;
