import React, { useContext } from 'react';

import { Dialog, DialogContent } from '@/components/Dialog';
import IssueServicesContext from '@/providers/issue/IssueServicesContext';
import { ICreateIssueModal } from '@/types/tanstack-query/issue/issue.interface';

import { CreateIssueComponent } from './CreateIssueComponent';

export const CreateIssueDialog = (props: ICreateIssueModal) => {
  const { isShowCreateIssue, showCreatePopup } =
    useContext(IssueServicesContext);

  return (
    <>
      <Dialog
        open={isShowCreateIssue}
        onOpenChange={(value) => showCreatePopup({ isOpen: value })}
      >
        <DialogContent
          className="flex max-w-[820px] flex-col gap-2"
          style={{ padding: 0 }}
        >
          <CreateIssueComponent {...props} type={'modal'} />
        </DialogContent>
      </Dialog>
    </>
  );
};
