/* eslint-disable import/no-extraneous-dependencies */
import { useContext, useEffect, useRef, useState } from 'react';

import { PlateEditor, TElement } from '@udecode/plate-common';
import { insertImage } from '@udecode/plate-media';
import { CalendarX2Icon, PackageIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useCopyToClipboard } from 'usehooks-ts';

import { AnimationFadeIn } from '@/components/Animations';
import { Button } from '@/components/Button';
import { Card } from '@/components/Card';
import EditorPlateComponent from '@/components/Editor/EditorPlateComponent';
import SingleLineEditorComponent from '@/components/Editor/TitleEditorComponent';
import { Label } from '@/components/Label';
import { ScrollArea, ScrollBar } from '@/components/ScrollArea';
import { UserAvatar } from '@/components/UserAvatar';
import { useErrorHandler, useSuccessHandler } from '@/hooks/useToasterHandler';
import useUploadAttachment from '@/hooks/useUploadAttachment';
import {
  AttachmentIcon,
  CopyIcon,
  LinkOutlineIcon,
  PlusIcon,
  SpinnerDotIcon,
} from '@/icons/core';
import { LabelIcon, UserAssignIcon } from '@/icons/core/issue';
import { initialValueEditor } from '@/pages/cores';
import { MemoizedAddToProjectButton } from '@/pages/issue/components/Properties/AddToProjectButton';
import MemoizedDueDateButton from '@/pages/issue/components/Properties/DueDateButton';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import {
  MemoizedRenderIssue,
  MemoizedRenderIssuesByParentId,
} from '@/renders/RenderIssue';
import { MemoizedRenderStatus } from '@/renders/RenderStatus';
import { MemoizedRenderTeam } from '@/renders/RenderTeam';
import { DOMAIN_URL } from '@/services/core/constant';
import { getNameByPriority } from '@/services/issue/issue-utils';
import {
  PriorityType,
  IssueFragment,
  TeamFragment,
  useUpdateIssueMutation,
  WorkspaceFragment,
} from '@/types/tanstack-query/generated';
import CustomDateTime from '@/utils/format-datetime';
import PlateUtils from '@/utils/plate-utils';

import { ToolsMenuIssue } from '../../../components/ContextMenuIssue';
import { CreateSubIssueDialog } from '../../../components/CreateIssue/CreateSubIssueDialog';
import MemoizedAssignToButton from '../../../components/Properties/AssignToButton';
import {
  MemoizedLabelButton,
  MemoizedRenderLabelsColor,
} from '../../../components/Properties/LabelButton';
import {
  MemoizedPriorityButton,
  MemoizedRenderPriorityIcon,
} from '../../../components/Properties/PriorityButton';
import {
  MemoizedRenderStatusIcon,
  MemoizedStatusButton,
} from '../../../components/Properties/StatusButton';
import { ParentIssuesCircleButton } from '../../../components/Properties/SubIssuesButton';
import ActivitiesComponent from '../Activities/ActivitiesComponent';
import AttachmentsComponent from '../Attachments/AttachmentsComponent';
import CreateCommentComponent from '../Comment/CreateComment/CreateCommentComponent';
import SubIssuesComponent from '../SubIssues/SubIssuesComponent';

const ParentIssue = (props: { issue: IssueFragment }) => {
  const navigate = useNavigate();
  const { selected } = useContext(WorkspaceContext);
  return (
    <div className="flex w-full flex-row items-center gap-1">
      <Label className="flex-none text-xs opacity-50">Sub-issue of</Label>
      <MemoizedRenderTeam
        id={props.issue.team_id ?? undefined}
        renderItem={(team) => (
          <>
            <div
              className="flex cursor-pointer flex-row items-center gap-2  rounded-md p-1 hover:bg-secondary"
              onClick={() =>
                navigate(
                  `/${selected?.url}/issue/${team?.identifier}-${props.issue.code}`,
                )
              }
            >
              <div className="flex-none">
                <MemoizedRenderStatus
                  id={props.issue.workflow_id ?? ''}
                  renderItem={(workflow) => (
                    <MemoizedRenderStatusIcon
                      type={workflow?.type}
                      color={workflow?.color}
                      processing={workflow?.processing}
                    />
                  )}
                />
              </div>
              <Label className="flex-none text-xs opacity-50">
                {`${team?.identifier}-${props.issue.code}`}
              </Label>
              <div className="grow">
                <Label className="line-clamp-1 text-xs">
                  {props.issue.title}
                </Label>
              </div>
            </div>
            <div>
              <ParentIssuesCircleButton id={props.issue.id} />
            </div>
          </>
        )}
      />
    </div>
  );
};

const PropertiesComponent = (props: {
  data: IssueFragment;
  team: TeamFragment;
  disabled: boolean;
  selectedWorkflowId: string | undefined;
  onSelectedWorkflowId: (value: string | undefined) => void;
  selectedPriority: PriorityType | undefined;
  onSelectedPriority: (value: PriorityType | undefined) => void;
  selectedLabelIds: string[];
  onSelectedLabelIds: (value: string[]) => void;
  selectedMemberId: string | undefined;
  onSelectedMemberId: (value: string | undefined) => void;
  selectedProjectId: string | undefined;
  onSelectedProjectId: (value: string | undefined) => void;
  selectedDueDateAt: Date | undefined;
  onSelectedDueDateAt: (value: Date | undefined) => void;
  onCopyLink: () => void;
  onCopyId: () => void;
}) => {
  const title = `${props.team?.identifier}-${props.data.code} ${props.data?.title}`;
  return (
    <div className="flex-wra flex w-full items-center px-[10px] md:h-[40px] md:flex-col">
      <div className="hidden w-full py-[15px] md:block">
        <div className="flex flex-row">
          <div className="flex w-full grow  items-center">
            <Label className="font-semibold">Properties</Label>
          </div>
          <div className="flex flex-row items-center gap-2">
            <Button
              onClick={props.onCopyLink}
              variant={'outline'}
              type="button"
              size={'icon'}
              className="flex h-[24px] items-center rounded"
            >
              <LinkOutlineIcon width={18} height={18} />
            </Button>
            <Button
              onClick={props.onCopyId}
              type="button"
              variant={'outline'}
              size={'icon'}
              className="flex h-[24px] items-center rounded"
            >
              <CopyIcon width={18} height={18} />
            </Button>
          </div>
        </div>
      </div>
      <div className="flex w-full flex-wrap gap-2 md:mt-2 md:flex-col md:gap-4">
        <MemoizedStatusButton
          title={title}
          teamId={props.data.team_id ?? ''}
          selected={props.selectedWorkflowId}
          onSelected={props.onSelectedWorkflowId}
          renderItem={(value) => (
            <Button
              disabled={props.disabled}
              className="flex flex-row items-center justify-start gap-2 text-left"
              variant="outline"
              type="button"
            >
              <span className="flex w-[20px] flex-none">
                <MemoizedRenderStatusIcon
                  type={value?.type}
                  color={value?.color}
                  processing={value?.processing}
                />
              </span>
              <Label>{value?.name}</Label>
            </Button>
          )}
        />
        <MemoizedPriorityButton
          title={title}
          selected={props.selectedPriority}
          onSelected={props.onSelectedPriority}
          renderItem={(value) => (
            <Button
              disabled={props.disabled}
              className="flex flex-row items-center justify-start gap-2 text-left"
              variant="outline"
              type="button"
            >
              <>
                <span className="flex">
                  <MemoizedRenderPriorityIcon type={value?.type} />
                </span>
                <Label className="hidden sm:block">
                  {getNameByPriority(value?.type)}
                </Label>
              </>
            </Button>
          )}
        />
        <MemoizedAssignToButton
          title={title}
          teamIds={props.data.team_id ? [props.data.team_id] : []}
          selected={props.selectedMemberId}
          onSelected={props.onSelectedMemberId}
          renderItem={(value) => (
            <Button
              disabled={props.disabled}
              className="flex flex-row items-center justify-start gap-2 text-left"
              variant="outline"
              type="button"
            >
              {!props.selectedMemberId && (
                <div className="flex flex-row items-center gap-2">
                  <span className="flex">
                    <UserAssignIcon width={22} height={22} />
                  </span>
                  <Label className="hidden sm:block">Assign</Label>
                </div>
              )}
              {props.selectedMemberId && (
                <div className="flex flex-row items-center gap-2">
                  <span className="flex items-center">
                    <UserAvatar
                      size="sm"
                      url={value?.image}
                      fallback={value?.username}
                    />
                  </span>
                  <Label className="hidden sm:block">{value?.username}</Label>
                </div>
              )}
            </Button>
          )}
        />
        <div className="hidden md:block">
          <Label>Labels</Label>
        </div>
        <MemoizedLabelButton
          title={title}
          teamId={props.data.team_id ?? ''}
          selected={props.selectedLabelIds}
          onSelected={props.onSelectedLabelIds}
          placeholder="Search label..."
          renderItem={(value) => (
            <Button
              disabled={props.disabled}
              className="flex flex-row items-center justify-start gap-2 text-left"
              variant="outline"
              type="button"
            >
              {value.length > 0 && (
                <div className="flex flex-row items-center gap-1">
                  <MemoizedRenderLabelsColor labels={value} />
                  <Label>{value.length} labels</Label>
                </div>
              )}
              {value.length === 0 && (
                <div className="flex flex-row items-center gap-1">
                  <span className="flex w-[20px] flex-none">
                    <LabelIcon width={24} height={24} />
                  </span>
                  <Label className="hidden sm:block">Add labels</Label>
                </div>
              )}
            </Button>
          )}
        />
        <div className="hidden md:block">
          <Label>Project</Label>
        </div>
        <MemoizedAddToProjectButton
          title={title}
          teamId={props.data.team_id ?? ''}
          selected={props.selectedProjectId}
          onSelected={props.onSelectedProjectId}
          renderItem={(value) => (
            <Button
              disabled={props.disabled}
              className="flex flex-row items-center justify-start gap-2 text-left"
              variant="outline"
              type="button"
            >
              {value && (
                <div className="flex w-full flex-row items-center gap-1">
                  <div className="flex flex-none">
                    <PackageIcon width={20} height={20} />
                  </div>
                  <div className="line-clamp-1 grow text-ellipsis">
                    <Label className="text-ellipsis">{value.title}</Label>
                  </div>
                </div>
              )}
              {!value && (
                <div className="flex flex-row items-center gap-1">
                  <div className="flex flex-none">
                    <PackageIcon width={20} height={20} />
                  </div>
                  <Label>Add to project</Label>
                </div>
              )}
            </Button>
          )}
        />
        <div className="hidden md:block">
          <Label>Due date</Label>
        </div>
        <MemoizedDueDateButton
          title={title}
          selected={props.selectedDueDateAt}
          onSelected={props.onSelectedDueDateAt}
          renderItem={(value) => (
            <Button
              variant={'outline'}
              disabled={props.disabled}
              className="flex flex-row items-center justify-start gap-2 text-left"
            >
              <CalendarX2Icon width={18} height={18} />
              <Label>
                {value ? CustomDateTime.formatDate(value) : 'Pick a due date'}
              </Label>
            </Button>
          )}
        />
      </div>
    </div>
  );
};

const BodyDetailContent = (props: {
  data: IssueFragment;
  team: TeamFragment;
  editorTitleRef: React.MutableRefObject<PlateEditor | null>;
  editorContentRef: React.MutableRefObject<PlateEditor | null>;
  selectedWorkflowId: string | undefined;
  onSelectedWorkflowId: (value: string | undefined) => void;
  selectedPriority: PriorityType | undefined;
  onSelectedPriority: (value: PriorityType | undefined) => void;
  selectedLabelIds: string[];
  onSelectedLabelIds: (value: string[]) => void;
  selectedMemberId: string | undefined;
  onSelectedMemberId: (value: string | undefined) => void;
  selectedProjectId: string | undefined;
  onSelectedProjectId: (value: string | undefined) => void;
  selectedDueDateAt: Date | undefined;
  onSelectedDueDateAt: (value: Date | undefined) => void;
  isOpenCreateSubIssue: Boolean;
  onOpenCreateSubIssue: (value: boolean) => void;
  onChangeTitle: () => void;
  onChangeContent: () => void;
  onCopyLink: () => void;
  onCopyId: () => void;
  loadingUpload: boolean;
  onUploadImage: () => void;
}) => {
  return (
    <div className="relative flex grow flex-col overflow-hidden md:flex-row">
      <div className="z-20 w-full flex-none bg-secondary py-2 md:relative md:order-last md:w-[220px] md:py-0">
        <ToolsMenuIssue
          contextMenu={true}
          issue={props.data}
          workspace_id={props.data.workspace_id}
          className="h-full w-full"
        >
          <PropertiesComponent
            data={props.data}
            team={props.team}
            disabled={props.data.is_deleted}
            selectedWorkflowId={props.selectedWorkflowId}
            onSelectedWorkflowId={props.onSelectedWorkflowId}
            selectedPriority={props.selectedPriority}
            onSelectedPriority={props.onSelectedPriority}
            selectedLabelIds={props.selectedLabelIds}
            onSelectedLabelIds={props.onSelectedLabelIds}
            selectedMemberId={props.selectedMemberId}
            onSelectedMemberId={props.onSelectedMemberId}
            selectedProjectId={props.selectedProjectId}
            onSelectedProjectId={props.onSelectedProjectId}
            onCopyLink={props.onCopyLink}
            onCopyId={props.onCopyId}
            selectedDueDateAt={props.selectedDueDateAt}
            onSelectedDueDateAt={props.onSelectedDueDateAt}
          />
        </ToolsMenuIssue>
      </div>

      <div className="relative z-10 flex h-full grow overflow-hidden md:pt-0">
        <ScrollArea className="h-full w-full" type="auto">
          <div className="m-auto w-full max-w-[900px] items-start px-[10px] md:px-[24px]">
            <div className="flex w-full flex-col">
              <div className="mb-[10px] mt-4 flex lg:mt-10 ">
                <SingleLineEditorComponent
                  id={props.data.id}
                  loading={false}
                  editorRef={props.editorTitleRef}
                  placeholder={'Issue title'}
                  disabled={props.data.is_deleted}
                  onSubmit={props.onChangeTitle}
                  className="min-h-[10px] text-2xl"
                />
              </div>
              {props.data.issue_id && (
                <MemoizedRenderIssue
                  id={props.data.issue_id}
                  renderItem={(parent) =>
                    parent && <ParentIssue issue={parent} />
                  }
                />
              )}
              <div className="relative flex w-full py-[20px] text-primary">
                <EditorPlateComponent
                  id={props.data.id}
                  editorRef={props.editorContentRef}
                  loading={false}
                  placeholder="Add description ..."
                  disabled={props.data.is_deleted}
                  onSubmit={props.onChangeContent}
                />
                <div className="absolute bottom-[5px] right-0 flex">
                  <Button
                    type="button"
                    size={'sm'}
                    variant={'ghost'}
                    disabled={props.loadingUpload}
                    onClick={() => props.onUploadImage()}
                  >
                    <div className="flex flex-row items-center gap-2">
                      {props.loadingUpload && <SpinnerDotIcon width={18} />}
                      <AttachmentIcon width={16} height={16} />
                    </div>
                  </Button>
                </div>
              </div>
              <div className="flex w-full py-[20px] text-primary">
                <AttachmentsComponent
                  issue_id={props.data.id}
                  workspace_id={props.data.workspace_id}
                  disabled={props.data.is_deleted}
                />
              </div>
              <div className="flex w-full">
                <Button
                  disabled={props.data.is_deleted}
                  type="button"
                  size={'sm'}
                  variant={'ghost'}
                  onClick={() => props.onOpenCreateSubIssue(true)}
                >
                  <div className="flex flex-row items-center gap-2">
                    <PlusIcon width={14} height={14} />
                    <Label className="text-xs">Add sub-issues</Label>
                  </div>
                </Button>
              </div>
              <MemoizedRenderIssuesByParentId
                id={props.data.id}
                renderItem={(children) =>
                  children.length > 0 && <SubIssuesComponent data={children} />
                }
              />
              {props.isOpenCreateSubIssue && (
                <AnimationFadeIn>
                  <Card className="mt-[10px] p-0">
                    <CreateSubIssueDialog
                      initIssueId={props.data.id}
                      initTeamId={props.data.team_id ?? ''}
                      initWorkflowId={props.data.workflow_id ?? ''}
                      onSuccess={() => props.onOpenCreateSubIssue(false)}
                      onCancel={() => props.onOpenCreateSubIssue(false)}
                    />
                  </Card>
                </AnimationFadeIn>
              )}
            </div>
            <div className="py-6">
              <ActivitiesComponent
                issue_id={props.data.id}
                workspace_id={props.data.workspace_id}
              />
            </div>
            <div className="pb-6">
              <CreateCommentComponent
                issue_id={props.data.id}
                workspace_id={props.data.workspace_id}
              />
            </div>
          </div>
          <ScrollBar orientation="vertical" />
        </ScrollArea>
      </div>
    </div>
  );
};

const IssueDetailComponent = (props: {
  issue: IssueFragment;
  team: TeamFragment;
  workspace: WorkspaceFragment;
}) => {
  const [copied, copy] = useCopyToClipboard();
  const { showErrorToast } = useErrorHandler();
  const { showSuccessToast } = useSuccessHandler();

  const [selectedWorkflowId, setSelectedWorkflowId] = useState<
    string | undefined
  >(props.issue.workflow_id ?? undefined);
  const [selectedMemberId, setSelectedMemberId] = useState<string | undefined>(
    props.issue.assignee_id ?? undefined,
  );
  const [selectedLabelIds, setSelectedLabelIds] = useState<string[]>(
    props.issue.labels?.map((item) => item.label_id) ?? [],
  );
  const [selectedPriority, setSelectedPriority] = useState<PriorityType>(
    props.issue.priority,
  );
  const [selectedProjectId, setSelectedProjectId] = useState<
    string | undefined
  >(props.issue.project_id ?? undefined);
  const [selectedDueDateAt, setSelectedDueDateAt] = useState<Date | undefined>(
    props.issue.due_date_at ? new Date(props.issue.due_date_at) : undefined,
  );
  const editorTitleRef = useRef<PlateEditor | null>(null);
  const editorContentRef = useRef<PlateEditor | null>(null);

  const [isOpenCreateSubIssue, setIsOpenCreateSubIssue] =
    useState<Boolean>(false);

  const updateIssue = useUpdateIssueMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {},
    onSettled: () => {},
  });

  const { onUpload, loading } = useUploadAttachment({
    type: 'issue',
    workspace_id: props.workspace.id,
    onUploadSuccess: (path) => {
      insertImage(editorContentRef.current, path);
    },
  });

  const onCopyLink = async () => {
    const data = `${DOMAIN_URL}/${props.workspace.url}/${props.team.identifier}-${props.issue.code}`;
    await copy(data);
    showSuccessToast({
      title: `"${data}" Copied`,
    });
  };

  const onCopyId = async () => {
    const data = `${props.team.identifier}-${props.issue.code}`;
    await copy(data);
    showSuccessToast({
      title: `"${data}" Copied`,
    });
  };

  useEffect(() => {
    if (props.issue) {
      setSelectedWorkflowId(props.issue.workflow_id ?? undefined);
      setSelectedPriority(props.issue.priority);
      setSelectedLabelIds(
        props.issue.labels?.map((item) => item.label_id) ?? [],
      );
      setSelectedMemberId(props.issue.assignee_id ?? undefined);
      setSelectedProjectId(props.issue.project_id ?? undefined);
      setSelectedDueDateAt(
        props.issue.due_date_at ? new Date(props.issue.due_date_at) : undefined,
      );
      // Editor Content
      if (!editorContentRef.current) return;
      if (props.issue.description) {
        try {
          editorContentRef.current.children = JSON.parse(
            props.issue.description,
          );
        } catch (error) {
          editorContentRef.current.children = [
            { id: 1, type: 'p', children: [{ text: props.issue.description }] },
          ] as TElement[];
        }
        editorContentRef.current.onChange();
      } else {
        editorContentRef.current.children = initialValueEditor;
        editorContentRef.current.onChange();
      }
      // Editor Title
      if (!editorTitleRef.current) return;
      if (props.issue.title) {
        editorTitleRef.current.children = [
          { id: 1, type: 'p', children: [{ text: props.issue.title }] },
        ] as TElement[];
        editorTitleRef.current.onChange();
      } else {
        editorTitleRef.current.children = initialValueEditor;
        editorContentRef.current.onChange();
      }
    }
  }, [props.issue]);

  // Reset when user navigate to new issue
  useEffect(() => {
    setIsOpenCreateSubIssue(false);
  }, [props.issue.id]);

  return props.issue && props.issue.team_id ? (
    <MemoizedRenderTeam
      id={props.issue.team_id}
      renderItem={(team) =>
        team && (
          <BodyDetailContent
            data={props.issue}
            team={team}
            editorTitleRef={editorTitleRef}
            editorContentRef={editorContentRef}
            selectedWorkflowId={selectedWorkflowId}
            onChangeTitle={() => {
              const getTitle = PlateUtils.getTitleFromTElement(
                editorTitleRef.current?.children,
              );
              if (props.issue.title === getTitle) return;
              updateIssue.mutate({
                updateInput: {
                  id: props.issue?.id,
                  title: getTitle,
                },
              });
            }}
            onChangeContent={() => {
              const getContent = PlateUtils.getContentFromTElement(
                editorContentRef.current?.children,
              );
              if (props.issue.description === getContent) return;

              updateIssue.mutate({
                updateInput: {
                  id: props.issue?.id,
                  description: getContent,
                },
              });
            }}
            onSelectedWorkflowId={(value) => {
              setSelectedWorkflowId(value);
              if (props.issue?.id) {
                updateIssue.mutate({
                  updateInput: {
                    id: props.issue?.id,
                    workflow_id: value,
                  },
                });
              }
            }}
            selectedPriority={selectedPriority}
            onSelectedPriority={(value) => {
              if (!value) return;
              setSelectedPriority(value);
              if (props.issue?.id && value) {
                updateIssue.mutate({
                  updateInput: {
                    id: props.issue?.id,
                    priority: value,
                  },
                });
              }
            }}
            selectedLabelIds={selectedLabelIds}
            onSelectedLabelIds={(value) => {
              setSelectedLabelIds(value);
              if (props.issue?.id) {
                updateIssue.mutate({
                  updateInput: {
                    id: props.issue?.id,
                    label_ids: value.map((item) => item),
                  },
                });
              }
            }}
            selectedMemberId={selectedMemberId}
            onSelectedMemberId={(value) => {
              setSelectedMemberId(value);
              if (props.issue?.id) {
                updateIssue.mutate({
                  updateInput: {
                    id: props.issue?.id,
                    assignee_id: value,
                  },
                });
              }
            }}
            selectedDueDateAt={selectedDueDateAt}
            onSelectedDueDateAt={(value) => {
              setSelectedDueDateAt(value);
              if (props.issue?.id) {
                updateIssue.mutate({
                  updateInput: {
                    id: props.issue?.id,
                    due_date_at: value?.toISOString(),
                  },
                });
              }
            }}
            isOpenCreateSubIssue={isOpenCreateSubIssue}
            onOpenCreateSubIssue={setIsOpenCreateSubIssue}
            onCopyId={onCopyId}
            onCopyLink={onCopyLink}
            loadingUpload={loading}
            onUploadImage={onUpload}
            selectedProjectId={selectedProjectId}
            onSelectedProjectId={(value) => {
              setSelectedProjectId(value);
              if (props.issue?.id) {
                updateIssue.mutate({
                  updateInput: {
                    id: props.issue?.id,
                    project_id: value,
                  },
                });
              }
            }}
          />
        )
      }
    />
  ) : (
    <></>
  );
};

export default IssueDetailComponent;
