import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { Drawer, DrawerContent } from '@/components/Drawer';
import {
  ResizablePanelGroup,
  ResizablePanel,
  ResizableHandle,
} from '@/components/Resizeable';
import { CalcPercentByWidth } from '@/utils/calc-percent-screen';

import MainSidebar from './components/MainSidebar';
import MainContext from './MainContext';
import useMain from './useMain';
import { useIssueServices } from '../issue/useIssueServices';

const MainWrapper = ({ children }: { children?: React.ReactNode }) => {
  const navigate = useNavigate();
  const { isOpenSidebar, event } = useMain();
  const { showCreatePopup } = useIssueServices();
  const widthPercent = CalcPercentByWidth({ width: 250 });

  const onOpenCreateIssue = () => {
    showCreatePopup({
      isOpen: true,
    });
    event.onToggleSidebar(false);
  };

  const onNavigate = (path: string, reload?: boolean) => {
    navigate(path);

    event.onToggleSidebar(false);
    if (reload) {
      navigate(0);
    }
  };

  useEffect(() => {}, []);

  return (
    <MainContext.Provider
      value={{ toggleMenuSidebar: (value) => event.onToggleSidebar(value) }}
    >
      <div className="bg-darkest flex h-full max-h-full min-h-full w-full flex-row">
        <ResizablePanelGroup direction="horizontal">
          <ResizablePanel
            defaultSize={widthPercent}
            className="hidden h-full lg:block"
          >
            <MainSidebar
              onOpenCreateIssue={onOpenCreateIssue}
              onNavigate={onNavigate}
            />
          </ResizablePanel>
          <ResizableHandle withHandle className="hidden lg:flex" />
          <ResizablePanel defaultSize={100 - widthPercent}>
            <div className="flex h-full w-full grow flex-col">
              <div className="flex h-full w-full flex-row overflow-hidden rounded border-[1px] border-solid border-secondary">
                {children}
              </div>
            </div>
          </ResizablePanel>
        </ResizablePanelGroup>
        <Drawer
          direction="left"
          open={isOpenSidebar}
          onOpenChange={(value) => event.onToggleSidebar(value)}
        >
          <DrawerContent>
            <MainSidebar
              onOpenCreateIssue={onOpenCreateIssue}
              onNavigate={onNavigate}
            />
          </DrawerContent>
        </Drawer>
      </div>
    </MainContext.Provider>
  );
};

export default MainWrapper;
