import { useContext } from 'react';

import { PackageIcon } from 'lucide-react';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/Accordion';
import { Button } from '@/components/Button';
import { Label } from '@/components/Label';
import { useErrorHandler } from '@/hooks/useToasterHandler';
import { CloseFillIcon, DocumentTextOutlineIcon } from '@/icons/core';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { MemoizedRenderDocument } from '@/renders/RenderDocument';
import { MemoizedRenderIssue } from '@/renders/RenderIssue';
import { MemoizedRenderProject } from '@/renders/RenderProject';
import { MemoizedRenderStatus } from '@/renders/RenderStatus';
import { MemoizedRenderTeam } from '@/renders/RenderTeam';
import {
  WorkspaceFragment,
  FavoriteFragment,
  useToggleFavoriteMutation,
  FavoriteType,
} from '@/types/tanstack-query/generated';
import { mayBeMatch } from '@/utils/custom-matcher';

import { MemoizedRenderStatusIcon } from '../../../pages/issue/components/Properties/StatusButton';

const FavoriteSection = (props: {
  workspace: WorkspaceFragment;
  favorites: FavoriteFragment[];
  onNavigate: (path: string, reload?: boolean) => void;
}) => {
  const { showErrorToast } = useErrorHandler();

  const { selected } = useContext(WorkspaceContext);

  const toggleFavorite = useToggleFavoriteMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {},
    onSettled: () => {},
  });

  return (
    <Accordion type="single" collapsible={true} defaultValue={'team'}>
      <AccordionItem value={'team'}>
        <Button
          type="button"
          variant={'ghost'}
          className="flex h-[30px] w-full flex-row justify-start overflow-hidden px-[10px] text-left"
        >
          <AccordionTrigger className="flex-none">
            <Label className="text-xs opacity-50">Favorites</Label>
          </AccordionTrigger>
        </Button>
        <AccordionContent>
          <div className="flex w-full flex-col">
            {props.favorites.map((item, index) =>
              mayBeMatch([item.type])({
                ISSUE: () => (
                  <MemoizedRenderIssue
                    key={item.id}
                    id={item.map_id}
                    renderItem={(issue) =>
                      issue &&
                      issue.team_id && (
                        <MemoizedRenderTeam
                          id={issue.team_id}
                          renderItem={(team) => (
                            <Button
                              asChild
                              key={index}
                              variant="ghost"
                              className={
                                'group  h-[30px] w-full cursor-pointer overflow-hidden px-[10px]'
                              }
                            >
                              <div className="relative flex flex-row pr-[30px]">
                                <div
                                  className="flex w-full flex-row items-center gap-2 "
                                  onClick={() =>
                                    props.onNavigate(
                                      `/${props.workspace.url}/issue/${team?.identifier}-${issue.code}`,
                                    )
                                  }
                                >
                                  {issue.workflow_id && (
                                    <MemoizedRenderStatus
                                      id={issue.workflow_id}
                                      renderItem={(status) => (
                                        <div className="w-[20px]">
                                          <MemoizedRenderStatusIcon
                                            type={status?.type}
                                            color={status?.color}
                                            processing={status?.processing}
                                          />
                                        </div>
                                      )}
                                    />
                                  )}
                                  <Label className="grow truncate">
                                    {issue.title}
                                  </Label>
                                </div>
                                <Button
                                  size={'sm'}
                                  variant={'link'}
                                  className="absolute right-0 top-0 hidden group-hover:block"
                                  onClick={() =>
                                    selected?.id &&
                                    toggleFavorite.mutate({
                                      toggleFavorite: {
                                        map_id: item.map_id,
                                        type: FavoriteType.Issue,
                                        workspace_id: selected?.id,
                                      },
                                    })
                                  }
                                >
                                  <CloseFillIcon width={10} height={10} />
                                </Button>
                              </div>
                            </Button>
                          )}
                        />
                      )
                    }
                  />
                ),
                DOCUMENT: () => (
                  <MemoizedRenderDocument
                    id={item.map_id}
                    key={item.id}
                    renderItem={(document) => (
                      <Button
                        asChild
                        key={index}
                        variant="ghost"
                        className={'group  h-[30px] cursor-pointer px-[10px]'}
                      >
                        <div className="relative flex flex-row pr-[30px]">
                          <div
                            className="flex w-full flex-row items-center gap-2"
                            onClick={() =>
                              props.onNavigate(
                                `/${props.workspace.url}/document/${document?.id}`,
                              )
                            }
                          >
                            <div className="w-[20px] flex-none">
                              <DocumentTextOutlineIcon width={20} height={20} />
                            </div>
                            <Label className="w-full grow truncate">
                              {document?.title}
                            </Label>
                          </div>
                          <Button
                            variant={'link'}
                            className="absolute right-0 top-0 hidden group-hover:block"
                            onClick={() =>
                              selected?.id &&
                              toggleFavorite.mutate({
                                toggleFavorite: {
                                  map_id: item.map_id,
                                  type: FavoriteType.Document,
                                  workspace_id: selected?.id,
                                },
                              })
                            }
                          >
                            <CloseFillIcon width={10} height={10} />
                          </Button>
                        </div>
                      </Button>
                    )}
                  />
                ),
                PROJECT: () => (
                  <MemoizedRenderProject
                    id={item.map_id}
                    key={item.id}
                    renderItem={(project) => (
                      <Button
                        asChild
                        key={index}
                        variant="ghost"
                        className={'group  h-[30px] cursor-pointer px-[10px]'}
                      >
                        <div className="relative flex flex-row pr-[30px]">
                          <div
                            className="flex w-full flex-row items-center gap-2"
                            onClick={() =>
                              props.onNavigate(
                                `/${props.workspace.url}/project/${project?.code}`,
                              )
                            }
                          >
                            <div className="w-[20px] flex-none">
                              <PackageIcon width={20} height={20} />
                            </div>
                            <Label className="w-full grow truncate">
                              {project?.title}
                            </Label>
                          </div>
                          <Button
                            variant={'link'}
                            className="absolute right-0 top-0 hidden group-hover:block"
                            onClick={() =>
                              selected?.id &&
                              toggleFavorite.mutate({
                                toggleFavorite: {
                                  map_id: item.map_id,
                                  type: FavoriteType.Project,
                                  workspace_id: selected?.id,
                                },
                              })
                            }
                          >
                            <CloseFillIcon width={10} height={10} />
                          </Button>
                        </div>
                      </Button>
                    )}
                  />
                ),
                orElse: () => <></>,
              }),
            )}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default FavoriteSection;
