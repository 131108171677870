import { useContext, useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { LabelFragment, TeamFragment } from '@/types/tanstack-query/generated';

import SettingLabelsComponent from '../../labels/SettingLabelsComponent';

const SettingTeamLabelsPage = () => {
  const { teams, labels: coreLabels } = useContext(WorkspaceContext);
  const params = useParams();
  const [labels, setLabels] = useState<LabelFragment[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<TeamFragment>();

  useEffect(() => {
    const identifier = params.team;
    const getTeam = teams.find((item) => item.identifier === identifier);
    setSelectedTeam(getTeam);
    setLabels(coreLabels.filter((item) => item.team_id === getTeam?.id));
  }, [params, coreLabels]);

  return <SettingLabelsComponent labels={labels} team={selectedTeam} />;
};

export default SettingTeamLabelsPage;
