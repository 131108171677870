import { useContext } from 'react';

import { cn } from '@udecode/cn';
import { CalendarX2Icon, PackageIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';
import { Label } from '@/components/Label';
import { UserAvatar } from '@/components/UserAvatar';
import { useErrorHandler } from '@/hooks/useToasterHandler';
import { UserAssignIcon } from '@/icons/core/issue';
import MemoizedAssignToButton from '@/pages/issue/components/Properties/AssignToButton';
import MemoizedDueDateButton from '@/pages/issue/components/Properties/DueDateButton';
import {
  MemoizedPriorityButton,
  MemoizedRenderPriorityIcon,
} from '@/pages/issue/components/Properties/PriorityButton';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import {
  PriorityType,
  ProjectFragment,
  useUpdateProjectMutation,
} from '@/types/tanstack-query/generated';
import { CalcPercentOfProject } from '@/utils/calc-percent';
import CustomDateTime from '@/utils/format-datetime';

import { ToolsMenuProject } from '../../ContextMenuProject';
import {
  MemoizedProjectStatusButton,
  MemoizedRenderProjectStatusIcon,
} from '../ProjectStatusButton';

const RowHeader = () => {
  return (
    <div className="border-bottom flex h-[40px] w-full cursor-pointer items-center gap-2 overflow-hidden border-[1px] border-secondary px-[10px] hover:bg-secondary">
      <div className="flex w-full flex-row items-center gap-2">
        <div className="flex w-full">
          <Label className="line-clamp-1 flex-auto">Title</Label>
        </div>
        <div className="hidden w-[60px] flex-none items-center  justify-center md:flex">
          <Label className="line-clamp-1 flex-auto">Priority</Label>
        </div>
        <div className="hidden w-[60px] flex-none items-center justify-center  md:flex">
          <Label className="line-clamp-1 flex-auto">Status</Label>
        </div>
        <div className="hidden w-[40px] flex-none items-center justify-center md:flex">
          <Label className="line-clamp-1 flex-auto">Lead</Label>
        </div>

        <div className="hidden w-[120px] flex-none items-center justify-center md:flex">
          <Label className="line-clamp-1 flex-auto">Target Date</Label>
        </div>
      </div>
    </div>
  );
};
const RowItem = (props: {
  project: ProjectFragment;
  onSelectedProjectStatus: (value: string | undefined) => void;
  onSelectedPriority: (value: PriorityType | undefined) => void;
  onSelectedLeadId: (value: string | undefined) => void;
  onSelectedEndAt: (value: Date | undefined) => void;
}) => {
  const { selected } = useContext(WorkspaceContext);
  const navigate = useNavigate();
  return (
    <div className="border-bottom flex h-[40px] w-full cursor-pointer items-center gap-2 overflow-hidden border-[1px] border-secondary px-[10px] hover:bg-secondary">
      <div className="flex w-full flex-row items-center gap-2">
        <span className="flex-none">
          <PackageIcon width={20} height={20} />
        </span>
        <div
          className="flex w-full"
          onClick={() =>
            navigate(`/${selected?.url}/project/${props.project.code}/overview`)
          }
        >
          <Label asChild className="line-clamp-1 flex-auto">
            <span> {props.project.title}</span>
          </Label>
        </div>
        <div className="flex flex-none items-start md:w-[60px] ">
          <MemoizedPriorityButton
            selected={props.project.priority}
            onSelected={props.onSelectedPriority}
            renderItem={(value) => (
              <Button
                className="flex flex-row items-center justify-start gap-2 p-0 text-left"
                variant="ghost"
                type="button"
              >
                <span className="flex">
                  <MemoizedRenderPriorityIcon type={value?.type} />
                </span>
              </Button>
            )}
          />
        </div>
        <div className="flex flex-none items-start md:w-[60px]">
          <MemoizedProjectStatusButton
            project_id={props.project.id}
            selected={props.project.project_status_id ?? undefined}
            onSelected={props.onSelectedProjectStatus}
            renderItem={(value) => (
              <Button
                size={'icon'}
                variant={'ghost'}
                type="button"
                className="flex flex-row items-center justify-start gap-2 text-left"
              >
                <MemoizedRenderProjectStatusIcon
                  type={value?.type}
                  color={value?.color}
                  processing={value?.processing}
                />
                {CalcPercentOfProject({
                  started: props.project.analytics?.started ?? 0,
                  completed: props.project.analytics?.completed ?? 0,
                  total: props.project.analytics?.scope ?? 0,
                })}
                %
              </Button>
            )}
          />
        </div>

        <div className="hidden w-[40px] flex-none items-start md:flex">
          <MemoizedAssignToButton
            placeholder={'Set project lead'}
            teamIds={props.project.team_ids?.split(',') ?? []}
            selected={props.project.leader_id ?? undefined}
            onSelected={props.onSelectedLeadId}
            renderItem={(value) => (
              <Button
                className="flex flex-row items-center justify-start gap-2 p-0 text-left"
                variant="ghost"
                type="button"
              >
                {!props.project.leader_id && (
                  <div className="flex flex-row items-center gap-2">
                    <UserAssignIcon
                      width={22}
                      height={22}
                      style={{ color: '#9e9fa8' }}
                    />
                  </div>
                )}
                {props.project.leader_id && (
                  <div className="flex flex-row items-center gap-2">
                    <UserAvatar
                      size="sm"
                      url={value?.image}
                      fallback={value?.username}
                    />
                  </div>
                )}
              </Button>
            )}
          />
        </div>

        <div className="hidden w-[120px] flex-none items-center justify-start md:flex">
          <MemoizedDueDateButton
            selected={
              props.project.end_at ? new Date(props.project.end_at) : undefined
            }
            onSelected={props.onSelectedEndAt}
            renderItem={(value) => (
              <Button
                variant={'outline'}
                className={cn(
                  'justify-start text-left font-normal',
                  !value && 'text-muted-foreground',
                )}
              >
                <CalendarX2Icon
                  className="mr-2"
                  width={16}
                  height={16}
                  color={'red'}
                />
                {value ? CustomDateTime.formatDate(value) : 'Pick a Due date'}
              </Button>
            )}
          />
        </div>
      </div>
    </div>
  );
};

const ProjectTableList = (props: { projects: ProjectFragment[] }) => {
  const { showErrorToast } = useErrorHandler();
  const updateProject = useUpdateProjectMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {},
    onSettled: () => {},
  });

  return (
    <div className="w-full">
      <RowHeader />
      {props.projects.map((item) => (
        <ToolsMenuProject
          contextMenu={true}
          key={item.id}
          project={item}
          workspace_id={item.workspace_id}
        >
          <RowItem
            key={item.id}
            project={item}
            onSelectedProjectStatus={(value) =>
              updateProject.mutate({
                updateInput: {
                  id: item.id,
                  project_status_id: value,
                },
              })
            }
            onSelectedPriority={(value) =>
              updateProject.mutate({
                updateInput: {
                  id: item.id,
                  priority: value,
                },
              })
            }
            onSelectedLeadId={(value) =>
              updateProject.mutate({
                updateInput: {
                  id: item.id,
                  leader_id: value,
                },
              })
            }
            onSelectedEndAt={(value) =>
              updateProject.mutate({
                updateInput: {
                  id: item.id,
                  end_at: value?.toISOString(),
                },
              })
            }
          />
        </ToolsMenuProject>
      ))}
    </div>
  );
};

export default ProjectTableList;
