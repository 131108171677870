import {
  InvalidInputError,
  InvalidCredentialsError,
} from '@/services/core/error-type';
import { ClientError } from 'graphql-request';
import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  InfiniteData,
  UseMutationOptions,
} from '@tanstack/react-query';
import { useFetchData } from '@/hooks/useFetchData';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: string; output: string };
  JSONObject: { input: object; output: object };
  Upload: { input: File; output: File };
};

export type ActivityEntity = {
  __typename?: 'ActivityEntity';
  comment?: Maybe<CommentEntity>;
  comment_id?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  created_by: Scalars['String']['output'];
  from_assignee?: Maybe<UserEntity>;
  from_assignee_id?: Maybe<Scalars['String']['output']>;
  from_due_date?: Maybe<Scalars['DateTime']['output']>;
  from_priority?: Maybe<PriorityType>;
  from_project?: Maybe<ProjectEntity>;
  from_project_id?: Maybe<Scalars['String']['output']>;
  from_workflow?: Maybe<WorkflowEntity>;
  from_workflow_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
  issue_id: Scalars['String']['output'];
  label_ids?: Maybe<Scalars['String']['output']>;
  to_assignee?: Maybe<UserEntity>;
  to_assignee_id?: Maybe<Scalars['String']['output']>;
  to_due_date?: Maybe<Scalars['DateTime']['output']>;
  to_priority?: Maybe<PriorityType>;
  to_project?: Maybe<ProjectEntity>;
  to_project_id?: Maybe<Scalars['String']['output']>;
  to_workflow?: Maybe<WorkflowEntity>;
  to_workflow_id?: Maybe<Scalars['String']['output']>;
  type: ActivityType;
  user?: Maybe<UserEntity>;
  workspace_id: Scalars['String']['output'];
};

export enum ActivityType {
  IssueCreated = 'ISSUE_CREATED',
  IssueCreatedComment = 'ISSUE_CREATED_COMMENT',
  IssueUpdateAssignee = 'ISSUE_UPDATE_ASSIGNEE',
  IssueUpdateComment = 'ISSUE_UPDATE_COMMENT',
  IssueUpdateDescription = 'ISSUE_UPDATE_DESCRIPTION',
  IssueUpdateDueDate = 'ISSUE_UPDATE_DUE_DATE',
  IssueUpdateLabels = 'ISSUE_UPDATE_LABELS',
  IssueUpdatePriority = 'ISSUE_UPDATE_PRIORITY',
  IssueUpdateProject = 'ISSUE_UPDATE_PROJECT',
  IssueUpdateTitle = 'ISSUE_UPDATE_TITLE',
  IssueUpdateWorkflow = 'ISSUE_UPDATE_WORKFLOW',
}

export type CommentEntity = {
  __typename?: 'CommentEntity';
  comment_id?: Maybe<Scalars['String']['output']>;
  content: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  created_by: Scalars['String']['output'];
  id: Scalars['String']['output'];
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
  issue_id: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  user?: Maybe<UserEntity>;
  workspace_id: Scalars['String']['output'];
};

export type CreateActivityInput = {
  comment_id?: InputMaybe<Scalars['String']['input']>;
  from_assignee_id?: InputMaybe<Scalars['String']['input']>;
  from_due_date?: InputMaybe<Scalars['DateTime']['input']>;
  from_priority?: InputMaybe<PriorityType>;
  from_project_id?: InputMaybe<Scalars['String']['input']>;
  from_workflow_id?: InputMaybe<Scalars['String']['input']>;
  issue_id: Scalars['String']['input'];
  label_ids?: InputMaybe<Scalars['String']['input']>;
  to_assignee_id?: InputMaybe<Scalars['String']['input']>;
  to_due_date?: InputMaybe<Scalars['DateTime']['input']>;
  to_priority?: InputMaybe<PriorityType>;
  to_project_id?: InputMaybe<Scalars['String']['input']>;
  to_workflow_id?: InputMaybe<Scalars['String']['input']>;
  type: ActivityType;
  workspace_id: Scalars['String']['input'];
};

export type CreateCommentInput = {
  comment_id?: InputMaybe<Scalars['String']['input']>;
  content: Scalars['String']['input'];
  issue_id: Scalars['String']['input'];
  workspace_id: Scalars['String']['input'];
};

export type CreateDocumentInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  document_id?: InputMaybe<Scalars['String']['input']>;
  team_id: Scalars['String']['input'];
  title: Scalars['String']['input'];
  workspace_id: Scalars['String']['input'];
};

export type CreateFavoriteInput = {
  map_id: Scalars['String']['input'];
  type: FavoriteType;
  workspace_id: Scalars['String']['input'];
};

export type CreateFileInput = {
  file: Scalars['Upload']['input'];
  type: Scalars['String']['input'];
  workspace_id: Scalars['String']['input'];
};

export type CreateIssueInput = {
  assignee_id?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  due_date_at?: InputMaybe<Scalars['DateTime']['input']>;
  issue_id?: InputMaybe<Scalars['String']['input']>;
  label_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  priority: PriorityType;
  project_id?: InputMaybe<Scalars['String']['input']>;
  team_id: Scalars['String']['input'];
  title: Scalars['String']['input'];
  workflow_id: Scalars['String']['input'];
  workspace_id: Scalars['String']['input'];
};

export type CreateLabelInput = {
  color: Scalars['String']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  team_id?: InputMaybe<Scalars['String']['input']>;
  workspace_id: Scalars['String']['input'];
};

export type CreateMemberWorkspaceInput = {
  email: Scalars['String']['input'];
  member_id?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Array<Scalars['String']['input']>>;
  workspace_id: Scalars['String']['input'];
};

export type CreateProjectInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  end_at?: InputMaybe<Scalars['DateTime']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  label_ids?: InputMaybe<Scalars['String']['input']>;
  leader_id?: InputMaybe<Scalars['String']['input']>;
  member_ids?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<PriorityType>;
  project_status_id?: InputMaybe<Scalars['String']['input']>;
  short_summary?: InputMaybe<Scalars['String']['input']>;
  start_at?: InputMaybe<Scalars['DateTime']['input']>;
  team_ids?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  workspace_id: Scalars['String']['input'];
};

export type CreateProjectStatusInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<ProjectStatusType>;
  workspace_id?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSubscriberInput = {
  map_id: Scalars['String']['input'];
  type: SubscriberType;
  workspace_id: Scalars['String']['input'];
};

export type CreateTeamInput = {
  color: Scalars['String']['input'];
  icon: Scalars['String']['input'];
  identifier: Scalars['String']['input'];
  name: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
  workspace_id: Scalars['String']['input'];
};

export type CreateUploadDocumentAttachmentInput = {
  document_id: Scalars['String']['input'];
  file: Scalars['Upload']['input'];
  workspace_id: Scalars['String']['input'];
};

export type CreateUploadIssueAttachmentInput = {
  file: Scalars['Upload']['input'];
  issue_id: Scalars['String']['input'];
  workspace_id: Scalars['String']['input'];
};

export type CreateUserInput = {
  email: Scalars['String']['input'];
  full_name: Scalars['String']['input'];
  mode?: ThemeType;
  username: Scalars['String']['input'];
};

export type CreateWorkflowInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Float']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<WorkflowType>;
  workspace_id?: InputMaybe<Scalars['String']['input']>;
};

export type CreateWorkspaceInput = {
  disabled_invite_link?: InputMaybe<Scalars['Boolean']['input']>;
  large_company?: InputMaybe<Scalars['Float']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  url: Scalars['String']['input'];
  your_role?: InputMaybe<Scalars['Float']['input']>;
};

export type DeleteWorkspaceInput = {
  code: Scalars['String']['input'];
  type: OtpType;
  workspace_id: Scalars['String']['input'];
};

export type DocumentAttachmentEntity = {
  __typename?: 'DocumentAttachmentEntity';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  created_by: Scalars['String']['output'];
  document_id: Scalars['String']['output'];
  file?: Maybe<FileEntity>;
  file_id: Scalars['String']['output'];
  id: Scalars['String']['output'];
  user?: Maybe<UserEntity>;
  workspace?: Maybe<WorkspaceEntity>;
  workspace_id?: Maybe<Scalars['String']['output']>;
};

export type DocumentEntity = {
  __typename?: 'DocumentEntity';
  created_at: Scalars['DateTime']['output'];
  created_by: Scalars['String']['output'];
  description: Scalars['String']['output'];
  document_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  is_deleted: Scalars['Boolean']['output'];
  team_id: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
  user: UserEntity;
  workspace_id: Scalars['String']['output'];
};

export type DocumentVersionEntity = {
  __typename?: 'DocumentVersionEntity';
  content?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  created_by: Scalars['String']['output'];
  document_id: Scalars['String']['output'];
  id: Scalars['String']['output'];
  user?: Maybe<UserEntity>;
  workspace_id: Scalars['String']['output'];
};

export type FavoriteEntity = {
  __typename?: 'FavoriteEntity';
  created_at: Scalars['DateTime']['output'];
  created_by: Scalars['String']['output'];
  id: Scalars['String']['output'];
  map_id: Scalars['String']['output'];
  type: FavoriteType;
  user?: Maybe<UserEntity>;
  workspace_id?: Maybe<Scalars['String']['output']>;
};

export enum FavoriteType {
  Document = 'DOCUMENT',
  Issue = 'ISSUE',
  Project = 'PROJECT',
  Team = 'TEAM',
}

export type FileEntity = {
  __typename?: 'FileEntity';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  mime_type: Scalars['String']['output'];
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  user?: Maybe<UserEntity>;
  workspace?: Maybe<WorkspaceEntity>;
  workspace_id?: Maybe<Scalars['String']['output']>;
};

export type GetActivitiesInput = {
  issue_id: Scalars['String']['input'];
  workspace_id: Scalars['String']['input'];
};

export type GetAnalyticsProject = {
  __typename?: 'GetAnalyticsProject';
  completed: Scalars['Float']['output'];
  labels: Array<GetAnalyticsProjectLabels>;
  members: Array<GetAnalyticsProjectMembers>;
  project_id: Scalars['String']['output'];
  scope: Scalars['Float']['output'];
  started: Scalars['Float']['output'];
  workspace_id: Scalars['String']['output'];
};

export type GetAnalyticsProjectLabels = {
  __typename?: 'GetAnalyticsProjectLabels';
  id: Scalars['String']['output'];
  total_completed: Scalars['Float']['output'];
  total_scope: Scalars['Float']['output'];
  total_started: Scalars['Float']['output'];
};

export type GetAnalyticsProjectMembers = {
  __typename?: 'GetAnalyticsProjectMembers';
  id: Scalars['String']['output'];
  total_completed: Scalars['Float']['output'];
  total_scope: Scalars['Float']['output'];
  total_started: Scalars['Float']['output'];
};

export type GetCommentsInput = {
  issue_id: Scalars['String']['input'];
  workspace_id: Scalars['String']['input'];
};

export type GetDocumentAttachmentsInput = {
  document_id: Scalars['String']['input'];
  workspace_id: Scalars['String']['input'];
};

export type GetDocumentInput = {
  id: Scalars['String']['input'];
};

export type GetDocumentVersionsInput = {
  document_id: Scalars['String']['input'];
};

export type GetDocumentsInput = {
  created_by?: InputMaybe<Scalars['String']['input']>;
  document_id?: InputMaybe<Scalars['String']['input']>;
  workspace_id: Scalars['String']['input'];
};

export type GetFilesInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  workspace_id: Scalars['String']['input'];
};

export type GetIssueAttachmentsInput = {
  issue_id: Scalars['String']['input'];
  workspace_id: Scalars['String']['input'];
};

export type GetIssueInput = {
  issue_identifier: Scalars['Float']['input'];
  team_identifier: Scalars['String']['input'];
  workspace_url: Scalars['String']['input'];
};

export type GetIssueLabelsInput = {
  issue_id: Scalars['String']['input'];
};

export type GetIssueVersionsInput = {
  issue_id: Scalars['String']['input'];
};

export type GetIssuesInput = {
  assignee_id?: InputMaybe<Scalars['String']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  project_id?: InputMaybe<Scalars['String']['input']>;
  subscribe_by?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  workspace_id: Scalars['String']['input'];
};

export type GetLabelsInput = {
  team_id?: InputMaybe<Scalars['String']['input']>;
  workspace_id: Scalars['String']['input'];
};

export type GetMemberTeamInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
};

export type GetMemberWorkspaceInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  workspace_id?: InputMaybe<Scalars['String']['input']>;
};

export type GetNotificationsInput = {
  workspace_id: Scalars['String']['input'];
};

export type GetProjectStatusesInput = {
  workspace_id: Scalars['String']['input'];
};

export type GetProjectVersionsInput = {
  project_id: Scalars['String']['input'];
};

export type GetProjectsInput = {
  workspace_id: Scalars['String']['input'];
};

export type GetWorkflowsInput = {
  team_id?: InputMaybe<Scalars['String']['input']>;
  workspace_id: Scalars['String']['input'];
};

export type IssueAttachmentEntity = {
  __typename?: 'IssueAttachmentEntity';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  created_by: Scalars['String']['output'];
  file?: Maybe<FileEntity>;
  file_id: Scalars['String']['output'];
  id: Scalars['String']['output'];
  issue_id: Scalars['String']['output'];
  user?: Maybe<UserEntity>;
  workspace?: Maybe<WorkspaceEntity>;
  workspace_id?: Maybe<Scalars['String']['output']>;
};

export type IssueEntity = {
  __typename?: 'IssueEntity';
  assignee_id?: Maybe<Scalars['String']['output']>;
  code: Scalars['Int']['output'];
  created_at: Scalars['DateTime']['output'];
  created_by: Scalars['String']['output'];
  description: Scalars['String']['output'];
  due_date_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  is_deleted: Scalars['Boolean']['output'];
  issue_id?: Maybe<Scalars['String']['output']>;
  labels?: Maybe<Array<IssueLabelEntity>>;
  priority: PriorityType;
  project_id?: Maybe<Scalars['String']['output']>;
  team_id?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
  user: UserEntity;
  workflow_id?: Maybe<Scalars['String']['output']>;
  workspace_id: Scalars['String']['output'];
};

export type IssueLabelEntity = {
  __typename?: 'IssueLabelEntity';
  created_at: Scalars['DateTime']['output'];
  created_by: Scalars['String']['output'];
  id: Scalars['String']['output'];
  issue_id: Scalars['String']['output'];
  label_id: Scalars['String']['output'];
  user?: Maybe<UserEntity>;
  workspace_id: Scalars['String']['output'];
};

export type IssueVersionEntity = {
  __typename?: 'IssueVersionEntity';
  content?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  created_by: Scalars['String']['output'];
  id: Scalars['String']['output'];
  issue_id: Scalars['String']['output'];
  user?: Maybe<UserEntity>;
  workspace_id: Scalars['String']['output'];
};

export type JoinWorkspaceInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  invite?: InputMaybe<Scalars['String']['input']>;
};

export type LabelEntity = {
  __typename?: 'LabelEntity';
  color: Scalars['String']['output'];
  created_at?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  team_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<UserEntity>;
  workspace?: Maybe<WorkspaceEntity>;
  workspace_id?: Maybe<Scalars['String']['output']>;
};

export type MemberTeamEntity = {
  __typename?: 'MemberTeamEntity';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  member?: Maybe<UserEntity>;
  member_id?: Maybe<Scalars['String']['output']>;
  team?: Maybe<TeamEntity>;
  team_id: Scalars['String']['output'];
  user?: Maybe<UserEntity>;
  workspace?: Maybe<WorkspaceEntity>;
  workspace_id: Scalars['String']['output'];
};

export type MemberWorkspaceEntity = {
  __typename?: 'MemberWorkspaceEntity';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  is_joined: Scalars['Boolean']['output'];
  joined_at?: Maybe<Scalars['DateTime']['output']>;
  member_id: Scalars['String']['output'];
  user?: Maybe<UserEntity>;
  workspace?: Maybe<WorkspaceEntity>;
};

export type Mutation = {
  __typename?: 'Mutation';
  confirmDeleteWorkspace: Scalars['Boolean']['output'];
  createActivity: ActivityEntity;
  createAttachment: FileEntity;
  createComment?: Maybe<Scalars['Boolean']['output']>;
  createDocument: DocumentEntity;
  createDocumentAttachment: DocumentAttachmentEntity;
  createIssue: IssueEntity;
  createIssueAttachment: IssueAttachmentEntity;
  createLabel: LabelEntity;
  createMemberWorkspace: Scalars['Boolean']['output'];
  createProject: ProjectEntity;
  createProjectStatus: ProjectStatusEntity;
  createTeam: TeamEntity;
  createUser: UserEntity;
  createWorkflow: WorkflowEntity;
  createWorkspace: WorkspaceEntity;
  deleteDocumentAttachment: Scalars['Boolean']['output'];
  deleteIssue: Scalars['Boolean']['output'];
  deleteIssueAttachment: Scalars['Boolean']['output'];
  deleteProject: Scalars['Boolean']['output'];
  generateInviteLink: Scalars['String']['output'];
  joinInviteLink: Scalars['Boolean']['output'];
  joinWorkspace: Scalars['Boolean']['output'];
  markReadNotification: Scalars['Boolean']['output'];
  removeComment: Scalars['Boolean']['output'];
  removeDocument: Scalars['Boolean']['output'];
  removeLabel: LabelEntity;
  removeMemberTeam: MemberTeamEntity;
  removeMemberWorkspace: Scalars['Boolean']['output'];
  removeProjectStatus: ProjectStatusEntity;
  removeTeam: TeamEntity;
  removeWorkflow: WorkflowEntity;
  removeWorkspace: WorkspaceEntity;
  restoreDocumentVersion: Scalars['Boolean']['output'];
  restoreProjectVersion: Scalars['Boolean']['output'];
  restoreVersion: Scalars['Boolean']['output'];
  sendOtpDeleteWorkspace: Scalars['Boolean']['output'];
  signIn: Scalars['Boolean']['output'];
  sortableProjectStatus: Scalars['Boolean']['output'];
  sortableWorkflow: Scalars['Boolean']['output'];
  subscribe: Scalars['Boolean']['output'];
  toggleFavorite: Scalars['Boolean']['output'];
  updateComment?: Maybe<Scalars['Boolean']['output']>;
  updateDocument: Scalars['Boolean']['output'];
  updateIssue: IssueEntity;
  updateLabel: LabelEntity;
  updateProject: Scalars['Boolean']['output'];
  updateProjectStatus: ProjectStatusEntity;
  updateTeam: TeamEntity;
  updateThemeMode: Scalars['Boolean']['output'];
  updateUser: Scalars['Boolean']['output'];
  updateWorkflow: WorkflowEntity;
  updateWorkspace: WorkspaceEntity;
  validateOTP: SignInResponse;
};

export type MutationConfirmDeleteWorkspaceArgs = {
  deleteInput: DeleteWorkspaceInput;
};

export type MutationCreateActivityArgs = {
  createInput: CreateActivityInput;
};

export type MutationCreateAttachmentArgs = {
  createInput: CreateFileInput;
};

export type MutationCreateCommentArgs = {
  createInput: CreateCommentInput;
};

export type MutationCreateDocumentArgs = {
  createInput: CreateDocumentInput;
};

export type MutationCreateDocumentAttachmentArgs = {
  createInput: CreateUploadDocumentAttachmentInput;
};

export type MutationCreateIssueArgs = {
  createInput: CreateIssueInput;
};

export type MutationCreateIssueAttachmentArgs = {
  createInput: CreateUploadIssueAttachmentInput;
};

export type MutationCreateLabelArgs = {
  createInput: CreateLabelInput;
};

export type MutationCreateMemberWorkspaceArgs = {
  createInput: CreateMemberWorkspaceInput;
};

export type MutationCreateProjectArgs = {
  createInput: CreateProjectInput;
};

export type MutationCreateProjectStatusArgs = {
  createInput: CreateProjectStatusInput;
};

export type MutationCreateTeamArgs = {
  createInput: CreateTeamInput;
};

export type MutationCreateUserArgs = {
  createUserInput: CreateUserInput;
};

export type MutationCreateWorkflowArgs = {
  createInput: CreateWorkflowInput;
};

export type MutationCreateWorkspaceArgs = {
  createInput: CreateWorkspaceInput;
};

export type MutationDeleteDocumentAttachmentArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteIssueArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteIssueAttachmentArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteProjectArgs = {
  id: Scalars['String']['input'];
};

export type MutationGenerateInviteLinkArgs = {
  workspace_id: Scalars['String']['input'];
};

export type MutationJoinInviteLinkArgs = {
  invite_link: Scalars['String']['input'];
};

export type MutationJoinWorkspaceArgs = {
  joinInput: JoinWorkspaceInput;
};

export type MutationMarkReadNotificationArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveCommentArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveDocumentArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveLabelArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveMemberTeamArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveMemberWorkspaceArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveProjectStatusArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveTeamArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveWorkflowArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveWorkspaceArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRestoreDocumentVersionArgs = {
  id: Scalars['String']['input'];
};

export type MutationRestoreProjectVersionArgs = {
  id: Scalars['String']['input'];
};

export type MutationRestoreVersionArgs = {
  id: Scalars['String']['input'];
};

export type MutationSendOtpDeleteWorkspaceArgs = {
  workspace_id: Scalars['String']['input'];
};

export type MutationSignInArgs = {
  email: Scalars['String']['input'];
};

export type MutationSortableProjectStatusArgs = {
  ids: Array<Scalars['String']['input']>;
};

export type MutationSortableWorkflowArgs = {
  ids: Array<Scalars['String']['input']>;
};

export type MutationSubscribeArgs = {
  input: CreateSubscriberInput;
};

export type MutationToggleFavoriteArgs = {
  toggleFavorite: CreateFavoriteInput;
};

export type MutationUpdateCommentArgs = {
  updateInput: UpdateCommentInput;
};

export type MutationUpdateDocumentArgs = {
  updateInput: UpdateDocumentInput;
};

export type MutationUpdateIssueArgs = {
  updateInput: UpdateIssueInput;
};

export type MutationUpdateLabelArgs = {
  updateInput: UpdateLabelInput;
};

export type MutationUpdateProjectArgs = {
  updateInput: UpdateProjectInput;
};

export type MutationUpdateProjectStatusArgs = {
  updateInput: UpdateProjectStatusInput;
};

export type MutationUpdateTeamArgs = {
  updateInput: UpdateTeamInput;
};

export type MutationUpdateThemeModeArgs = {
  updateInput: UpdateThemeInput;
};

export type MutationUpdateUserArgs = {
  updateInput: UpdateUserInput;
};

export type MutationUpdateWorkflowArgs = {
  updateInput: UpdateWorkflowInput;
};

export type MutationUpdateWorkspaceArgs = {
  updateInput: UpdateWorkspaceInput;
};

export type MutationValidateOtpArgs = {
  validateInput: ValidateOtpSignInInput;
};

export type NotificationEntity = {
  __typename?: 'NotificationEntity';
  activity_id?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  created_by: Scalars['String']['output'];
  event?: Maybe<PushEventType>;
  id: Scalars['String']['output'];
  map_id: Scalars['String']['output'];
  mark_read: Scalars['Boolean']['output'];
  member_id: Scalars['String']['output'];
  type: NotificationType;
  updated_at: Scalars['DateTime']['output'];
  user?: Maybe<UserEntity>;
  workspace_id: Scalars['String']['output'];
};

export enum NotificationType {
  Document = 'DOCUMENT',
  Issue = 'ISSUE',
  Project = 'PROJECT',
}

export enum OtpType {
  DeleteWorkspace = 'DELETE_WORKSPACE',
  SignIn = 'SIGN_IN',
}

export enum PriorityType {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  NoPriority = 'NO_PRIORITY',
  Urgent = 'URGENT',
}

export type ProjectEntity = {
  __typename?: 'ProjectEntity';
  analytics?: Maybe<GetAnalyticsProject>;
  code: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  created_by: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  end_at?: Maybe<Scalars['DateTime']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  is_deleted: Scalars['Boolean']['output'];
  label_ids?: Maybe<Scalars['String']['output']>;
  leader_id?: Maybe<Scalars['String']['output']>;
  member_ids?: Maybe<Scalars['String']['output']>;
  priority: PriorityType;
  project_status_id?: Maybe<Scalars['String']['output']>;
  short_summary?: Maybe<Scalars['String']['output']>;
  start_at?: Maybe<Scalars['DateTime']['output']>;
  team_ids?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
  user: UserEntity;
  workspace_id: Scalars['String']['output'];
};

export type ProjectStatusEntity = {
  __typename?: 'ProjectStatusEntity';
  color?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  processing: Scalars['Float']['output'];
  type: ProjectStatusType;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<UserEntity>;
  workspace?: Maybe<WorkspaceEntity>;
};

export enum ProjectStatusType {
  Backlog = 'BACKLOG',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Planned = 'PLANNED',
}

export type ProjectVersionEntity = {
  __typename?: 'ProjectVersionEntity';
  content?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  created_by: Scalars['String']['output'];
  id: Scalars['String']['output'];
  project_id: Scalars['String']['output'];
  user?: Maybe<UserEntity>;
  workspace_id: Scalars['String']['output'];
};

export enum PushEventType {
  ActivityAddedComment = 'ACTIVITY_ADDED_COMMENT',
  ActivityDeletedComment = 'ACTIVITY_DELETED_COMMENT',
  ActivityUpdatedAssignee = 'ACTIVITY_UPDATED_ASSIGNEE',
  ActivityUpdatedDescription = 'ACTIVITY_UPDATED_DESCRIPTION',
  ActivityUpdatedDueDate = 'ACTIVITY_UPDATED_DUE_DATE',
  ActivityUpdatedLabels = 'ACTIVITY_UPDATED_LABELS',
  ActivityUpdatedPriority = 'ACTIVITY_UPDATED_PRIORITY',
  ActivityUpdatedProject = 'ACTIVITY_UPDATED_PROJECT',
  ActivityUpdatedWorkflow = 'ACTIVITY_UPDATED_WORKFLOW',
  CommentAdded = 'COMMENT_ADDED',
  CommentDeleted = 'COMMENT_DELETED',
  CommentUpdated = 'COMMENT_UPDATED',
  DocumentAdded = 'DOCUMENT_ADDED',
  DocumentAttachmentAdded = 'DOCUMENT_ATTACHMENT_ADDED',
  DocumentAttachmentDeleted = 'DOCUMENT_ATTACHMENT_DELETED',
  DocumentDeleted = 'DOCUMENT_DELETED',
  DocumentSubscribed = 'DOCUMENT_SUBSCRIBED',
  DocumentUnsubscribed = 'DOCUMENT_UNSUBSCRIBED',
  DocumentUpdated = 'DOCUMENT_UPDATED',
  FavoriteAdded = 'FAVORITE_ADDED',
  FavoriteDeleted = 'FAVORITE_DELETED',
  IssueAdded = 'ISSUE_ADDED',
  IssueAttachmentAdded = 'ISSUE_ATTACHMENT_ADDED',
  IssueAttachmentDeleted = 'ISSUE_ATTACHMENT_DELETED',
  IssueDeleted = 'ISSUE_DELETED',
  IssueSubscribed = 'ISSUE_SUBSCRIBED',
  IssueUnsubscribed = 'ISSUE_UNSUBSCRIBED',
  IssueUpdated = 'ISSUE_UPDATED',
  LabelAdded = 'LABEL_ADDED',
  LabelDeleted = 'LABEL_DELETED',
  LabelUpdated = 'LABEL_UPDATED',
  MemberWorkspaceAdded = 'MEMBER_WORKSPACE_ADDED',
  MemberWorkspaceDeleted = 'MEMBER_WORKSPACE_DELETED',
  NotificationDocumentUpdated = 'NOTIFICATION_DOCUMENT_UPDATED',
  NotificationNewAssignee = 'NOTIFICATION_NEW_ASSIGNEE',
  NotificationNewComment = 'NOTIFICATION_NEW_COMMENT',
  NotificationNewLeader = 'NOTIFICATION_NEW_LEADER',
  NotificationReplyComment = 'NOTIFICATION_REPLY_COMMENT',
  NotificationUpdateDueDate = 'NOTIFICATION_UPDATE_DUE_DATE',
  NotificationUpdateWorkflow = 'NOTIFICATION_UPDATE_WORKFLOW',
  ProjectAdded = 'PROJECT_ADDED',
  ProjectDeleted = 'PROJECT_DELETED',
  ProjectStatusAdded = 'PROJECT_STATUS_ADDED',
  ProjectStatusDeleted = 'PROJECT_STATUS_DELETED',
  ProjectStatusUpdated = 'PROJECT_STATUS_UPDATED',
  ProjectUpdated = 'PROJECT_UPDATED',
  TeamAdded = 'TEAM_ADDED',
  TeamDeleted = 'TEAM_DELETED',
  TeamUpdated = 'TEAM_UPDATED',
  UserUpdated = 'USER_UPDATED',
  WorkflowAdded = 'WORKFLOW_ADDED',
  WorkflowDeleted = 'WORKFLOW_DELETED',
  WorkflowUpdated = 'WORKFLOW_UPDATED',
}

export type Query = {
  __typename?: 'Query';
  activities: Array<ActivityEntity>;
  comments: Array<CommentEntity>;
  document: DocumentEntity;
  documentAttachments: Array<DocumentAttachmentEntity>;
  documentVersions: Array<DocumentVersionEntity>;
  documents: Array<DocumentEntity>;
  files: Array<FileEntity>;
  getInviteEmail: WorkspaceInviteEmail;
  getInviteLink: WorkspaceInviteLink;
  issue: IssueEntity;
  issueAttachments: Array<IssueAttachmentEntity>;
  issueLabels: Array<IssueLabelEntity>;
  issueVersions: Array<IssueVersionEntity>;
  issues: Array<IssueEntity>;
  labels: Array<LabelEntity>;
  labelsIncludeCore: Array<LabelEntity>;
  me: UserEntity;
  membersTeam: Array<MemberTeamEntity>;
  membersWorkspace: Array<MemberWorkspaceEntity>;
  notifications: Array<NotificationEntity>;
  pendingMembersWorkspace: Array<MemberWorkspaceEntity>;
  projectStatuses: Array<ProjectStatusEntity>;
  projectVersions: Array<ProjectVersionEntity>;
  projects: Array<ProjectEntity>;
  subscribers: Array<SubscriberEntity>;
  team: TeamEntity;
  teams: Array<TeamEntity>;
  workflows: Array<WorkflowEntity>;
  workspace: WorkspaceEntity;
  workspaceByURL?: Maybe<WorkspaceCore>;
  workspaces: Array<WorkspaceEntity>;
};

export type QueryActivitiesArgs = {
  getInput: GetActivitiesInput;
};

export type QueryCommentsArgs = {
  getInput: GetCommentsInput;
};

export type QueryDocumentArgs = {
  getInput: GetDocumentInput;
};

export type QueryDocumentAttachmentsArgs = {
  getInput: GetDocumentAttachmentsInput;
};

export type QueryDocumentVersionsArgs = {
  getInput: GetDocumentVersionsInput;
};

export type QueryDocumentsArgs = {
  getInput: GetDocumentsInput;
};

export type QueryFilesArgs = {
  getInput: GetFilesInput;
};

export type QueryGetInviteEmailArgs = {
  invite: Scalars['String']['input'];
};

export type QueryGetInviteLinkArgs = {
  invite_link: Scalars['String']['input'];
};

export type QueryIssueArgs = {
  getInput: GetIssueInput;
};

export type QueryIssueAttachmentsArgs = {
  getInput: GetIssueAttachmentsInput;
};

export type QueryIssueLabelsArgs = {
  getInput: GetIssueLabelsInput;
};

export type QueryIssueVersionsArgs = {
  getInput: GetIssueVersionsInput;
};

export type QueryIssuesArgs = {
  getInput: GetIssuesInput;
};

export type QueryLabelsArgs = {
  getInput: GetLabelsInput;
};

export type QueryLabelsIncludeCoreArgs = {
  getInput: GetLabelsInput;
};

export type QueryMembersTeamArgs = {
  getInput: GetMemberTeamInput;
};

export type QueryMembersWorkspaceArgs = {
  getInput: GetMemberWorkspaceInput;
};

export type QueryNotificationsArgs = {
  getInput: GetNotificationsInput;
};

export type QueryPendingMembersWorkspaceArgs = {
  getInput: GetMemberWorkspaceInput;
};

export type QueryProjectStatusesArgs = {
  getInput: GetProjectStatusesInput;
};

export type QueryProjectVersionsArgs = {
  getInput: GetProjectVersionsInput;
};

export type QueryProjectsArgs = {
  getInput: GetProjectsInput;
};

export type QuerySubscribersArgs = {
  map_id: Scalars['String']['input'];
};

export type QueryTeamArgs = {
  id: Scalars['Int']['input'];
};

export type QueryTeamsArgs = {
  workspace_id: Scalars['String']['input'];
};

export type QueryWorkflowsArgs = {
  getInput: GetWorkflowsInput;
};

export type QueryWorkspaceArgs = {
  id: Scalars['String']['input'];
};

export type QueryWorkspaceByUrlArgs = {
  URL?: InputMaybe<Scalars['String']['input']>;
};

export enum RoleType {
  Admin = 'ADMIN',
  Member = 'MEMBER',
}

export type SignInResponse = {
  __typename?: 'SignInResponse';
  access_token: Scalars['String']['output'];
  is_finished: Scalars['Boolean']['output'];
};

export type SubscriberEntity = {
  __typename?: 'SubscriberEntity';
  created_at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  map_id: Scalars['String']['output'];
  member?: Maybe<UserEntity>;
  member_id?: Maybe<Scalars['String']['output']>;
  type: SubscriberType;
  user?: Maybe<UserEntity>;
  workspace?: Maybe<WorkspaceEntity>;
  workspace_id: Scalars['String']['output'];
};

export enum SubscriberType {
  Document = 'DOCUMENT',
  Issue = 'ISSUE',
  Project = 'PROJECT',
}

export type Subscription = {
  __typename?: 'Subscription';
  subscribes: SubscriptionResponse;
};

export type SubscriptionSubscribesArgs = {
  workspace_id: Scalars['String']['input'];
};

export type SubscriptionResponse = {
  __typename?: 'SubscriptionResponse';
  created_at: Scalars['DateTime']['output'];
  data?: Maybe<Scalars['JSONObject']['output']>;
  event: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  workspace_id?: Maybe<Scalars['String']['output']>;
};

export type TeamEntity = {
  __typename?: 'TeamEntity';
  color: Scalars['String']['output'];
  created_at?: Maybe<Scalars['DateTime']['output']>;
  icon: Scalars['String']['output'];
  id: Scalars['String']['output'];
  identifier: Scalars['String']['output'];
  name: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
  total_Issues: Scalars['Int']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<UserEntity>;
  workspace?: Maybe<WorkspaceEntity>;
  workspace_id: Scalars['String']['output'];
};

export enum ThemeType {
  Dark = 'dark',
  Light = 'light',
  System = 'system',
}

export type UpdateCommentInput = {
  comment_id?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  issue_id?: InputMaybe<Scalars['String']['input']>;
  workspace_id?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDocumentInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateIssueInput = {
  assignee_id?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  due_date_at?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['String']['input'];
  label_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  priority?: InputMaybe<PriorityType>;
  project_id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  workflow_id?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLabelInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  workspace_id?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProjectInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  end_at?: InputMaybe<Scalars['DateTime']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  label_ids?: InputMaybe<Scalars['String']['input']>;
  leader_id?: InputMaybe<Scalars['String']['input']>;
  member_ids?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<PriorityType>;
  project_status_id?: InputMaybe<Scalars['String']['input']>;
  short_summary?: InputMaybe<Scalars['String']['input']>;
  start_at?: InputMaybe<Scalars['DateTime']['input']>;
  team_ids?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  workspace_id?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProjectStatusInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<ProjectStatusType>;
  workspace_id?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTeamInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  identifier?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  workspace_id?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateThemeInput = {
  mode: ThemeType;
};

export type UpdateUserInput = {
  full_name?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  theme?: InputMaybe<ThemeType>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWorkflowInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Float']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<WorkflowType>;
  workspace_id?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWorkspaceInput = {
  disabled_invite_link?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  large_company?: InputMaybe<Scalars['Float']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  your_role?: InputMaybe<Scalars['Float']['input']>;
};

export type UserEntity = {
  __typename?: 'UserEntity';
  created_at: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  full_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  image?: Maybe<Scalars['String']['output']>;
  theme?: Maybe<ThemeType>;
  username?: Maybe<Scalars['String']['output']>;
};

export type ValidateOtpSignInInput = {
  email: Scalars['String']['input'];
  otp: Scalars['String']['input'];
  type: OtpType;
};

export type WorkflowEntity = {
  __typename?: 'WorkflowEntity';
  color?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  processing: Scalars['Float']['output'];
  team?: Maybe<TeamEntity>;
  team_id: Scalars['String']['output'];
  type: WorkflowType;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<UserEntity>;
  workspace?: Maybe<WorkspaceEntity>;
  workspace_id: Scalars['String']['output'];
};

export enum WorkflowType {
  Backlog = 'BACKLOG',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Planned = 'PLANNED',
}

export type WorkspaceCore = {
  __typename?: 'WorkspaceCore';
  favorites: Array<FavoriteEntity>;
  labels: Array<LabelEntity>;
  member_teams: Array<MemberTeamEntity>;
  member_workspaces: Array<MemberWorkspaceEntity>;
  members: Array<UserEntity>;
  notifications: Array<NotificationEntity>;
  project_statuses: Array<ProjectStatusEntity>;
  role: RoleType;
  subscribers: Array<SubscriberEntity>;
  teams: Array<TeamEntity>;
  workflows: Array<WorkflowEntity>;
  workspace: WorkspaceEntity;
  workspaces: Array<WorkspaceEntity>;
};

export type WorkspaceEntity = {
  __typename?: 'WorkspaceEntity';
  created_at: Scalars['DateTime']['output'];
  created_by: Scalars['String']['output'];
  disabled_invite_link: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  invite_link?: Maybe<Scalars['String']['output']>;
  is_deleted: Scalars['Boolean']['output'];
  large_company?: Maybe<Scalars['Int']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
  user?: Maybe<UserEntity>;
  your_role?: Maybe<Scalars['Int']['output']>;
};

export type WorkspaceInviteEmail = {
  __typename?: 'WorkspaceInviteEmail';
  email_received: Scalars['String']['output'];
  email_sent: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type WorkspaceInviteLink = {
  __typename?: 'WorkspaceInviteLink';
  invite_link: Scalars['String']['output'];
  is_joined: Scalars['Boolean']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  members: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type ActivityFragment = {
  __typename?: 'ActivityEntity';
  id: string;
  type: ActivityType;
  issue_id: string;
  workspace_id: string;
  from_assignee_id?: string | null;
  to_assignee_id?: string | null;
  from_workflow_id?: string | null;
  to_workflow_id?: string | null;
  from_priority?: PriorityType | null;
  to_priority?: PriorityType | null;
  from_project_id?: string | null;
  to_project_id?: string | null;
  from_due_date?: string | null;
  to_due_date?: string | null;
  comment_id?: string | null;
  label_ids?: string | null;
  created_at: string;
  created_by: string;
};

export type GetActivitiesQueryVariables = Exact<{
  getInput: GetActivitiesInput;
}>;

export type GetActivitiesQuery = {
  __typename?: 'Query';
  activities: Array<{
    __typename?: 'ActivityEntity';
    id: string;
    type: ActivityType;
    issue_id: string;
    workspace_id: string;
    from_assignee_id?: string | null;
    to_assignee_id?: string | null;
    from_workflow_id?: string | null;
    to_workflow_id?: string | null;
    from_priority?: PriorityType | null;
    to_priority?: PriorityType | null;
    from_project_id?: string | null;
    to_project_id?: string | null;
    from_due_date?: string | null;
    to_due_date?: string | null;
    comment_id?: string | null;
    label_ids?: string | null;
    created_at: string;
    created_by: string;
  }>;
};

export type SignInMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type SignInMutation = { __typename?: 'Mutation'; signIn: boolean };

export type ValidateOtpMutationVariables = Exact<{
  validateInput: ValidateOtpSignInInput;
}>;

export type ValidateOtpMutation = {
  __typename?: 'Mutation';
  validateOTP: { __typename?: 'SignInResponse'; access_token: string };
};

export type CommentFragment = {
  __typename?: 'CommentEntity';
  id: string;
  issue_id: string;
  comment_id?: string | null;
  workspace_id: string;
  content: string;
  created_at: string;
  updated_at: string;
  created_by: string;
};

export type CreateCommentMutationVariables = Exact<{
  createInput: CreateCommentInput;
}>;

export type CreateCommentMutation = {
  __typename?: 'Mutation';
  createComment?: boolean | null;
};

export type UpdateCommentMutationVariables = Exact<{
  updateInput: UpdateCommentInput;
}>;

export type UpdateCommentMutation = {
  __typename?: 'Mutation';
  updateComment?: boolean | null;
};

export type RemoveCommentMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveCommentMutation = {
  __typename?: 'Mutation';
  removeComment: boolean;
};

export type GetCommentsQueryVariables = Exact<{
  getInput: GetCommentsInput;
}>;

export type GetCommentsQuery = {
  __typename?: 'Query';
  comments: Array<{
    __typename?: 'CommentEntity';
    id: string;
    issue_id: string;
    comment_id?: string | null;
    workspace_id: string;
    content: string;
    created_at: string;
    updated_at: string;
    created_by: string;
  }>;
};

export type DocumentAttachmentFragment = {
  __typename?: 'DocumentAttachmentEntity';
  id: string;
  document_id: string;
  workspace_id?: string | null;
  file_id: string;
  created_by: string;
  created_at?: string | null;
  file?: {
    __typename?: 'FileEntity';
    id: string;
    name: string;
    path: string;
    mime_type: string;
    size: number;
    workspace_id?: string | null;
    created_at?: string | null;
  } | null;
  user?: {
    __typename?: 'UserEntity';
    email: string;
    username?: string | null;
    id: string;
    image?: string | null;
    full_name?: string | null;
    theme?: ThemeType | null;
  } | null;
};

export type DeleteDocumentAttachmentMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteDocumentAttachmentMutation = {
  __typename?: 'Mutation';
  deleteDocumentAttachment: boolean;
};

export type GetDocumentAttachmentsQueryVariables = Exact<{
  getInput: GetDocumentAttachmentsInput;
}>;

export type GetDocumentAttachmentsQuery = {
  __typename?: 'Query';
  documentAttachments: Array<{
    __typename?: 'DocumentAttachmentEntity';
    id: string;
    document_id: string;
    workspace_id?: string | null;
    file_id: string;
    created_by: string;
    created_at?: string | null;
    file?: {
      __typename?: 'FileEntity';
      id: string;
      name: string;
      path: string;
      mime_type: string;
      size: number;
      workspace_id?: string | null;
      created_at?: string | null;
    } | null;
    user?: {
      __typename?: 'UserEntity';
      email: string;
      username?: string | null;
      id: string;
      image?: string | null;
      full_name?: string | null;
      theme?: ThemeType | null;
    } | null;
  }>;
};

export type DocumentVersionFragment = {
  __typename?: 'DocumentVersionEntity';
  id: string;
  document_id: string;
  workspace_id: string;
  content?: string | null;
  created_by: string;
  created_at: string;
};

export type RestoreDocumentVersionMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RestoreDocumentVersionMutation = {
  __typename?: 'Mutation';
  restoreDocumentVersion: boolean;
};

export type GetDocumentVersionsQueryVariables = Exact<{
  getInput: GetDocumentVersionsInput;
}>;

export type GetDocumentVersionsQuery = {
  __typename?: 'Query';
  documentVersions: Array<{
    __typename?: 'DocumentVersionEntity';
    id: string;
    document_id: string;
    workspace_id: string;
    content?: string | null;
    created_by: string;
    created_at: string;
  }>;
};

export type IDocumentFragment = {
  __typename?: 'DocumentEntity';
  id: string;
  title: string;
  document_id?: string | null;
  team_id: string;
  workspace_id: string;
  description: string;
  created_by: string;
  created_at: string;
  updated_at: string;
  is_deleted: boolean;
};

export type CreateDocumentMutationVariables = Exact<{
  createInput: CreateDocumentInput;
}>;

export type CreateDocumentMutation = {
  __typename?: 'Mutation';
  createDocument: {
    __typename?: 'DocumentEntity';
    id: string;
    title: string;
    document_id?: string | null;
    team_id: string;
    workspace_id: string;
    description: string;
    created_by: string;
    created_at: string;
    updated_at: string;
    is_deleted: boolean;
  };
};

export type UpdateDocumentMutationVariables = Exact<{
  updateInput: UpdateDocumentInput;
}>;

export type UpdateDocumentMutation = {
  __typename?: 'Mutation';
  updateDocument: boolean;
};

export type RemoveDocumentMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveDocumentMutation = {
  __typename?: 'Mutation';
  removeDocument: boolean;
};

export type GetDocumentsQueryVariables = Exact<{
  getInput: GetDocumentsInput;
}>;

export type GetDocumentsQuery = {
  __typename?: 'Query';
  documents: Array<{
    __typename?: 'DocumentEntity';
    id: string;
    title: string;
    document_id?: string | null;
    team_id: string;
    workspace_id: string;
    description: string;
    created_by: string;
    created_at: string;
    updated_at: string;
    is_deleted: boolean;
  }>;
};

export type FavoriteFragment = {
  __typename?: 'FavoriteEntity';
  id: string;
  type: FavoriteType;
  map_id: string;
  workspace_id?: string | null;
  created_by: string;
  created_at: string;
};

export type ToggleFavoriteMutationVariables = Exact<{
  toggleFavorite: CreateFavoriteInput;
}>;

export type ToggleFavoriteMutation = {
  __typename?: 'Mutation';
  toggleFavorite: boolean;
};

export type FileFragment = {
  __typename?: 'FileEntity';
  id: string;
  name: string;
  path: string;
  mime_type: string;
  size: number;
  workspace_id?: string | null;
  created_at?: string | null;
};

export type GetFilesQueryVariables = Exact<{
  getInput: GetFilesInput;
}>;

export type GetFilesQuery = {
  __typename?: 'Query';
  files: Array<{
    __typename?: 'FileEntity';
    id: string;
    name: string;
    path: string;
    mime_type: string;
    size: number;
    workspace_id?: string | null;
    created_at?: string | null;
  }>;
};

export type IssueAttachmentFragment = {
  __typename?: 'IssueAttachmentEntity';
  id: string;
  issue_id: string;
  workspace_id?: string | null;
  file_id: string;
  created_by: string;
  created_at?: string | null;
  file?: {
    __typename?: 'FileEntity';
    id: string;
    name: string;
    path: string;
    mime_type: string;
    size: number;
    workspace_id?: string | null;
    created_at?: string | null;
  } | null;
  user?: {
    __typename?: 'UserEntity';
    email: string;
    username?: string | null;
    id: string;
    image?: string | null;
    full_name?: string | null;
    theme?: ThemeType | null;
  } | null;
};

export type DeleteIssueAttachmentMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteIssueAttachmentMutation = {
  __typename?: 'Mutation';
  deleteIssueAttachment: boolean;
};

export type GetIssueAttachmentsQueryVariables = Exact<{
  getInput: GetIssueAttachmentsInput;
}>;

export type GetIssueAttachmentsQuery = {
  __typename?: 'Query';
  issueAttachments: Array<{
    __typename?: 'IssueAttachmentEntity';
    id: string;
    issue_id: string;
    workspace_id?: string | null;
    file_id: string;
    created_by: string;
    created_at?: string | null;
    file?: {
      __typename?: 'FileEntity';
      id: string;
      name: string;
      path: string;
      mime_type: string;
      size: number;
      workspace_id?: string | null;
      created_at?: string | null;
    } | null;
    user?: {
      __typename?: 'UserEntity';
      email: string;
      username?: string | null;
      id: string;
      image?: string | null;
      full_name?: string | null;
      theme?: ThemeType | null;
    } | null;
  }>;
};

export type IssueVersionFragment = {
  __typename?: 'IssueVersionEntity';
  id: string;
  issue_id: string;
  workspace_id: string;
  content?: string | null;
  created_by: string;
  created_at: string;
};

export type RestoreVersionMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RestoreVersionMutation = {
  __typename?: 'Mutation';
  restoreVersion: boolean;
};

export type GetIssueVersionsQueryVariables = Exact<{
  getInput: GetIssueVersionsInput;
}>;

export type GetIssueVersionsQuery = {
  __typename?: 'Query';
  issueVersions: Array<{
    __typename?: 'IssueVersionEntity';
    id: string;
    issue_id: string;
    workspace_id: string;
    content?: string | null;
    created_by: string;
    created_at: string;
  }>;
};

export type IssueLabelFragment = {
  __typename?: 'IssueLabelEntity';
  id: string;
  label_id: string;
};

export type IssueFragment = {
  __typename?: 'IssueEntity';
  id: string;
  title: string;
  description: string;
  priority: PriorityType;
  code: number;
  issue_id?: string | null;
  team_id?: string | null;
  workspace_id: string;
  project_id?: string | null;
  assignee_id?: string | null;
  workflow_id?: string | null;
  due_date_at?: string | null;
  created_at: string;
  created_by: string;
  updated_at: string;
  is_deleted: boolean;
  labels?: Array<{
    __typename?: 'IssueLabelEntity';
    id: string;
    label_id: string;
  }> | null;
};

export type CreateIssueMutationVariables = Exact<{
  createInput: CreateIssueInput;
}>;

export type CreateIssueMutation = {
  __typename?: 'Mutation';
  createIssue: {
    __typename?: 'IssueEntity';
    id: string;
    title: string;
    description: string;
    priority: PriorityType;
    code: number;
    issue_id?: string | null;
    team_id?: string | null;
    workspace_id: string;
    project_id?: string | null;
    assignee_id?: string | null;
    workflow_id?: string | null;
    due_date_at?: string | null;
    created_at: string;
    created_by: string;
    updated_at: string;
    is_deleted: boolean;
    labels?: Array<{
      __typename?: 'IssueLabelEntity';
      id: string;
      label_id: string;
    }> | null;
  };
};

export type UpdateIssueMutationVariables = Exact<{
  updateInput: UpdateIssueInput;
}>;

export type UpdateIssueMutation = {
  __typename?: 'Mutation';
  updateIssue: {
    __typename?: 'IssueEntity';
    id: string;
    title: string;
    description: string;
    priority: PriorityType;
    code: number;
    issue_id?: string | null;
    team_id?: string | null;
    workspace_id: string;
    project_id?: string | null;
    assignee_id?: string | null;
    workflow_id?: string | null;
    due_date_at?: string | null;
    created_at: string;
    created_by: string;
    updated_at: string;
    is_deleted: boolean;
    labels?: Array<{
      __typename?: 'IssueLabelEntity';
      id: string;
      label_id: string;
    }> | null;
  };
};

export type DeleteIssueMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteIssueMutation = {
  __typename?: 'Mutation';
  deleteIssue: boolean;
};

export type GetIssuesQueryVariables = Exact<{
  getInput: GetIssuesInput;
}>;

export type GetIssuesQuery = {
  __typename?: 'Query';
  issues: Array<{
    __typename?: 'IssueEntity';
    id: string;
    title: string;
    description: string;
    priority: PriorityType;
    code: number;
    issue_id?: string | null;
    team_id?: string | null;
    workspace_id: string;
    project_id?: string | null;
    assignee_id?: string | null;
    workflow_id?: string | null;
    due_date_at?: string | null;
    created_at: string;
    created_by: string;
    updated_at: string;
    is_deleted: boolean;
    labels?: Array<{
      __typename?: 'IssueLabelEntity';
      id: string;
      label_id: string;
    }> | null;
  }>;
};

export type GetIssueQueryVariables = Exact<{
  getInput: GetIssueInput;
}>;

export type GetIssueQuery = {
  __typename?: 'Query';
  issue: {
    __typename?: 'IssueEntity';
    id: string;
    title: string;
    description: string;
    priority: PriorityType;
    code: number;
    issue_id?: string | null;
    team_id?: string | null;
    workspace_id: string;
    project_id?: string | null;
    assignee_id?: string | null;
    workflow_id?: string | null;
    due_date_at?: string | null;
    created_at: string;
    created_by: string;
    updated_at: string;
    is_deleted: boolean;
    labels?: Array<{
      __typename?: 'IssueLabelEntity';
      id: string;
      label_id: string;
    }> | null;
  };
};

export type LabelFragment = {
  __typename?: 'LabelEntity';
  id: string;
  name: string;
  color: string;
  description?: string | null;
  workspace_id?: string | null;
  team_id?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
};

export type CreateLabelMutationVariables = Exact<{
  createInput: CreateLabelInput;
}>;

export type CreateLabelMutation = {
  __typename?: 'Mutation';
  createLabel: {
    __typename?: 'LabelEntity';
    id: string;
    name: string;
    color: string;
    description?: string | null;
    workspace_id?: string | null;
    team_id?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
  };
};

export type UpdateLabelMutationVariables = Exact<{
  updateInput: UpdateLabelInput;
}>;

export type UpdateLabelMutation = {
  __typename?: 'Mutation';
  updateLabel: {
    __typename?: 'LabelEntity';
    id: string;
    name: string;
    color: string;
    description?: string | null;
    workspace_id?: string | null;
    team_id?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
  };
};

export type RemoveLabelMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveLabelMutation = {
  __typename?: 'Mutation';
  removeLabel: {
    __typename?: 'LabelEntity';
    id: string;
    name: string;
    color: string;
    description?: string | null;
    workspace_id?: string | null;
    team_id?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
  };
};

export type GetLabelsQueryVariables = Exact<{
  getInput: GetLabelsInput;
}>;

export type GetLabelsQuery = {
  __typename?: 'Query';
  labels: Array<{
    __typename?: 'LabelEntity';
    id: string;
    name: string;
    color: string;
    description?: string | null;
    workspace_id?: string | null;
    team_id?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
  }>;
};

export type GetLabelsIncludeCoreQueryVariables = Exact<{
  getInput: GetLabelsInput;
}>;

export type GetLabelsIncludeCoreQuery = {
  __typename?: 'Query';
  labelsIncludeCore: Array<{
    __typename?: 'LabelEntity';
    id: string;
    name: string;
    color: string;
    description?: string | null;
    workspace_id?: string | null;
    team_id?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
  }>;
};

export type MemberTeamFragment = {
  __typename?: 'MemberTeamEntity';
  id: string;
  email: string;
  created_at?: string | null;
  team_id: string;
  workspace_id: string;
  member_id?: string | null;
  member?: {
    __typename?: 'UserEntity';
    email: string;
    username?: string | null;
    id: string;
    image?: string | null;
    full_name?: string | null;
    theme?: ThemeType | null;
  } | null;
};

export type RemoveMemberTeamMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveMemberTeamMutation = {
  __typename?: 'Mutation';
  removeMemberTeam: {
    __typename?: 'MemberTeamEntity';
    id: string;
    email: string;
    created_at?: string | null;
    team_id: string;
    workspace_id: string;
    member_id?: string | null;
    member?: {
      __typename?: 'UserEntity';
      email: string;
      username?: string | null;
      id: string;
      image?: string | null;
      full_name?: string | null;
      theme?: ThemeType | null;
    } | null;
  };
};

export type GetMembersTeamQueryVariables = Exact<{
  getInput: GetMemberTeamInput;
}>;

export type GetMembersTeamQuery = {
  __typename?: 'Query';
  membersTeam: Array<{
    __typename?: 'MemberTeamEntity';
    id: string;
    email: string;
    created_at?: string | null;
    team_id: string;
    workspace_id: string;
    member_id?: string | null;
    member?: {
      __typename?: 'UserEntity';
      email: string;
      username?: string | null;
      id: string;
      image?: string | null;
      full_name?: string | null;
      theme?: ThemeType | null;
    } | null;
  }>;
};

export type MemberWorkspaceFragment = {
  __typename?: 'MemberWorkspaceEntity';
  id: string;
  email: string;
  is_joined: boolean;
  joined_at?: string | null;
  created_at?: string | null;
  workspace?: {
    __typename?: 'WorkspaceEntity';
    id: string;
    name: string;
    logo?: string | null;
    url: string;
    invite_link?: string | null;
    disabled_invite_link: boolean;
    created_by: string;
  } | null;
};

export type CreateMemberWorkspaceMutationVariables = Exact<{
  createInput: CreateMemberWorkspaceInput;
}>;

export type CreateMemberWorkspaceMutation = {
  __typename?: 'Mutation';
  createMemberWorkspace: boolean;
};

export type RemoveMemberWorkspaceMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveMemberWorkspaceMutation = {
  __typename?: 'Mutation';
  removeMemberWorkspace: boolean;
};

export type JoinWorkspaceMutationVariables = Exact<{
  joinInput: JoinWorkspaceInput;
}>;

export type JoinWorkspaceMutation = {
  __typename?: 'Mutation';
  joinWorkspace: boolean;
};

export type GetMembersWorkspaceQueryVariables = Exact<{
  getInput: GetMemberWorkspaceInput;
}>;

export type GetMembersWorkspaceQuery = {
  __typename?: 'Query';
  membersWorkspace: Array<{
    __typename?: 'MemberWorkspaceEntity';
    id: string;
    email: string;
    is_joined: boolean;
    joined_at?: string | null;
    created_at?: string | null;
    workspace?: {
      __typename?: 'WorkspaceEntity';
      id: string;
      name: string;
      logo?: string | null;
      url: string;
      invite_link?: string | null;
      disabled_invite_link: boolean;
      created_by: string;
    } | null;
  }>;
};

export type GetPendingMembersWorkspaceQueryVariables = Exact<{
  getInput: GetMemberWorkspaceInput;
}>;

export type GetPendingMembersWorkspaceQuery = {
  __typename?: 'Query';
  pendingMembersWorkspace: Array<{
    __typename?: 'MemberWorkspaceEntity';
    id: string;
    email: string;
    is_joined: boolean;
    joined_at?: string | null;
    created_at?: string | null;
    workspace?: {
      __typename?: 'WorkspaceEntity';
      id: string;
      name: string;
      logo?: string | null;
      url: string;
      invite_link?: string | null;
      disabled_invite_link: boolean;
      created_by: string;
    } | null;
  }>;
};

export type NotificationFragment = {
  __typename?: 'NotificationEntity';
  id: string;
  map_id: string;
  workspace_id: string;
  member_id: string;
  type: NotificationType;
  event?: PushEventType | null;
  activity_id?: string | null;
  mark_read: boolean;
  created_at: string;
  created_by: string;
  updated_at: string;
};

export type MarkReadNotificationMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type MarkReadNotificationMutation = {
  __typename?: 'Mutation';
  markReadNotification: boolean;
};

export type GetNotificationsQueryVariables = Exact<{
  getInput: GetNotificationsInput;
}>;

export type GetNotificationsQuery = {
  __typename?: 'Query';
  notifications: Array<{
    __typename?: 'NotificationEntity';
    id: string;
    map_id: string;
    workspace_id: string;
    member_id: string;
    type: NotificationType;
    event?: PushEventType | null;
    activity_id?: string | null;
    mark_read: boolean;
    created_at: string;
    created_by: string;
    updated_at: string;
  }>;
};

export type ProjectStatusFragment = {
  __typename?: 'ProjectStatusEntity';
  id: string;
  name: string;
  description?: string | null;
  order?: number | null;
  processing: number;
  type: ProjectStatusType;
  color?: string | null;
  created_at: string;
};

export type CreateProjectStatusMutationVariables = Exact<{
  createInput: CreateProjectStatusInput;
}>;

export type CreateProjectStatusMutation = {
  __typename?: 'Mutation';
  createProjectStatus: {
    __typename?: 'ProjectStatusEntity';
    id: string;
    name: string;
    description?: string | null;
    order?: number | null;
    processing: number;
    type: ProjectStatusType;
    color?: string | null;
    created_at: string;
  };
};

export type UpdateProjectStatusMutationVariables = Exact<{
  updateInput: UpdateProjectStatusInput;
}>;

export type UpdateProjectStatusMutation = {
  __typename?: 'Mutation';
  updateProjectStatus: {
    __typename?: 'ProjectStatusEntity';
    id: string;
    name: string;
    description?: string | null;
    order?: number | null;
    processing: number;
    type: ProjectStatusType;
    color?: string | null;
    created_at: string;
  };
};

export type RemoveProjectStatusMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveProjectStatusMutation = {
  __typename?: 'Mutation';
  removeProjectStatus: {
    __typename?: 'ProjectStatusEntity';
    id: string;
    name: string;
    description?: string | null;
    order?: number | null;
    processing: number;
    type: ProjectStatusType;
    color?: string | null;
    created_at: string;
  };
};

export type SortableProjectStatusMutationVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type SortableProjectStatusMutation = {
  __typename?: 'Mutation';
  sortableProjectStatus: boolean;
};

export type GetProjectStatusesQueryVariables = Exact<{
  getInput: GetProjectStatusesInput;
}>;

export type GetProjectStatusesQuery = {
  __typename?: 'Query';
  projectStatuses: Array<{
    __typename?: 'ProjectStatusEntity';
    id: string;
    name: string;
    description?: string | null;
    order?: number | null;
    processing: number;
    type: ProjectStatusType;
    color?: string | null;
    created_at: string;
  }>;
};

export type ProjectVersionFragment = {
  __typename?: 'ProjectVersionEntity';
  id: string;
  project_id: string;
  workspace_id: string;
  content?: string | null;
  created_by: string;
  created_at: string;
};

export type RestoreProjectVersionMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RestoreProjectVersionMutation = {
  __typename?: 'Mutation';
  restoreProjectVersion: boolean;
};

export type GetProjectVersionsQueryVariables = Exact<{
  getInput: GetProjectVersionsInput;
}>;

export type GetProjectVersionsQuery = {
  __typename?: 'Query';
  projectVersions: Array<{
    __typename?: 'ProjectVersionEntity';
    id: string;
    project_id: string;
    workspace_id: string;
    content?: string | null;
    created_by: string;
    created_at: string;
  }>;
};

export type ProjectFragment = {
  __typename?: 'ProjectEntity';
  id: string;
  title: string;
  short_summary?: string | null;
  code: string;
  workspace_id: string;
  description?: string | null;
  priority: PriorityType;
  project_status_id?: string | null;
  start_at?: string | null;
  end_at?: string | null;
  leader_id?: string | null;
  team_ids?: string | null;
  label_ids?: string | null;
  member_ids?: string | null;
  created_by: string;
  created_at: string;
  updated_at: string;
  is_deleted: boolean;
  analytics?: {
    __typename?: 'GetAnalyticsProject';
    project_id: string;
    workspace_id: string;
    scope: number;
    started: number;
    completed: number;
    members: Array<{
      __typename?: 'GetAnalyticsProjectMembers';
      id: string;
      total_scope: number;
      total_started: number;
      total_completed: number;
    }>;
  } | null;
};

export type CreateProjectMutationVariables = Exact<{
  createInput: CreateProjectInput;
}>;

export type CreateProjectMutation = {
  __typename?: 'Mutation';
  createProject: {
    __typename?: 'ProjectEntity';
    id: string;
    title: string;
    short_summary?: string | null;
    code: string;
    workspace_id: string;
    description?: string | null;
    priority: PriorityType;
    project_status_id?: string | null;
    start_at?: string | null;
    end_at?: string | null;
    leader_id?: string | null;
    team_ids?: string | null;
    label_ids?: string | null;
    member_ids?: string | null;
    created_by: string;
    created_at: string;
    updated_at: string;
    is_deleted: boolean;
    analytics?: {
      __typename?: 'GetAnalyticsProject';
      project_id: string;
      workspace_id: string;
      scope: number;
      started: number;
      completed: number;
      members: Array<{
        __typename?: 'GetAnalyticsProjectMembers';
        id: string;
        total_scope: number;
        total_started: number;
        total_completed: number;
      }>;
    } | null;
  };
};

export type UpdateProjectMutationVariables = Exact<{
  updateInput: UpdateProjectInput;
}>;

export type UpdateProjectMutation = {
  __typename?: 'Mutation';
  updateProject: boolean;
};

export type DeleteProjectMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteProjectMutation = {
  __typename?: 'Mutation';
  deleteProject: boolean;
};

export type GetProjectsQueryVariables = Exact<{
  getInput: GetProjectsInput;
}>;

export type GetProjectsQuery = {
  __typename?: 'Query';
  projects: Array<{
    __typename?: 'ProjectEntity';
    id: string;
    title: string;
    short_summary?: string | null;
    code: string;
    workspace_id: string;
    description?: string | null;
    priority: PriorityType;
    project_status_id?: string | null;
    start_at?: string | null;
    end_at?: string | null;
    leader_id?: string | null;
    team_ids?: string | null;
    label_ids?: string | null;
    member_ids?: string | null;
    created_by: string;
    created_at: string;
    updated_at: string;
    is_deleted: boolean;
    analytics?: {
      __typename?: 'GetAnalyticsProject';
      project_id: string;
      workspace_id: string;
      scope: number;
      started: number;
      completed: number;
      members: Array<{
        __typename?: 'GetAnalyticsProjectMembers';
        id: string;
        total_scope: number;
        total_started: number;
        total_completed: number;
      }>;
    } | null;
  }>;
};

export type SubscriberFragment = {
  __typename?: 'SubscriberEntity';
  id: string;
  map_id: string;
  type: SubscriberType;
  member_id?: string | null;
};

export type SubscribeMutationVariables = Exact<{
  input: CreateSubscriberInput;
}>;

export type SubscribeMutation = { __typename?: 'Mutation'; subscribe: boolean };

export type GetSubscribersQueryVariables = Exact<{
  map_id: Scalars['String']['input'];
}>;

export type GetSubscribersQuery = {
  __typename?: 'Query';
  subscribers: Array<{
    __typename?: 'SubscriberEntity';
    id: string;
    map_id: string;
    type: SubscriberType;
    member_id?: string | null;
  }>;
};

export type TeamFragment = {
  __typename?: 'TeamEntity';
  id: string;
  name: string;
  color: string;
  icon: string;
  identifier: string;
  timezone: string;
  workspace_id: string;
  created_at?: string | null;
  updated_at?: string | null;
};

export type CreateTeamMutationVariables = Exact<{
  createInput: CreateTeamInput;
}>;

export type CreateTeamMutation = {
  __typename?: 'Mutation';
  createTeam: {
    __typename?: 'TeamEntity';
    id: string;
    name: string;
    color: string;
    icon: string;
    identifier: string;
    timezone: string;
    workspace_id: string;
    created_at?: string | null;
    updated_at?: string | null;
  };
};

export type UpdateTeamMutationVariables = Exact<{
  updateInput: UpdateTeamInput;
}>;

export type UpdateTeamMutation = {
  __typename?: 'Mutation';
  updateTeam: {
    __typename?: 'TeamEntity';
    id: string;
    name: string;
    color: string;
    icon: string;
    identifier: string;
    timezone: string;
    workspace_id: string;
    created_at?: string | null;
    updated_at?: string | null;
  };
};

export type RemoveTeamMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveTeamMutation = {
  __typename?: 'Mutation';
  removeTeam: {
    __typename?: 'TeamEntity';
    id: string;
    name: string;
    color: string;
    icon: string;
    identifier: string;
    timezone: string;
    workspace_id: string;
    created_at?: string | null;
    updated_at?: string | null;
  };
};

export type GetTeamsQueryVariables = Exact<{
  workspace_id: Scalars['String']['input'];
}>;

export type GetTeamsQuery = {
  __typename?: 'Query';
  teams: Array<{
    __typename?: 'TeamEntity';
    id: string;
    name: string;
    color: string;
    icon: string;
    identifier: string;
    timezone: string;
    workspace_id: string;
    created_at?: string | null;
    updated_at?: string | null;
  }>;
};

export type UserFragment = {
  __typename?: 'UserEntity';
  email: string;
  username?: string | null;
  id: string;
  image?: string | null;
  full_name?: string | null;
  theme?: ThemeType | null;
};

export type UpdateThemeModeMutationVariables = Exact<{
  updateInput: UpdateThemeInput;
}>;

export type UpdateThemeModeMutation = {
  __typename?: 'Mutation';
  updateThemeMode: boolean;
};

export type UpdateUserMutationVariables = Exact<{
  updateInput: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: boolean;
};

export type GetMeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'UserEntity';
    email: string;
    username?: string | null;
    id: string;
    image?: string | null;
    full_name?: string | null;
    theme?: ThemeType | null;
  };
};

export type WorkflowFragment = {
  __typename?: 'WorkflowEntity';
  id: string;
  name: string;
  description?: string | null;
  order?: number | null;
  processing: number;
  type: WorkflowType;
  color?: string | null;
  workspace_id: string;
  team_id: string;
  created_at: string;
};

export type CreateWorkflowMutationVariables = Exact<{
  createInput: CreateWorkflowInput;
}>;

export type CreateWorkflowMutation = {
  __typename?: 'Mutation';
  createWorkflow: {
    __typename?: 'WorkflowEntity';
    id: string;
    name: string;
    description?: string | null;
    order?: number | null;
    processing: number;
    type: WorkflowType;
    color?: string | null;
    workspace_id: string;
    team_id: string;
    created_at: string;
  };
};

export type UpdateWorkflowMutationVariables = Exact<{
  updateInput: UpdateWorkflowInput;
}>;

export type UpdateWorkflowMutation = {
  __typename?: 'Mutation';
  updateWorkflow: {
    __typename?: 'WorkflowEntity';
    id: string;
    name: string;
    description?: string | null;
    order?: number | null;
    processing: number;
    type: WorkflowType;
    color?: string | null;
    workspace_id: string;
    team_id: string;
    created_at: string;
  };
};

export type RemoveWorkflowMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveWorkflowMutation = {
  __typename?: 'Mutation';
  removeWorkflow: {
    __typename?: 'WorkflowEntity';
    id: string;
    name: string;
    description?: string | null;
    order?: number | null;
    processing: number;
    type: WorkflowType;
    color?: string | null;
    workspace_id: string;
    team_id: string;
    created_at: string;
  };
};

export type SortableWorkflowMutationVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type SortableWorkflowMutation = {
  __typename?: 'Mutation';
  sortableWorkflow: boolean;
};

export type GetWorkflowsQueryVariables = Exact<{
  getInput: GetWorkflowsInput;
}>;

export type GetWorkflowsQuery = {
  __typename?: 'Query';
  workflows: Array<{
    __typename?: 'WorkflowEntity';
    id: string;
    name: string;
    description?: string | null;
    order?: number | null;
    processing: number;
    type: WorkflowType;
    color?: string | null;
    workspace_id: string;
    team_id: string;
    created_at: string;
  }>;
};

export type WorkspaceFragment = {
  __typename?: 'WorkspaceEntity';
  id: string;
  name: string;
  logo?: string | null;
  url: string;
  invite_link?: string | null;
  disabled_invite_link: boolean;
  created_by: string;
};

export type WorkspaceCoreFragment = {
  __typename?: 'WorkspaceCore';
  role: RoleType;
  workspace: {
    __typename?: 'WorkspaceEntity';
    id: string;
    name: string;
    logo?: string | null;
    url: string;
    invite_link?: string | null;
    disabled_invite_link: boolean;
    created_by: string;
  };
  workspaces: Array<{
    __typename?: 'WorkspaceEntity';
    id: string;
    name: string;
    logo?: string | null;
    url: string;
    invite_link?: string | null;
    disabled_invite_link: boolean;
    created_by: string;
  }>;
  teams: Array<{
    __typename?: 'TeamEntity';
    id: string;
    name: string;
    color: string;
    icon: string;
    identifier: string;
    timezone: string;
    workspace_id: string;
    created_at?: string | null;
    updated_at?: string | null;
  }>;
  labels: Array<{
    __typename?: 'LabelEntity';
    id: string;
    name: string;
    color: string;
    description?: string | null;
    workspace_id?: string | null;
    team_id?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
  }>;
  workflows: Array<{
    __typename?: 'WorkflowEntity';
    id: string;
    name: string;
    description?: string | null;
    order?: number | null;
    processing: number;
    type: WorkflowType;
    color?: string | null;
    workspace_id: string;
    team_id: string;
    created_at: string;
  }>;
  project_statuses: Array<{
    __typename?: 'ProjectStatusEntity';
    id: string;
    name: string;
    description?: string | null;
    order?: number | null;
    processing: number;
    type: ProjectStatusType;
    color?: string | null;
    created_at: string;
  }>;
  member_teams: Array<{
    __typename?: 'MemberTeamEntity';
    id: string;
    email: string;
    created_at?: string | null;
    team_id: string;
    workspace_id: string;
    member_id?: string | null;
    member?: {
      __typename?: 'UserEntity';
      email: string;
      username?: string | null;
      id: string;
      image?: string | null;
      full_name?: string | null;
      theme?: ThemeType | null;
    } | null;
  }>;
  member_workspaces: Array<{
    __typename?: 'MemberWorkspaceEntity';
    id: string;
    email: string;
    is_joined: boolean;
    joined_at?: string | null;
    created_at?: string | null;
  }>;
  members: Array<{
    __typename?: 'UserEntity';
    email: string;
    username?: string | null;
    id: string;
    image?: string | null;
    full_name?: string | null;
    theme?: ThemeType | null;
  }>;
  subscribers: Array<{
    __typename?: 'SubscriberEntity';
    id: string;
    map_id: string;
    type: SubscriberType;
    member_id?: string | null;
  }>;
  favorites: Array<{
    __typename?: 'FavoriteEntity';
    id: string;
    type: FavoriteType;
    map_id: string;
    workspace_id?: string | null;
    created_by: string;
    created_at: string;
  }>;
};

export type WorkspaceInviteLinkFragment = {
  __typename?: 'WorkspaceInviteLink';
  name: string;
  logo?: string | null;
  invite_link: string;
  is_joined: boolean;
  members: Array<string>;
};

export type WorkspaceInviteEmailFragment = {
  __typename?: 'WorkspaceInviteEmail';
  name: string;
  email_sent: string;
  email_received: string;
};

export type CreateWorkspaceMutationVariables = Exact<{
  createInput: CreateWorkspaceInput;
}>;

export type CreateWorkspaceMutation = {
  __typename?: 'Mutation';
  createWorkspace: {
    __typename?: 'WorkspaceEntity';
    id: string;
    name: string;
    logo?: string | null;
    url: string;
    invite_link?: string | null;
    disabled_invite_link: boolean;
    created_by: string;
  };
};

export type UpdateWorkspaceMutationVariables = Exact<{
  updateInput: UpdateWorkspaceInput;
}>;

export type UpdateWorkspaceMutation = {
  __typename?: 'Mutation';
  updateWorkspace: {
    __typename?: 'WorkspaceEntity';
    id: string;
    name: string;
    logo?: string | null;
    url: string;
    invite_link?: string | null;
    disabled_invite_link: boolean;
    created_by: string;
  };
};

export type GenerateInviteLinkMutationVariables = Exact<{
  workspace_id: Scalars['String']['input'];
}>;

export type GenerateInviteLinkMutation = {
  __typename?: 'Mutation';
  generateInviteLink: string;
};

export type JoinInviteLinkMutationVariables = Exact<{
  invite_link: Scalars['String']['input'];
}>;

export type JoinInviteLinkMutation = {
  __typename?: 'Mutation';
  joinInviteLink: boolean;
};

export type SendOtpDeleteWorkspaceMutationVariables = Exact<{
  workspace_id: Scalars['String']['input'];
}>;

export type SendOtpDeleteWorkspaceMutation = {
  __typename?: 'Mutation';
  sendOtpDeleteWorkspace: boolean;
};

export type ConfirmDeleteWorkspaceMutationVariables = Exact<{
  deleteInput: DeleteWorkspaceInput;
}>;

export type ConfirmDeleteWorkspaceMutation = {
  __typename?: 'Mutation';
  confirmDeleteWorkspace: boolean;
};

export type GetWorkspacesQueryVariables = Exact<{ [key: string]: never }>;

export type GetWorkspacesQuery = {
  __typename?: 'Query';
  workspaces: Array<{
    __typename?: 'WorkspaceEntity';
    id: string;
    name: string;
    logo?: string | null;
    url: string;
    invite_link?: string | null;
    disabled_invite_link: boolean;
    created_by: string;
  }>;
};

export type GetWorkspaceQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetWorkspaceQuery = {
  __typename?: 'Query';
  workspace: {
    __typename?: 'WorkspaceEntity';
    id: string;
    name: string;
    logo?: string | null;
    url: string;
    invite_link?: string | null;
    disabled_invite_link: boolean;
    created_by: string;
  };
};

export type GetWorkspaceByUrlQueryVariables = Exact<{
  URL?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetWorkspaceByUrlQuery = {
  __typename?: 'Query';
  workspaceByURL?: {
    __typename?: 'WorkspaceCore';
    role: RoleType;
    workspace: {
      __typename?: 'WorkspaceEntity';
      id: string;
      name: string;
      logo?: string | null;
      url: string;
      invite_link?: string | null;
      disabled_invite_link: boolean;
      created_by: string;
    };
    workspaces: Array<{
      __typename?: 'WorkspaceEntity';
      id: string;
      name: string;
      logo?: string | null;
      url: string;
      invite_link?: string | null;
      disabled_invite_link: boolean;
      created_by: string;
    }>;
    teams: Array<{
      __typename?: 'TeamEntity';
      id: string;
      name: string;
      color: string;
      icon: string;
      identifier: string;
      timezone: string;
      workspace_id: string;
      created_at?: string | null;
      updated_at?: string | null;
    }>;
    labels: Array<{
      __typename?: 'LabelEntity';
      id: string;
      name: string;
      color: string;
      description?: string | null;
      workspace_id?: string | null;
      team_id?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
    }>;
    workflows: Array<{
      __typename?: 'WorkflowEntity';
      id: string;
      name: string;
      description?: string | null;
      order?: number | null;
      processing: number;
      type: WorkflowType;
      color?: string | null;
      workspace_id: string;
      team_id: string;
      created_at: string;
    }>;
    project_statuses: Array<{
      __typename?: 'ProjectStatusEntity';
      id: string;
      name: string;
      description?: string | null;
      order?: number | null;
      processing: number;
      type: ProjectStatusType;
      color?: string | null;
      created_at: string;
    }>;
    member_teams: Array<{
      __typename?: 'MemberTeamEntity';
      id: string;
      email: string;
      created_at?: string | null;
      team_id: string;
      workspace_id: string;
      member_id?: string | null;
      member?: {
        __typename?: 'UserEntity';
        email: string;
        username?: string | null;
        id: string;
        image?: string | null;
        full_name?: string | null;
        theme?: ThemeType | null;
      } | null;
    }>;
    member_workspaces: Array<{
      __typename?: 'MemberWorkspaceEntity';
      id: string;
      email: string;
      is_joined: boolean;
      joined_at?: string | null;
      created_at?: string | null;
    }>;
    members: Array<{
      __typename?: 'UserEntity';
      email: string;
      username?: string | null;
      id: string;
      image?: string | null;
      full_name?: string | null;
      theme?: ThemeType | null;
    }>;
    subscribers: Array<{
      __typename?: 'SubscriberEntity';
      id: string;
      map_id: string;
      type: SubscriberType;
      member_id?: string | null;
    }>;
    favorites: Array<{
      __typename?: 'FavoriteEntity';
      id: string;
      type: FavoriteType;
      map_id: string;
      workspace_id?: string | null;
      created_by: string;
      created_at: string;
    }>;
  } | null;
};

export type GetInviteLinkQueryVariables = Exact<{
  invite_link: Scalars['String']['input'];
}>;

export type GetInviteLinkQuery = {
  __typename?: 'Query';
  getInviteLink: {
    __typename?: 'WorkspaceInviteLink';
    name: string;
    logo?: string | null;
    invite_link: string;
    is_joined: boolean;
    members: Array<string>;
  };
};

export type GetInviteEmailQueryVariables = Exact<{
  invite: Scalars['String']['input'];
}>;

export type GetInviteEmailQuery = {
  __typename?: 'Query';
  getInviteEmail: {
    __typename?: 'WorkspaceInviteEmail';
    name: string;
    email_sent: string;
    email_received: string;
  };
};

export const ActivityFragmentDoc = `
    fragment Activity on ActivityEntity {
  id
  type
  issue_id
  workspace_id
  from_assignee_id
  to_assignee_id
  from_workflow_id
  to_workflow_id
  from_priority
  to_priority
  from_project_id
  to_project_id
  from_due_date
  to_due_date
  comment_id
  label_ids
  created_at
  created_by
}
    `;
export const CommentFragmentDoc = `
    fragment Comment on CommentEntity {
  id
  issue_id
  comment_id
  workspace_id
  content
  created_at
  updated_at
  created_by
}
    `;
export const FileFragmentDoc = `
    fragment File on FileEntity {
  id
  name
  path
  mime_type
  size
  workspace_id
  created_at
}
    `;
export const UserFragmentDoc = `
    fragment User on UserEntity {
  email
  username
  id
  image
  full_name
  theme
}
    `;
export const DocumentAttachmentFragmentDoc = `
    fragment DocumentAttachment on DocumentAttachmentEntity {
  id
  document_id
  workspace_id
  file_id
  file {
    ...File
  }
  user {
    ...User
  }
  created_by
  created_at
}
    `;
export const DocumentVersionFragmentDoc = `
    fragment DocumentVersion on DocumentVersionEntity {
  id
  document_id
  workspace_id
  content
  created_by
  created_at
}
    `;
export const IDocumentFragmentDoc = `
    fragment IDocument on DocumentEntity {
  id
  title
  document_id
  team_id
  workspace_id
  description
  created_by
  created_at
  updated_at
  is_deleted
}
    `;
export const IssueAttachmentFragmentDoc = `
    fragment IssueAttachment on IssueAttachmentEntity {
  id
  issue_id
  workspace_id
  file_id
  file {
    ...File
  }
  user {
    ...User
  }
  created_by
  created_at
}
    `;
export const IssueVersionFragmentDoc = `
    fragment IssueVersion on IssueVersionEntity {
  id
  issue_id
  workspace_id
  content
  created_by
  created_at
}
    `;
export const IssueLabelFragmentDoc = `
    fragment IssueLabel on IssueLabelEntity {
  id
  label_id
}
    `;
export const IssueFragmentDoc = `
    fragment Issue on IssueEntity {
  id
  title
  description
  priority
  code
  issue_id
  team_id
  workspace_id
  project_id
  assignee_id
  workflow_id
  due_date_at
  labels {
    ...IssueLabel
  }
  created_at
  created_by
  updated_at
  is_deleted
}
    `;
export const WorkspaceFragmentDoc = `
    fragment Workspace on WorkspaceEntity {
  id
  name
  logo
  url
  invite_link
  disabled_invite_link
  created_by
}
    `;
export const MemberWorkspaceFragmentDoc = `
    fragment MemberWorkspace on MemberWorkspaceEntity {
  id
  email
  is_joined
  joined_at
  created_at
  workspace {
    ...Workspace
  }
}
    `;
export const NotificationFragmentDoc = `
    fragment Notification on NotificationEntity {
  id
  map_id
  workspace_id
  member_id
  type
  event
  activity_id
  mark_read
  created_at
  created_by
  updated_at
}
    `;
export const ProjectVersionFragmentDoc = `
    fragment ProjectVersion on ProjectVersionEntity {
  id
  project_id
  workspace_id
  content
  created_by
  created_at
}
    `;
export const ProjectFragmentDoc = `
    fragment Project on ProjectEntity {
  id
  title
  short_summary
  code
  workspace_id
  description
  priority
  project_status_id
  start_at
  end_at
  leader_id
  team_ids
  label_ids
  member_ids
  analytics {
    project_id
    workspace_id
    scope
    started
    completed
    members {
      id
      total_scope
      total_started
      total_completed
    }
  }
  created_by
  created_at
  updated_at
  is_deleted
}
    `;
export const TeamFragmentDoc = `
    fragment Team on TeamEntity {
  id
  name
  color
  icon
  identifier
  timezone
  workspace_id
  created_at
  updated_at
}
    `;
export const LabelFragmentDoc = `
    fragment Label on LabelEntity {
  id
  name
  color
  description
  workspace_id
  team_id
  created_at
  updated_at
}
    `;
export const WorkflowFragmentDoc = `
    fragment Workflow on WorkflowEntity {
  id
  name
  description
  order
  processing
  type
  color
  workspace_id
  team_id
  created_at
}
    `;
export const ProjectStatusFragmentDoc = `
    fragment ProjectStatus on ProjectStatusEntity {
  id
  name
  description
  order
  processing
  type
  color
  created_at
}
    `;
export const MemberTeamFragmentDoc = `
    fragment MemberTeam on MemberTeamEntity {
  id
  email
  created_at
  team_id
  workspace_id
  member_id
  member {
    ...User
  }
}
    `;
export const SubscriberFragmentDoc = `
    fragment Subscriber on SubscriberEntity {
  id
  map_id
  type
  member_id
}
    `;
export const FavoriteFragmentDoc = `
    fragment Favorite on FavoriteEntity {
  id
  type
  map_id
  workspace_id
  created_by
  created_at
}
    `;
export const WorkspaceCoreFragmentDoc = `
    fragment WorkspaceCore on WorkspaceCore {
  role
  workspace {
    ...Workspace
  }
  workspaces {
    ...Workspace
  }
  teams {
    ...Team
  }
  labels {
    ...Label
  }
  workflows {
    ...Workflow
  }
  project_statuses {
    ...ProjectStatus
  }
  member_teams {
    ...MemberTeam
  }
  member_workspaces {
    id
    email
    is_joined
    joined_at
    created_at
  }
  members {
    ...User
  }
  subscribers {
    ...Subscriber
  }
  favorites {
    ...Favorite
  }
}
    `;
export const WorkspaceInviteLinkFragmentDoc = `
    fragment WorkspaceInviteLink on WorkspaceInviteLink {
  name
  logo
  invite_link
  is_joined
  members
}
    `;
export const WorkspaceInviteEmailFragmentDoc = `
    fragment WorkspaceInviteEmail on WorkspaceInviteEmail {
  name
  email_sent
  email_received
}
    `;
export const GetActivitiesDocument = `
    query getActivities($getInput: GetActivitiesInput!) {
  activities(getInput: $getInput) {
    ...Activity
  }
}
    ${ActivityFragmentDoc}`;

export const useGetActivitiesQuery = <
  TData = GetActivitiesQuery,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetActivitiesQueryVariables,
  options?: Omit<
    UseQueryOptions<GetActivitiesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<GetActivitiesQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetActivitiesQuery, TError, TData>({
    queryKey: ['getActivities', variables],
    queryFn: useFetchData<GetActivitiesQuery, GetActivitiesQueryVariables>(
      GetActivitiesDocument,
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetActivitiesQuery = <
  TData = InfiniteData<GetActivitiesQuery>,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetActivitiesQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetActivitiesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetActivitiesQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  const query = useFetchData<GetActivitiesQuery, GetActivitiesQueryVariables>(
    GetActivitiesDocument,
  );
  return useInfiniteQuery<GetActivitiesQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getActivities.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })(),
  );
};

export const SignInDocument = `
    mutation signIn($email: String!) {
  signIn(email: $email)
}
    `;

export const useSignInMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    SignInMutation,
    TError,
    SignInMutationVariables,
    TContext
  >,
) => {
  return useMutation<SignInMutation, TError, SignInMutationVariables, TContext>(
    {
      mutationKey: ['signIn'],
      mutationFn: useFetchData<SignInMutation, SignInMutationVariables>(
        SignInDocument,
      ),
      ...options,
    },
  );
};

export const ValidateOtpDocument = `
    mutation validateOTP($validateInput: ValidateOTPSignInInput!) {
  validateOTP(validateInput: $validateInput) {
    access_token
  }
}
    `;

export const useValidateOtpMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    ValidateOtpMutation,
    TError,
    ValidateOtpMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    ValidateOtpMutation,
    TError,
    ValidateOtpMutationVariables,
    TContext
  >({
    mutationKey: ['validateOTP'],
    mutationFn: useFetchData<ValidateOtpMutation, ValidateOtpMutationVariables>(
      ValidateOtpDocument,
    ),
    ...options,
  });
};

export const CreateCommentDocument = `
    mutation createComment($createInput: CreateCommentInput!) {
  createComment(createInput: $createInput)
}
    `;

export const useCreateCommentMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreateCommentMutation,
    TError,
    CreateCommentMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    CreateCommentMutation,
    TError,
    CreateCommentMutationVariables,
    TContext
  >({
    mutationKey: ['createComment'],
    mutationFn: useFetchData<
      CreateCommentMutation,
      CreateCommentMutationVariables
    >(CreateCommentDocument),
    ...options,
  });
};

export const UpdateCommentDocument = `
    mutation updateComment($updateInput: UpdateCommentInput!) {
  updateComment(updateInput: $updateInput)
}
    `;

export const useUpdateCommentMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateCommentMutation,
    TError,
    UpdateCommentMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    UpdateCommentMutation,
    TError,
    UpdateCommentMutationVariables,
    TContext
  >({
    mutationKey: ['updateComment'],
    mutationFn: useFetchData<
      UpdateCommentMutation,
      UpdateCommentMutationVariables
    >(UpdateCommentDocument),
    ...options,
  });
};

export const RemoveCommentDocument = `
    mutation removeComment($id: String!) {
  removeComment(id: $id)
}
    `;

export const useRemoveCommentMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    RemoveCommentMutation,
    TError,
    RemoveCommentMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    RemoveCommentMutation,
    TError,
    RemoveCommentMutationVariables,
    TContext
  >({
    mutationKey: ['removeComment'],
    mutationFn: useFetchData<
      RemoveCommentMutation,
      RemoveCommentMutationVariables
    >(RemoveCommentDocument),
    ...options,
  });
};

export const GetCommentsDocument = `
    query getComments($getInput: GetCommentsInput!) {
  comments(getInput: $getInput) {
    ...Comment
  }
}
    ${CommentFragmentDoc}`;

export const useGetCommentsQuery = <
  TData = GetCommentsQuery,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetCommentsQueryVariables,
  options?: Omit<
    UseQueryOptions<GetCommentsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<GetCommentsQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetCommentsQuery, TError, TData>({
    queryKey: ['getComments', variables],
    queryFn: useFetchData<GetCommentsQuery, GetCommentsQueryVariables>(
      GetCommentsDocument,
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetCommentsQuery = <
  TData = InfiniteData<GetCommentsQuery>,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetCommentsQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetCommentsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetCommentsQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  const query = useFetchData<GetCommentsQuery, GetCommentsQueryVariables>(
    GetCommentsDocument,
  );
  return useInfiniteQuery<GetCommentsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getComments.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })(),
  );
};

export const DeleteDocumentAttachmentDocument = `
    mutation deleteDocumentAttachment($id: String!) {
  deleteDocumentAttachment(id: $id)
}
    `;

export const useDeleteDocumentAttachmentMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    DeleteDocumentAttachmentMutation,
    TError,
    DeleteDocumentAttachmentMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    DeleteDocumentAttachmentMutation,
    TError,
    DeleteDocumentAttachmentMutationVariables,
    TContext
  >({
    mutationKey: ['deleteDocumentAttachment'],
    mutationFn: useFetchData<
      DeleteDocumentAttachmentMutation,
      DeleteDocumentAttachmentMutationVariables
    >(DeleteDocumentAttachmentDocument),
    ...options,
  });
};

export const GetDocumentAttachmentsDocument = `
    query getDocumentAttachments($getInput: GetDocumentAttachmentsInput!) {
  documentAttachments(getInput: $getInput) {
    ...DocumentAttachment
  }
}
    ${DocumentAttachmentFragmentDoc}
${FileFragmentDoc}
${UserFragmentDoc}`;

export const useGetDocumentAttachmentsQuery = <
  TData = GetDocumentAttachmentsQuery,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetDocumentAttachmentsQueryVariables,
  options?: Omit<
    UseQueryOptions<GetDocumentAttachmentsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      GetDocumentAttachmentsQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  return useQuery<GetDocumentAttachmentsQuery, TError, TData>({
    queryKey: ['getDocumentAttachments', variables],
    queryFn: useFetchData<
      GetDocumentAttachmentsQuery,
      GetDocumentAttachmentsQueryVariables
    >(GetDocumentAttachmentsDocument).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetDocumentAttachmentsQuery = <
  TData = InfiniteData<GetDocumentAttachmentsQuery>,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetDocumentAttachmentsQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetDocumentAttachmentsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetDocumentAttachmentsQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  const query = useFetchData<
    GetDocumentAttachmentsQuery,
    GetDocumentAttachmentsQueryVariables
  >(GetDocumentAttachmentsDocument);
  return useInfiniteQuery<GetDocumentAttachmentsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'getDocumentAttachments.infinite',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })(),
  );
};

export const RestoreDocumentVersionDocument = `
    mutation restoreDocumentVersion($id: String!) {
  restoreDocumentVersion(id: $id)
}
    `;

export const useRestoreDocumentVersionMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    RestoreDocumentVersionMutation,
    TError,
    RestoreDocumentVersionMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    RestoreDocumentVersionMutation,
    TError,
    RestoreDocumentVersionMutationVariables,
    TContext
  >({
    mutationKey: ['restoreDocumentVersion'],
    mutationFn: useFetchData<
      RestoreDocumentVersionMutation,
      RestoreDocumentVersionMutationVariables
    >(RestoreDocumentVersionDocument),
    ...options,
  });
};

export const GetDocumentVersionsDocument = `
    query getDocumentVersions($getInput: GetDocumentVersionsInput!) {
  documentVersions(getInput: $getInput) {
    ...DocumentVersion
  }
}
    ${DocumentVersionFragmentDoc}`;

export const useGetDocumentVersionsQuery = <
  TData = GetDocumentVersionsQuery,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetDocumentVersionsQueryVariables,
  options?: Omit<
    UseQueryOptions<GetDocumentVersionsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      GetDocumentVersionsQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  return useQuery<GetDocumentVersionsQuery, TError, TData>({
    queryKey: ['getDocumentVersions', variables],
    queryFn: useFetchData<
      GetDocumentVersionsQuery,
      GetDocumentVersionsQueryVariables
    >(GetDocumentVersionsDocument).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetDocumentVersionsQuery = <
  TData = InfiniteData<GetDocumentVersionsQuery>,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetDocumentVersionsQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetDocumentVersionsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetDocumentVersionsQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  const query = useFetchData<
    GetDocumentVersionsQuery,
    GetDocumentVersionsQueryVariables
  >(GetDocumentVersionsDocument);
  return useInfiniteQuery<GetDocumentVersionsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'getDocumentVersions.infinite',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })(),
  );
};

export const CreateDocumentDocument = `
    mutation createDocument($createInput: CreateDocumentInput!) {
  createDocument(createInput: $createInput) {
    ...IDocument
  }
}
    ${IDocumentFragmentDoc}`;

export const useCreateDocumentMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreateDocumentMutation,
    TError,
    CreateDocumentMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    CreateDocumentMutation,
    TError,
    CreateDocumentMutationVariables,
    TContext
  >({
    mutationKey: ['createDocument'],
    mutationFn: useFetchData<
      CreateDocumentMutation,
      CreateDocumentMutationVariables
    >(CreateDocumentDocument),
    ...options,
  });
};

export const UpdateDocumentDocument = `
    mutation updateDocument($updateInput: UpdateDocumentInput!) {
  updateDocument(updateInput: $updateInput)
}
    `;

export const useUpdateDocumentMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateDocumentMutation,
    TError,
    UpdateDocumentMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    UpdateDocumentMutation,
    TError,
    UpdateDocumentMutationVariables,
    TContext
  >({
    mutationKey: ['updateDocument'],
    mutationFn: useFetchData<
      UpdateDocumentMutation,
      UpdateDocumentMutationVariables
    >(UpdateDocumentDocument),
    ...options,
  });
};

export const RemoveDocumentDocument = `
    mutation removeDocument($id: String!) {
  removeDocument(id: $id)
}
    `;

export const useRemoveDocumentMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    RemoveDocumentMutation,
    TError,
    RemoveDocumentMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    RemoveDocumentMutation,
    TError,
    RemoveDocumentMutationVariables,
    TContext
  >({
    mutationKey: ['removeDocument'],
    mutationFn: useFetchData<
      RemoveDocumentMutation,
      RemoveDocumentMutationVariables
    >(RemoveDocumentDocument),
    ...options,
  });
};

export const GetDocumentsDocument = `
    query getDocuments($getInput: GetDocumentsInput!) {
  documents(getInput: $getInput) {
    ...IDocument
  }
}
    ${IDocumentFragmentDoc}`;

export const useGetDocumentsQuery = <
  TData = GetDocumentsQuery,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetDocumentsQueryVariables,
  options?: Omit<
    UseQueryOptions<GetDocumentsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<GetDocumentsQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetDocumentsQuery, TError, TData>({
    queryKey: ['getDocuments', variables],
    queryFn: useFetchData<GetDocumentsQuery, GetDocumentsQueryVariables>(
      GetDocumentsDocument,
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetDocumentsQuery = <
  TData = InfiniteData<GetDocumentsQuery>,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetDocumentsQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetDocumentsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetDocumentsQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  const query = useFetchData<GetDocumentsQuery, GetDocumentsQueryVariables>(
    GetDocumentsDocument,
  );
  return useInfiniteQuery<GetDocumentsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getDocuments.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })(),
  );
};

export const ToggleFavoriteDocument = `
    mutation toggleFavorite($toggleFavorite: CreateFavoriteInput!) {
  toggleFavorite(toggleFavorite: $toggleFavorite)
}
    `;

export const useToggleFavoriteMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    ToggleFavoriteMutation,
    TError,
    ToggleFavoriteMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    ToggleFavoriteMutation,
    TError,
    ToggleFavoriteMutationVariables,
    TContext
  >({
    mutationKey: ['toggleFavorite'],
    mutationFn: useFetchData<
      ToggleFavoriteMutation,
      ToggleFavoriteMutationVariables
    >(ToggleFavoriteDocument),
    ...options,
  });
};

export const GetFilesDocument = `
    query getFiles($getInput: GetFilesInput!) {
  files(getInput: $getInput) {
    ...File
  }
}
    ${FileFragmentDoc}`;

export const useGetFilesQuery = <
  TData = GetFilesQuery,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetFilesQueryVariables,
  options?: Omit<UseQueryOptions<GetFilesQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<GetFilesQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetFilesQuery, TError, TData>({
    queryKey: ['getFiles', variables],
    queryFn: useFetchData<GetFilesQuery, GetFilesQueryVariables>(
      GetFilesDocument,
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetFilesQuery = <
  TData = InfiniteData<GetFilesQuery>,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetFilesQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetFilesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetFilesQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  const query = useFetchData<GetFilesQuery, GetFilesQueryVariables>(
    GetFilesDocument,
  );
  return useInfiniteQuery<GetFilesQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getFiles.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })(),
  );
};

export const DeleteIssueAttachmentDocument = `
    mutation deleteIssueAttachment($id: String!) {
  deleteIssueAttachment(id: $id)
}
    `;

export const useDeleteIssueAttachmentMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    DeleteIssueAttachmentMutation,
    TError,
    DeleteIssueAttachmentMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    DeleteIssueAttachmentMutation,
    TError,
    DeleteIssueAttachmentMutationVariables,
    TContext
  >({
    mutationKey: ['deleteIssueAttachment'],
    mutationFn: useFetchData<
      DeleteIssueAttachmentMutation,
      DeleteIssueAttachmentMutationVariables
    >(DeleteIssueAttachmentDocument),
    ...options,
  });
};

export const GetIssueAttachmentsDocument = `
    query getIssueAttachments($getInput: GetIssueAttachmentsInput!) {
  issueAttachments(getInput: $getInput) {
    ...IssueAttachment
  }
}
    ${IssueAttachmentFragmentDoc}
${FileFragmentDoc}
${UserFragmentDoc}`;

export const useGetIssueAttachmentsQuery = <
  TData = GetIssueAttachmentsQuery,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetIssueAttachmentsQueryVariables,
  options?: Omit<
    UseQueryOptions<GetIssueAttachmentsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      GetIssueAttachmentsQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  return useQuery<GetIssueAttachmentsQuery, TError, TData>({
    queryKey: ['getIssueAttachments', variables],
    queryFn: useFetchData<
      GetIssueAttachmentsQuery,
      GetIssueAttachmentsQueryVariables
    >(GetIssueAttachmentsDocument).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetIssueAttachmentsQuery = <
  TData = InfiniteData<GetIssueAttachmentsQuery>,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetIssueAttachmentsQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetIssueAttachmentsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetIssueAttachmentsQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  const query = useFetchData<
    GetIssueAttachmentsQuery,
    GetIssueAttachmentsQueryVariables
  >(GetIssueAttachmentsDocument);
  return useInfiniteQuery<GetIssueAttachmentsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'getIssueAttachments.infinite',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })(),
  );
};

export const RestoreVersionDocument = `
    mutation restoreVersion($id: String!) {
  restoreVersion(id: $id)
}
    `;

export const useRestoreVersionMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    RestoreVersionMutation,
    TError,
    RestoreVersionMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    RestoreVersionMutation,
    TError,
    RestoreVersionMutationVariables,
    TContext
  >({
    mutationKey: ['restoreVersion'],
    mutationFn: useFetchData<
      RestoreVersionMutation,
      RestoreVersionMutationVariables
    >(RestoreVersionDocument),
    ...options,
  });
};

export const GetIssueVersionsDocument = `
    query getIssueVersions($getInput: GetIssueVersionsInput!) {
  issueVersions(getInput: $getInput) {
    ...IssueVersion
  }
}
    ${IssueVersionFragmentDoc}`;

export const useGetIssueVersionsQuery = <
  TData = GetIssueVersionsQuery,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetIssueVersionsQueryVariables,
  options?: Omit<
    UseQueryOptions<GetIssueVersionsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      GetIssueVersionsQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  return useQuery<GetIssueVersionsQuery, TError, TData>({
    queryKey: ['getIssueVersions', variables],
    queryFn: useFetchData<
      GetIssueVersionsQuery,
      GetIssueVersionsQueryVariables
    >(GetIssueVersionsDocument).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetIssueVersionsQuery = <
  TData = InfiniteData<GetIssueVersionsQuery>,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetIssueVersionsQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetIssueVersionsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetIssueVersionsQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  const query = useFetchData<
    GetIssueVersionsQuery,
    GetIssueVersionsQueryVariables
  >(GetIssueVersionsDocument);
  return useInfiniteQuery<GetIssueVersionsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getIssueVersions.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })(),
  );
};

export const CreateIssueDocument = `
    mutation createIssue($createInput: CreateIssueInput!) {
  createIssue(createInput: $createInput) {
    ...Issue
  }
}
    ${IssueFragmentDoc}
${IssueLabelFragmentDoc}`;

export const useCreateIssueMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreateIssueMutation,
    TError,
    CreateIssueMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    CreateIssueMutation,
    TError,
    CreateIssueMutationVariables,
    TContext
  >({
    mutationKey: ['createIssue'],
    mutationFn: useFetchData<CreateIssueMutation, CreateIssueMutationVariables>(
      CreateIssueDocument,
    ),
    ...options,
  });
};

export const UpdateIssueDocument = `
    mutation updateIssue($updateInput: UpdateIssueInput!) {
  updateIssue(updateInput: $updateInput) {
    ...Issue
  }
}
    ${IssueFragmentDoc}
${IssueLabelFragmentDoc}`;

export const useUpdateIssueMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateIssueMutation,
    TError,
    UpdateIssueMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    UpdateIssueMutation,
    TError,
    UpdateIssueMutationVariables,
    TContext
  >({
    mutationKey: ['updateIssue'],
    mutationFn: useFetchData<UpdateIssueMutation, UpdateIssueMutationVariables>(
      UpdateIssueDocument,
    ),
    ...options,
  });
};

export const DeleteIssueDocument = `
    mutation deleteIssue($id: String!) {
  deleteIssue(id: $id)
}
    `;

export const useDeleteIssueMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    DeleteIssueMutation,
    TError,
    DeleteIssueMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    DeleteIssueMutation,
    TError,
    DeleteIssueMutationVariables,
    TContext
  >({
    mutationKey: ['deleteIssue'],
    mutationFn: useFetchData<DeleteIssueMutation, DeleteIssueMutationVariables>(
      DeleteIssueDocument,
    ),
    ...options,
  });
};

export const GetIssuesDocument = `
    query getIssues($getInput: GetIssuesInput!) {
  issues(getInput: $getInput) {
    ...Issue
  }
}
    ${IssueFragmentDoc}
${IssueLabelFragmentDoc}`;

export const useGetIssuesQuery = <
  TData = GetIssuesQuery,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetIssuesQueryVariables,
  options?: Omit<UseQueryOptions<GetIssuesQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<GetIssuesQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetIssuesQuery, TError, TData>({
    queryKey: ['getIssues', variables],
    queryFn: useFetchData<GetIssuesQuery, GetIssuesQueryVariables>(
      GetIssuesDocument,
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetIssuesQuery = <
  TData = InfiniteData<GetIssuesQuery>,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetIssuesQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetIssuesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetIssuesQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  const query = useFetchData<GetIssuesQuery, GetIssuesQueryVariables>(
    GetIssuesDocument,
  );
  return useInfiniteQuery<GetIssuesQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getIssues.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })(),
  );
};

export const GetIssueDocument = `
    query getIssue($getInput: GetIssueInput!) {
  issue(getInput: $getInput) {
    ...Issue
  }
}
    ${IssueFragmentDoc}
${IssueLabelFragmentDoc}`;

export const useGetIssueQuery = <
  TData = GetIssueQuery,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetIssueQueryVariables,
  options?: Omit<UseQueryOptions<GetIssueQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<GetIssueQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetIssueQuery, TError, TData>({
    queryKey: ['getIssue', variables],
    queryFn: useFetchData<GetIssueQuery, GetIssueQueryVariables>(
      GetIssueDocument,
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetIssueQuery = <
  TData = InfiniteData<GetIssueQuery>,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetIssueQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetIssueQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetIssueQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  const query = useFetchData<GetIssueQuery, GetIssueQueryVariables>(
    GetIssueDocument,
  );
  return useInfiniteQuery<GetIssueQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getIssue.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })(),
  );
};

export const CreateLabelDocument = `
    mutation createLabel($createInput: CreateLabelInput!) {
  createLabel(createInput: $createInput) {
    ...Label
  }
}
    ${LabelFragmentDoc}`;

export const useCreateLabelMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreateLabelMutation,
    TError,
    CreateLabelMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    CreateLabelMutation,
    TError,
    CreateLabelMutationVariables,
    TContext
  >({
    mutationKey: ['createLabel'],
    mutationFn: useFetchData<CreateLabelMutation, CreateLabelMutationVariables>(
      CreateLabelDocument,
    ),
    ...options,
  });
};

export const UpdateLabelDocument = `
    mutation updateLabel($updateInput: UpdateLabelInput!) {
  updateLabel(updateInput: $updateInput) {
    ...Label
  }
}
    ${LabelFragmentDoc}`;

export const useUpdateLabelMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateLabelMutation,
    TError,
    UpdateLabelMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    UpdateLabelMutation,
    TError,
    UpdateLabelMutationVariables,
    TContext
  >({
    mutationKey: ['updateLabel'],
    mutationFn: useFetchData<UpdateLabelMutation, UpdateLabelMutationVariables>(
      UpdateLabelDocument,
    ),
    ...options,
  });
};

export const RemoveLabelDocument = `
    mutation removeLabel($id: String!) {
  removeLabel(id: $id) {
    ...Label
  }
}
    ${LabelFragmentDoc}`;

export const useRemoveLabelMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    RemoveLabelMutation,
    TError,
    RemoveLabelMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    RemoveLabelMutation,
    TError,
    RemoveLabelMutationVariables,
    TContext
  >({
    mutationKey: ['removeLabel'],
    mutationFn: useFetchData<RemoveLabelMutation, RemoveLabelMutationVariables>(
      RemoveLabelDocument,
    ),
    ...options,
  });
};

export const GetLabelsDocument = `
    query getLabels($getInput: GetLabelsInput!) {
  labels(getInput: $getInput) {
    ...Label
  }
}
    ${LabelFragmentDoc}`;

export const useGetLabelsQuery = <
  TData = GetLabelsQuery,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetLabelsQueryVariables,
  options?: Omit<UseQueryOptions<GetLabelsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<GetLabelsQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetLabelsQuery, TError, TData>({
    queryKey: ['getLabels', variables],
    queryFn: useFetchData<GetLabelsQuery, GetLabelsQueryVariables>(
      GetLabelsDocument,
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetLabelsQuery = <
  TData = InfiniteData<GetLabelsQuery>,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetLabelsQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetLabelsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetLabelsQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  const query = useFetchData<GetLabelsQuery, GetLabelsQueryVariables>(
    GetLabelsDocument,
  );
  return useInfiniteQuery<GetLabelsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getLabels.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })(),
  );
};

export const GetLabelsIncludeCoreDocument = `
    query getLabelsIncludeCore($getInput: GetLabelsInput!) {
  labelsIncludeCore(getInput: $getInput) {
    ...Label
  }
}
    ${LabelFragmentDoc}`;

export const useGetLabelsIncludeCoreQuery = <
  TData = GetLabelsIncludeCoreQuery,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetLabelsIncludeCoreQueryVariables,
  options?: Omit<
    UseQueryOptions<GetLabelsIncludeCoreQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      GetLabelsIncludeCoreQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  return useQuery<GetLabelsIncludeCoreQuery, TError, TData>({
    queryKey: ['getLabelsIncludeCore', variables],
    queryFn: useFetchData<
      GetLabelsIncludeCoreQuery,
      GetLabelsIncludeCoreQueryVariables
    >(GetLabelsIncludeCoreDocument).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetLabelsIncludeCoreQuery = <
  TData = InfiniteData<GetLabelsIncludeCoreQuery>,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetLabelsIncludeCoreQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetLabelsIncludeCoreQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetLabelsIncludeCoreQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  const query = useFetchData<
    GetLabelsIncludeCoreQuery,
    GetLabelsIncludeCoreQueryVariables
  >(GetLabelsIncludeCoreDocument);
  return useInfiniteQuery<GetLabelsIncludeCoreQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'getLabelsIncludeCore.infinite',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })(),
  );
};

export const RemoveMemberTeamDocument = `
    mutation removeMemberTeam($id: String!) {
  removeMemberTeam(id: $id) {
    ...MemberTeam
  }
}
    ${MemberTeamFragmentDoc}
${UserFragmentDoc}`;

export const useRemoveMemberTeamMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    RemoveMemberTeamMutation,
    TError,
    RemoveMemberTeamMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    RemoveMemberTeamMutation,
    TError,
    RemoveMemberTeamMutationVariables,
    TContext
  >({
    mutationKey: ['removeMemberTeam'],
    mutationFn: useFetchData<
      RemoveMemberTeamMutation,
      RemoveMemberTeamMutationVariables
    >(RemoveMemberTeamDocument),
    ...options,
  });
};

export const GetMembersTeamDocument = `
    query getMembersTeam($getInput: GetMemberTeamInput!) {
  membersTeam(getInput: $getInput) {
    ...MemberTeam
  }
}
    ${MemberTeamFragmentDoc}
${UserFragmentDoc}`;

export const useGetMembersTeamQuery = <
  TData = GetMembersTeamQuery,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetMembersTeamQueryVariables,
  options?: Omit<
    UseQueryOptions<GetMembersTeamQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<GetMembersTeamQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetMembersTeamQuery, TError, TData>({
    queryKey: ['getMembersTeam', variables],
    queryFn: useFetchData<GetMembersTeamQuery, GetMembersTeamQueryVariables>(
      GetMembersTeamDocument,
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetMembersTeamQuery = <
  TData = InfiniteData<GetMembersTeamQuery>,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetMembersTeamQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetMembersTeamQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetMembersTeamQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  const query = useFetchData<GetMembersTeamQuery, GetMembersTeamQueryVariables>(
    GetMembersTeamDocument,
  );
  return useInfiniteQuery<GetMembersTeamQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getMembersTeam.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })(),
  );
};

export const CreateMemberWorkspaceDocument = `
    mutation createMemberWorkspace($createInput: CreateMemberWorkspaceInput!) {
  createMemberWorkspace(createInput: $createInput)
}
    `;

export const useCreateMemberWorkspaceMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreateMemberWorkspaceMutation,
    TError,
    CreateMemberWorkspaceMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    CreateMemberWorkspaceMutation,
    TError,
    CreateMemberWorkspaceMutationVariables,
    TContext
  >({
    mutationKey: ['createMemberWorkspace'],
    mutationFn: useFetchData<
      CreateMemberWorkspaceMutation,
      CreateMemberWorkspaceMutationVariables
    >(CreateMemberWorkspaceDocument),
    ...options,
  });
};

export const RemoveMemberWorkspaceDocument = `
    mutation removeMemberWorkspace($id: String!) {
  removeMemberWorkspace(id: $id)
}
    `;

export const useRemoveMemberWorkspaceMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    RemoveMemberWorkspaceMutation,
    TError,
    RemoveMemberWorkspaceMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    RemoveMemberWorkspaceMutation,
    TError,
    RemoveMemberWorkspaceMutationVariables,
    TContext
  >({
    mutationKey: ['removeMemberWorkspace'],
    mutationFn: useFetchData<
      RemoveMemberWorkspaceMutation,
      RemoveMemberWorkspaceMutationVariables
    >(RemoveMemberWorkspaceDocument),
    ...options,
  });
};

export const JoinWorkspaceDocument = `
    mutation joinWorkspace($joinInput: JoinWorkspaceInput!) {
  joinWorkspace(joinInput: $joinInput)
}
    `;

export const useJoinWorkspaceMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    JoinWorkspaceMutation,
    TError,
    JoinWorkspaceMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    JoinWorkspaceMutation,
    TError,
    JoinWorkspaceMutationVariables,
    TContext
  >({
    mutationKey: ['joinWorkspace'],
    mutationFn: useFetchData<
      JoinWorkspaceMutation,
      JoinWorkspaceMutationVariables
    >(JoinWorkspaceDocument),
    ...options,
  });
};

export const GetMembersWorkspaceDocument = `
    query getMembersWorkspace($getInput: GetMemberWorkspaceInput!) {
  membersWorkspace(getInput: $getInput) {
    ...MemberWorkspace
  }
}
    ${MemberWorkspaceFragmentDoc}
${WorkspaceFragmentDoc}`;

export const useGetMembersWorkspaceQuery = <
  TData = GetMembersWorkspaceQuery,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetMembersWorkspaceQueryVariables,
  options?: Omit<
    UseQueryOptions<GetMembersWorkspaceQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      GetMembersWorkspaceQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  return useQuery<GetMembersWorkspaceQuery, TError, TData>({
    queryKey: ['getMembersWorkspace', variables],
    queryFn: useFetchData<
      GetMembersWorkspaceQuery,
      GetMembersWorkspaceQueryVariables
    >(GetMembersWorkspaceDocument).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetMembersWorkspaceQuery = <
  TData = InfiniteData<GetMembersWorkspaceQuery>,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetMembersWorkspaceQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetMembersWorkspaceQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetMembersWorkspaceQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  const query = useFetchData<
    GetMembersWorkspaceQuery,
    GetMembersWorkspaceQueryVariables
  >(GetMembersWorkspaceDocument);
  return useInfiniteQuery<GetMembersWorkspaceQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'getMembersWorkspace.infinite',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })(),
  );
};

export const GetPendingMembersWorkspaceDocument = `
    query getPendingMembersWorkspace($getInput: GetMemberWorkspaceInput!) {
  pendingMembersWorkspace(getInput: $getInput) {
    ...MemberWorkspace
  }
}
    ${MemberWorkspaceFragmentDoc}
${WorkspaceFragmentDoc}`;

export const useGetPendingMembersWorkspaceQuery = <
  TData = GetPendingMembersWorkspaceQuery,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetPendingMembersWorkspaceQueryVariables,
  options?: Omit<
    UseQueryOptions<GetPendingMembersWorkspaceQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      GetPendingMembersWorkspaceQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  return useQuery<GetPendingMembersWorkspaceQuery, TError, TData>({
    queryKey: ['getPendingMembersWorkspace', variables],
    queryFn: useFetchData<
      GetPendingMembersWorkspaceQuery,
      GetPendingMembersWorkspaceQueryVariables
    >(GetPendingMembersWorkspaceDocument).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetPendingMembersWorkspaceQuery = <
  TData = InfiniteData<GetPendingMembersWorkspaceQuery>,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetPendingMembersWorkspaceQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetPendingMembersWorkspaceQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetPendingMembersWorkspaceQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  const query = useFetchData<
    GetPendingMembersWorkspaceQuery,
    GetPendingMembersWorkspaceQueryVariables
  >(GetPendingMembersWorkspaceDocument);
  return useInfiniteQuery<GetPendingMembersWorkspaceQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'getPendingMembersWorkspace.infinite',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })(),
  );
};

export const MarkReadNotificationDocument = `
    mutation markReadNotification($id: String!) {
  markReadNotification(id: $id)
}
    `;

export const useMarkReadNotificationMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    MarkReadNotificationMutation,
    TError,
    MarkReadNotificationMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    MarkReadNotificationMutation,
    TError,
    MarkReadNotificationMutationVariables,
    TContext
  >({
    mutationKey: ['markReadNotification'],
    mutationFn: useFetchData<
      MarkReadNotificationMutation,
      MarkReadNotificationMutationVariables
    >(MarkReadNotificationDocument),
    ...options,
  });
};

export const GetNotificationsDocument = `
    query getNotifications($getInput: GetNotificationsInput!) {
  notifications(getInput: $getInput) {
    ...Notification
  }
}
    ${NotificationFragmentDoc}`;

export const useGetNotificationsQuery = <
  TData = GetNotificationsQuery,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetNotificationsQueryVariables,
  options?: Omit<
    UseQueryOptions<GetNotificationsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      GetNotificationsQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  return useQuery<GetNotificationsQuery, TError, TData>({
    queryKey: ['getNotifications', variables],
    queryFn: useFetchData<
      GetNotificationsQuery,
      GetNotificationsQueryVariables
    >(GetNotificationsDocument).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetNotificationsQuery = <
  TData = InfiniteData<GetNotificationsQuery>,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetNotificationsQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetNotificationsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetNotificationsQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  const query = useFetchData<
    GetNotificationsQuery,
    GetNotificationsQueryVariables
  >(GetNotificationsDocument);
  return useInfiniteQuery<GetNotificationsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getNotifications.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })(),
  );
};

export const CreateProjectStatusDocument = `
    mutation createProjectStatus($createInput: CreateProjectStatusInput!) {
  createProjectStatus(createInput: $createInput) {
    ...ProjectStatus
  }
}
    ${ProjectStatusFragmentDoc}`;

export const useCreateProjectStatusMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreateProjectStatusMutation,
    TError,
    CreateProjectStatusMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    CreateProjectStatusMutation,
    TError,
    CreateProjectStatusMutationVariables,
    TContext
  >({
    mutationKey: ['createProjectStatus'],
    mutationFn: useFetchData<
      CreateProjectStatusMutation,
      CreateProjectStatusMutationVariables
    >(CreateProjectStatusDocument),
    ...options,
  });
};

export const UpdateProjectStatusDocument = `
    mutation updateProjectStatus($updateInput: UpdateProjectStatusInput!) {
  updateProjectStatus(updateInput: $updateInput) {
    ...ProjectStatus
  }
}
    ${ProjectStatusFragmentDoc}`;

export const useUpdateProjectStatusMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateProjectStatusMutation,
    TError,
    UpdateProjectStatusMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    UpdateProjectStatusMutation,
    TError,
    UpdateProjectStatusMutationVariables,
    TContext
  >({
    mutationKey: ['updateProjectStatus'],
    mutationFn: useFetchData<
      UpdateProjectStatusMutation,
      UpdateProjectStatusMutationVariables
    >(UpdateProjectStatusDocument),
    ...options,
  });
};

export const RemoveProjectStatusDocument = `
    mutation removeProjectStatus($id: String!) {
  removeProjectStatus(id: $id) {
    ...ProjectStatus
  }
}
    ${ProjectStatusFragmentDoc}`;

export const useRemoveProjectStatusMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    RemoveProjectStatusMutation,
    TError,
    RemoveProjectStatusMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    RemoveProjectStatusMutation,
    TError,
    RemoveProjectStatusMutationVariables,
    TContext
  >({
    mutationKey: ['removeProjectStatus'],
    mutationFn: useFetchData<
      RemoveProjectStatusMutation,
      RemoveProjectStatusMutationVariables
    >(RemoveProjectStatusDocument),
    ...options,
  });
};

export const SortableProjectStatusDocument = `
    mutation sortableProjectStatus($ids: [String!]!) {
  sortableProjectStatus(ids: $ids)
}
    `;

export const useSortableProjectStatusMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    SortableProjectStatusMutation,
    TError,
    SortableProjectStatusMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    SortableProjectStatusMutation,
    TError,
    SortableProjectStatusMutationVariables,
    TContext
  >({
    mutationKey: ['sortableProjectStatus'],
    mutationFn: useFetchData<
      SortableProjectStatusMutation,
      SortableProjectStatusMutationVariables
    >(SortableProjectStatusDocument),
    ...options,
  });
};

export const GetProjectStatusesDocument = `
    query getProjectStatuses($getInput: GetProjectStatusesInput!) {
  projectStatuses(getInput: $getInput) {
    ...ProjectStatus
  }
}
    ${ProjectStatusFragmentDoc}`;

export const useGetProjectStatusesQuery = <
  TData = GetProjectStatusesQuery,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetProjectStatusesQueryVariables,
  options?: Omit<
    UseQueryOptions<GetProjectStatusesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      GetProjectStatusesQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  return useQuery<GetProjectStatusesQuery, TError, TData>({
    queryKey: ['getProjectStatuses', variables],
    queryFn: useFetchData<
      GetProjectStatusesQuery,
      GetProjectStatusesQueryVariables
    >(GetProjectStatusesDocument).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetProjectStatusesQuery = <
  TData = InfiniteData<GetProjectStatusesQuery>,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetProjectStatusesQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetProjectStatusesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetProjectStatusesQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  const query = useFetchData<
    GetProjectStatusesQuery,
    GetProjectStatusesQueryVariables
  >(GetProjectStatusesDocument);
  return useInfiniteQuery<GetProjectStatusesQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getProjectStatuses.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })(),
  );
};

export const RestoreProjectVersionDocument = `
    mutation restoreProjectVersion($id: String!) {
  restoreProjectVersion(id: $id)
}
    `;

export const useRestoreProjectVersionMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    RestoreProjectVersionMutation,
    TError,
    RestoreProjectVersionMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    RestoreProjectVersionMutation,
    TError,
    RestoreProjectVersionMutationVariables,
    TContext
  >({
    mutationKey: ['restoreProjectVersion'],
    mutationFn: useFetchData<
      RestoreProjectVersionMutation,
      RestoreProjectVersionMutationVariables
    >(RestoreProjectVersionDocument),
    ...options,
  });
};

export const GetProjectVersionsDocument = `
    query getProjectVersions($getInput: GetProjectVersionsInput!) {
  projectVersions(getInput: $getInput) {
    ...ProjectVersion
  }
}
    ${ProjectVersionFragmentDoc}`;

export const useGetProjectVersionsQuery = <
  TData = GetProjectVersionsQuery,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetProjectVersionsQueryVariables,
  options?: Omit<
    UseQueryOptions<GetProjectVersionsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      GetProjectVersionsQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  return useQuery<GetProjectVersionsQuery, TError, TData>({
    queryKey: ['getProjectVersions', variables],
    queryFn: useFetchData<
      GetProjectVersionsQuery,
      GetProjectVersionsQueryVariables
    >(GetProjectVersionsDocument).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetProjectVersionsQuery = <
  TData = InfiniteData<GetProjectVersionsQuery>,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetProjectVersionsQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetProjectVersionsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetProjectVersionsQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  const query = useFetchData<
    GetProjectVersionsQuery,
    GetProjectVersionsQueryVariables
  >(GetProjectVersionsDocument);
  return useInfiniteQuery<GetProjectVersionsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getProjectVersions.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })(),
  );
};

export const CreateProjectDocument = `
    mutation createProject($createInput: CreateProjectInput!) {
  createProject(createInput: $createInput) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;

export const useCreateProjectMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreateProjectMutation,
    TError,
    CreateProjectMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    CreateProjectMutation,
    TError,
    CreateProjectMutationVariables,
    TContext
  >({
    mutationKey: ['createProject'],
    mutationFn: useFetchData<
      CreateProjectMutation,
      CreateProjectMutationVariables
    >(CreateProjectDocument),
    ...options,
  });
};

export const UpdateProjectDocument = `
    mutation updateProject($updateInput: UpdateProjectInput!) {
  updateProject(updateInput: $updateInput)
}
    `;

export const useUpdateProjectMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateProjectMutation,
    TError,
    UpdateProjectMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    UpdateProjectMutation,
    TError,
    UpdateProjectMutationVariables,
    TContext
  >({
    mutationKey: ['updateProject'],
    mutationFn: useFetchData<
      UpdateProjectMutation,
      UpdateProjectMutationVariables
    >(UpdateProjectDocument),
    ...options,
  });
};

export const DeleteProjectDocument = `
    mutation deleteProject($id: String!) {
  deleteProject(id: $id)
}
    `;

export const useDeleteProjectMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    DeleteProjectMutation,
    TError,
    DeleteProjectMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    DeleteProjectMutation,
    TError,
    DeleteProjectMutationVariables,
    TContext
  >({
    mutationKey: ['deleteProject'],
    mutationFn: useFetchData<
      DeleteProjectMutation,
      DeleteProjectMutationVariables
    >(DeleteProjectDocument),
    ...options,
  });
};

export const GetProjectsDocument = `
    query getProjects($getInput: GetProjectsInput!) {
  projects(getInput: $getInput) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;

export const useGetProjectsQuery = <
  TData = GetProjectsQuery,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetProjectsQueryVariables,
  options?: Omit<
    UseQueryOptions<GetProjectsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<GetProjectsQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetProjectsQuery, TError, TData>({
    queryKey: ['getProjects', variables],
    queryFn: useFetchData<GetProjectsQuery, GetProjectsQueryVariables>(
      GetProjectsDocument,
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetProjectsQuery = <
  TData = InfiniteData<GetProjectsQuery>,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetProjectsQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetProjectsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetProjectsQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  const query = useFetchData<GetProjectsQuery, GetProjectsQueryVariables>(
    GetProjectsDocument,
  );
  return useInfiniteQuery<GetProjectsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getProjects.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })(),
  );
};

export const SubscribeDocument = `
    mutation subscribe($input: CreateSubscriberInput!) {
  subscribe(input: $input)
}
    `;

export const useSubscribeMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    SubscribeMutation,
    TError,
    SubscribeMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    SubscribeMutation,
    TError,
    SubscribeMutationVariables,
    TContext
  >({
    mutationKey: ['subscribe'],
    mutationFn: useFetchData<SubscribeMutation, SubscribeMutationVariables>(
      SubscribeDocument,
    ),
    ...options,
  });
};

export const GetSubscribersDocument = `
    query getSubscribers($map_id: String!) {
  subscribers(map_id: $map_id) {
    ...Subscriber
  }
}
    ${SubscriberFragmentDoc}`;

export const useGetSubscribersQuery = <
  TData = GetSubscribersQuery,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetSubscribersQueryVariables,
  options?: Omit<
    UseQueryOptions<GetSubscribersQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<GetSubscribersQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetSubscribersQuery, TError, TData>({
    queryKey: ['getSubscribers', variables],
    queryFn: useFetchData<GetSubscribersQuery, GetSubscribersQueryVariables>(
      GetSubscribersDocument,
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetSubscribersQuery = <
  TData = InfiniteData<GetSubscribersQuery>,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetSubscribersQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetSubscribersQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetSubscribersQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  const query = useFetchData<GetSubscribersQuery, GetSubscribersQueryVariables>(
    GetSubscribersDocument,
  );
  return useInfiniteQuery<GetSubscribersQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getSubscribers.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })(),
  );
};

export const CreateTeamDocument = `
    mutation createTeam($createInput: CreateTeamInput!) {
  createTeam(createInput: $createInput) {
    ...Team
  }
}
    ${TeamFragmentDoc}`;

export const useCreateTeamMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreateTeamMutation,
    TError,
    CreateTeamMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    CreateTeamMutation,
    TError,
    CreateTeamMutationVariables,
    TContext
  >({
    mutationKey: ['createTeam'],
    mutationFn: useFetchData<CreateTeamMutation, CreateTeamMutationVariables>(
      CreateTeamDocument,
    ),
    ...options,
  });
};

export const UpdateTeamDocument = `
    mutation updateTeam($updateInput: UpdateTeamInput!) {
  updateTeam(updateInput: $updateInput) {
    ...Team
  }
}
    ${TeamFragmentDoc}`;

export const useUpdateTeamMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateTeamMutation,
    TError,
    UpdateTeamMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    UpdateTeamMutation,
    TError,
    UpdateTeamMutationVariables,
    TContext
  >({
    mutationKey: ['updateTeam'],
    mutationFn: useFetchData<UpdateTeamMutation, UpdateTeamMutationVariables>(
      UpdateTeamDocument,
    ),
    ...options,
  });
};

export const RemoveTeamDocument = `
    mutation removeTeam($id: String!) {
  removeTeam(id: $id) {
    ...Team
  }
}
    ${TeamFragmentDoc}`;

export const useRemoveTeamMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    RemoveTeamMutation,
    TError,
    RemoveTeamMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    RemoveTeamMutation,
    TError,
    RemoveTeamMutationVariables,
    TContext
  >({
    mutationKey: ['removeTeam'],
    mutationFn: useFetchData<RemoveTeamMutation, RemoveTeamMutationVariables>(
      RemoveTeamDocument,
    ),
    ...options,
  });
};

export const GetTeamsDocument = `
    query getTeams($workspace_id: String!) {
  teams(workspace_id: $workspace_id) {
    ...Team
  }
}
    ${TeamFragmentDoc}`;

export const useGetTeamsQuery = <
  TData = GetTeamsQuery,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetTeamsQueryVariables,
  options?: Omit<UseQueryOptions<GetTeamsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<GetTeamsQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetTeamsQuery, TError, TData>({
    queryKey: ['getTeams', variables],
    queryFn: useFetchData<GetTeamsQuery, GetTeamsQueryVariables>(
      GetTeamsDocument,
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetTeamsQuery = <
  TData = InfiniteData<GetTeamsQuery>,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetTeamsQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetTeamsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetTeamsQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  const query = useFetchData<GetTeamsQuery, GetTeamsQueryVariables>(
    GetTeamsDocument,
  );
  return useInfiniteQuery<GetTeamsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getTeams.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })(),
  );
};

export const UpdateThemeModeDocument = `
    mutation updateThemeMode($updateInput: UpdateThemeInput!) {
  updateThemeMode(updateInput: $updateInput)
}
    `;

export const useUpdateThemeModeMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateThemeModeMutation,
    TError,
    UpdateThemeModeMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    UpdateThemeModeMutation,
    TError,
    UpdateThemeModeMutationVariables,
    TContext
  >({
    mutationKey: ['updateThemeMode'],
    mutationFn: useFetchData<
      UpdateThemeModeMutation,
      UpdateThemeModeMutationVariables
    >(UpdateThemeModeDocument),
    ...options,
  });
};

export const UpdateUserDocument = `
    mutation updateUser($updateInput: UpdateUserInput!) {
  updateUser(updateInput: $updateInput)
}
    `;

export const useUpdateUserMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateUserMutation,
    TError,
    UpdateUserMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    UpdateUserMutation,
    TError,
    UpdateUserMutationVariables,
    TContext
  >({
    mutationKey: ['updateUser'],
    mutationFn: useFetchData<UpdateUserMutation, UpdateUserMutationVariables>(
      UpdateUserDocument,
    ),
    ...options,
  });
};

export const GetMeDocument = `
    query getMe {
  me {
    ...User
  }
}
    ${UserFragmentDoc}`;

export const useGetMeQuery = <
  TData = GetMeQuery,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables?: GetMeQueryVariables,
  options?: Omit<UseQueryOptions<GetMeQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<GetMeQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetMeQuery, TError, TData>({
    queryKey: variables === undefined ? ['getMe'] : ['getMe', variables],
    queryFn: useFetchData<GetMeQuery, GetMeQueryVariables>(GetMeDocument).bind(
      null,
      variables,
    ),
    ...options,
  });
};

export const useInfiniteGetMeQuery = <
  TData = InfiniteData<GetMeQuery>,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetMeQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetMeQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<GetMeQuery, TError, TData>['queryKey'];
  },
) => {
  const query = useFetchData<GetMeQuery, GetMeQueryVariables>(GetMeDocument);
  return useInfiniteQuery<GetMeQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey:
          optionsQueryKey ?? variables === undefined
            ? ['getMe.infinite']
            : ['getMe.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })(),
  );
};

export const CreateWorkflowDocument = `
    mutation createWorkflow($createInput: CreateWorkflowInput!) {
  createWorkflow(createInput: $createInput) {
    ...Workflow
  }
}
    ${WorkflowFragmentDoc}`;

export const useCreateWorkflowMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreateWorkflowMutation,
    TError,
    CreateWorkflowMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    CreateWorkflowMutation,
    TError,
    CreateWorkflowMutationVariables,
    TContext
  >({
    mutationKey: ['createWorkflow'],
    mutationFn: useFetchData<
      CreateWorkflowMutation,
      CreateWorkflowMutationVariables
    >(CreateWorkflowDocument),
    ...options,
  });
};

export const UpdateWorkflowDocument = `
    mutation updateWorkflow($updateInput: UpdateWorkflowInput!) {
  updateWorkflow(updateInput: $updateInput) {
    ...Workflow
  }
}
    ${WorkflowFragmentDoc}`;

export const useUpdateWorkflowMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateWorkflowMutation,
    TError,
    UpdateWorkflowMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    UpdateWorkflowMutation,
    TError,
    UpdateWorkflowMutationVariables,
    TContext
  >({
    mutationKey: ['updateWorkflow'],
    mutationFn: useFetchData<
      UpdateWorkflowMutation,
      UpdateWorkflowMutationVariables
    >(UpdateWorkflowDocument),
    ...options,
  });
};

export const RemoveWorkflowDocument = `
    mutation removeWorkflow($id: String!) {
  removeWorkflow(id: $id) {
    ...Workflow
  }
}
    ${WorkflowFragmentDoc}`;

export const useRemoveWorkflowMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    RemoveWorkflowMutation,
    TError,
    RemoveWorkflowMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    RemoveWorkflowMutation,
    TError,
    RemoveWorkflowMutationVariables,
    TContext
  >({
    mutationKey: ['removeWorkflow'],
    mutationFn: useFetchData<
      RemoveWorkflowMutation,
      RemoveWorkflowMutationVariables
    >(RemoveWorkflowDocument),
    ...options,
  });
};

export const SortableWorkflowDocument = `
    mutation sortableWorkflow($ids: [String!]!) {
  sortableWorkflow(ids: $ids)
}
    `;

export const useSortableWorkflowMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    SortableWorkflowMutation,
    TError,
    SortableWorkflowMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    SortableWorkflowMutation,
    TError,
    SortableWorkflowMutationVariables,
    TContext
  >({
    mutationKey: ['sortableWorkflow'],
    mutationFn: useFetchData<
      SortableWorkflowMutation,
      SortableWorkflowMutationVariables
    >(SortableWorkflowDocument),
    ...options,
  });
};

export const GetWorkflowsDocument = `
    query getWorkflows($getInput: GetWorkflowsInput!) {
  workflows(getInput: $getInput) {
    ...Workflow
  }
}
    ${WorkflowFragmentDoc}`;

export const useGetWorkflowsQuery = <
  TData = GetWorkflowsQuery,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetWorkflowsQueryVariables,
  options?: Omit<
    UseQueryOptions<GetWorkflowsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<GetWorkflowsQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetWorkflowsQuery, TError, TData>({
    queryKey: ['getWorkflows', variables],
    queryFn: useFetchData<GetWorkflowsQuery, GetWorkflowsQueryVariables>(
      GetWorkflowsDocument,
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetWorkflowsQuery = <
  TData = InfiniteData<GetWorkflowsQuery>,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetWorkflowsQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetWorkflowsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetWorkflowsQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  const query = useFetchData<GetWorkflowsQuery, GetWorkflowsQueryVariables>(
    GetWorkflowsDocument,
  );
  return useInfiniteQuery<GetWorkflowsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getWorkflows.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })(),
  );
};

export const CreateWorkspaceDocument = `
    mutation createWorkspace($createInput: CreateWorkspaceInput!) {
  createWorkspace(createInput: $createInput) {
    ...Workspace
  }
}
    ${WorkspaceFragmentDoc}`;

export const useCreateWorkspaceMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreateWorkspaceMutation,
    TError,
    CreateWorkspaceMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    CreateWorkspaceMutation,
    TError,
    CreateWorkspaceMutationVariables,
    TContext
  >({
    mutationKey: ['createWorkspace'],
    mutationFn: useFetchData<
      CreateWorkspaceMutation,
      CreateWorkspaceMutationVariables
    >(CreateWorkspaceDocument),
    ...options,
  });
};

export const UpdateWorkspaceDocument = `
    mutation updateWorkspace($updateInput: UpdateWorkspaceInput!) {
  updateWorkspace(updateInput: $updateInput) {
    ...Workspace
  }
}
    ${WorkspaceFragmentDoc}`;

export const useUpdateWorkspaceMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateWorkspaceMutation,
    TError,
    UpdateWorkspaceMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    UpdateWorkspaceMutation,
    TError,
    UpdateWorkspaceMutationVariables,
    TContext
  >({
    mutationKey: ['updateWorkspace'],
    mutationFn: useFetchData<
      UpdateWorkspaceMutation,
      UpdateWorkspaceMutationVariables
    >(UpdateWorkspaceDocument),
    ...options,
  });
};

export const GenerateInviteLinkDocument = `
    mutation generateInviteLink($workspace_id: String!) {
  generateInviteLink(workspace_id: $workspace_id)
}
    `;

export const useGenerateInviteLinkMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    GenerateInviteLinkMutation,
    TError,
    GenerateInviteLinkMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    GenerateInviteLinkMutation,
    TError,
    GenerateInviteLinkMutationVariables,
    TContext
  >({
    mutationKey: ['generateInviteLink'],
    mutationFn: useFetchData<
      GenerateInviteLinkMutation,
      GenerateInviteLinkMutationVariables
    >(GenerateInviteLinkDocument),
    ...options,
  });
};

export const JoinInviteLinkDocument = `
    mutation joinInviteLink($invite_link: String!) {
  joinInviteLink(invite_link: $invite_link)
}
    `;

export const useJoinInviteLinkMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    JoinInviteLinkMutation,
    TError,
    JoinInviteLinkMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    JoinInviteLinkMutation,
    TError,
    JoinInviteLinkMutationVariables,
    TContext
  >({
    mutationKey: ['joinInviteLink'],
    mutationFn: useFetchData<
      JoinInviteLinkMutation,
      JoinInviteLinkMutationVariables
    >(JoinInviteLinkDocument),
    ...options,
  });
};

export const SendOtpDeleteWorkspaceDocument = `
    mutation sendOtpDeleteWorkspace($workspace_id: String!) {
  sendOtpDeleteWorkspace(workspace_id: $workspace_id)
}
    `;

export const useSendOtpDeleteWorkspaceMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    SendOtpDeleteWorkspaceMutation,
    TError,
    SendOtpDeleteWorkspaceMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    SendOtpDeleteWorkspaceMutation,
    TError,
    SendOtpDeleteWorkspaceMutationVariables,
    TContext
  >({
    mutationKey: ['sendOtpDeleteWorkspace'],
    mutationFn: useFetchData<
      SendOtpDeleteWorkspaceMutation,
      SendOtpDeleteWorkspaceMutationVariables
    >(SendOtpDeleteWorkspaceDocument),
    ...options,
  });
};

export const ConfirmDeleteWorkspaceDocument = `
    mutation confirmDeleteWorkspace($deleteInput: DeleteWorkspaceInput!) {
  confirmDeleteWorkspace(deleteInput: $deleteInput)
}
    `;

export const useConfirmDeleteWorkspaceMutation = <
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    ConfirmDeleteWorkspaceMutation,
    TError,
    ConfirmDeleteWorkspaceMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    ConfirmDeleteWorkspaceMutation,
    TError,
    ConfirmDeleteWorkspaceMutationVariables,
    TContext
  >({
    mutationKey: ['confirmDeleteWorkspace'],
    mutationFn: useFetchData<
      ConfirmDeleteWorkspaceMutation,
      ConfirmDeleteWorkspaceMutationVariables
    >(ConfirmDeleteWorkspaceDocument),
    ...options,
  });
};

export const GetWorkspacesDocument = `
    query getWorkspaces {
  workspaces {
    ...Workspace
  }
}
    ${WorkspaceFragmentDoc}`;

export const useGetWorkspacesQuery = <
  TData = GetWorkspacesQuery,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables?: GetWorkspacesQueryVariables,
  options?: Omit<
    UseQueryOptions<GetWorkspacesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<GetWorkspacesQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetWorkspacesQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['getWorkspaces']
        : ['getWorkspaces', variables],
    queryFn: useFetchData<GetWorkspacesQuery, GetWorkspacesQueryVariables>(
      GetWorkspacesDocument,
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetWorkspacesQuery = <
  TData = InfiniteData<GetWorkspacesQuery>,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetWorkspacesQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetWorkspacesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetWorkspacesQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  const query = useFetchData<GetWorkspacesQuery, GetWorkspacesQueryVariables>(
    GetWorkspacesDocument,
  );
  return useInfiniteQuery<GetWorkspacesQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey:
          optionsQueryKey ?? variables === undefined
            ? ['getWorkspaces.infinite']
            : ['getWorkspaces.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })(),
  );
};

export const GetWorkspaceDocument = `
    query getWorkspace($id: String!) {
  workspace(id: $id) {
    ...Workspace
  }
}
    ${WorkspaceFragmentDoc}`;

export const useGetWorkspaceQuery = <
  TData = GetWorkspaceQuery,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetWorkspaceQueryVariables,
  options?: Omit<
    UseQueryOptions<GetWorkspaceQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<GetWorkspaceQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetWorkspaceQuery, TError, TData>({
    queryKey: ['getWorkspace', variables],
    queryFn: useFetchData<GetWorkspaceQuery, GetWorkspaceQueryVariables>(
      GetWorkspaceDocument,
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetWorkspaceQuery = <
  TData = InfiniteData<GetWorkspaceQuery>,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetWorkspaceQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetWorkspaceQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetWorkspaceQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  const query = useFetchData<GetWorkspaceQuery, GetWorkspaceQueryVariables>(
    GetWorkspaceDocument,
  );
  return useInfiniteQuery<GetWorkspaceQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getWorkspace.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })(),
  );
};

export const GetWorkspaceByUrlDocument = `
    query getWorkspaceByURL($URL: String) {
  workspaceByURL(URL: $URL) {
    ...WorkspaceCore
  }
}
    ${WorkspaceCoreFragmentDoc}
${WorkspaceFragmentDoc}
${TeamFragmentDoc}
${LabelFragmentDoc}
${WorkflowFragmentDoc}
${ProjectStatusFragmentDoc}
${MemberTeamFragmentDoc}
${UserFragmentDoc}
${SubscriberFragmentDoc}
${FavoriteFragmentDoc}`;

export const useGetWorkspaceByUrlQuery = <
  TData = GetWorkspaceByUrlQuery,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables?: GetWorkspaceByUrlQueryVariables,
  options?: Omit<
    UseQueryOptions<GetWorkspaceByUrlQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      GetWorkspaceByUrlQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  return useQuery<GetWorkspaceByUrlQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['getWorkspaceByURL']
        : ['getWorkspaceByURL', variables],
    queryFn: useFetchData<
      GetWorkspaceByUrlQuery,
      GetWorkspaceByUrlQueryVariables
    >(GetWorkspaceByUrlDocument).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetWorkspaceByUrlQuery = <
  TData = InfiniteData<GetWorkspaceByUrlQuery>,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetWorkspaceByUrlQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetWorkspaceByUrlQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetWorkspaceByUrlQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  const query = useFetchData<
    GetWorkspaceByUrlQuery,
    GetWorkspaceByUrlQueryVariables
  >(GetWorkspaceByUrlDocument);
  return useInfiniteQuery<GetWorkspaceByUrlQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey:
          optionsQueryKey ?? variables === undefined
            ? ['getWorkspaceByURL.infinite']
            : ['getWorkspaceByURL.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })(),
  );
};

export const GetInviteLinkDocument = `
    query getInviteLink($invite_link: String!) {
  getInviteLink(invite_link: $invite_link) {
    ...WorkspaceInviteLink
  }
}
    ${WorkspaceInviteLinkFragmentDoc}`;

export const useGetInviteLinkQuery = <
  TData = GetInviteLinkQuery,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetInviteLinkQueryVariables,
  options?: Omit<
    UseQueryOptions<GetInviteLinkQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<GetInviteLinkQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetInviteLinkQuery, TError, TData>({
    queryKey: ['getInviteLink', variables],
    queryFn: useFetchData<GetInviteLinkQuery, GetInviteLinkQueryVariables>(
      GetInviteLinkDocument,
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetInviteLinkQuery = <
  TData = InfiniteData<GetInviteLinkQuery>,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetInviteLinkQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetInviteLinkQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetInviteLinkQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  const query = useFetchData<GetInviteLinkQuery, GetInviteLinkQueryVariables>(
    GetInviteLinkDocument,
  );
  return useInfiniteQuery<GetInviteLinkQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getInviteLink.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })(),
  );
};

export const GetInviteEmailDocument = `
    query getInviteEmail($invite: String!) {
  getInviteEmail(invite: $invite) {
    ...WorkspaceInviteEmail
  }
}
    ${WorkspaceInviteEmailFragmentDoc}`;

export const useGetInviteEmailQuery = <
  TData = GetInviteEmailQuery,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetInviteEmailQueryVariables,
  options?: Omit<
    UseQueryOptions<GetInviteEmailQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<GetInviteEmailQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetInviteEmailQuery, TError, TData>({
    queryKey: ['getInviteEmail', variables],
    queryFn: useFetchData<GetInviteEmailQuery, GetInviteEmailQueryVariables>(
      GetInviteEmailDocument,
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteGetInviteEmailQuery = <
  TData = InfiniteData<GetInviteEmailQuery>,
  TError = InvalidInputError | InvalidCredentialsError | ClientError | Error,
>(
  variables: GetInviteEmailQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<GetInviteEmailQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      GetInviteEmailQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  const query = useFetchData<GetInviteEmailQuery, GetInviteEmailQueryVariables>(
    GetInviteEmailDocument,
  );
  return useInfiniteQuery<GetInviteEmailQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['getInviteEmail.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })(),
  );
};
