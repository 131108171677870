import * as Types from '../../types';

import { gql } from '@apollo/client';
export type FileFragment = { __typename?: 'FileEntity', id: string, name: string, path: string, mime_type: string, size: number, workspace_id?: string | null, created_at?: string | null };

export const FileFragmentDoc = gql`
    fragment File on FileEntity {
  id
  name
  path
  mime_type
  size
  workspace_id
  created_at
}
    `;