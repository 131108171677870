import { createContext } from 'react';

import { SubscriptionResponseFragment } from '@/types/apollo-client/subscription/graphql/fragments.graphql.generated';

type ISubscriptionContext = {
  pubsub: SubscriptionResponseFragment | undefined;
  onConnect: (workspace_id: string) => void;
};

const SubscriptionContext = createContext<ISubscriptionContext>({
  pubsub: undefined,
  onConnect: () => {},
});

export default SubscriptionContext;
