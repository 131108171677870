import { useEffect, useRef, useState } from 'react';

import { PlateEditor, TElement } from '@udecode/plate-common';
import { insertImage } from '@udecode/plate-media';
import { useParams } from 'react-router-dom';

import { Button } from '@/components/Button';
import EditorPlateComponent from '@/components/Editor/EditorPlateComponent';
import SingleLineEditorComponent from '@/components/Editor/TitleEditorComponent';
import { Label } from '@/components/Label';
import { ScrollArea, ScrollBar } from '@/components/ScrollArea';
import { Separator } from '@/components/Separator';
import { useErrorHandler } from '@/hooks/useToasterHandler';
import useUploadAttachment from '@/hooks/useUploadAttachment';
import { SpinnerDotIcon, AttachmentIcon } from '@/icons/core';
import { MemoizedRenderProject } from '@/renders/RenderProject';
import {
  PriorityType,
  ProjectFragment,
  useUpdateProjectMutation,
} from '@/types/tanstack-query/generated';
import PlateUtils from '@/utils/plate-utils';

import ProjectAnalyticsComponent from './components/ProjectAnalyticsComponent';
import ProjectNotFoundComponent from './components/ProjectNotFoundComponent';
import ProjectPropertiesComponent from './components/ProjectPropertiesComponent';
import HeaderProjectComponent from './HeaderProjectComponent';
import { initialValueEditor } from '../cores';

const OverviewComponent = (props: { project: ProjectFragment }) => {
  const { showErrorToast } = useErrorHandler();
  const editorTitleRef = useRef<PlateEditor | null>(null);
  const editorShortSummaryRef = useRef<PlateEditor | null>(null);
  const editorContentRef = useRef<PlateEditor | null>(null);

  const [selectedProjectStatusId, setSelectedProjectStatusId] = useState<
    string | undefined
  >(props.project.project_status_id ?? undefined);

  const [selectedLeadId, onSelectedLeadId] = useState<string | undefined>(
    props.project.leader_id ?? undefined,
  );
  const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>(
    props.project.member_ids?.split(',') ?? [],
  );
  const [selectedStartAt, setSelectedStartAt] = useState<Date | undefined>(
    props.project.start_at ? new Date(props.project.start_at) : undefined,
  );
  const [selectedEndAt, setSelectedEndAt] = useState<Date | undefined>(
    props.project.end_at ? new Date(props.project.end_at) : undefined,
  );
  const [selectedPriority, setSelectedPriority] = useState<
    PriorityType | undefined
  >(props.project.priority);
  const [selectedLabelIds, setSelectedLabelIds] = useState<string[]>(
    props.project.label_ids?.split(',') ?? [],
  );

  const updateProject = useUpdateProjectMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {},
    onSettled: () => {},
  });

  const { onUpload, loading: loadingUpload } = useUploadAttachment({
    type: 'project',
    workspace_id: props.project.workspace_id,
    onUploadSuccess: (path) => {
      insertImage(editorContentRef.current, path);
    },
  });

  useEffect(() => {
    if (props.project) {
      if (!editorContentRef.current) return;
      if (props.project.description) {
        editorContentRef.current.children = JSON.parse(
          props.project.description,
        );
        editorContentRef.current.onChange();
      } else {
        editorContentRef.current.children = initialValueEditor;
        editorContentRef.current.onChange();
      }
      // Editor Title
      if (!editorTitleRef.current) return;
      if (props.project.title) {
        editorTitleRef.current.children = [
          { id: 1, type: 'p', children: [{ text: props.project.title }] },
        ] as TElement[];
        editorTitleRef.current.onChange();
      } else {
        editorTitleRef.current.children = initialValueEditor;
        editorContentRef.current.onChange();
      }
      // Editor Title
      if (!editorShortSummaryRef.current) return;
      if (props.project.short_summary) {
        editorShortSummaryRef.current.children = [
          {
            id: 1,
            type: 'p',
            children: [{ text: props.project.short_summary }],
          },
        ] as TElement[];
        editorShortSummaryRef.current.onChange();
      } else {
        editorShortSummaryRef.current.children = initialValueEditor;
        editorContentRef.current.onChange();
      }
    }
  }, [props.project]);

  return (
    <div className="relative flex grow flex-col overflow-hidden md:flex-row">
      <div className="z-20 w-full flex-none bg-secondary py-2 md:relative md:order-last md:w-[300px] md:py-0">
        <ProjectAnalyticsComponent project={props.project} />
      </div>
      <div className="relative z-10 flex h-full grow md:pt-0">
        <ScrollArea className="w-full" type="auto">
          <div className="m-auto w-full max-w-[900px] items-start px-[24px] pb-[70px]">
            <div className="mb-[10px] mt-10 flex ">
              <SingleLineEditorComponent
                className="min-h-[10px] text-2xl"
                id={props.project.id}
                loading={false}
                editorRef={editorTitleRef}
                disabled={props.project.is_deleted}
                placeholder={'Document title'}
                onSubmit={() => {
                  const getData = PlateUtils.getTitleFromTElement(
                    editorTitleRef.current?.children,
                  );
                  updateProject.mutate({
                    updateInput: {
                      id: props.project.id,
                      title: getData ?? undefined,
                    },
                  });
                }}
              />
            </div>
            <div className="mb-[10px] flex">
              <SingleLineEditorComponent
                id={props.project.id}
                className="h-auto"
                loading={false}
                editorRef={editorShortSummaryRef}
                disabled={props.project.is_deleted}
                placeholder={'Short summary'}
                onSubmit={() => {
                  const getData = PlateUtils.getTitleFromTElement(
                    editorShortSummaryRef.current?.children,
                  );
                  updateProject.mutate({
                    updateInput: {
                      id: props.project.id,
                      short_summary: getData ?? undefined,
                    },
                  });
                }}
              />
            </div>

            <div>
              <ProjectPropertiesComponent
                data={props.project}
                disabled={false}
                teamIds={props.project.team_ids?.split(',') ?? []}
                selectedProjectStatusId={selectedProjectStatusId}
                onSelectedProjectStatusId={(value) => {
                  setSelectedProjectStatusId(value);
                  if (props.project?.id) {
                    updateProject.mutate({
                      updateInput: {
                        id: props.project?.id,
                        project_status_id: value,
                      },
                    });
                  }
                }}
                selectedPriority={selectedPriority}
                onSelectedPriority={(value) => {
                  setSelectedPriority(value);
                  if (props.project?.id) {
                    updateProject.mutate({
                      updateInput: {
                        id: props.project?.id,
                        priority: value,
                      },
                    });
                  }
                }}
                selectedLabelIds={selectedLabelIds}
                onSelectedLabelIds={(value) => {
                  setSelectedLabelIds(value);
                  if (props.project?.id) {
                    updateProject.mutate({
                      updateInput: {
                        id: props.project?.id,
                        label_ids: value.join(','),
                      },
                    });
                  }
                }}
                selectedLeadId={selectedLeadId}
                onSelectedLeadId={(value) => {
                  onSelectedLeadId(value);
                  if (props.project?.id) {
                    updateProject.mutate({
                      updateInput: {
                        id: props.project?.id,
                        leader_id: value,
                      },
                    });
                  }
                }}
                selectedMemberIds={selectedMemberIds}
                onSelectedMemberIds={(value) => {
                  setSelectedMemberIds(value);
                  if (props.project?.id) {
                    updateProject.mutate({
                      updateInput: {
                        id: props.project?.id,
                        member_ids: value.join(','),
                      },
                    });
                  }
                }}
                onCopyId={() => {}}
                onCopyLink={() => {}}
                selectedStartAt={selectedStartAt}
                selectedEndAt={selectedEndAt}
                onSelectedStartAt={(value) => {
                  setSelectedStartAt(value);
                  if (props.project?.id) {
                    updateProject.mutate({
                      updateInput: {
                        id: props.project?.id,
                        start_at: value?.toISOString(),
                      },
                    });
                  }
                }}
                onSelectedEndAt={(value) => {
                  setSelectedEndAt(value);
                  if (props.project?.id) {
                    updateProject.mutate({
                      updateInput: {
                        id: props.project?.id,
                        end_at: value?.toISOString(),
                      },
                    });
                  }
                }}
              />
            </div>

            <div className="relative my-[20px] flex w-full flex-col gap-2">
              <div>
                <Label>Description</Label>
              </div>
              <EditorPlateComponent
                id={props.project.id}
                loading={false}
                disabled={props.project.is_deleted}
                editorRef={editorContentRef}
                placeholder="Add description ..."
                className="min-h-[300px]"
                onSubmit={() => {
                  const getContent = PlateUtils.getContentFromTElement(
                    editorContentRef.current?.children,
                  );
                  if (getContent === props.project.description) return;
                  updateProject.mutate({
                    updateInput: {
                      id: props.project.id,
                      description: getContent,
                    },
                  });
                }}
              />
              <div className="absolute bottom-[5px] right-0 flex">
                <Button
                  type="button"
                  size={'sm'}
                  variant={'ghost'}
                  disabled={loadingUpload}
                  onClick={() => onUpload()}
                >
                  <div className="flex flex-row items-center gap-2">
                    {loadingUpload && <SpinnerDotIcon width={18} />}
                    <AttachmentIcon width={16} height={16} />
                  </div>
                </Button>
              </div>
            </div>
          </div>
          <ScrollBar orientation="vertical" />
        </ScrollArea>
      </div>
    </div>
  );
};

const ProjectOverviewPage = (props?: { code?: string; id?: string }) => {
  const params = useParams();

  return (
    <div className="relative flex w-full flex-col md:h-full md:overflow-hidden">
      <MemoizedRenderProject
        code={props?.code ?? params.projectCode}
        id={props?.id ?? params.id}
        renderItem={(project) => {
          return project ? (
            <>
              <HeaderProjectComponent data={project} />
              <Separator />
              <OverviewComponent project={project} />
            </>
          ) : (
            <ProjectNotFoundComponent />
          );
        }}
      />
    </div>
  );
};

export default ProjectOverviewPage;
