import React, { useContext } from 'react';

import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { LabelFragment } from '@/types/tanstack-query/generated';

const RenderLabel = (props: {
  id: string | undefined;
  renderItem: (value: LabelFragment | undefined) => React.ReactNode;
}) => {
  if (!props.id) {
    return <>{props.renderItem(undefined)}</>;
  }
  const { labels } = useContext(WorkspaceContext);
  return <>{props.renderItem(labels.find((item) => item.id === props.id))}</>;
};
const RenderLabels = (props: {
  ids: string[];
  renderItem: (values: LabelFragment[]) => React.ReactNode;
}) => {
  const { labels } = useContext(WorkspaceContext);
  return (
    <>
      {props.renderItem(labels.filter((item) => props.ids.includes(item.id)))}
    </>
  );
};
const MemoizedRenderLabel = React.memo(RenderLabel);
const MemoizedRenderLabels = React.memo(RenderLabels);
export { MemoizedRenderLabel, MemoizedRenderLabels };
