import { createContext } from 'react';

import { UserFragment } from '@/types/tanstack-query/generated';

type IAuthenticatedUserContext = {
  user: UserFragment | undefined;
  refetch: () => void;
  logout: () => void;
};

const AuthenticatedUserContext = createContext<IAuthenticatedUserContext>({
  user: undefined,
  refetch: () => {},
  logout: () => {},
});

export default AuthenticatedUserContext;
