import { Dialog, DialogContent } from '@/components/Dialog';
import ProjectContentHistoryComponent from '@/pages/project/components/ProjectContentHistory/ProjectContentHistoryComponent';

import ProjectContentHistoryContext from './ProjectServicesContext';
import useProjectContentHistoryProvider from './useProjectContentHistoryProvider';

const ProjectContentHistoryProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const { isOpen, projectId, event } = useProjectContentHistoryProvider();
  return (
    <ProjectContentHistoryContext.Provider
      value={{
        isOpen: isOpen,
        projectId: projectId,
        showModal: (value) => event.setDataHistory(value),
      }}
    >
      {children}
      <Dialog
        modal={true}
        open={isOpen}
        onOpenChange={(value) => event.setDataHistory({ isOpen: value })}
      >
        <DialogContent className="h-[94%] w-[94%] max-w-5xl">
          {projectId && (
            <ProjectContentHistoryComponent
              project_id={projectId}
              onCancel={() => event.setDataHistory({ isOpen: false })}
            />
          )}
        </DialogContent>
      </Dialog>
    </ProjectContentHistoryContext.Provider>
  );
};
export default ProjectContentHistoryProvider;
