import { useContext, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import useIssuesFilters from '@/hooks/useIssuesFilters';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { WorkflowType } from '@/types/tanstack-query/generated';

import useTeam from '../useTeam';

const useTeamActives = () => {
  const params = useParams();
  const { getIssuesByTeam, issues: rootIssues } = useContext(WorkspaceContext);

  const { team } = useTeam();
  const [initial, setInitial] = useState<boolean>(false);

  const {
    issues,
    selectedStatuses,
    selectedConditionStatus,
    selectedPriorities,
    selectedConditionPriority,
    selectedLabels,
    selectedConditionLabel,
    selectedAssignees,
    selectedConditionAssignee,
    event: {
      setIssues,
      setSelectedStatuses,
      setSelectedConditionStatus,
      setSelectedPriorities,
      setSelectedConditionPriority,
      setSelectedLabels,
      setSelectedConditionLabel,
      setSelectedAssignees,
      setSelectedConditionAssignee,
    },
  } = useIssuesFilters();

  useEffect(() => {
    if (params.team && getIssuesByTeam && team) {
      const getIssues = getIssuesByTeam({ team_identifier: params.team });
      const getWorkflows = team?.workflows.filter(
        (item) =>
          item.type === WorkflowType.InProgress ||
          item.type === WorkflowType.Planned,
      );

      const filters = getIssues.filter(
        (item) =>
          getWorkflows?.find((workflow) => workflow.id === item.workflow_id) &&
          item.is_deleted === false,
      );
      setIssues(filters);

      if (!initial) setTimeout(() => setInitial(true), 500);
    }
  }, [params.team, rootIssues, team]);

  return {
    initial,
    issues,
    workflows:
      team?.workflows.filter(
        (item) =>
          item.type === WorkflowType.InProgress ||
          item.type === WorkflowType.Planned,
      ) ?? [],
    labels: team?.labels ?? [],
    members: team?.members ?? [],
    selectedStatuses,
    selectedConditionStatus,
    selectedPriorities,
    selectedConditionPriority,
    selectedLabels,
    selectedConditionLabel,
    selectedAssignees,
    selectedConditionAssignee,
    event: {
      setSelectedStatuses,
      setSelectedConditionStatus,
      setSelectedPriorities,
      setSelectedConditionPriority,
      setSelectedLabels,
      setSelectedConditionLabel,
      setSelectedAssignees,
      setSelectedConditionAssignee,
    },
  };
};

export default useTeamActives;
