import React, { useContext } from 'react';

import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { IssueFragment, TeamFragment } from '@/types/tanstack-query/generated';

const RenderIssue = (props: {
  id?: string | undefined;
  identifier?: string | undefined;
  renderItem: (value: IssueFragment | undefined) => React.ReactNode;
}) => {
  if (!props.id && !props.identifier) {
    return <>{props.renderItem(undefined)}</>;
  }
  const { issues, teams } = useContext(WorkspaceContext);
  if (props.identifier) {
    const array = props.identifier.split('-');
    const getTeam = teams.find((item) => item.identifier === array[0]);
    return (
      <>
        {props.renderItem(
          issues.find(
            (item) =>
              item.team_id === getTeam?.id && item.code === Number(array[1]),
          ),
        )}
      </>
    );
  }
  return <>{props.renderItem(issues.find((item) => item.id === props.id))}</>;
};

const RenderIssueTeam = (props: {
  id?: string | undefined;
  renderItem: (
    value?: IssueFragment | undefined,
    team?: TeamFragment | undefined,
  ) => React.ReactNode;
}) => {
  const { issues, teams } = useContext(WorkspaceContext);
  if (props.id) {
    const getIssue = issues.find((item) => item.id === props.id);
    const getTeam = teams.find((item) => item.id === getIssue?.team_id);

    return <>{props.renderItem(getIssue, getTeam)}</>;
  }
  return <>{props.renderItem()}</>;
};

const RenderIssuesByParentId = (props: {
  id: string | undefined;
  renderItem: (value: IssueFragment[]) => React.ReactNode;
}) => {
  if (!props.id) {
    return <>{props.renderItem([])}</>;
  }
  const { issues } = useContext(WorkspaceContext);
  return (
    <>{props.renderItem(issues.filter((item) => item.issue_id === props.id))}</>
  );
};

const MemoizedRenderIssuesByParentId = React.memo(RenderIssuesByParentId);
const MemoizedRenderIssue = React.memo(RenderIssue);
const MemoizedRenderIssueTeam = React.memo(RenderIssueTeam);
export {
  MemoizedRenderIssue,
  MemoizedRenderIssueTeam,
  MemoizedRenderIssuesByParentId,
};
