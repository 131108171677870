import { useContext } from 'react';

import { PlusIcon } from 'lucide-react';

import { Button } from '@/components/Button';
import { Card, CardContent } from '@/components/Card';
import { Label } from '@/components/Label';
import LoadingFullPage from '@/components/LoadingPage';
import ProjectTableList from '@/pages/project/components/ProjectTableList/ProjectTableList';
import ProjectServicesContext from '@/providers/project/ProjectServicesContext';

import useProjects from './useProjects';
import TeamHeader from '../components/TeamHeader';
import useTeam from '../useTeam';

const ProjectsPage = () => {
  const { initial, projects } = useProjects();
  const { team } = useTeam();
  const { showCreateProjectPopup } = useContext(ProjectServicesContext);

  return (
    <div className="flex w-full flex-col">
      {initial && (
        <div className="flex w-full flex-col">
          <TeamHeader
            team={team?.team}
            name={'Project'}
            isCreateDocument={true}
            right={
              <div className="flex-none">
                <Button
                  type="button"
                  variant={'ghost'}
                  size={'sm'}
                  onClick={() =>
                    showCreateProjectPopup({
                      isOpen: true,
                      initTeamId: team?.team?.id,
                    })
                  }
                >
                  <PlusIcon width={20} height={20} />
                </Button>
              </div>
            }
          />
          {projects.length > 0 && (
            <ProjectTableList
              projects={projects.filter((item) => item.title)}
            />
          )}
        </div>
      )}
      {initial && projects.length === 0 && (
        <div className="flex grow flex-col items-center justify-center">
          <Card className="max-w-[500px] bg-secondary">
            <CardContent className="p-6">
              <div className="flex flex-col gap-2">
                <Label className="text-xl font-semibold">Projects</Label>
                <Label className="opacity-50">
                  Projects are larger units of work with a clear outcome, such
                  as a new feature you want to ship. They can be shared across
                  multiple teams and are comprised of issues and optional
                  documents.
                </Label>
                <div className="pt-[20px]">
                  <Button
                    size={'sm'}
                    type="button"
                    onClick={() => {
                      showCreateProjectPopup({
                        isOpen: true,
                        initTeamId: team?.team?.id,
                      });
                    }}
                    variant={'default'}
                  >
                    Create new project
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      {!initial && <LoadingFullPage description={'Loading projects...'} />}
    </div>
  );
};

export default ProjectsPage;
