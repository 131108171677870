import { useContext, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { ProjectFragment } from '@/types/tanstack-query/generated';

import useTeam from '../useTeam';

const useProjects = () => {
  const params = useParams();
  const { getProjectsByTeam, projects: rootProjects } =
    useContext(WorkspaceContext);

  const { team } = useTeam();
  const [initial, setInitial] = useState<boolean>(false);
  const [projects, setProjects] = useState<ProjectFragment[]>([]);

  useEffect(() => {
    if (params.team && getProjectsByTeam && team) {
      const getData = getProjectsByTeam({ team_identifier: params.team });
      setProjects(getData);
      if (!initial) setTimeout(() => setInitial(true), 500);
    }
  }, [params.team, rootProjects, team]);

  return {
    initial,
    projects,
  };
};

export default useProjects;
