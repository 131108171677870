import { useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';
import { Label } from '@/components/Label';
import { TopNavigationBody } from '@/providers/main/components/TopNavigationBody';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { IIssueMenu } from '@/types/tanstack-query/core/core';

const MyIssueHeader = (props: { active?: IIssueMenu }) => {
  const { selected } = useContext(WorkspaceContext);
  const navigate = useNavigate();

  const menus = [
    { name: 'Assigned', value: 'assigned' },
    { name: 'Created', value: 'created' },
    { name: 'Subscribed', value: 'subscribed' },
    { name: 'Activity', value: 'activity' },
  ];

  const onNavigate = (item: string) => {
    navigate(`/${selected?.url}/my-issues/${item}`);
  };

  return (
    <TopNavigationBody>
      <div className="flex flex-none flex-row items-center gap-2">
        <div onClick={() => navigate(`/${selected?.url}/my-issues/assigned`)}>
          <Label className="font-semibold">My issues</Label>
        </div>
        <div className="flex flex-row items-center gap-4">
          {menus.map((item) => (
            <Button
              key={item.value}
              type="button"
              variant={props.active === item.value ? 'default' : 'outline'}
              onClick={() => onNavigate(item.value)}
            >
              <Label>{item.name}</Label>
            </Button>
          ))}
        </div>
      </div>
    </TopNavigationBody>
  );
};
export default MyIssueHeader;
