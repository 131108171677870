import { DeniedIcon, PlusIcon } from '@/icons/core';
import { IFilterType } from '@/types/tanstack-query/issue/issue.interface';

export interface IConditionFilter {
  id: number;
  name: string;
  icon: React.ReactNode;
  value: IFilterType;
}
export const ConditionFilterList = [
  {
    id: 1,
    name: 'is any of',
    icon: <PlusIcon width={14} height={14} />,
    value: 'or' as IFilterType,
  },
  {
    id: 2,
    name: 'is not',
    icon: <DeniedIcon width={14} height={14} />,
    value: 'or_not' as IFilterType,
  },
];
