import { Dialog, DialogContent } from '@/components/Dialog';

import IssueContentHistoryContext from './IssueServicesContext';
import useIssueContentHistory from './useIssueContentHistoryProvider';
import IssueContentHistoryComponent from '../../../pages/issue/components/IssueContentHistory/IssueContentHistoryComponent';

export const IssueContentHistoryDialog = () => {
  const { isOpen, issueId, event } = useIssueContentHistory();

  return (
    <>
      <Dialog
        open={isOpen}
        onOpenChange={(value) => event.setDataHistory({ isOpen: value })}
      >
        <DialogContent className="h-[94%] w-[94%] max-w-5xl ">
          {issueId && (
            <IssueContentHistoryComponent
              issue_id={issueId}
              onCancel={() => event.setDataHistory({ isOpen: false })}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

const IssueContentHistoryProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const { isOpen, issueId, event } = useIssueContentHistory();

  return (
    <IssueContentHistoryContext.Provider
      value={{
        isOpen: isOpen,
        issueId: issueId,
        showModal: (value) => event.setDataHistory(value),
      }}
    >
      {children}
      <IssueContentHistoryDialog />
    </IssueContentHistoryContext.Provider>
  );
};
export default IssueContentHistoryProvider;
