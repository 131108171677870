/* eslint-disable import/no-extraneous-dependencies */
import { useMemo } from 'react';

import { useDndContext } from '@dnd-kit/core';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Label } from '@radix-ui/react-context-menu';
import { cva } from 'class-variance-authority';

import { Card, CardContent, CardHeader } from '@/components/Card';
import { ScrollArea, ScrollBar } from '@/components/ScrollArea';
import { MemoizedRenderStatus } from '@/renders/RenderStatus';

import {
  BoardColumnProps,
  ColumnDragData,
  DragStatus,
} from './kanban.interface';
import TaskCard from './TaskCard';
import { MemoizedRenderStatusIcon } from '../issue/components/Properties/StatusButton';

export function BoardColumn({ column, tasks, isOverlay }: BoardColumnProps) {
  const taskIds = useMemo(() => tasks.map((task) => task.id), [tasks]);

  const { setNodeRef, transform, transition, isDragging } = useSortable({
    id: column.id,
    data: {
      type: 'Column',
      column,
    } satisfies ColumnDragData,
    attributes: {
      roleDescription: `Column: ${column.title}`,
    },
  });

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };

  let draggingClass: DragStatus;
  if (isOverlay) {
    draggingClass = 'overlay';
  } else if (isDragging) {
    draggingClass = 'over';
  }

  const variants = cva(
    'w-[350px] max-w-full bg-primary-foreground flex flex-col flex-shrink-0 snap-center h-full max-h-full',
    {
      variants: {
        dragging: {
          default: 'border-2 border-transparent',
          over: 'ring-2 opacity-30',
          overlay: 'ring-2 ring-primary',
        },
      },
    },
  );

  return (
    <Card
      ref={setNodeRef}
      style={style}
      className={variants({
        dragging: draggingClass,
      })}
    >
      <MemoizedRenderStatus
        id={column.id}
        renderItem={(workflow) => (
          <>
            <CardHeader className="flex flex-row items-center p-2 text-left font-semibold">
              <div className="flex items-center gap-2">
                <span className="w-[20px] flex-none">
                  <MemoizedRenderStatusIcon
                    type={workflow?.type}
                    color={workflow?.color}
                    processing={workflow?.processing}
                  />
                </span>
                <Label className="text-md grow">{workflow?.name}</Label>
              </div>
            </CardHeader>
            <ScrollArea>
              <CardContent className="flex flex-grow flex-col gap-2 p-0">
                <SortableContext items={taskIds}>
                  {tasks.map((task) => (
                    <TaskCard key={task.id} task={task} />
                  ))}
                </SortableContext>
              </CardContent>
            </ScrollArea>
          </>
        )}
      />
    </Card>
  );
}

export function BoardContainer({ children }: { children: React.ReactNode }) {
  const dndContext = useDndContext();

  const variations = cva(
    'px-2 md:px-0 flex lg:justify-center pb-4 w-full h-full [&>div>div]:!h-full',
    {
      variants: {
        dragging: {
          default: 'snap-x snap-mandatory',
          active: 'snap-none',
        },
      },
    },
  );

  return (
    <ScrollArea
      className={variations({
        dragging: dndContext.active ? 'active' : 'default',
      })}
    >
      <div className="flex h-full w-full flex-row items-start gap-4">
        {children}
      </div>
      <ScrollBar orientation="horizontal" />
    </ScrollArea>
  );
}
