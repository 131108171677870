import { useContext } from 'react';

import { cn } from '@udecode/cn';
import { BookTextIcon, LayersIcon } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';
import { Label } from '@/components/Label';
import { TopNavigationBody } from '@/providers/main/components/TopNavigationBody';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { ProjectFragment } from '@/types/tanstack-query/generated';

const HeaderProjectComponent = (props: { data: ProjectFragment }) => {
  const { selected } = useContext(WorkspaceContext);
  const navigate = useNavigate();
  const location = useLocation();
  const isActiveOverview = location.pathname.includes(
    `/${selected?.url}/project/${props.data.code}/overview`,
  );
  const isActiveIssues = location.pathname.includes(
    `/${selected?.url}/project/${props.data.code}/issues`,
  );
  return (
    <TopNavigationBody>
      <div className="flex w-full flex-row items-center gap-2">
        <div className="flex grow flex-row items-center gap-2">
          <div className="flex flex-row items-center">
            <Label className="font-semibold">Projects</Label>
          </div>

          {props.data.is_deleted && (
            <Button
              variant={'destructive'}
              className="flex items-center font-semibold"
            >
              Deleted
            </Button>
          )}
          <Button
            variant={'outline'}
            type="button"
            className={cn(isActiveOverview && 'bg-primary text-white')}
            onClick={() =>
              navigate(`/${selected?.url}/project/${props.data.code}/overview`)
            }
          >
            <div className="flex flex-row items-center gap-1">
              <BookTextIcon width={18} height={18} />
              <Label>Overview</Label>
            </div>
          </Button>
          <Button
            variant={'outline'}
            type="button"
            className={cn(isActiveIssues && 'bg-primary text-white')}
            onClick={() =>
              navigate(`/${selected?.url}/project/${props.data.code}/issues`)
            }
          >
            <div className="flex flex-row items-center gap-1">
              <LayersIcon width={18} height={18} />
              <Label>Issues</Label>
            </div>
          </Button>
        </div>
      </div>
    </TopNavigationBody>
  );
};
export default HeaderProjectComponent;
