import React, { useEffect, useState } from 'react';

import { Calendar } from '@/components/Calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/Popover';

const DueDateButton = (props: {
  title?: string;
  selected: Date | undefined;
  onSelected: (value: Date | undefined) => void;
  renderItem: (value: Date | undefined) => React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [date, setDate] = React.useState<Date | undefined>(props.selected);

  useEffect(() => {
    setDate(props.selected);
  }, [props.selected]);
  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>{props.renderItem(date)}</PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={(value) => {
            props.onSelected?.(value);
            setDate(value);
            setIsOpen(false);
          }}
        />
      </PopoverContent>
    </Popover>
  );
};
const MemoizedDueDateButton = React.memo(DueDateButton);
export default MemoizedDueDateButton;
