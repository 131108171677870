import React, { useContext, useState } from 'react';

import classNames from 'classnames';
import { PackageIcon } from 'lucide-react';

import { Button } from '@/components/Button';
import { Input } from '@/components/Input';
import { Label } from '@/components/Label';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/Popover';
import { ScrollArea } from '@/components/ScrollArea';
import { Separator } from '@/components/Separator';
import CheckedIcon from '@/icons/checked';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { ProjectFragment } from '@/types/tanstack-query/generated';

const OrderButton = (props: {
  data: ProjectFragment[];
  selected: string | undefined;
  onSelected: (value: string) => void;
}) => {
  return (
    <div>
      {props.data.map((item) => {
        return (
          <Button
            key={item.id}
            type="button"
            variant={item.id === props.selected ? 'secondary' : 'ghost'}
            className="flex h-10 w-full flex-row items-center gap-2 px-[5px] text-left"
            onClick={() => props.onSelected(item.id)}
          >
            <div className="flex w-[30px] flex-none items-center justify-center">
              <PackageIcon width={20} color="#9e9fa8" />
            </div>
            <div className="line-clamp-1 grow text-ellipsis">
              <Label className="text-ellipsis">{item.title}</Label>
            </div>
            <div
              className={classNames(
                'w-[16px] flex-none text-center opacity-0',
                {
                  'opacity-100': item.id === props.selected,
                },
              )}
            >
              <CheckedIcon />
            </div>
          </Button>
        );
      })}
    </div>
  );
};

const ItemList = (props: {
  list: ProjectFragment[];
  selected: string | undefined;
  onSelected: (value: string | undefined) => void;
}) => {
  return props.list.length > 0 ? (
    <div>
      <Separator className="my-[5px] w-full" orientation="horizontal" />
      <ScrollArea className="h-full max-h-[300px] w-full">
        <OrderButton
          data={props.list}
          onSelected={props.onSelected}
          selected={props.selected}
        />
      </ScrollArea>
    </div>
  ) : (
    <></>
  );
};

const AddToProjectButton = (props: {
  title?: string;
  teamId?: string;
  selected: string | undefined;
  onSelected: (value: string | undefined) => void;
  renderItem: (value: ProjectFragment | undefined) => React.ReactNode;
}) => {
  const { getProjectsByTeam } = useContext(WorkspaceContext);
  const projects =
    (getProjectsByTeam && getProjectsByTeam({ team_id: props.teamId })) ?? [];
  const [filter, setFilter] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const selectedProject = projects.find((item) => item.id === props.selected);

  return (
    <Popover modal={true} open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        {props.renderItem(selectedProject)}
      </PopoverTrigger>

      <PopoverContent className="p-[5px]">
        <div className="w-full px-[8px] pt-[4px]">
          {props.title && (
            <Label className="line-clamp-1 max-w-[250px]">{props.title}</Label>
          )}
        </div>
        <div className="flex w-full items-center p-[8px]">
          <Input
            className="h-[30px] w-full border-none p-0 text-lg shadow-none"
            placeholder="Search project..."
            value={filter}
            autoFocus
            onChange={(e) => setFilter(e.currentTarget.value)}
          ></Input>
        </div>
        <ItemList
          list={projects.filter((item) => item.title.includes(filter)) ?? []}
          onSelected={(data) => {
            props.onSelected(data);
            setIsOpen(false);
          }}
          selected={props.selected}
        />
      </PopoverContent>
    </Popover>
  );
};

const MemoizedAddToProjectButton = React.memo(AddToProjectButton);

export { MemoizedAddToProjectButton };
