import { createContext } from 'react';

type IIssueServicesContext = {
  isShowCreateIssue: boolean;
  showCreatePopup: (props: {
    initTeamId?: string;
    initWorkflowId?: string;
    initMemberId?: string;
    initProjectId?: string;
    initTitle?: string;
    initContent?: string;
    isOpen: boolean;
  }) => void;
};

const IssueServicesContext = createContext<IIssueServicesContext>({
  isShowCreateIssue: false,
  showCreatePopup: () => undefined,
});

export default IssueServicesContext;
