import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from '@/components/Button';
import { FormField, Form } from '@/components/Form';
import { Input } from '@/components/Input';
import { LabelError } from '@/components/LabelError';
import { useErrorHandler } from '@/hooks/useToasterHandler';
import { MemoizedRenderProjectStatusIcon } from '@/pages/project/components/ProjectStatusButton';
import ColorPicker from '@/pages/settings/components/ColorPicker';
import { BaseColors } from '@/services/core/constant';
import {
  useCreateProjectStatusMutation,
  ProjectStatusFragment,
  ProjectStatusType,
} from '@/types/tanstack-query/generated';
import { convertHexToRGBA } from '@/utils/color';

export type ICreateProjectStatus = {
  name: string;
  description?: string;
  color?: string;
};

export const schemaCreateProjectStatus = yup.object().shape({
  name: yup.string().required(),
  color: yup.string(),
  description: yup.string(),
});

const CreateProjectStatusForm = (props: {
  workspaceId: string;
  type: ProjectStatusType;
  onSuccess: (data: ProjectStatusFragment) => void;
  onCancel: () => void;
}) => {
  const { showErrorToast } = useErrorHandler();

  const createProjectStatus = useCreateProjectStatusMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: (res) => {
      props.onSuccess(res.createProjectStatus);
    },
    onSettled: () => {},
  });

  const form = useForm<ICreateProjectStatus>({
    resolver: yupResolver<ICreateProjectStatus>(schemaCreateProjectStatus),
    defaultValues: {
      name: '',
      description: '',
      color: BaseColors[0],
    },
  });

  const onSubmitCreate = (data: ICreateProjectStatus) => {
    createProjectStatus.mutate({
      createInput: {
        name: data.name,
        description: data.description,
        order: 1,
        type: props.type,
        color: data.color,
        workspace_id: props.workspaceId,
      },
    });
  };

  return (
    <div className="animate-fade-down animate-delay-0 animate-duration-100 animate-ease-in">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmitCreate)}>
          <div className="flex flex-row gap-4">
            <ColorPicker
              onSelected={(data) => form.setValue('color', data)}
              color={form.watch('color')}
            >
              <Button
                size={'sm'}
                type="button"
                className={`bg-opacity-50! h-[36px] w-[36px] flex-none p-0`}
                style={{
                  backgroundColor: convertHexToRGBA(
                    form.watch('color') ?? '',
                    0.3,
                  ),
                }}
              >
                <MemoizedRenderProjectStatusIcon
                  type={props.type}
                  color={form.watch('color') ?? ''}
                />
              </Button>
            </ColorPicker>

            <div className="w-[160px] flex-none">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <>
                    <Input {...field} placeholder="Name"></Input>

                    <LabelError>
                      {form.formState.errors.name?.message}
                    </LabelError>
                  </>
                )}
              />
            </div>
            <div className="w-full">
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <>
                    <Input {...field} placeholder="Description..."></Input>

                    <LabelError>
                      {form.formState.errors.description?.message}
                    </LabelError>
                  </>
                )}
              />
            </div>
            <Button
              type="button"
              variant={'secondary'}
              onClick={props.onCancel}
              className="h-[36px]"
              size={'sm'}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={
                createProjectStatus.isPending || !form.formState.isValid
              }
              className="h-[36px]"
              size={'sm'}
            >
              Create
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default CreateProjectStatusForm;
