/* eslint-disable import/no-extraneous-dependencies */

import React, { useContext, useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import { PlusIcon } from 'lucide-react';
import { useLocation } from 'react-router-dom';

import { Button } from '@/components/Button';
import { Input } from '@/components/Input';
import LoadingFullPage from '@/components/LoadingPage';
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from '@/components/Resizeable';
import { ScrollArea, ScrollBar } from '@/components/ScrollArea';
import DocumentDetailPage from '@/pages/document';
import DocumentTableList from '@/pages/document/DocumentTableList/DocumentTableList';
import DocumentTreeList from '@/pages/document/DocumentTreeList/DocumentTreeList';
import DocumentServicesContext from '@/providers/document/DocumentServicesContext';
import { CalcPercentByWidth } from '@/utils/calc-percent-screen';

import useDocuments from './useDocuments';
import TeamHeader from '../components/TeamHeader';
import useTeam from '../useTeam';

const DocumentsPage = () => {
  const [filter, setFilter] = useState<string>();
  const { initial, documents } = useDocuments();
  const { showCreateDocumentPopup } = useContext(DocumentServicesContext);
  const { team } = useTeam();
  const location = useLocation();
  const { hash } = location;
  const [selectedDocumentId, setSelectedDocumentId] = useState<
    string | undefined
  >();
  const widthPercent = CalcPercentByWidth({ width: 400 });

  useEffect(() => {
    const itemId = hash ? hash.split('#')[1] : null;
    if (itemId) {
      setSelectedDocumentId(itemId);
    }
  }, [location.hash]);
  return (
    <>
      {initial && (
        <div className="flex h-full w-full grow flex-col overflow-hidden">
          <TeamHeader
            team={team?.team}
            name={'Document'}
            isCreateDocument={true}
            right={
              <div className="flex-none">
                <Button
                  type="button"
                  variant={'ghost'}
                  size={'sm'}
                  onClick={() =>
                    showCreateDocumentPopup({
                      isOpen: true,
                      initTeamId: team?.team?.id,
                    })
                  }
                >
                  <PlusIcon width={20} height={20} />
                </Button>
              </div>
            }
          />
          <div className="flex-none p-1">
            <Input
              placeholder="Search ..."
              onChange={(e) => setFilter(e.target.value)}
            />
          </div>
          <ResizablePanelGroup direction="horizontal">
            <ResizablePanel defaultSize={widthPercent}>
              <div className="flex h-full w-full flex-none flex-col">
                <ScrollArea type="auto" style={{ maxHeight: '100%' }}>
                  {isEmpty(filter) ? (
                    <DocumentTreeList
                      selectedDocumentId={selectedDocumentId}
                      onClick={(item) => {
                        setSelectedDocumentId(item.id);
                      }}
                      documents={documents.filter(
                        (item) =>
                          item.title.includes(filter ?? '') && !item.is_deleted,
                      )}
                    />
                  ) : (
                    <DocumentTableList
                      isSubDocuments={false}
                      documents={documents.filter(
                        (item) =>
                          item.title.includes(filter ?? '') && !item.is_deleted,
                      )}
                    />
                  )}
                  <ScrollBar orientation="vertical" />
                </ScrollArea>
              </div>
            </ResizablePanel>
            <ResizableHandle withHandle className="hidden md:flex" />
            <ResizablePanel
              defaultSize={100 - widthPercent}
              className="hidden md:block"
            >
              <ScrollArea
                type="auto"
                style={{ maxHeight: '100%', height: '100%' }}
              >
                <DocumentDetailPage id={selectedDocumentId} isHideHeader />
                <ScrollBar orientation="vertical" />
              </ScrollArea>
            </ResizablePanel>
          </ResizablePanelGroup>
        </div>
      )}

      {!initial && <LoadingFullPage description={'Loading issues...'} />}
    </>
  );
};

export default DocumentsPage;
