import { useContext } from 'react';

import { MoreHorizontal } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';
import { Label } from '@/components/Label';
import { useErrorHandler } from '@/hooks/useToasterHandler';
import {
  FavoriteIcon,
  FavoriteFilledIcon,
  PlusIcon,
  ArrowRightIcon,
} from '@/icons/core';
import EmojiIcon, { IEmojiIcon } from '@/icons/emoji';
import useDocumentServices from '@/providers/document/useDocumentServices';
import { TopNavigationBody } from '@/providers/main/components/TopNavigationBody';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { MemoizedRenderDocument } from '@/renders/RenderDocument';
import { MemoizedRenderFavorite } from '@/renders/RenderFavorite';
import {
  FavoriteType,
  IDocumentFragment,
  TeamFragment,
  useToggleFavoriteMutation,
} from '@/types/tanstack-query/generated';

import { ToolsMenuDocument } from './ContextMenuDocument';

const HeaderDocumentComponent = (props: {
  data: IDocumentFragment;
  team: TeamFragment | undefined;
}) => {
  const navigate = useNavigate();
  const { showErrorToast } = useErrorHandler();
  const { selected } = useContext(WorkspaceContext);
  const toggleFavorite = useToggleFavoriteMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {},
    onSettled: () => {},
  });
  const { showCreateDocumentPopup } = useDocumentServices();

  return (
    <TopNavigationBody>
      <div className="flex w-full flex-row items-center gap-2">
        <div className="flex grow flex-row items-center gap-2">
          <div className="flex flex-row items-center gap-2">
            <EmojiIcon
              tag={props.team?.icon as IEmojiIcon}
              attrs={{
                width: 18,
                height: 18,
                color: props.team?.color,
              }}
            />
            <Label className="font-semibold">{props.team?.name}</Label>
            <ArrowRightIcon className="flex-none" width={18} height={18} />
          </div>
          <div
            className="flex flex-row items-center"
            onClick={() =>
              navigate(
                `/${selected?.url}/team/${props.team?.identifier}/documents`,
              )
            }
          >
            <Label className="font-semibold">Documents</Label>
          </div>
          {props?.data && (
            <>
              <ArrowRightIcon className="flex-none" width={18} height={18} />
              <div className="flex flex-row items-center">
                {!props.data.document_id && (
                  <div
                    onClick={() =>
                      navigate(`/${selected?.url}/document/${props.data?.id}`)
                    }
                  >
                    <Label className="line-clamp-1">{props.data.title}</Label>
                  </div>
                )}
                {props.data.document_id && (
                  <>
                    <MemoizedRenderDocument
                      id={props.data.document_id}
                      renderItem={(parent) => (
                        <div className="flex flex-row items-center gap-2">
                          <div
                            onClick={() =>
                              navigate(
                                `/${selected?.url}/document/${parent?.id}`,
                              )
                            }
                          >
                            <Label className="line-clamp-1">
                              {parent?.title}
                            </Label>
                          </div>
                          <ArrowRightIcon
                            className="flex-none"
                            width={18}
                            height={18}
                          />
                          <div>
                            <Label className="line-clamp-1">
                              {props.data?.title}
                            </Label>
                          </div>
                        </div>
                      )}
                    />
                  </>
                )}
                <MemoizedRenderFavorite
                  map_id={props.data.id}
                  type={FavoriteType.Document}
                  renderItem={(value) => (
                    <div className="flex w-[50px] cursor-pointer items-center justify-center">
                      {!value ? (
                        <FavoriteIcon
                          width={18}
                          height={18}
                          onClick={() =>
                            selected?.id &&
                            toggleFavorite.mutate({
                              toggleFavorite: {
                                map_id: props.data?.id,
                                type: FavoriteType.Document,
                                workspace_id: selected?.id,
                              },
                            })
                          }
                        />
                      ) : (
                        <FavoriteFilledIcon
                          width={18}
                          height={18}
                          style={{ color: '#ff9000' }}
                          onClick={() =>
                            selected?.id &&
                            toggleFavorite.mutate({
                              toggleFavorite: {
                                map_id: props.data?.id,
                                type: FavoriteType.Document,
                                workspace_id: selected?.id,
                              },
                            })
                          }
                        />
                      )}
                    </div>
                  )}
                />
              </div>
            </>
          )}
          <ToolsMenuDocument
            contextMenu={false}
            document={props.data}
            workspace_id={props.data.workspace_id}
          >
            <Button
              size={'sm'}
              variant={'ghost'}
              disabled={props.data.is_deleted}
            >
              <MoreHorizontal />
            </Button>
          </ToolsMenuDocument>
          {props.data.is_deleted && (
            <Button
              variant={'destructive'}
              className="flex items-center font-semibold"
            >
              Deleted
            </Button>
          )}
        </div>
        <div className="flex-none">
          <Button
            type="button"
            variant={'ghost'}
            size={'sm'}
            onClick={() => showCreateDocumentPopup({ isOpen: true })}
          >
            <PlusIcon width={20} height={20} />
          </Button>
        </div>
      </div>
    </TopNavigationBody>
  );
};
export default HeaderDocumentComponent;
