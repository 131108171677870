import { useContext, useEffect, useState } from 'react';

import useIssuesFilters from '@/hooks/useIssuesFilters';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { ProjectFragment } from '@/types/tanstack-query/generated';

const useProjectIssues = (props: { project: ProjectFragment }) => {
  const {
    getIssuesByProject,
    getDataByTeam,
    issues: rootIssues,
  } = useContext(WorkspaceContext);

  const [initial, setInitial] = useState<boolean>(false);

  const {
    issues,
    selectedStatuses,
    selectedConditionStatus,
    selectedPriorities,
    selectedConditionPriority,
    selectedLabels,
    selectedConditionLabel,
    selectedAssignees,
    selectedConditionAssignee,
    event: {
      setIssues,
      setSelectedStatuses,
      setSelectedConditionStatus,
      setSelectedPriorities,
      setSelectedConditionPriority,
      setSelectedLabels,
      setSelectedConditionLabel,
      setSelectedAssignees,
      setSelectedConditionAssignee,
    },
  } = useIssuesFilters();

  useEffect(() => {
    if (props.project.code && getIssuesByProject) {
      const getIssues = getIssuesByProject({
        project_code: props.project.code,
      });
      setIssues(getIssues);
      if (!initial) setTimeout(() => setInitial(true), 500);
    }
  }, [rootIssues, props.project]);

  return {
    initial,
    issues,
    teamData: getDataByTeam
      ? getDataByTeam({
          team_id: props.project.team_ids?.split(',')[0],
        })
      : undefined,
    workflows: [],
    labels: [],
    members: [],
    selectedStatuses,
    selectedConditionStatus,
    selectedPriorities,
    selectedConditionPriority,
    selectedLabels,
    selectedConditionLabel,
    selectedAssignees,
    selectedConditionAssignee,
    event: {
      getDataByTeam,
      setSelectedStatuses,
      setSelectedConditionStatus,
      setSelectedPriorities,
      setSelectedConditionPriority,
      setSelectedLabels,
      setSelectedConditionLabel,
      setSelectedAssignees,
      setSelectedConditionAssignee,
    },
  };
};

export default useProjectIssues;
