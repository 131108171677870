import orderBy from 'lodash/orderBy';

import { Label } from '@/components/Label';
import { Separator } from '@/components/Separator';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/Tab';
import { UserAvatar } from '@/components/UserAvatar';
import { InProgressIcon } from '@/icons/core/issue';
import { MemoizedRenderMember } from '@/renders/RenderMember';
import { ProjectFragment } from '@/types/tanstack-query/generated';
import { CalcPercent, CalcPercentOfProject } from '@/utils/calc-percent';

const TotalSection = (props: { project: ProjectFragment }) => {
  return (
    <div className="flex w-full flex-row gap-2 px-2 py-4">
      <div className="flex flex-1 flex-col gap-2">
        <Label className="font-bold">Scope</Label>
        <Label>{props.project.analytics?.scope}</Label>
      </div>
      <div className="flex flex-1 flex-col gap-2">
        <Label className="font-bold">Started</Label>
        <div className="flex flex-row items-center gap-2">
          <Label>{props.project.analytics?.started}</Label>
          <span className="h-1.5 w-1.5 rounded-full bg-primary" />
          <Label className="text-sm opacity-50">
            {CalcPercent({
              value: props.project.analytics?.started ?? 0,
              total: props.project.analytics?.scope ?? 0,
            })}
            %
          </Label>
        </div>
      </div>
      <div className="flex flex-1 flex-col gap-2">
        <Label className="font-bold">Completed</Label>
        <div className="flex flex-row items-center gap-2">
          <Label>{props.project.analytics?.completed}</Label>
          <span className="h-1.5 w-1.5 rounded-full bg-primary" />
          <Label className="text-sm opacity-50">
            {CalcPercent({
              value: props.project.analytics?.completed ?? 0,
              total: props.project.analytics?.scope ?? 0,
            })}
            %
          </Label>
        </div>
      </div>
    </div>
  );
};

const TotalByTypeSection = (props: { project: ProjectFragment }) => {
  return (
    <div className="flex flex-col gap-2 px-2 py-4">
      <Tabs defaultValue="assignees" className="w-full p-0">
        <TabsList className="w-full p-0">
          <TabsTrigger className="w-full" value="assignees">
            <Label className="font-bold">Assignees</Label>
          </TabsTrigger>
          {/* <TabsTrigger className="w-full" value="labels">
            <Label className="font-bold">Labels</Label>
          </TabsTrigger> */}
        </TabsList>
        <div className="pt-2">
          <TabsContent value="assignees">
            <div className="flex flex-col gap-4">
              {orderBy(
                props.project.analytics?.members,
                'total_scope',
                'desc',
              )?.map((assignee) => (
                <MemoizedRenderMember
                  key={assignee.id}
                  id={assignee.id}
                  renderItem={(value) => {
                    const percent = CalcPercentOfProject({
                      started: assignee?.total_started ?? 0,
                      completed: assignee?.total_completed ?? 0,
                      total: assignee?.total_scope ?? 0,
                    });
                    return (
                      value && (
                        <div className="flex flex-row items-center gap-2">
                          <div className="flex grow flex-row items-center gap-2">
                            <UserAvatar
                              size="sm"
                              url={value?.image}
                              fallback={value?.username}
                            />
                            <Label>{value?.username}</Label>
                          </div>
                          <div className="flex flex-row items-center gap-1.5">
                            <InProgressIcon
                              attrs={{
                                width: 20,
                                height: 20,
                                style: { color: 'rgb(76, 183, 130)' },
                              }}
                              percent={(percent ? percent / 100 : 0) * 13}
                            />
                            <Label>
                              {CalcPercentOfProject({
                                started: assignee?.total_started ?? 0,
                                completed: assignee?.total_completed ?? 0,
                                total: assignee?.total_scope ?? 0,
                              })}
                              %
                            </Label>
                            <Label>of</Label>
                            <Label className="w-3">
                              {assignee.total_scope}
                            </Label>
                          </div>
                        </div>
                      )
                    );
                  }}
                />
              ))}
            </div>
          </TabsContent>
          {/* <TabsContent value="labels">Change your password here.</TabsContent> */}
        </div>
      </Tabs>
    </div>
  );
};

const ProjectAnalyticsComponent = (props: { project: ProjectFragment }) => {
  return (
    <div className="flex w-full flex-col gap-2">
      <TotalSection project={props.project} />
      <Separator />
      <TotalByTypeSection project={props.project} />
    </div>
  );
};

export default ProjectAnalyticsComponent;
