import { useContext, useEffect, useState } from 'react';

import orderBy from 'lodash/orderBy';

import { PushEventType, IssueFragment } from '@/types/tanstack-query/generated';

import SubscriptionContext from '../subscriptions/SubscriptionContext';

const useWorkspaceIssue = () => {
  const [issues, setIssues] = useState<IssueFragment[]>([]);
  const { pubsub } = useContext(SubscriptionContext);

  const storeIssues = (value: IssueFragment[]) => {
    setIssues(orderBy(value, ['created_at'], ['asc']));
  };

  const getIssuesByTeam = (props: { team_id: string }): IssueFragment[] => {
    const getIssues = issues.filter(
      (item) => item.team_id === props.team_id && item.is_deleted === false,
    );
    return getIssues;
  };
  const getIssuesByProject = (props: {
    project_id: string;
  }): IssueFragment[] => {
    const getIssues = issues.filter(
      (item) =>
        item.project_id === props.project_id && item.is_deleted === false,
    );
    return getIssues;
  };

  const pushItem = async (props: { issue: IssueFragment }) => {
    const clone = Object.assign([], issues);
    clone.push(props.issue);
    storeIssues(clone);
  };

  const updateItem = async (props: { issue: IssueFragment }) => {
    const clone: IssueFragment[] = Object.assign([], issues);

    storeIssues(
      clone.map((item) => {
        if (item.id === props.issue.id) return { ...props.issue };
        return { ...item };
      }),
    );
  };

  const popItem = async (props: { issue_id: string }) => {
    const getIssue = issues.find((item) => item.id === props.issue_id);
    const clone = { ...getIssue, is_deleted: true } as IssueFragment;
    updateItem({ issue: clone });
  };

  useEffect(() => {
    if (pubsub && pubsub.id && pubsub.event) {
      switch (pubsub.event) {
        case PushEventType.IssueAdded:
          pushItem({ issue: pubsub.data as IssueFragment });
          break;
        case PushEventType.IssueUpdated:
          updateItem({ issue: pubsub.data as IssueFragment });
          break;
        case PushEventType.IssueDeleted:
          popItem({ issue_id: pubsub.id });
          break;
        default:
          break;
      }
    }
  }, [pubsub]);

  return {
    issues,
    event: {
      storeIssues,
      getIssuesByTeam,
      getIssuesByProject,
    },
  };
};

export default useWorkspaceIssue;
