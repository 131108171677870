/* eslint-disable import/no-extraneous-dependencies */

import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

class CustomDateTime {
  static convertStringToDateTime(
    datetime: string | null,
    formatString?: string,
  ) {
    if (datetime) {
      return formatInTimeZone(
        new Date(datetime),
        'Asia/Ho_Chi_Minh',
        formatString ?? 'dd/MM/yyyy HH:mm:ss aa',
      );
    }
    return '';
  }

  static convertStringToCommon(dateTime: string | null) {
    if (dateTime) {
      return `${this.convertStringToDateTime(
        dateTime,
        'PP',
      )} ${this.convertStringToDateTime(dateTime, 'p')}`;
    }
    return '';
  }

  static convertStringToNoTimeZone(datetime: string) {
    const date = format(new Date(datetime), `yyyy-MM-dd`);
    const time = format(new Date(datetime), `HH:mm:ss`);
    return `${date}T${time}`;
  }

  static formatDate(date: Date | undefined) {
    if (!date) {
      return '';
    }
    if (date.getFullYear() === new Date().getFullYear()) {
      return format(date, 'MMM dd');
    }
    return format(date, 'MMM dd, yy');
  }
}

export default CustomDateTime;
