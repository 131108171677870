import { useContext } from 'react';

import { cn } from '@udecode/cn';
import { ArrowBigUp, ChevronDownIcon, InboxIcon } from 'lucide-react';
import { useParams, useLocation } from 'react-router-dom';

import { Badge } from '@/components/Badge';
import { Button } from '@/components/Button';
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenu,
  DropdownMenuSeparator,
  DropdownMenuSubTrigger,
  DropdownMenuSubContent,
  DropdownMenuSub,
  DropdownMenuLabel,
  DropdownMenuCheckboxItem,
} from '@/components/DropdownMenu';
import { Label } from '@/components/Label';
import { ScrollArea, ScrollBar } from '@/components/ScrollArea';
import { UserAvatar } from '@/components/UserAvatar';
import { EditSquareOutlineIcon, FocusFillIcon } from '@/icons/core';
import { SearchIcon } from '@/icons/index';
import AuthenticatedUserContext from '@/providers/auth/AuthenticatedUserContext';
import {
  UserFragment,
  WorkspaceFragment,
  RoleType,
  NotificationFragment,
  FavoriteFragment,
} from '@/types/tanstack-query/generated';

import FavoriteSection from './FavoriteSection';
import FreePlanModal from './FreePlanModal';
import TeamSection from './TeamSection';
import WorkspaceSection from './WorkspaceSection';
import WorkspaceContext from '../../workspace/WorkspaceContext';

const MenuSystem = (props: {
  user: UserFragment;
  children: React.ReactNode;
  workspace: WorkspaceFragment;
  workspaces: WorkspaceFragment[];
  onNavigate: (path: string, reload?: boolean) => void;
  onLogout: () => void;
}) => {
  const { role } = useContext(WorkspaceContext);
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{props.children}</DropdownMenuTrigger>
      <DropdownMenuContent color="gray">
        <DropdownMenuItem
          onClick={() =>
            props.onNavigate(
              `/${props.workspace?.url}/settings/account/preferences`,
            )
          }
        >
          <Label>Preferences</Label>
        </DropdownMenuItem>

        {role === RoleType.Admin && (
          <>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              onClick={() =>
                props.onNavigate(`/${props.workspace?.url}/settings/workspace`)
              }
            >
              <Label>Workspace settings</Label>
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() =>
                props.onNavigate(`/${props.workspace?.url}/settings/members`)
              }
            >
              <Label>Invite and manage members</Label>
            </DropdownMenuItem>
          </>
        )}

        <DropdownMenuSeparator />
        <DropdownMenuSub>
          <DropdownMenuSubTrigger>Switch workspace</DropdownMenuSubTrigger>
          <DropdownMenuSubContent>
            <DropdownMenuLabel>
              <Label>{props.user.email}</Label>
            </DropdownMenuLabel>
            {props.workspaces.map((item) => (
              <DropdownMenuCheckboxItem
                key={item.id}
                checked={props.workspace.id === item.id}
                onCheckedChange={() =>
                  props.onNavigate(`/${item.url}/my-issues/assigned`, true)
                }
              >
                <div className="flex flex-row items-center gap-2">
                  <UserAvatar size={'sm'} fallback={item.name} />
                  <Label>{item.name}</Label>
                </div>
              </DropdownMenuCheckboxItem>
            ))}

            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={() => props.onNavigate(`/join`)}>
              <Label>Create or join a workspace</Label>
            </DropdownMenuItem>
          </DropdownMenuSubContent>
        </DropdownMenuSub>
        <DropdownMenuSeparator />
        <DropdownMenuItem color="gray" onClick={() => props.onLogout()}>
          <Label>Logout</Label>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const HeaderSidebar = (props: {
  user: UserFragment;
  workspace: WorkspaceFragment;
  workspaces: WorkspaceFragment[];
  onOpenCreateIssue: () => void;
  onNavigate: (path: string, reload?: boolean) => void;
  onLogout: () => void;
}) => {
  return (
    <div className="flex w-full flex-col gap-2 p-[10px]">
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center">
          <MenuSystem
            user={props.user}
            workspace={props.workspace}
            workspaces={props.workspaces}
            onNavigate={props.onNavigate}
            onLogout={props.onLogout}
          >
            <Button
              size={'sm'}
              type="button"
              className="flex items-center gap-2 px-[10px]"
              variant="ghost"
            >
              <UserAvatar size={'sm'} fallback={props.workspace.name} />
              <Label className="max-w-[60px] truncate">
                {props.workspace.name}
              </Label>
              <ChevronDownIcon size={16} />
            </Button>
          </MenuSystem>
        </div>
        <div className="flex flex-row items-center">
          <Button
            variant="ghost"
            size={'sm'}
            type="button"
            onClick={() => props.onNavigate(`${props.workspace.url}/search`)}
          >
            <SearchIcon width={20} height={20} />
          </Button>
          <Button
            variant="ghost"
            size={'sm'}
            type="button"
            onClick={() => props.onOpenCreateIssue()}
          >
            <EditSquareOutlineIcon width={20} height={20} />
          </Button>
        </div>
      </div>
    </div>
  );
};

const BodySidebar = (props: {
  workspace: WorkspaceFragment;
  notifications: NotificationFragment[];
  favorites: FavoriteFragment[];
  currentPath: string;
  currentParams: Record<string, string | undefined>;
  onNavigate: (path: string, reload?: boolean) => void;
}) => {
  const countBadge = props.notifications.filter(
    (item) => !item.mark_read,
  ).length;
  const items = [
    {
      title: 'Inbox',
      path: `/${props.workspace.url}/inbox`,
      icon: <InboxIcon width={20} height={20} />,
      badge: countBadge,
    },
    {
      title: 'My issues',
      path: `/${props.workspace.url}/my-issues`,
      icon: <FocusFillIcon width={20} height={20} />,
      badge: 0,
    },
  ];

  return (
    <div className="px-[10px]">
      <div className="flex flex-col">
        {items.map((item, index) => (
          <Button
            type="button"
            key={index}
            variant="ghost"
            className={cn(
              'h-[30px] cursor-pointer px-[10px]',
              props.currentPath.startsWith(item.path) && 'bg-muted',
            )}
            onClick={() => props.onNavigate(`${item.path}/assigned`)}
          >
            <div className="flex w-full flex-row items-center gap-2">
              <div className="w-[20px]">{item.icon}</div>
              <Label>{item.title}</Label>
              {item.badge > 0 && (
                <Badge className="rounded-full text-xs" variant={'secondary'}>
                  {item.badge}
                </Badge>
              )}
            </div>
          </Button>
        ))}
      </div>
      <WorkspaceSection
        workspace={props.workspace}
        onNavigate={props.onNavigate}
        currentPath={props.currentPath}
        currentParams={props.currentParams}
      />
      {props.favorites.length > 0 && (
        <FavoriteSection
          workspace={props.workspace}
          favorites={props.favorites}
          onNavigate={props.onNavigate}
        />
      )}
      <TeamSection
        onNavigate={props.onNavigate}
        currentPath={props.currentPath}
        currentParams={props.currentParams}
      />
    </div>
  );
};

const MainSidebar = (props: {
  onOpenCreateIssue: () => void;
  onNavigate: (path: string, reload?: boolean) => void;
}) => {
  const {
    selected: selectedWorkspace,
    workspaces,
    notifications,
    favorites,
  } = useContext(WorkspaceContext);
  const { user, logout } = useContext(AuthenticatedUserContext);
  const params = useParams();
  const location = useLocation();
  return (
    <div className="relative h-full w-full">
      {selectedWorkspace && user && (
        <div className="flex h-full w-full flex-col">
          <div className="flex-none">
            <HeaderSidebar
              user={user}
              workspace={selectedWorkspace}
              workspaces={workspaces}
              onOpenCreateIssue={props.onOpenCreateIssue}
              onNavigate={props.onNavigate}
              onLogout={() => logout()}
            />
          </div>
          <div className="grow overflow-hidden">
            <ScrollArea type="auto" style={{ height: '100%' }}>
              <BodySidebar
                workspace={selectedWorkspace}
                notifications={notifications}
                favorites={favorites}
                onNavigate={props.onNavigate}
                currentPath={location.pathname}
                currentParams={params}
              />
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          </div>
        </div>
      )}
      <FreePlanModal>
        <Button
          variant={'outline'}
          className="absolute bottom-[10px] right-[10px] cursor-pointer"
          size={'sm'}
        >
          <div className="flex flex-row items-center gap-2">
            <ArrowBigUp width={18} />
            <Label>Free plan</Label>
          </div>
        </Button>
      </FreePlanModal>
    </div>
  );
};
export default MainSidebar;
