import {
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogTitle,
} from '@/components/AlertDialog';
import { Button } from '@/components/Button';
import { useErrorHandler, useSuccessHandler } from '@/hooks/useToasterHandler';
import { MemoizedRenderIssue } from '@/renders/RenderIssue';
import { MemoizedRenderTeam } from '@/renders/RenderTeam';
import { useDeleteIssueMutation } from '@/types/tanstack-query/generated';

import IssueDeleteContext from './IssueDeleteContext';
import useIssueContentHistory from './useIssueContentHistoryProvider';

const IssueContentHistoryProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const { showSuccessToast } = useSuccessHandler();
  const { showErrorToast } = useErrorHandler();
  const { isOpen, issueId, event } = useIssueContentHistory();

  const deleteIssue = useDeleteIssueMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {
      event.setData({ isOpen: false });
      showSuccessToast({
        title: 'Issue has been deleted',
      });
    },
    onSettled: () => {},
  });

  return (
    <IssueDeleteContext.Provider
      value={{
        isOpen: isOpen,
        issueId: issueId,
        showModal: (value) => event.setData(value),
      }}
    >
      {children}

      <AlertDialog
        open={isOpen}
        onOpenChange={(value) => event.setData({ isOpen: value })}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <MemoizedRenderIssue
              id={issueId}
              renderItem={(issue) =>
                issue?.team_id && (
                  <MemoizedRenderTeam
                    id={issue?.team_id}
                    renderItem={(team) => (
                      <AlertDialogTitle>
                        Are you sure you want to delete "{team?.identifier}-
                        {issue.code} ‒ {issue?.title}" ?
                      </AlertDialogTitle>
                    )}
                  />
                )
              }
            />

            <AlertDialogDescription>
              Deleted issues are available in the "Recently deleted" view for 30
              days, before they are permanently deleted.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <Button
              disabled={deleteIssue.isPending}
              variant={'secondary'}
              onClick={() => event.setData({ isOpen: false })}
            >
              Cancel
            </Button>
            <Button
              disabled={deleteIssue.isPending}
              variant={'destructive'}
              onClick={() =>
                issueId &&
                deleteIssue.mutate({
                  id: issueId,
                })
              }
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </IssueDeleteContext.Provider>
  );
};
export default IssueContentHistoryProvider;
