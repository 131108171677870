import React, { ReactNode } from 'react';

import { Button } from '@/components/Button';
import { Label } from '@/components/Label';
import { Separator } from '@/components/Separator';
import { PlusIcon } from '@/icons/core';
import { MemoizedRenderStatus } from '@/renders/RenderStatus';
import {
  WorkflowFragment,
  IssueFragment,
} from '@/types/tanstack-query/generated';

import { MemoizedRenderStatusIcon } from './Properties/StatusButton';

const ItemGroupHeader = (props: {
  icon: ReactNode;
  title: ReactNode;
  onShowCreate?: () => void;
}) => {
  return (
    <>
      <div className="flex h-[40px] flex-row items-center gap-4 bg-secondary bg-opacity-20">
        <div className="flex grow flex-row items-center gap-2">
          <div className="flex w-[30px] flex-none items-center justify-center">
            {props.icon}
          </div>
          <Label className="text-base font-semibold">{props.title}</Label>
        </div>
        <div className="flex w-[30px] items-center justify-center">
          <Button
            type="button"
            size={'icon'}
            variant={'link'}
            className="h-[20px] w-[20px] p-0"
            onClick={() => props.onShowCreate?.()}
          >
            <PlusIcon width={14} height={14} />
          </Button>
        </div>
      </div>
      <Separator className="w-full" orientation="horizontal" />
    </>
  );
};

const StatusFilterHeader = React.memo(
  (props: { workflow: WorkflowFragment; onShowCreate?: () => void }) => {
    return (
      <>
        <ItemGroupHeader
          icon={
            <>
              <MemoizedRenderStatusIcon
                type={props.workflow?.type}
                color={props.workflow?.color}
                processing={props.workflow?.processing}
              />
            </>
          }
          title={
            <>
              <Label>{props.workflow.name}</Label>
            </>
          }
          onShowCreate={props.onShowCreate}
        />
      </>
    );
  },
);

const IssueFilterHeader = React.memo(
  (props: { issue?: IssueFragment; onShowCreate?: () => void }) => {
    return (
      <>
        <ItemGroupHeader
          onShowCreate={props.onShowCreate}
          icon={
            <>
              <MemoizedRenderStatus
                id={props.issue?.workflow_id ?? ''}
                renderItem={(workflow) => (
                  <MemoizedRenderStatusIcon
                    type={workflow?.type}
                    color={workflow?.color}
                    processing={workflow?.processing}
                  />
                )}
              />
            </>
          }
          title={
            <>
              <Label>{props.issue ? props.issue.title : 'No parent'}</Label>
            </>
          }
        />
      </>
    );
  },
);

export { StatusFilterHeader, IssueFilterHeader };
