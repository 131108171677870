import { cn } from '@udecode/cn';
import { ArrowRightIcon, CalendarDaysIcon, CalendarX2Icon } from 'lucide-react';

import { Button } from '@/components/Button';
import { Label } from '@/components/Label';
import { UserAvatar } from '@/components/UserAvatar';
import { LabelIcon, UserAssignIcon } from '@/icons/core/issue';
import MemoizedAssignToButton from '@/pages/issue/components/Properties/AssignToButton';
import MemoizedDueDateButton from '@/pages/issue/components/Properties/DueDateButton';
import {
  MemoizedLabelButton,
  MemoizedRenderLabelsColor,
} from '@/pages/issue/components/Properties/LabelButton';
import MemoizedMembersToButton from '@/pages/issue/components/Properties/MembersToButton';
import {
  MemoizedPriorityButton,
  MemoizedRenderPriorityIcon,
} from '@/pages/issue/components/Properties/PriorityButton';
import { getNameByPriority } from '@/services/issue/issue-utils';
import {
  ProjectFragment,
  PriorityType,
} from '@/types/tanstack-query/generated';
import CustomDateTime from '@/utils/format-datetime';

import {
  MemoizedProjectStatusButton,
  MemoizedRenderProjectStatusIcon,
} from './ProjectStatusButton';

const ProjectPropertiesComponent = (props: {
  data?: ProjectFragment | undefined;
  teamIds: string[];
  disabled: boolean;
  selectedProjectStatusId: string | undefined;
  onSelectedProjectStatusId: (value: string | undefined) => void;
  selectedPriority: PriorityType | undefined;
  onSelectedPriority: (value: PriorityType | undefined) => void;
  selectedLabelIds: string[];
  onSelectedLabelIds: (value: string[]) => void;
  selectedLeadId: string | undefined;
  onSelectedLeadId: (value: string | undefined) => void;
  selectedMemberIds: string[];
  onSelectedMemberIds: (value: string[]) => void;
  onCopyLink: () => void;
  onCopyId: () => void;
  selectedStartAt: Date | undefined;
  selectedEndAt: Date | undefined;
  onSelectedStartAt: (value: Date | undefined) => void;
  onSelectedEndAt: (value: Date | undefined) => void;
}) => {
  return (
    <div className="flex w-full flex-wrap gap-2">
      <div>
        <MemoizedProjectStatusButton
          project_id={props.data?.id}
          selected={props.selectedProjectStatusId}
          onSelected={props.onSelectedProjectStatusId}
          renderItem={(value) => (
            <Button
              disabled={props.disabled}
              className="flex flex-row items-center justify-start gap-2 text-left"
              variant="outline"
              type="button"
            >
              <span className="flex w-[20px] flex-none">
                <MemoizedRenderProjectStatusIcon
                  type={value?.type}
                  color={value?.color}
                  processing={value?.processing}
                />
              </span>
              <Label>{value?.name}</Label>
            </Button>
          )}
        />
      </div>
      <div>
        <MemoizedPriorityButton
          selected={props.selectedPriority}
          onSelected={props.onSelectedPriority}
          renderItem={(value) => (
            <Button
              disabled={props.disabled}
              className="flex flex-row items-center justify-start gap-2 text-left"
              variant="outline"
              type="button"
            >
              <span className="flex">
                <MemoizedRenderPriorityIcon type={value?.type} />
              </span>
              <Label className="hidden sm:block">
                {getNameByPriority(value?.type)}
              </Label>
            </Button>
          )}
        />
      </div>
      <div>
        <MemoizedAssignToButton
          placeholder={'Set project lead'}
          teamIds={props.teamIds}
          selected={props.selectedLeadId}
          onSelected={props.onSelectedLeadId}
          renderItem={(value) => (
            <Button
              disabled={props.disabled}
              className="flex flex-row items-center justify-start gap-2 text-left"
              variant="outline"
              type="button"
            >
              {!props.selectedLeadId && (
                <div className="flex flex-row items-center gap-2">
                  <span className="flex">
                    <UserAssignIcon
                      width={18}
                      height={18}
                      style={{ color: '#9e9fa8' }}
                    />
                  </span>
                  <Label className="hidden sm:block">Lead</Label>
                </div>
              )}
              {props.selectedLeadId && (
                <div className="flex flex-row items-center gap-2">
                  <span className="flex items-center">
                    <UserAvatar
                      size="sm"
                      url={value?.image}
                      fallback={value?.username}
                    />
                  </span>
                  <Label className="hidden sm:block">{value?.username}</Label>
                </div>
              )}
            </Button>
          )}
        />
      </div>

      <div>
        <MemoizedMembersToButton
          placeholder={'Set members'}
          teamIds={props.teamIds}
          selected={props.selectedMemberIds}
          onSelected={props.onSelectedMemberIds}
          renderItem={(members) => (
            <Button
              disabled={props.disabled}
              className="flex flex-row items-center justify-start gap-2 text-left"
              variant="outline"
              type="button"
            >
              {!props.selectedMemberIds.length && (
                <div className="flex flex-row items-center gap-2">
                  <span className="flex">
                    <UserAssignIcon
                      width={18}
                      height={18}
                      style={{ color: '#9e9fa8' }}
                    />
                  </span>
                  <Label className="hidden sm:block">Members</Label>
                </div>
              )}
              {props.selectedMemberIds.length > 0 && (
                <div className="flex flex-row items-center gap-2">
                  <span className="flex items-center">
                    {members.map((member, index) => (
                      <UserAvatar
                        style={{ marginLeft: index === 0 ? 0 : -10 }}
                        size="sm"
                        url={member?.image}
                        fallback={member?.username}
                      />
                    ))}
                  </span>
                  <Label className="hidden sm:block">
                    {props.selectedMemberIds.length > 1
                      ? `${props.selectedMemberIds.length} Members`
                      : `${props.selectedMemberIds.length} Member`}
                  </Label>
                </div>
              )}
            </Button>
          )}
        />
      </div>

      <div>
        <MemoizedLabelButton
          selected={props.selectedLabelIds}
          onSelected={props.onSelectedLabelIds}
          placeholder="Search label..."
          renderItem={(value) => (
            <Button
              disabled={props.disabled}
              className="flex flex-row items-center justify-start gap-2 text-left"
              variant="outline"
              type="button"
            >
              {value.length > 0 && (
                <div className="flex flex-row items-center gap-1">
                  <MemoizedRenderLabelsColor labels={value} />
                  <Label>{value.length} labels</Label>
                </div>
              )}
              {value.length === 0 && (
                <div className="flex flex-row items-center gap-1">
                  <span className="flex w-[20px] flex-none">
                    <LabelIcon width={22} height={22} />
                  </span>
                  <Label className="hidden sm:block">Add labels</Label>
                </div>
              )}
            </Button>
          )}
        />
      </div>
      <div>
        <div className="flex flex-row items-center gap-2">
          <MemoizedDueDateButton
            selected={
              props.selectedStartAt
                ? new Date(props.selectedStartAt)
                : undefined
            }
            onSelected={props.onSelectedStartAt}
            renderItem={(value) => (
              <Button
                variant={'outline'}
                className={cn(
                  'justify-start text-left font-normal',
                  !value && 'text-muted-foreground',
                )}
              >
                <CalendarDaysIcon className="mr-2" width={18} height={18} />
                {value ? CustomDateTime.formatDate(value) : 'Pick a start date'}
              </Button>
            )}
          />
          <ArrowRightIcon width={20} height={20} />
          <MemoizedDueDateButton
            selected={
              props.selectedEndAt ? new Date(props.selectedEndAt) : undefined
            }
            onSelected={props.onSelectedEndAt}
            renderItem={(value) => (
              <Button
                variant={'outline'}
                className={cn(
                  'justify-start text-left font-normal',
                  !value && 'text-muted-foreground',
                )}
              >
                <CalendarX2Icon
                  className="mr-2"
                  width={18}
                  height={18}
                  color={'red'}
                />
                {value
                  ? CustomDateTime.formatDate(value)
                  : 'Pick a target date'}
              </Button>
            )}
          />
        </div>
      </div>
    </div>
  );
};
export default ProjectPropertiesComponent;
