/* eslint-disable import/no-extraneous-dependencies */

import React, { useEffect, useState } from 'react';

import { cn } from '@udecode/cn';
import {
  TreeItemComponentProps,
  SimpleTreeItemWrapper,
  SortableTree,
  TreeItems,
} from 'dnd-kit-sortable-tree';

import { Label } from '@/components/Label';
import { IDocumentFragment } from '@/types/tanstack-query/generated';

type TSortableTree = {
  id: string;
  title: string;
  document_id?: string | null;
};

const TreeItem = React.forwardRef<
  HTMLDivElement,
  TreeItemComponentProps<TSortableTree> & {
    selectedDocumentId: string | undefined;
    onItemClick: (item: TSortableTree) => void;
  }
>(({ onItemClick, selectedDocumentId, ...props }, ref) => {
  const handleItemClick = (item: TSortableTree) => {
    if (window.location.hash !== `#${item.id}`) {
      window.history.pushState({}, '', `#${item.id}`);
    }
    onItemClick(item);
  };
  return (
    <SimpleTreeItemWrapper
      {...props}
      ref={ref}
      className=""
      contentClassName="!border-none gap-1 !pt-[3px] !pb-[3px]"
      disableCollapseOnItemClick
    >
      <Label
        className={cn(
          'cursor-pointer rounded-md p-1 text-sm font-medium hover:bg-gray-200',
          selectedDocumentId === props.item.id && 'bg-gray-200',
        )}
        onClick={() => handleItemClick(props.item)}
      >
        {props.item.title}
      </Label>
    </SimpleTreeItemWrapper>
  );
});

const DocumentTreeList = (props: {
  selectedDocumentId: string | undefined;
  documents: IDocumentFragment[];
  onClick: (item: TSortableTree) => void;
}) => {
  const [items, setItems] = useState<TreeItems<TSortableTree>>([]);

  const convertToNestedData = (values: TSortableTree[]) => {
    const documentMap = new Map<
      string,
      TSortableTree & { children: TSortableTree[] }
    >();
    const rootDocuments: (TSortableTree & {
      children: TSortableTree[];
    })[] = [];

    values.forEach((doc) => {
      documentMap.set(doc.id, { ...doc, children: [] });
    });

    values.forEach((doc) => {
      const documentWithChildren = documentMap.get(doc.id)!;
      if (doc.document_id) {
        const parent = documentMap.get(doc.document_id);
        if (parent) {
          parent.children.push(documentWithChildren);
        }
      } else {
        rootDocuments.push(documentWithChildren);
      }
    });

    return rootDocuments;
  };

  useEffect(() => {
    const nestedDocuments = convertToNestedData(
      props.documents.map((doc) => ({
        id: doc.id,
        title: doc.title,
        document_id: doc.document_id,
      })),
    );
    setItems(nestedDocuments);
  }, [props.documents]);

  return (
    <div className="h-full w-full">
      <SortableTree
        items={items}
        indicator
        disableSorting
        onItemsChanged={(values) => {
          setItems(values);
        }}
        TreeItemComponent={(propsChild) => (
          <TreeItem
            {...propsChild}
            selectedDocumentId={props.selectedDocumentId}
            onItemClick={props.onClick}
          />
        )}
      />
    </div>
  );
};

export default DocumentTreeList;
