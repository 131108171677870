import { useContext, useState } from 'react';

import { PlusIcon } from 'lucide-react';

import { Button } from '@/components/Button';
import { Card } from '@/components/Card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/DropdownMenu';
import { Label } from '@/components/Label';
import { Separator } from '@/components/Separator';
import { TooltipRoot } from '@/components/Tooltip';
import {
  InfoIcon,
  Edit2Icon,
  MoreHorizontalIcon,
  MoreVerticalIcon,
} from '@/icons/index';
import { MemoizedRenderProjectStatusIcon } from '@/pages/project/components/ProjectStatusButton';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { BaseProjectStatuses } from '@/services/core/constant';
import {
  ProjectStatusFragment,
  ProjectStatusType,
} from '@/types/tanstack-query/generated';
import { convertHexToRGBA } from '@/utils/color';
import CustomDateTime from '@/utils/format-datetime';

import CreateProjectStatusForm from './components/create/CreateProjectStatusForm';
import DeleteProjectStatusDialog from './components/delete/DeleteProjectStatusDialog';
import UpdateProjectStatusForm from './components/update/UpdateProjectStatusForm';
import useProjectStatuses from './useProjectStatus';
import HeaderSetting from '../components/HeaderSetting';
import { SortableList } from '../components/sortable/SortableList';

const MoreButton = (props: {
  children: React.ReactNode;
  projectStatus: ProjectStatusFragment;
  onDelete: (projectStatus: ProjectStatusFragment) => void;
  onUpdate: (projectStatus: ProjectStatusFragment) => void;
}) => {
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger>{props.children}</DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem onClick={() => props.onUpdate(props.projectStatus)}>
            Edit
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => props.onDelete(props.projectStatus)}
            color="red"
          >
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};

const ActionButtons = (props: {
  projectStatus: ProjectStatusFragment;
  onDelete: (projectStatus: ProjectStatusFragment) => void;
  onUpdate: (projectStatus: ProjectStatusFragment) => void;
}) => {
  return (
    <div className="flex flex-row items-center">
      <TooltipRoot
        content={CustomDateTime.convertStringToDateTime(
          props.projectStatus.created_at ?? '',
          'PPPP',
        )}
      >
        <Button
          size={'sm'}
          type="button"
          variant="ghost"
          className="opacity-30 hover:opacity-100"
        >
          <InfoIcon size={18} />
        </Button>
      </TooltipRoot>
      <TooltipRoot content={'Edit'}>
        <Button
          className="opacity-30 hover:opacity-100"
          size={'sm'}
          type="button"
          variant="ghost"
          onClick={() => props.onUpdate(props.projectStatus)}
        >
          <Edit2Icon size={18} />
        </Button>
      </TooltipRoot>
      <TooltipRoot content={'Actions'}>
        <MoreButton
          onDelete={props.onDelete}
          onUpdate={props.onUpdate}
          projectStatus={props.projectStatus}
        >
          <Button
            size={'sm'}
            type="button"
            variant="ghost"
            className="opacity-30 hover:opacity-100"
          >
            <MoreHorizontalIcon size={18} />
          </Button>
        </MoreButton>
      </TooltipRoot>
    </div>
  );
};

const HeaderProjectStatus = (props: {
  name: string;
  onAddMore: () => void;
}) => {
  return (
    <Card>
      <div className="flex w-full items-center justify-between">
        <Label className="font-semibold opacity-50">{props.name}</Label>
        <Button
          size={'sm'}
          type="button"
          variant="ghost"
          onClick={() => props.onAddMore()}
        >
          <PlusIcon />
        </Button>
      </div>
    </Card>
  );
};

const ProjectStatues = (props: {
  workspaceId: string;
  projectStatuses: ProjectStatusFragment[];
  onCreateSuccess: (data: ProjectStatusFragment) => void;
  onUpdateSuccess: (data: ProjectStatusFragment) => void;
  onDelete: (data: ProjectStatusFragment) => void;
  onSortable: (data: ProjectStatusFragment[]) => void;
}) => {
  const [selectedType, setSelectedType] = useState<ProjectStatusType>();
  const [selectedUpdate, setSelectedUpdate] = useState<ProjectStatusFragment>();
  return (
    <Card>
      <div className="flex w-full flex-col gap-2">
        {BaseProjectStatuses.map((parent) => (
          <div key={parent.name} className="flex w-full flex-col gap-2">
            <HeaderProjectStatus
              name={parent.name}
              onAddMore={() => setSelectedType(parent.type)}
            />
            <div className="flex flex-col gap-2 px-[10px]">
              <SortableList
                items={props.projectStatuses.filter(
                  (x) => x.type === parent.type,
                )}
                onChange={(sort) => props.onSortable(sort)}
                renderItem={(item) => (
                  <SortableList.Item id={item.id} key={item.id}>
                    <div className="flex flex-row gap-2">
                      <div className="relative flex w-full grow cursor-grab items-center">
                        {selectedUpdate?.id !== item.id ? (
                          <div className="flex w-full flex-row items-center gap-4">
                            <div className="flex w-full flex-row gap-4">
                              <div className="absolute -left-[16px] top-0 flex h-full items-center opacity-50">
                                <SortableList.DragHandle>
                                  <MoreVerticalIcon size={16} />
                                </SortableList.DragHandle>
                              </div>

                              <div className="flex w-full grow flex-row items-center gap-4">
                                <Button
                                  size={'sm'}
                                  type="button"
                                  className={`bg-opacity-50! h-[36px] w-[36px] flex-none p-0`}
                                  style={{
                                    backgroundColor: convertHexToRGBA(
                                      item.color ?? '',
                                      0.3,
                                    ),
                                  }}
                                >
                                  <MemoizedRenderProjectStatusIcon
                                    type={item.type}
                                    color={item.color}
                                    processing={item.processing}
                                  />
                                </Button>
                                <SortableList.DragHandle>
                                  <div className="flex w-full grow flex-col">
                                    <Label className="font-semibold">
                                      {item.name}
                                    </Label>
                                    {item.description && (
                                      <Label className="opacity-50">
                                        {item.description}
                                      </Label>
                                    )}
                                  </div>
                                </SortableList.DragHandle>
                              </div>
                            </div>
                            <ActionButtons
                              projectStatus={item}
                              onDelete={props.onDelete}
                              onUpdate={setSelectedUpdate}
                            />
                          </div>
                        ) : (
                          <UpdateProjectStatusForm
                            projectStatus={item}
                            onSuccess={(data) => {
                              setSelectedUpdate(undefined);
                              props.onUpdateSuccess(data);
                            }}
                            onCancel={() => {
                              setSelectedUpdate(undefined);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </SortableList.Item>
                )}
              />

              {selectedType === parent.type && (
                <>
                  <Separator className="w-full opacity-50" />
                  <CreateProjectStatusForm
                    workspaceId={props.workspaceId}
                    type={parent.type}
                    onSuccess={(data) => {
                      setSelectedType(undefined);
                      props.onCreateSuccess(data);
                    }}
                    onCancel={() => setSelectedType(undefined)}
                  />
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};

const SettingProjectStatusPage = () => {
  const { projectStatuses, selected } = useContext(WorkspaceContext);

  const { event } = useProjectStatuses();
  const [selectedDelete, setSelectedDelete] = useState<
    ProjectStatusFragment | undefined
  >();

  const onCreateSuccess = () => {};
  const onUpdateSuccess = () => {};
  const onDeleteSuccess = () => {
    setSelectedDelete(undefined);
  };

  const onSortable = (data: ProjectStatusFragment[]) => {
    event.sortItems({ projectStatuses: data });
  };

  return (
    <div>
      <HeaderSetting
        title={'Projects'}
        subtitle={'Manage project setting for your workspace'}
      >
        <div className="flex flex-col">
          <Label className="font-semibold">Project statuses</Label>
          <Label className="opacity-50">
            Customize project statuses for your workspace
          </Label>
        </div>
      </HeaderSetting>
      <div className="mt-[30px] w-full">
        <div className="mt-[10px] flex flex-col gap-2">
          <ProjectStatues
            workspaceId={selected?.id ?? ''}
            projectStatuses={projectStatuses}
            onCreateSuccess={onCreateSuccess}
            onUpdateSuccess={onUpdateSuccess}
            onDelete={(data) => setSelectedDelete(data)}
            onSortable={onSortable}
          />
        </div>
      </div>
      <DeleteProjectStatusDialog
        projectStatus={selectedDelete}
        onCancel={() => setSelectedDelete(undefined)}
        onDeleteSuccess={onDeleteSuccess}
      />
    </div>
  );
};

export default SettingProjectStatusPage;
