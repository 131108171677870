import React, { useContext } from 'react';

import { StarFilledIcon } from '@radix-ui/react-icons';
import {
  BlocksIcon,
  Clipboard,
  Copy,
  CopyIcon,
  HistoryIcon,
  Link,
  StarIcon,
  TrashIcon,
} from 'lucide-react';
import { useCopyToClipboard } from 'usehooks-ts';

import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubTrigger,
  ContextMenuTrigger,
} from '@/components/ContextMenu';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@/components/DropdownMenu';
import { Label } from '@/components/Label';
import { useErrorHandler, useSuccessHandler } from '@/hooks/useToasterHandler';
import ProjectContentHistoryContext from '@/providers/project/ProjectContentHistory/ProjectServicesContext';
import ProjectDeleteContext from '@/providers/project/ProjectDelete/ProjectDeleteContext';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { MemoizedRenderFavorite } from '@/renders/RenderFavorite';
import { DOMAIN_URL } from '@/services/core/constant';
import {
  FavoriteType,
  ProjectFragment,
  useToggleFavoriteMutation,
} from '@/types/tanstack-query/generated';

const MenuItemIcon = (props: { title: string; icon: React.ReactNode }) => {
  return (
    <div className="flex flex-row items-center gap-2">
      <div className="flex w-[20px] flex-none items-center">{props.icon}</div>
      <Label>{props.title}</Label>
    </div>
  );
};

export const ContextMenuProject = (props: {
  disabled?: boolean;
  project_id: string;
  workspace_id: string;
  className?: string;
  children: React.ReactNode;
  onToggleFavorite: () => void;
  onShowVersions: () => void;
  onShowDelete: () => void;
  onCopyId: () => void;
  onCopyTitle: () => void;
  onCopyLink: () => void;
  onCopyTitleAsLink: () => void;
}) => {
  return (
    <ContextMenu modal>
      <ContextMenuTrigger disabled={props.disabled} asChild>
        <div className={props.className}>{props.children}</div>
      </ContextMenuTrigger>
      <ContextMenuContent>
        <MemoizedRenderFavorite
          map_id={props.project_id}
          type={FavoriteType.Project}
          renderItem={(favorite) =>
            favorite ? (
              <ContextMenuItem onClick={props.onToggleFavorite}>
                <MenuItemIcon
                  icon={<StarFilledIcon />}
                  title="Remove from favorites"
                />
              </ContextMenuItem>
            ) : (
              <ContextMenuItem onClick={props.onToggleFavorite}>
                <MenuItemIcon icon={<StarIcon />} title="Favorite" />
              </ContextMenuItem>
            )
          }
        />

        <ContextMenuSub>
          <ContextMenuSubTrigger onClick={props.onCopyId}>
            <MenuItemIcon icon={<CopyIcon />} title="Copy" />
          </ContextMenuSubTrigger>
          <ContextMenuSubContent>
            <ContextMenuItem onClick={props.onCopyId}>
              <MenuItemIcon icon={<Copy />} title="Copy ID" />
            </ContextMenuItem>
            <ContextMenuItem onClick={props.onCopyTitle}>
              <MenuItemIcon icon={<BlocksIcon />} title="Copy title" />
            </ContextMenuItem>
            <ContextMenuItem onClick={props.onCopyLink}>
              <MenuItemIcon icon={<Link />} title="Copy link" />
            </ContextMenuItem>
            <ContextMenuItem onClick={props.onCopyTitleAsLink}>
              <MenuItemIcon icon={<Clipboard />} title="Copy title as link" />
            </ContextMenuItem>
          </ContextMenuSubContent>
        </ContextMenuSub>
        <ContextMenuSeparator />

        <ContextMenuItem onClick={props.onShowVersions}>
          <MenuItemIcon
            icon={<HistoryIcon />}
            title="Project content history"
          />
        </ContextMenuItem>

        <ContextMenuItem onClick={props.onShowDelete}>
          <MenuItemIcon icon={<TrashIcon />} title="Delete" />
        </ContextMenuItem>
      </ContextMenuContent>
    </ContextMenu>
  );
};

export const DropdownMenuProject = (props: {
  disabled?: boolean;
  project_id: string;
  workspace_id: string;
  className?: string;
  children: React.ReactNode;
  onToggleFavorite: () => void;
  onShowVersions: () => void;
  onShowDelete: () => void;
  onCopyId: () => void;
  onCopyTitle: () => void;
  onCopyLink: () => void;
  onCopyTitleAsLink: () => void;
}) => {
  return (
    <DropdownMenu modal>
      <DropdownMenuTrigger disabled={props.disabled} asChild>
        <div className={props.className}>{props.children}</div>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <MemoizedRenderFavorite
          map_id={props.project_id}
          type={FavoriteType.Project}
          renderItem={(favorite) =>
            favorite ? (
              <DropdownMenuItem onClick={props.onToggleFavorite}>
                <MenuItemIcon
                  icon={<StarFilledIcon />}
                  title="Remove from favorites"
                />
              </DropdownMenuItem>
            ) : (
              <DropdownMenuItem onClick={props.onToggleFavorite}>
                <MenuItemIcon icon={<StarIcon />} title="Favorite" />
              </DropdownMenuItem>
            )
          }
        />

        <DropdownMenuSub>
          <DropdownMenuSubTrigger>
            <MenuItemIcon icon={<CopyIcon />} title="Copy" />
          </DropdownMenuSubTrigger>
          <DropdownMenuSubContent>
            <DropdownMenuItem onClick={props.onCopyId}>
              <MenuItemIcon icon={<Copy />} title="Copy ID" />
            </DropdownMenuItem>
            <DropdownMenuItem onClick={props.onCopyTitle}>
              <MenuItemIcon icon={<BlocksIcon />} title="Copy title" />
            </DropdownMenuItem>
            <DropdownMenuItem onClick={props.onCopyLink}>
              <MenuItemIcon icon={<Link />} title="Copy link" />
            </DropdownMenuItem>
            <DropdownMenuItem onClick={props.onCopyTitleAsLink}>
              <MenuItemIcon icon={<Clipboard />} title="Copy title as link" />
            </DropdownMenuItem>
          </DropdownMenuSubContent>
        </DropdownMenuSub>
        <DropdownMenuSeparator />

        <DropdownMenuItem onClick={props.onShowVersions}>
          <MenuItemIcon
            icon={<HistoryIcon />}
            title="Project content history"
          />
        </DropdownMenuItem>

        <DropdownMenuItem onClick={props.onShowDelete}>
          <MenuItemIcon icon={<TrashIcon />} title="Delete" />
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const ToolsMenuProject = (props: {
  children: React.ReactNode;
  project: ProjectFragment;
  workspace_id: string;
  contextMenu: boolean;
  className?: string;
  disabled?: boolean;
}) => {
  const [copied, copy] = useCopyToClipboard();
  const { selected } = useContext(WorkspaceContext);
  const { showSuccessToast } = useSuccessHandler();
  const { showErrorToast } = useErrorHandler();

  const { showModal: showVersionModal } = useContext(
    ProjectContentHistoryContext,
  );

  const toggleFavorite = useToggleFavoriteMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {},
    onSettled: () => {},
  });

  const { showModal: showDeleteModal } = useContext(ProjectDeleteContext);

  const onToggleFavorite = () => {
    toggleFavorite.mutate({
      toggleFavorite: {
        map_id: props.project.id,
        type: FavoriteType.Project,
        workspace_id: props.workspace_id,
      },
    });
  };

  const onShowVersions = () => {
    showVersionModal({ isOpen: true, projectId: props.project.id ?? '' });
  };

  const onShowDelete = () => {
    showDeleteModal({ isOpen: true, projectId: props.project.id ?? '' });
  };

  const onCopyId = () => {
    const data = `${props.project.id}`;
    copy(data);

    showSuccessToast({
      title: `"${data}" Copied`,
    });
  };

  const onCopyTitle = () => {
    const data = `${props.project.title}`;
    copy(data);
    showSuccessToast({ title: `"${data}" Copied` });
  };

  const onCopyLink = () => {
    const data = `${DOMAIN_URL}/${selected?.url}/project/-${props.project.code}`;
    copy(data);
    showSuccessToast({
      title: `"${data}" Copied`,
    });
  };

  const onCopyTitleAsLink = () => {
    const link = `${DOMAIN_URL}/${selected?.url}/project/${props.project.code}`;

    const data = `${props.project.title} - ${link}`;
    copy(data);
    showSuccessToast({
      title: `"${data}" Copied`,
    });
  };

  return !props.contextMenu ? (
    <DropdownMenuProject
      disabled={props.disabled}
      onToggleFavorite={onToggleFavorite}
      onShowVersions={onShowVersions}
      onShowDelete={onShowDelete}
      project_id={props.project.id ?? ''}
      workspace_id={props.workspace_id}
      className={props.className}
      onCopyId={onCopyId}
      onCopyTitle={onCopyTitle}
      onCopyLink={onCopyLink}
      onCopyTitleAsLink={onCopyTitleAsLink}
    >
      {props.children}
    </DropdownMenuProject>
  ) : (
    <ContextMenuProject
      disabled={props.disabled}
      onToggleFavorite={onToggleFavorite}
      onShowVersions={onShowVersions}
      onShowDelete={onShowDelete}
      project_id={props.project.id ?? ''}
      workspace_id={props.workspace_id}
      className={props.className}
      onCopyId={onCopyId}
      onCopyTitle={onCopyTitle}
      onCopyLink={onCopyLink}
      onCopyTitleAsLink={onCopyTitleAsLink}
    >
      {props.children}
    </ContextMenuProject>
  );
};
