import { ChevronDown, ChevronUp } from 'lucide-react';

import { Button } from '@/components/Button';
import { Label } from '@/components/Label';
import { IssueFragment, TeamFragment } from '@/types/tanstack-query/generated';

import useIssueNavigation from './useIssueNavigation';

const IssueNavigationComponent = (props: {
  issue: IssueFragment;
  team: TeamFragment;
  onNavigation: (issue: IssueFragment) => void;
}) => {
  const { currentIndex, total, event } = useIssueNavigation({
    team: props.team,
    issue: props.issue,
    onNavigation: props.onNavigation,
  });
  return (
    <div className="flex flex-none flex-row items-center gap-4">
      <div className="flex gap-1">
        <Label>{currentIndex + 1}</Label>
        <Label className=" text-slate-500">/</Label>
        <Label className=" text-slate-500">{total}</Label>
      </div>
      <div className="flex gap-1">
        <Button
          type="button"
          variant="outline"
          size={'icon'}
          onClick={() => event.getIssueByIndex(currentIndex - 1)}
          disabled={currentIndex === 0}
        >
          <ChevronDown size={14} />
        </Button>
        <Button
          type="button"
          variant="outline"
          size={'icon'}
          onClick={() => event.getIssueByIndex(currentIndex + 1)}
          disabled={currentIndex + 1 === total}
        >
          <ChevronUp size={14} />
        </Button>
      </div>
    </div>
  );
};

export default IssueNavigationComponent;
