import { useSortable } from '@dnd-kit/sortable';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CSS } from '@dnd-kit/utilities';
import { cva } from 'class-variance-authority';
import { useNavigate } from 'react-router-dom';

import { CardContent, Card } from '@/components/Card';
import { Label } from '@/components/Label';
import { UserAvatar } from '@/components/UserAvatar';
import { UserAssignIcon } from '@/icons/core/issue';
import { MemoizedRenderIssue } from '@/renders/RenderIssue';
import { MemoizedRenderMember } from '@/renders/RenderMember';
import { MemoizedRenderStatus } from '@/renders/RenderStatus';

import { DragStatus, Task, TaskDragData } from './kanban.interface';
import { MemoizedIssuePropertiesByIssueComponent } from '../issue/components/IssuePropertiesComponent';
import { MemoizedRenderStatusIcon } from '../issue/components/Properties/StatusButton';

const TaskCard = ({ task, isOverlay }: { task: Task; isOverlay?: boolean }) => {
  const navigate = useNavigate();
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: task.id,
    data: {
      type: 'Task',
      task,
    } satisfies TaskDragData,
    attributes: {
      roleDescription: 'Task',
    },
  });

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };

  let draggingClass: DragStatus;
  if (isOverlay) {
    draggingClass = 'overlay';
  } else if (isDragging) {
    draggingClass = 'over';
  }

  const variants = cva('', {
    variants: {
      dragging: {
        over: 'ring-2 opacity-30',
        overlay: 'ring-2 ring-primary',
      },
    },
  });

  return (
    <MemoizedRenderIssue
      id={task.id}
      renderItem={(issue) => {
        return issue ? (
          <Card
            ref={setNodeRef}
            style={style}
            className={variants({
              dragging: draggingClass,
            })}
            {...attributes}
            {...listeners}
          >
            <CardContent
              className="whitespace-pre-wrap px-1 pb-6 pt-1 text-left"
              onClick={() => navigate(task.path)}
            >
              <div className="flex w-full">
                <Label className="grow opacity-50">{task.code}</Label>
                <MemoizedRenderMember
                  id={task.assignee_id}
                  renderItem={(member) =>
                    member ? (
                      <UserAvatar size={'sm'} fallback={member?.username} />
                    ) : (
                      <UserAssignIcon
                        width={22}
                        height={22}
                        style={{ color: '#9e9fa8' }}
                      />
                    )
                  }
                />
              </div>
              <div className="flex flex-row items-start justify-start gap-2 pt-2">
                <span className="pt-0.3 w-[20px] flex-none">
                  <MemoizedRenderStatus
                    id={task.workflow_id}
                    renderItem={(workflow) =>
                      workflow && (
                        <MemoizedRenderStatusIcon
                          type={workflow?.type}
                          color={workflow?.color}
                          processing={workflow?.processing}
                        />
                      )
                    }
                  />
                </span>
                <Label>{task.content}</Label>
              </div>

              <div className="flex w-full flex-wrap gap-1 pt-2">
                <MemoizedIssuePropertiesByIssueComponent issue={issue} />
              </div>
            </CardContent>
          </Card>
        ) : (
          <></>
        );
      }}
    />
  );
};
export default TaskCard;
