import { useContext, useEffect, useState } from 'react';

import orderBy from 'lodash/orderBy';

import {
  PushEventType,
  NotificationFragment,
} from '@/types/tanstack-query/generated';

import SubscriptionContext from '../subscriptions/SubscriptionContext';

const useWorkspaceNotification = () => {
  const { pubsub } = useContext(SubscriptionContext);

  const [notifications, setNotifications] = useState<NotificationFragment[]>(
    [],
  );

  const storeNotifications = async (value: NotificationFragment[]) => {
    setNotifications(orderBy(value, ['updated_at'], ['desc']));
  };

  const pushItem = async (props: { notification: NotificationFragment }) => {
    const clone = Object.assign([], notifications);
    clone.push(props.notification);
    setNotifications(clone);
  };

  const updateItem = async (props: { notification: NotificationFragment }) => {
    const clone: NotificationFragment[] = Object.assign([], notifications);
    setNotifications(
      clone.map((item) => {
        if (item.id === props.notification.id) return { ...props.notification };
        return { ...item };
      }),
    );
  };

  useEffect(() => {
    if (pubsub && pubsub.data) {
      switch (pubsub.event) {
        case PushEventType.NotificationNewAssignee:
        case PushEventType.NotificationNewComment:
        case PushEventType.NotificationUpdateWorkflow:
          {
            const pubsubData = pubsub.data as NotificationFragment;
            const find = notifications.find(
              (item) => item.id === pubsubData.id,
            );
            if (find) {
              updateItem({ notification: pubsubData });
            } else {
              pushItem({ notification: pubsubData });
            }
          }
          break;

        default:
          break;
      }
    }
  }, [pubsub]);

  return {
    notifications,
    event: {
      storeNotifications,
    },
  };
};

export default useWorkspaceNotification;
