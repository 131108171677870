import { useContext, useEffect, useState } from 'react';

import orderBy from 'lodash/orderBy';

import {
  ProjectFragment,
  PushEventType,
} from '@/types/tanstack-query/generated';

import SubscriptionContext from '../subscriptions/SubscriptionContext';

const useWorkspaceProject = () => {
  const { pubsub } = useContext(SubscriptionContext);
  const [projects, setProjects] = useState<ProjectFragment[]>([]);

  const storeProjects = async (value: ProjectFragment[]) => {
    setProjects(orderBy(value, ['created_at'], ['desc']));
  };

  const getProjectsByTeam = (props: { team_id: string }): ProjectFragment[] => {
    const result = projects.filter(
      (item) =>
        item.team_ids?.split(',').includes(props.team_id) &&
        item.is_deleted === false,
    );
    return result;
  };

  const pushItem = async (props: { project: ProjectFragment }) => {
    const clone = Object.assign([], projects);
    clone.push(props.project);
    storeProjects(clone);
  };

  const updateItem = async (props: { project: ProjectFragment }) => {
    const clone = [...projects];
    storeProjects(
      clone.map((item) => {
        if (item.id === props.project.id) return { ...props.project };
        return { ...item };
      }),
    );
  };

  const popItem = async (props: { project_id: string }) => {
    const getProject = projects.find((item) => item.id === props.project_id);
    const clone = { ...getProject, is_deleted: true } as ProjectFragment;
    updateItem({ project: clone });
  };

  useEffect(() => {
    if (pubsub && pubsub.id && pubsub.event) {
      switch (pubsub.event) {
        case PushEventType.ProjectAdded:
          pushItem({ project: pubsub.data as ProjectFragment });
          break;
        case PushEventType.ProjectUpdated:
          updateItem({ project: pubsub.data as ProjectFragment });
          break;
        case PushEventType.ProjectDeleted:
          popItem({ project_id: pubsub.id });
          break;
        default:
          break;
      }
    }
  }, [pubsub]);

  return {
    projects,
    event: {
      storeProjects,
      getProjectsByTeam,
    },
  };
};

export default useWorkspaceProject;
