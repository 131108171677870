import { useContext, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from '@/components/Button';
import { Checkbox } from '@/components/Checkbox';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/Dialog';
import { Form, FormField, FormItem, FormLabel } from '@/components/Form';
import { Input } from '@/components/Input';
import { Label } from '@/components/Label';
import { LabelError } from '@/components/LabelError';
import { Separator } from '@/components/Separator';
import { useErrorHandler, useSuccessHandler } from '@/hooks/useToasterHandler';
import AuthenticatedUserContext from '@/providers/auth/AuthenticatedUserContext';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { OtpType, RoleType } from '@/types/tanstack-query/generated';
import {
  useConfirmDeleteWorkspaceMutation,
  useSendOtpDeleteWorkspaceMutation,
  WorkspaceFragment,
} from '@/types/tanstack-query/generated';
import { Delay } from '@/utils/delay';

type IDeleteWorkspace = {
  code: string;
  terms: boolean;
};

const schemaDeleteWorkspace = yup.object().shape({
  code: yup.string().required(),
  terms: yup.bool().oneOf([true], 'Field must be checked').required(),
});

const DeleteWorkspaceComponent = (props: { workspace: WorkspaceFragment }) => {
  const { user, logout } = useContext(AuthenticatedUserContext);
  const { role } = useContext(WorkspaceContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { showSuccessToast } = useSuccessHandler();
  const { showErrorToast } = useErrorHandler();

  const getVerificationCode = useSendOtpDeleteWorkspaceMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: (res) => {
      if (res.sendOtpDeleteWorkspace) {
        showSuccessToast({
          title: 'Deletion Verification code sent',
          description: (
            <div className="flex flex-col gap-2">
              <div className="flex flex-row gap-2">
                <Label>
                  An email containing a verification code sent to {user?.email}
                </Label>
              </div>
            </div>
          ),
        });
      }
    },
    onSettled: () => {},
  });

  const confirmDelete = useConfirmDeleteWorkspaceMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: async (res) => {
      if (res.confirmDeleteWorkspace) {
        showSuccessToast({
          title: 'Workspace deletion scheduled',
          description:
            'We have scheduled your workspace for deletion in 48 hours. You will received an email confirmation when it has completed. You will now be logged out.',
        });
        setIsOpen(false);
        await Delay(4000);
        logout();
      }
    },
    onSettled: () => {},
  });

  const form = useForm<IDeleteWorkspace>({
    resolver: yupResolver<IDeleteWorkspace>(schemaDeleteWorkspace),
    defaultValues: {
      code: '',
      terms: false,
    },
  });

  const onSubmitCreate = (formValues: IDeleteWorkspace) => {
    confirmDelete.mutate({
      deleteInput: {
        code: formValues.code,
        type: OtpType.DeleteWorkspace,
        workspace_id: props.workspace.id,
      },
    });
  };

  const onSubmitSendVerificationCode = () => {
    getVerificationCode.mutate({
      workspace_id: props.workspace.id,
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <Label className="text-md font-bold">Delete workspace</Label>
      <Label className="opacity-50">
        If you want to permanently delete this workspace and all of its data,
        including but not limited to users, issues, and comments, you can do so
        below.
      </Label>
      <div>
        <Dialog open={isOpen} onOpenChange={(value) => setIsOpen(value)}>
          <DialogTrigger asChild>
            <Button
              type="button"
              disabled={role !== RoleType.Admin}
              variant="destructive"
              onClick={() => onSubmitSendVerificationCode()}
            >
              Delete this workspace
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle className="mb-4">
                Verify workspace deletion request
              </DialogTitle>
            </DialogHeader>
            <DialogDescription>
              <Form {...form}>
                <form
                  id="createIssueForm"
                  className="flex grow flex-col"
                  onSubmit={form.handleSubmit(onSubmitCreate)}
                >
                  <div className="flex flex-col gap-4">
                    <Label>
                      <span className="opacity-70">
                        If you are sure you want to proceed with the deletion of
                        the workspace
                      </span>{' '}
                      <span className="font-semibold">
                        {props.workspace.name}
                      </span>
                      <span className="opacity-70">
                        , please continue below.
                      </span>
                    </Label>
                    <Label className="opacity-70">
                      Keep in mind this operation is irreversible and will
                      result in a complete deletion of all the data associated
                      with the workspace.
                    </Label>
                    <Label className="opacity-70">
                      Data including but not limited to users, issues and
                      comments will be permanently deleted.
                    </Label>
                    <FormField
                      control={form.control}
                      name="code"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            Enter deletion code sent to{' '}
                            <span className="font-semibold">{user?.email}</span>
                            :
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder="Enter deletion code"
                            autoFocus
                            className={classNames(
                              'w-full shadow-none outline-none focus-visible:outline-none',
                            )}
                          />
                          <LabelError>
                            {form.formState.errors.code?.message}
                          </LabelError>
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="terms"
                      render={({ field }) => (
                        <FormItem>
                          <div className="flex items-start space-x-2">
                            <div className="pt-[3px]">
                              <Checkbox
                                id="terms"
                                checked={field.value}
                                onCheckedChange={field.onChange}
                              />
                            </div>
                            <Label htmlFor="terms">
                              I acknowledge that all of the workspace data will
                              be deleted and want to proceed.
                            </Label>
                          </div>
                          <LabelError>
                            {form.formState.errors.terms?.message}
                          </LabelError>
                        </FormItem>
                      )}
                    />

                    <Separator />
                    <div>
                      <Button
                        type="submit"
                        disabled={
                          confirmDelete.isPending || !form.formState.isValid
                        }
                        className="h-[40px] w-full"
                        variant={'destructive'}
                      >
                        Delete my workspace
                      </Button>
                    </div>
                  </div>
                </form>
              </Form>
            </DialogDescription>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default DeleteWorkspaceComponent;
