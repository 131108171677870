import { useContext, useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { TeamFragment } from '@/types/tanstack-query/generated';

import InviteMemberTeam from './components/invite-member/InviteMemberTeam';
import HeaderSetting from '../../components/HeaderSetting';

const SettingTeamMembersPage = () => {
  const { teams, selected } = useContext(WorkspaceContext);
  const params = useParams();
  const [selectedTeam, setSelectedTeam] = useState<TeamFragment | undefined>();

  useEffect(() => {
    const getTeam = teams.find((item) => item.identifier === params.team);
    setSelectedTeam(getTeam);
  }, [params]);
  return (
    <div>
      <HeaderSetting
        title={'Members'}
        subtitle={`Manage who is a member of the ${selectedTeam?.name} team`}
      ></HeaderSetting>

      <div>
        {selectedTeam?.identifier === params.team && selected && (
          <InviteMemberTeam team={selectedTeam} workspace={selected} />
        )}
      </div>
    </div>
  );
};

export default SettingTeamMembersPage;
