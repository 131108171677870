import * as Types from '../../types';

import { gql } from '@apollo/client';
export type SubscriptionResponseFragment = { __typename?: 'SubscriptionResponse', id?: string | null, event: string, workspace_id?: string | null, created_at: string, data?: object | null };

export const SubscriptionResponseFragmentDoc = gql`
    fragment SubscriptionResponse on SubscriptionResponse {
  id
  event
  workspace_id
  created_at
  data
}
    `;