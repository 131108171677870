/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable consistent-return */
import { useState, useRef, useMemo, useEffect } from 'react';

import {
  useSensors,
  useSensor,
  MouseSensor,
  TouchSensor,
  KeyboardSensor,
  UniqueIdentifier,
  Announcements,
  DndContext,
  DragOverlay,
  DragStartEvent,
  DragEndEvent,
  DragOverEvent,
  PointerSensor,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { createPortal } from 'react-dom';

import { useErrorHandler } from '@/hooks/useToasterHandler';
import {
  IssueFragment,
  TeamFragment,
  useUpdateIssueMutation,
  WorkflowFragment,
  WorkspaceFragment,
} from '@/types/tanstack-query/generated';

import { BoardContainer, BoardColumn } from './BoardColumn';
import { Column, Task } from './kanban.interface';
import { coordinateGetter } from './multipleContainersKeyboardPreset';
import TaskCard from './TaskCard';

export function KanbanBoard(props: {
  workflows: WorkflowFragment[];
  issues: IssueFragment[];
  team: TeamFragment;
  workspace: WorkspaceFragment;
}) {
  const { showErrorToast } = useErrorHandler();
  const getColumns = useMemo(
    () =>
      props.workflows.map((item) => {
        return {
          id: item.id,
          title: item.name,
        };
      }),
    [props.workflows],
  );

  const [columns, setColumns] = useState<Column[]>(getColumns);
  const pickedUpTaskColumn = useRef<string | null>(null);
  const columnsId = useMemo(() => columns.map((col) => col.id), [columns]);

  const [tasks, setTasks] = useState<Task[]>([]);

  const [activeColumn, setActiveColumn] = useState<Column | null>(null);
  const [activeTask, setActiveTask] = useState<Task | null>(null);

  const updateIssue = useUpdateIssueMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {},
    onSettled: () => {},
  });

  const onUpdateIssueWorkflow = (props: {
    id: string;
    workflow_id: string;
  }) => {
    updateIssue.mutate({
      updateInput: { id: props.id, workflow_id: props.workflow_id },
    });
  };

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: coordinateGetter,
    }),
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 0.01,
      },
    }),
  );

  const getDraggingTaskData = (taskId: UniqueIdentifier, columnId: string) => {
    const tasksInColumn = tasks.filter((task) => task.columnId === columnId);
    const taskPosition = tasksInColumn.findIndex((task) => task.id === taskId);
    const column = columns.find((col) => col.id === columnId);
    return { tasksInColumn, taskPosition, column };
  };

  const hasDraggableData = (
    item: any,
  ): item is {
    data: { current: { type: string; task?: Task; column?: Column } };
  } => {
    return item && item.data && item.data.current;
  };

  function onDragStart(event: DragStartEvent) {
    if (!hasDraggableData(event.active)) return;
    const data = event.active.data.current;
    if (data.type === 'Column') {
      setActiveColumn(data.column!);
      return;
    }

    if (data.type === 'Task') {
      setActiveTask(data.task!);
    }
  }

  function onDragEnd(event: DragEndEvent) {
    setActiveColumn(null);
    setActiveTask(null);

    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (!hasDraggableData(active)) return;

    const activeData = active.data.current;

    if (activeId === overId) return;

    if (activeData.type === 'Column') {
      setColumns((columns) => {
        const activeColumnIndex = columns.findIndex(
          (col) => col.id === activeId,
        );
        const overColumnIndex = columns.findIndex((col) => col.id === overId);
        return arrayMove(columns, activeColumnIndex, overColumnIndex);
      });
    }

    if (activeData.type === 'Task') {
      setTasks((tasks) => {
        const activeIndex = tasks.findIndex((t) => t.id === activeId);
        const overIndex = tasks.findIndex((t) => t.id === overId);
        const activeTask = tasks[activeIndex];
        const overTask = tasks[overIndex];
        if (
          activeTask &&
          overTask &&
          activeTask.columnId !== overTask.columnId
        ) {
          activeTask.columnId = overTask.columnId;
          return arrayMove(tasks, activeIndex, overIndex - 1);
        }
        return arrayMove(tasks, activeIndex, overIndex);
      });
    }
  }

  function onDragOver(event: DragOverEvent) {
    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) return;

    if (!hasDraggableData(active) || !hasDraggableData(over)) return;

    const activeData = active.data.current;
    const overData = over.data.current;

    if (activeData.type === 'Task') {
      if (overData.type === 'Task') {
        setTasks((tasks) => {
          const activeIndex = tasks.findIndex((t) => t.id === activeId);
          const overIndex = tasks.findIndex((t) => t.id === overId);
          const activeTask = tasks[activeIndex];
          const overTask = tasks[overIndex];
          if (
            activeTask &&
            overTask &&
            activeTask.columnId !== overTask.columnId
          ) {
            activeTask.columnId = overTask.columnId;
            return arrayMove(tasks, activeIndex, overIndex - 1);
          }
          return arrayMove(tasks, activeIndex, overIndex);
        });
      }

      if (overData.type === 'Column') {
        setTasks((tasks) => {
          const activeIndex = tasks.findIndex((t) => t.id === activeId);
          const activeTask = tasks[activeIndex];
          if (activeTask) {
            activeTask.columnId = overId as string;
            return arrayMove(tasks, activeIndex, activeIndex);
          }
          return tasks;
        });
      }
    }
  }

  const announcements: Announcements = {
    onDragStart({ active }) {
      if (!hasDraggableData(active)) return;
      if (active.data.current.type === 'Column') {
        const startColumnIdx = columnsId.findIndex((id) => id === active.id);
        const startColumn = columns[startColumnIdx];
        return `Picked up Column ${startColumn?.title} at position: ${
          startColumnIdx + 1
        } of ${columnsId.length}`;
      }
      if (active.data.current.type === 'Task') {
        pickedUpTaskColumn.current = active.data.current.task!.columnId;
        const { tasksInColumn, taskPosition, column } = getDraggingTaskData(
          active.id,
          pickedUpTaskColumn.current,
        );
        return `Picked up Task ${
          active.data.current.task!.content
        } at position: ${taskPosition + 1} of ${
          tasksInColumn.length
        } in column ${column?.title}`;
      }
    },
    onDragOver({ active, over }) {
      if (!hasDraggableData(active) || !hasDraggableData(over)) return;

      if (
        active.data.current.type === 'Column' &&
        over.data.current.type === 'Column'
      ) {
        const overColumnIdx = columnsId.findIndex((id) => id === over.id);
        return `Column ${active.data.current.column?.title} was moved over ${
          over.data.current.column?.title
        } at position ${overColumnIdx + 1} of ${columnsId.length}`;
      }
      if (
        active.data.current.type === 'Task' &&
        over.data.current.type === 'Task'
      ) {
        const { tasksInColumn, taskPosition, column } = getDraggingTaskData(
          over.id,
          over.data.current.task!.columnId,
        );
        if (over.data.current.task!.columnId !== pickedUpTaskColumn.current) {
          return `Task ${
            active.data.current.task!.content
          } was moved over column ${column?.title} in position ${
            taskPosition + 1
          } of ${tasksInColumn.length}`;
        }
        return `Task was moved over position ${taskPosition + 1} of ${
          tasksInColumn.length
        } in column ${column?.title}`;
      }
    },
    onDragEnd({ active, over }) {
      if (!hasDraggableData(active) || !hasDraggableData(over)) {
        pickedUpTaskColumn.current = null;
        return;
      }
      if (
        active.data.current.type === 'Column' &&
        over.data.current.type === 'Column'
      ) {
        const overColumnPosition = columnsId.findIndex((id) => id === over.id);
        return `Column ${
          active.data.current.column!.title
        } was dropped into position ${overColumnPosition + 1} of ${
          columnsId.length
        }`;
      }
      if (
        active.data.current.type === 'Task' &&
        over.data.current.type === 'Task'
      ) {
        const { tasksInColumn, taskPosition, column } = getDraggingTaskData(
          over.id,
          over.data.current.task!.columnId,
        );
        if (over.data.current.task!.columnId !== pickedUpTaskColumn.current) {
          if (column?.id && over.data.current.task?.id) {
            onUpdateIssueWorkflow({
              workflow_id: column?.id,
              id: over.data.current.task?.id.toString(),
            });
          }
          return `Task was dropped into column ${column?.title} in position ${
            taskPosition + 1
          } of ${tasksInColumn.length}`;
        }
        return `Task was dropped into position ${taskPosition + 1} of ${
          tasksInColumn.length
        } in column ${column?.title}`;
      }
      pickedUpTaskColumn.current = null;
    },
    onDragCancel({ active }) {
      pickedUpTaskColumn.current = null;
      if (!hasDraggableData(active)) return;
      return `Dragging ${active.data.current.type} cancelled.`;
    },
  };

  useEffect(() => {
    const convertTask = props.issues.map((item) => {
      return {
        id: item.id,
        columnId: item.workflow_id,
        content: item.title,
        assignee_id: item.assignee_id,
        code: `${props.team.identifier}-${item.code}`,
        path: `/${props.workspace.url}/issue/${props.team.identifier}-${item.code}`,
        priority: item.priority,
        labels: item.labels,
        project_id: item.project_id,
        workflow_id: item.workflow_id,
      } as Task;
    });
    setTasks(convertTask);
  }, [props.issues]);

  return (
    <DndContext
      accessibility={{ announcements }}
      sensors={sensors}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
    >
      <BoardContainer>
        {/* <SortableContext items={columnsId}> */}
        {columns.map((col) => (
          <BoardColumn
            key={col.id}
            column={col}
            tasks={tasks.filter((task) => task.columnId === col.id)}
          />
        ))}
        {/* </SortableContext> */}
      </BoardContainer>

      {'document' in window &&
        createPortal(
          <DragOverlay>
            {activeColumn && (
              <BoardColumn
                isOverlay
                column={activeColumn}
                tasks={tasks.filter(
                  (task) => task.columnId === activeColumn.id,
                )}
              />
            )}
            {activeTask && <TaskCard task={activeTask} isOverlay />}
          </DragOverlay>,
          document.body,
        )}
    </DndContext>
  );
}
