import React, { useContext, useState } from 'react';

import { Button } from '@/components/Button';
import { Input } from '@/components/Input';
import { Label } from '@/components/Label';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/Popover';
import { ScrollArea } from '@/components/ScrollArea';
import { Separator } from '@/components/Separator';
import { UserAvatar } from '@/components/UserAvatar';
import { UserAssignIcon } from '@/icons/core/issue';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { UserFragment } from '@/types/tanstack-query/generated';

const OrderButton = (props: {
  data: UserFragment[];
  selected: string | undefined;
  onSelected: (value: string | undefined) => void;
}) => {
  return (
    <div className="flex w-full flex-col">
      <Button
        type="button"
        variant={!props.selected ? 'secondary' : 'ghost'}
        className="flex h-10 w-full flex-row items-center gap-2 px-[5px] text-left"
        onClick={() => props.onSelected(undefined)}
      >
        <div className="flex w-6 flex-none items-center">
          <UserAssignIcon width={23} height={23} style={{ color: '#9e9fa8' }} />
        </div>
        <div className="grow">
          <Label>Unassigned</Label>
        </div>
      </Button>
      {props.data.map((item) => (
        <Button
          key={item.id}
          type="button"
          variant={item.id === props.selected ? 'secondary' : 'ghost'}
          className="flex h-10 w-full flex-row items-center gap-2 px-[5px] text-left"
          onClick={() => props.onSelected(item.id)}
        >
          <div className="flex w-6 flex-none items-center">
            <UserAvatar size="sm" url={item.image} fallback={item.username} />
          </div>
          <div className="grow">
            <Label>{item.username}</Label>
          </div>
        </Button>
      ))}
    </div>
  );
};

const ItemList = (props: {
  list: UserFragment[];
  selected: string | undefined;
  onSelected: (value: string | undefined) => void;
}) => {
  return props.list.length > 0 ? (
    <div>
      <Separator className="my-[5px] w-full" orientation="horizontal" />
      <ScrollArea className="h-full max-h-[300px] w-full">
        <OrderButton
          data={props.list}
          onSelected={props.onSelected}
          selected={props.selected}
        />
      </ScrollArea>
    </div>
  ) : (
    <></>
  );
};

const AssignToButton = (props: {
  title?: string;
  teamIds: string[];
  selected: string | undefined;
  placeholder?: string;
  onSelected: (value: string | undefined) => void;
  renderItem: (value: UserFragment | undefined) => React.ReactNode;
}) => {
  const { getDataByTeam } = useContext(WorkspaceContext);

  const members = props.teamIds.flatMap((item) => {
    const data = getDataByTeam && getDataByTeam({ team_id: item });
    return data?.members ?? [];
  });
  const [filter, setFilter] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const selectedAssignee = members.find((item) => item.id === props.selected);
  return (
    <Popover modal={true} open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        {props.renderItem(selectedAssignee)}
      </PopoverTrigger>

      <PopoverContent className="p-[5px]">
        {props.title && (
          <div className="w-full px-[8px] pt-[4px]">
            <Label className="line-clamp-1 max-w-[250px]">{props.title}</Label>
          </div>
        )}
        <div className="flex w-full items-center p-[8px]">
          <Input
            className="h-[30px] w-full border-none p-0 text-lg shadow-none"
            placeholder={props.placeholder ?? 'Change member...'}
            value={filter}
            autoFocus
            onChange={(e) => setFilter(e.currentTarget.value)}
          ></Input>
        </div>
        <ItemList
          list={members.filter((item) => item.email.includes(filter)) ?? []}
          onSelected={(data) => {
            props.onSelected(data);
            setIsOpen(false);
          }}
          selected={props.selected}
        />
      </PopoverContent>
    </Popover>
  );
};
const MemoizedAssignToButton = React.memo(AssignToButton);
export default MemoizedAssignToButton;
