import { useState } from 'react';

import { Button } from '@/components/Button';
import { Card } from '@/components/Card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/DropdownMenu';
import { Input } from '@/components/Input';
import { Label } from '@/components/Label';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/Tooltip';
import { LabelFragment, TeamFragment } from '@/types/tanstack-query/generated';

import CreateLabelForm from './components/create/CreateLabelForm';
import DeleteLabelDialog from './components/delete/DeleteLabelDialog';
import UpdateLabelForm from './components/update/UpdateLabelForm';
import { MoreHorizontalIcon, Edit2Icon, InfoIcon } from '../../../icons/index';
import CustomDateTime from '../../../utils/format-datetime';
import HeaderSetting from '../components/HeaderSetting';

const MoreButton = (props: {
  children: React.ReactNode;
  label: LabelFragment;
  onDelete: (label: LabelFragment) => void;
}) => {
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger>{props.children}</DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem>Edit</DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => props.onDelete(props.label)}
            color="red"
          >
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};

const LabelList = (props: {
  labels: LabelFragment[];
  onDelete: (label: LabelFragment) => void;
  onUpdateSuccess: (label: LabelFragment) => void;
}) => {
  const [selectedUpdate, setSelectedUpdate] = useState<LabelFragment>();
  return (
    <>
      {props.labels.map((item) => (
        <div key={item.id}>
          {selectedUpdate?.id !== item.id ? (
            <Card>
              <div className="flex flex-row items-center gap-4 px-[12px]">
                <div className="flex grow flex-row items-center gap-4">
                  <span
                    className={`inline-block h-[10px] w-[10px] rounded-full`}
                    style={{ backgroundColor: item.color }}
                  ></span>
                  <Label>{item.name}</Label>
                </div>
                <div className="flex items-center">
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <Button
                          size={'sm'}
                          type="button"
                          variant="ghost"
                          className="opacity-30 hover:opacity-100"
                        >
                          <InfoIcon size={18} />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        {CustomDateTime.convertStringToDateTime(
                          item.created_at ?? '',
                          'PPPP',
                        )}
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>

                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <Button
                          size={'sm'}
                          type="button"
                          className="opacity-30 hover:opacity-100"
                          variant="ghost"
                          onClick={() => setSelectedUpdate(item)}
                        >
                          <Edit2Icon size={18} />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>Edit Label</TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <MoreButton onDelete={props.onDelete} label={item}>
                          <Button
                            size={'sm'}
                            type="button"
                            variant="ghost"
                            className="opacity-30 hover:opacity-100"
                          >
                            <MoreHorizontalIcon size={18} />
                          </Button>
                        </MoreButton>
                      </TooltipTrigger>
                      <TooltipContent>Actions</TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </div>
            </Card>
          ) : (
            <div className="h-[56px] w-full">
              <UpdateLabelForm
                label={item}
                onSuccess={(data) => {
                  setSelectedUpdate(undefined);
                  props.onUpdateSuccess(data);
                }}
                onCancel={() => setSelectedUpdate(undefined)}
              />
            </div>
          )}
        </div>
      ))}
    </>
  );
};

const SettingLabelsComponent = (props: {
  labels: LabelFragment[];
  team: TeamFragment | undefined;
}) => {
  const [filterName, setFilterName] = useState<string>();
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [selectedDelete, setSelectedDelete] = useState<
    LabelFragment | undefined
  >();

  const onCreateSuccess = () => {
    setIsCreate(false);
  };

  const onUpdateSuccess = () => {};

  const onDeleteSuccess = () => {
    setSelectedDelete(undefined);
  };

  const onDeleteLabel = (data: LabelFragment) => {
    setSelectedDelete(data);
  };

  return (
    <div>
      {!props.team ? (
        <HeaderSetting
          title={'Workspace Labels'}
          subtitle={'Manage workspace labels'}
        >
          <Label className="opacity-50">
            Use labels and label groups to help organize and filter issues in
            your workspace. Labels created in this section are available for all
            teams to use. To create labels or label groups that only apply to
            certain teams, add them in the team-specific label settings.
          </Label>
        </HeaderSetting>
      ) : (
        <HeaderSetting
          title={'Labels'}
          subtitle={`Manage team ${props.team.name} specific labels`}
        >
          <Label className="block opacity-50">
            Use labels to help organize and filter issues in your team. Labels
            created in this section are specific to this team, so they can be
            customized to your team’s needs.
          </Label>
          <Label className="mt-[10px] block opacity-50">
            To create labels that are common for all teams in the workspace, add
            them in <Label>workspace labels settings</Label>
          </Label>
        </HeaderSetting>
      )}

      <div className="mt-[30px] w-full">
        <div className="flex w-full flex-row">
          <div className="grow">
            <div className="max-w-[200px]">
              <Input
                placeholder="Filter by name"
                value={filterName}
                onChange={(e) => setFilterName(e.currentTarget.value)}
              ></Input>
            </div>
          </div>
          <div>
            <Button size={'sm'} type="button" onClick={() => setIsCreate(true)}>
              New label
            </Button>
          </div>
        </div>

        <div className="mt-[10px] flex flex-col gap-2">
          {isCreate && (
            <div className="w-full">
              <CreateLabelForm
                teamId={props.team?.id}
                onSuccess={onCreateSuccess}
                onCancel={() => setIsCreate(false)}
              />
            </div>
          )}
          <LabelList
            labels={props.labels.filter((item) =>
              item.name.includes(filterName ?? ''),
            )}
            onDelete={onDeleteLabel}
            onUpdateSuccess={onUpdateSuccess}
          />
        </div>
      </div>
      <DeleteLabelDialog
        label={selectedDelete}
        onCancel={() => setSelectedDelete(undefined)}
        onDeleteSuccess={onDeleteSuccess}
      />
    </div>
  );
};

export default SettingLabelsComponent;
