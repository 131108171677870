import * as Types from '../../types';

import { gql } from '@apollo/client';
import { FileFragmentDoc } from '../../file/graphql/fragments.graphql.generated';
import { UserFragmentDoc } from '../../user/graphql/fragments.graphql.generated';
export type DocumentAttachmentFragment = { __typename?: 'DocumentAttachmentEntity', id: string, document_id: string, workspace_id?: string | null, file_id: string, created_by: string, created_at?: string | null, file?: { __typename?: 'FileEntity', id: string, name: string, path: string, mime_type: string, size: number, workspace_id?: string | null, created_at?: string | null } | null, user?: { __typename?: 'UserEntity', email: string, username?: string | null, id: string, image?: string | null, full_name?: string | null, theme?: Types.ThemeType | null } | null };

export const DocumentAttachmentFragmentDoc = gql`
    fragment DocumentAttachment on DocumentAttachmentEntity {
  id
  document_id
  workspace_id
  file_id
  file {
    ...File
  }
  user {
    ...User
  }
  created_by
  created_at
}
    ${FileFragmentDoc}
${UserFragmentDoc}`;