import * as Types from '../../types';

import { gql } from '@apollo/client';
import { FileFragmentDoc } from './fragments.graphql.generated';
import * as Apollo from '@apollo/client';
export type CreateAttachmentMutationVariables = Types.Exact<{
  createInput: Types.CreateFileInput;
}>;


export type CreateAttachmentMutation = { __typename?: 'Mutation', createAttachment: { __typename?: 'FileEntity', id: string, name: string, path: string, mime_type: string, size: number, workspace_id?: string | null, created_at?: string | null } };


export const CreateAttachmentDocument = gql`
    mutation createAttachment($createInput: CreateFileInput!) {
  createAttachment(createInput: $createInput) {
    ...File
  }
}
    ${FileFragmentDoc}`;
export type CreateAttachmentMutationFn = Apollo.MutationFunction<CreateAttachmentMutation, CreateAttachmentMutationVariables>;
export type CreateAttachmentMutationResult = Apollo.MutationResult<CreateAttachmentMutation>;
export type CreateAttachmentMutationOptions = Apollo.BaseMutationOptions<CreateAttachmentMutation, CreateAttachmentMutationVariables>;