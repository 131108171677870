import { Label } from '@radix-ui/react-context-menu';
import { LayoutDashboard, ListIcon, MenuIcon } from 'lucide-react';

import { Button } from '@/components/Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/DropdownMenu';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/Select';
import { IGroupingIssue, ILayoutIssue } from '@/types/tanstack-query/core/core';

const FilterOptions = (props: {
  children: React.ReactNode;
  layout?: ILayoutIssue;
  grouping?: IGroupingIssue;
  onSelectedLayout?: (value: ILayoutIssue) => void;
  onSelectedGrouping?: (value: IGroupingIssue) => void;
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{props.children}</DropdownMenuTrigger>
      <DropdownMenuContent className="min-w-[300px]">
        <div className="flex flex-col gap-4 p-2">
          {props.layout && (
            <div className="flex flex-row gap-2 ">
              <Button
                variant={props.layout === 'list' ? 'secondary' : 'outline'}
                className="h-[60px] w-full"
                onClick={() => props.onSelectedLayout?.('list')}
              >
                <div className="flex flex-col items-center justify-center  gap-0">
                  <ListIcon width={20} />
                  <p>List</p>
                </div>
              </Button>
              <Button
                variant={props.layout === 'board' ? 'secondary' : 'outline'}
                className="h-[60px] w-full"
                onClick={() => props.onSelectedLayout?.('board')}
              >
                <div className="flex flex-col items-center justify-center gap-0">
                  <LayoutDashboard width={20} />
                  <p>Board</p>
                </div>
              </Button>
            </div>
          )}
          <div className="flex items-center">
            <div className="flex grow flex-row items-center gap-2">
              <MenuIcon width={16} height={16} />
              <Label>Grouping</Label>
            </div>
            <div>
              <Select
                value={props.grouping}
                onValueChange={props.onSelectedGrouping}
              >
                <SelectTrigger className="w-[120px]">
                  <SelectValue placeholder="Column" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="status">Status</SelectItem>
                  {/* <SelectItem value="assignee">Assignee</SelectItem> */}
                  {/* <SelectItem value="label">Label</SelectItem> */}
                  {/* <SelectItem value="priority">Priority</SelectItem> */}
                  <SelectItem value="parent">Parent issue</SelectItem>
                  <SelectItem value="no_grouping">No grouping</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
export default FilterOptions;
