import React, { useContext } from 'react';

import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { ProjectStatusFragment } from '@/types/tanstack-query/generated';

const RenderProjectStatus = (props: {
  id: string | undefined;
  renderItem: (value: ProjectStatusFragment | undefined) => React.ReactNode;
}) => {
  if (!props.id) {
    return <>{props.renderItem(undefined)}</>;
  }
  const { projectStatuses } = useContext(WorkspaceContext);
  return (
    <>
      {props.renderItem(projectStatuses.find((item) => item.id === props.id))}
    </>
  );
};
const MemoizedRenderProjectStatus = React.memo(RenderProjectStatus);

export { MemoizedRenderProjectStatus };
