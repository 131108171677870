/* eslint-disable import/no-extraneous-dependencies */

import React, { useEffect } from 'react';

import classNames from 'classnames';
import { CalendarX2Icon, MessageCircle, PackageIcon } from 'lucide-react';
import { useLocation } from 'react-router';

import { Label } from '@/components/Label';
import { Separator } from '@/components/Separator';
import { UserAvatar } from '@/components/UserAvatar';
import { PencilIcon } from '@/icons/core';
import { MemoizedRenderLabels } from '@/renders/RenderLabel';
import { MemoizedRenderMember } from '@/renders/RenderMember';
import { MemoizedRenderProject } from '@/renders/RenderProject';
import { MemoizedRenderStatus } from '@/renders/RenderStatus';
import { getNameByPriority } from '@/services/issue/issue-utils';
import {
  ActivityFragment,
  UserFragment,
  useGetActivitiesQuery,
} from '@/types/tanstack-query/generated';
import { mayBeMatch } from '@/utils/custom-matcher';
import CustomDateTime from '@/utils/format-datetime';

import useActivities from './useActivities';
import { MemoizedRenderPriorityIcon } from '../../../components/Properties/PriorityButton';
import { MemoizedRenderStatusIcon } from '../../../components/Properties/StatusButton';
import { MemoizedRenderComment } from '../../renders/RenderComment';
import UpdateCommentComponent from '../Comment/UpdateComment/UpdateCommentComponent';
import { FormatTimeAgo } from '../Comment/utils';
import SubscribersComponent from '../Subscribers/SubscribersComponent';

const UpdateAssigneeSection = (props: {
  data: ActivityFragment;
  createdBy: UserFragment;
}) => {
  if (
    (props.data.created_by === props.data.from_assignee_id &&
      !props.data.to_assignee_id) ||
    props.data.created_by === props.data.to_assignee_id
  ) {
    return (
      <div className="flex flex-wrap gap-1">
        <Label className="text-xs">{props.createdBy.username}</Label>
        <Label className="text-xs opacity-50">self-assigned the issue</Label>
        <Label className="text-xs opacity-50">
          <FormatTimeAgo date={props.data.created_at} />
        </Label>
      </div>
    );
  }
  if (props.data.to_assignee_id) {
    return (
      <div className="flex flex-wrap gap-1">
        <Label className="text-xs">{props.createdBy.username}</Label>
        <Label className="text-xs opacity-50">re-assign to</Label>
        <Label className="text-xs">
          <MemoizedRenderMember
            id={props.data.to_assignee_id}
            renderItem={(assignee) => assignee?.username}
          />
        </Label>
        <Label className="text-xs opacity-50">
          <FormatTimeAgo date={props.data.created_at} />
        </Label>
      </div>
    );
  }
  if (!props.data.to_assignee_id) {
    return (
      <div className="flex flex-wrap gap-1">
        <Label className="text-xs">{props.createdBy.username}</Label>
        <Label className="text-xs opacity-50">assign to</Label>
        <Label className="text-xs">
          <MemoizedRenderMember
            id={props.data.from_assignee_id ?? ''}
            renderItem={(assignee) => assignee?.username}
          />
        </Label>
        <Label className="text-xs opacity-50">
          <FormatTimeAgo date={props.data.created_at} />
        </Label>
      </div>
    );
  }
  return <></>;
};

const UpdateWorkflowSection = (props: {
  data: ActivityFragment;
  createdBy: UserFragment;
}) => {
  if (!props.data.to_workflow_id) {
    return (
      <div className="flex flex-wrap gap-1">
        <Label className="text-xs">{props.createdBy.username}</Label>
        <Label className="text-xs opacity-50">set status</Label>
        <MemoizedRenderStatus
          id={props.data.from_workflow_id ?? ''}
          renderItem={(status) =>
            status && (
              <Label className="text-xs" style={{ color: status.color ?? '' }}>
                {status.name}
              </Label>
            )
          }
        />
        <Label className="text-xs opacity-50">
          <FormatTimeAgo date={props.data.created_at} />
        </Label>
      </div>
    );
  }
  if (props.data.to_workflow_id) {
    return (
      <div className="flex flex-wrap gap-1">
        <Label className="text-xs">{props.createdBy.username}</Label>
        <Label className="text-xs opacity-50">changed status</Label>
        <MemoizedRenderStatus
          id={props.data.from_workflow_id ?? ''}
          renderItem={(status) =>
            status && (
              <Label className="text-xs" style={{ color: status.color ?? '' }}>
                {status.name}
              </Label>
            )
          }
        />
        <Label className="text-xs opacity-50">to</Label>

        <MemoizedRenderStatus
          id={props.data.to_workflow_id ?? ''}
          renderItem={(status) =>
            status && (
              <Label className="text-xs" style={{ color: status.color ?? '' }}>
                {status.name}
              </Label>
            )
          }
        />

        <Label className="text-xs opacity-50">
          <FormatTimeAgo date={props.data.created_at} />
        </Label>
      </div>
    );
  }
  return <></>;
};

const UpdateProjectSection = (props: {
  data: ActivityFragment;
  createdBy: UserFragment;
}) => {
  if (!props.data.to_project_id) {
    return (
      <div className="flex flex-wrap gap-1">
        <Label className="text-xs">{props.createdBy.username}</Label>
        <Label className="text-xs opacity-50">set project</Label>
        <MemoizedRenderProject
          id={props.data.from_project_id ?? ''}
          renderItem={(project) =>
            project && <Label className="text-xs">{project.title}</Label>
          }
        />
        <Label className="text-xs opacity-50">
          <FormatTimeAgo date={props.data.created_at} />
        </Label>
      </div>
    );
  }
  if (props.data.to_project_id) {
    return (
      <div className="flex flex-wrap gap-1">
        <Label className="text-xs">{props.createdBy.username}</Label>
        <Label className="text-xs opacity-50">changed project</Label>
        <MemoizedRenderProject
          id={props.data.from_project_id ?? ''}
          renderItem={(project) =>
            project && <Label className="text-xs">{project.title}</Label>
          }
        />
        <Label className="text-xs opacity-50">to</Label>

        <MemoizedRenderProject
          id={props.data.to_project_id ?? ''}
          renderItem={(project) =>
            project && <Label className="text-xs">{project.title}</Label>
          }
        />

        <Label className="text-xs opacity-50">
          <FormatTimeAgo date={props.data.created_at} />
        </Label>
      </div>
    );
  }
  return <></>;
};

const UpdatePrioritySection = (props: {
  data: ActivityFragment;
  createdBy: UserFragment;
}) => {
  if (!props.data.to_priority) {
    return (
      <div className="flex flex-wrap gap-1">
        <Label className="text-xs">{props.createdBy.username}</Label>
        <Label className="text-xs opacity-50">set priority</Label>
        <Label className="text-xs">
          {getNameByPriority(props.data.from_priority)}
        </Label>
        <Label className="text-xs opacity-50">
          <FormatTimeAgo date={props.data.created_at} />
        </Label>
      </div>
    );
  }
  if (props.data.to_priority) {
    return (
      <div className="flex flex-wrap gap-1">
        <Label className="text-xs">{props.createdBy.username}</Label>
        <Label className="text-xs opacity-50">changed priority</Label>
        <Label className="text-xs">
          {getNameByPriority(props.data.from_priority)}
        </Label>
        <Label className="text-xs opacity-50">to</Label>
        <Label className="text-xs">
          {getNameByPriority(props.data.to_priority)}
        </Label>
        <Label className="text-xs opacity-50">
          <FormatTimeAgo date={props.data.created_at} />
        </Label>
      </div>
    );
  }
  return <></>;
};

const UpdateDueDateSection = (props: {
  data: ActivityFragment;
  createdBy: UserFragment;
}) => {
  if (!props.data.to_due_date) {
    return (
      <div className="flex flex-wrap gap-1">
        <Label className="text-xs">{props.createdBy.username}</Label>
        <Label className="text-xs opacity-50">set the due date</Label>
        <Label className="text-xs">
          {CustomDateTime.formatDate(
            props.data.from_due_date
              ? new Date(props.data.from_due_date)
              : undefined,
          )}
        </Label>
        <Label className="text-xs opacity-50">
          <FormatTimeAgo date={props.data.created_at} />
        </Label>
      </div>
    );
  }
  if (props.data.to_due_date) {
    return (
      <div className="flex flex-wrap gap-1">
        <Label className="text-xs">{props.createdBy.username}</Label>
        <Label className="text-xs opacity-50">changed the due date</Label>
        <Label className="text-xs">
          {CustomDateTime.formatDate(
            props.data.from_due_date
              ? new Date(props.data.from_due_date)
              : undefined,
          )}
        </Label>
        <Label className="text-xs opacity-50">to</Label>
        <Label className="text-xs">
          {CustomDateTime.formatDate(
            props.data.to_due_date
              ? new Date(props.data.to_due_date)
              : undefined,
          )}
        </Label>
        <Label className="text-xs opacity-50">
          <FormatTimeAgo date={props.data.created_at} />
        </Label>
      </div>
    );
  }
  return <></>;
};

const UpdateLabelsSection = (props: {
  data: ActivityFragment;
  createdBy: UserFragment;
}) => {
  return (
    <div className="flex flex-wrap gap-1">
      <Label className="text-xs">{props.createdBy.username}</Label>
      <Label className="text-xs opacity-50">set labels</Label>

      <MemoizedRenderLabels
        ids={props.data.label_ids?.split(',') ?? []}
        renderItem={(values) =>
          values.map((item) => (
            <div
              key={item.id}
              className="flex flex-row items-center gap-1 pl-[5px]"
            >
              <span
                className="inline-block h-[8px] w-[8px] rounded-xl"
                style={{ backgroundColor: item.color }}
              ></span>
              <Label className="text-xs">{item.name}</Label>
            </div>
          ))
        }
      />

      <Label className="text-xs opacity-50">
        <FormatTimeAgo date={props.data.created_at} />
      </Label>
    </div>
  );
};

const CreatedIssueSection = (props: {
  data: ActivityFragment;
  createdBy: UserFragment;
}) => {
  return (
    <div className="flex flex-wrap gap-1">
      <Label className="text-xs">{props.createdBy.username}</Label>
      <Label className="text-xs opacity-50">created issue</Label>
      <Label className="text-xs opacity-50">
        <FormatTimeAgo date={props.data.created_at} />
      </Label>
    </div>
  );
};

const UpdateIssueSection = (props: {
  data: ActivityFragment;
  createdBy: UserFragment;
}) => {
  return (
    <div className="flex flex-wrap gap-1">
      <Label className="text-xs">{props.createdBy.username}</Label>
      <Label className="text-xs opacity-50">updated description</Label>
      <Label className="text-xs opacity-50">
        <FormatTimeAgo date={props.data.created_at} />
      </Label>
    </div>
  );
};

const CommentSection = React.memo(
  (props: { data: ActivityFragment; createdBy: UserFragment }) => {
    return (
      <MemoizedRenderComment
        id={props.data.comment_id ?? ''}
        renderItem={(comment) =>
          comment ? (
            <UpdateCommentComponent comment={comment} />
          ) : (
            <div className="flex items-center gap-4">
              <div className="flex w-[30px] flex-none items-center justify-center bg-background py-1">
                <MessageCircle width={22} color="#9e9fa8" height={22} />
              </div>
              <div className="flex grow flex-wrap gap-1">
                <Label className="text-xs">Comment has been deleted</Label>
                <Label className="text-xs opacity-50">
                  <FormatTimeAgo date={props.data.created_at} />
                </Label>
              </div>
            </div>
          )
        }
      />
    );
  },
);

const ItemSection = React.memo(
  (props: { data: ActivityFragment; createdBy: UserFragment }) => {
    return (
      <div className="relative z-20 flex w-full flex-row items-center justify-center gap-4">
        {mayBeMatch([props.data.type])({
          ISSUE_CREATED: () => (
            <>
              <div className="flex w-[30px] flex-none items-center justify-center bg-background py-1">
                <UserAvatar size={'sm'} fallback={props.createdBy.username} />
              </div>
              <div className="grow">
                <CreatedIssueSection
                  data={props.data}
                  createdBy={props.createdBy}
                />
              </div>
            </>
          ),
          ISSUE_UPDATE_ASSIGNEE: () => (
            <>
              <div className="flex w-[30px] flex-none items-center justify-center bg-background py-1">
                <UserAvatar size={'sm'} fallback={props.createdBy.username} />
              </div>
              <div className="grow">
                <UpdateAssigneeSection
                  data={props.data}
                  createdBy={props.createdBy}
                />
              </div>
            </>
          ),
          ISSUE_UPDATE_PRIORITY: () => (
            <>
              <div className="flex w-[30px] flex-none items-center justify-center bg-background py-1">
                <MemoizedRenderPriorityIcon
                  type={props.data.to_priority ?? props.data.from_priority}
                />
              </div>
              <div className="grow">
                <UpdatePrioritySection
                  data={props.data}
                  createdBy={props.createdBy}
                />
              </div>
            </>
          ),
          ISSUE_UPDATE_WORKFLOW: () => {
            const workflowId =
              props.data.to_workflow_id ?? props.data.from_workflow_id;
            return (
              <>
                <div className="flex w-[30px] flex-none items-center justify-center bg-background py-1">
                  <MemoizedRenderStatus
                    id={workflowId ?? ''}
                    renderItem={(status) =>
                      status && (
                        <MemoizedRenderStatusIcon
                          type={status?.type}
                          color={status?.color}
                          processing={status?.processing}
                        />
                      )
                    }
                  />
                </div>
                <div className="grow">
                  <UpdateWorkflowSection
                    data={props.data}
                    createdBy={props.createdBy}
                  />
                </div>
              </>
            );
          },
          ISSUE_UPDATE_LABELS: () => (
            <>
              <div className="flex w-[30px] flex-none items-center justify-center bg-background py-1">
                <UserAvatar size={'sm'} fallback={props.createdBy.username} />
              </div>
              <div className="grow">
                <UpdateLabelsSection
                  data={props.data}
                  createdBy={props.createdBy}
                />
              </div>
            </>
          ),
          ISSUE_CREATED_COMMENT: () => (
            <>
              <div className="w-full grow">
                <CommentSection data={props.data} createdBy={props.createdBy} />
              </div>
            </>
          ),
          ISSUE_UPDATE_DESCRIPTION: () => (
            <>
              <div className="flex w-[30px] flex-none items-center justify-center bg-background py-1">
                <PencilIcon width={20} />
              </div>
              <div className="grow">
                <UpdateIssueSection
                  data={props.data}
                  createdBy={props.createdBy}
                />
              </div>
            </>
          ),
          ISSUE_UPDATE_PROJECT: () => {
            return (
              <>
                <div className="flex w-[30px] flex-none items-center justify-center bg-background py-1">
                  <PackageIcon width={20} color="#9e9fa8" />
                </div>
                <div className="grow">
                  <UpdateProjectSection
                    data={props.data}
                    createdBy={props.createdBy}
                  />
                </div>
              </>
            );
          },
          ISSUE_UPDATE_DUE_DATE: () => {
            return (
              <>
                <div className="flex w-[30px] flex-none items-center justify-center bg-background py-1">
                  <CalendarX2Icon width={20} height={20} color="red" />
                </div>
                <div className="grow">
                  <UpdateDueDateSection
                    data={props.data}
                    createdBy={props.createdBy}
                  />
                </div>
              </>
            );
          },
          orElse: () => <></>,
        })}
      </div>
    );
  },
);

const ActivitiesSection = React.memo((props: { data: ActivityFragment[] }) => {
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      if (location.hash) {
        const element = document.getElementById(location.hash);
        if (!element) return;
        window.scroll({
          top: element.offsetTop,
          behavior: 'smooth',
        });
      }
    }, 1000);
  }, [location]);
  return (
    <div className="relative">
      <div className="relative z-10 flex w-full flex-col items-start justify-start gap-2">
        {props.data.map((item) => (
          <div
            className={classNames('w-full', {
              'focus-activity': `#${item.id}` === location.hash,
            })}
            key={item.id}
            id={`#${item.id}`}
          >
            <MemoizedRenderMember
              id={item.created_by}
              renderItem={(createdBy) =>
                createdBy && (
                  <ItemSection
                    key={item.id}
                    data={item}
                    createdBy={createdBy}
                  />
                )
              }
            />
            <div className="absolute left-[15px] top-0 z-[9] h-full py-[13px]">
              <Separator className="h-full" orientation={'vertical'} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

const Header = (props: { issue_id: string; workspace_id: string }) => {
  return (
    <div className="flex w-full items-center">
      <Label className="text-xl font-semibold">Activity</Label>
      <div className="grow">
        <SubscribersComponent
          issue_id={props.issue_id}
          workspace_id={props.workspace_id}
        />
      </div>
    </div>
  );
};

const ActivitiesComponent = (props: {
  issue_id: string;
  workspace_id: string;
}) => {
  const { event, activities } = useActivities();
  const getActivities = useGetActivitiesQuery(
    {
      getInput: {
        issue_id: props.issue_id,
        workspace_id: props.workspace_id,
      },
    },
    {
      enabled: !!props.issue_id && !!props.workspace_id,
    },
  );

  useEffect(() => {
    event.storeActivities({
      issue_id: props.issue_id,
      activities: getActivities.data?.activities ?? [],
    });
  }, [getActivities.data?.activities]);

  return (
    <div className="flex w-full flex-col gap-6">
      <Separator />
      <Header issue_id={props.issue_id} workspace_id={props.workspace_id} />
      <ActivitiesSection data={activities} />
    </div>
  );
};

export default ActivitiesComponent;
