import { InfoIcon } from 'lucide-react';

const ProjectNotFoundComponent = () => {
  return (
    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
      <div className="flex flex-col gap-2">
        <h2 className="flex items-center justify-center gap-2 font-semibold">
          <InfoIcon />
          Project Not Found
        </h2>
        <p>There is no project with the identifier </p>
      </div>
    </div>
  );
};
export default ProjectNotFoundComponent;
