import * as Types from '../../types';

import { gql } from '@apollo/client';
import { SubscriptionResponseFragmentDoc } from './fragments.graphql.generated';
import * as Apollo from '@apollo/client';
export type OnSubscriptionsSubscriptionVariables = Types.Exact<{
  workspace_id: Types.Scalars['String']['input'];
}>;


export type OnSubscriptionsSubscription = { __typename?: 'Subscription', subscribes: { __typename?: 'SubscriptionResponse', id?: string | null, event: string, workspace_id?: string | null, created_at: string, data?: object | null } };


export const OnSubscriptionsDocument = gql`
    subscription OnSubscriptions($workspace_id: String!) {
  subscribes(workspace_id: $workspace_id) {
    ...SubscriptionResponse
  }
}
    ${SubscriptionResponseFragmentDoc}`;
export type OnSubscriptionsSubscriptionResult = Apollo.SubscriptionResult<OnSubscriptionsSubscription>;