import React, { useContext, useState } from 'react';

import { Button } from '@/components/Button';
import { Checkbox } from '@/components/Checkbox';
import { Input } from '@/components/Input';
import { Label } from '@/components/Label';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/Popover';
import { ScrollArea } from '@/components/ScrollArea';
import { Separator } from '@/components/Separator';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { LabelFragment } from '@/types/tanstack-query/generated';

const RenderLabelColor = (props: { label: LabelFragment }) => {
  return (
    <span
      className="inline-block h-[12px] w-[12px] rounded-full"
      style={{ backgroundColor: props.label.color }}
    ></span>
  );
};

const RenderLabelsColor = (props: { labels: LabelFragment[] }) => {
  return (
    <div
      className="relative h-[12px]"
      style={{ width: 6 * props.labels.length + 6 }}
    >
      {props.labels.map((item, index) => (
        <span
          key={index}
          className="absolute left-0 inline-block h-[12px] w-[12px] rounded-full"
          style={{ backgroundColor: item.color, marginLeft: `${index * 6}px` }}
        ></span>
      ))}
    </div>
  );
};

const OrderButton = (props: {
  data: LabelFragment[];
  selected: string[];
  onSelected: (value: string[]) => void;
}) => {
  return (
    <div className="flex w-full flex-col">
      {props.data.map((item) => (
        <Button
          key={item.id}
          type="button"
          variant={props.selected.includes(item.id) ? 'secondary' : 'ghost'}
          className="flex h-10 w-full flex-row items-center gap-2 px-[5px] text-left"
        >
          <Checkbox
            checked={props.selected.includes(item.id)}
            onCheckedChange={(checked) => {
              return checked
                ? props.onSelected([...props.selected, item.id])
                : props.onSelected(
                    props.selected.filter((value) => value !== item.id),
                  );
            }}
          />
          <div className="flex w-[14px] flex-none items-center">
            <span
              className={`inline-block h-[14px] w-[14px] rounded-full`}
              style={{ backgroundColor: item.color }}
            ></span>
          </div>
          <div className="grow truncate">
            <Label>{item.name}</Label>
          </div>
        </Button>
      ))}
    </div>
  );
};

const ItemList = (props: {
  list: LabelFragment[];
  selected: string[];
  onSelected: (value: string[]) => void;
}) => {
  return props.list.length > 0 ? (
    <div>
      <Separator className="my-[5px] w-full" orientation="horizontal" />
      <ScrollArea className="h-full max-h-[300px] w-full">
        <OrderButton
          data={props.list}
          onSelected={props.onSelected}
          selected={props.selected}
        />
      </ScrollArea>
    </div>
  ) : (
    <></>
  );
};

const LabelButton = (props: {
  title?: string;
  teamId?: string;
  selected: string[];
  placeholder?: string;
  onSelected: (value: string[]) => void;
  renderItem: (value: LabelFragment[]) => React.ReactNode;
}) => {
  const { getDataByTeam, labels: labelsCore } = useContext(WorkspaceContext);
  const teamData = getDataByTeam && getDataByTeam({ team_id: props.teamId });
  const [filter, setFilter] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const labels = props.teamId ? teamData?.labels : labelsCore;
  const selectedLabelIds = labels?.filter((item) =>
    props.selected.includes(item.id),
  );
  return (
    <Popover modal={true} open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild className="md:w-full">
        {props.renderItem(selectedLabelIds ?? [])}
      </PopoverTrigger>

      <PopoverContent className="p-[5px]">
        <div className="w-full px-[8px] pt-[4px]">
          {props.title && (
            <Label className="line-clamp-1 max-w-[250px]">{props.title}</Label>
          )}
        </div>
        <div className="flex w-full items-center p-[8px]">
          <Input
            className="h-[30px] w-full border-none p-0 text-lg shadow-none"
            placeholder={props.placeholder ?? 'Search label...'}
            value={filter}
            autoFocus
            onChange={(e) => setFilter(e.currentTarget.value)}
          ></Input>
        </div>
        <ItemList
          list={labels?.filter((item) => item.name.includes(filter)) ?? []}
          onSelected={(data) => {
            props.onSelected(data);
          }}
          selected={props.selected}
        />
      </PopoverContent>
    </Popover>
  );
};
const MemoizedLabelButton = React.memo(LabelButton);
const MemoizedRenderLabelColor = React.memo(RenderLabelColor);
const MemoizedRenderLabelsColor = React.memo(RenderLabelsColor);
export {
  MemoizedLabelButton,
  MemoizedRenderLabelColor,
  MemoizedRenderLabelsColor,
};
