/* eslint-disable import/no-extraneous-dependencies */
import { useContext, useEffect, useRef, useState } from 'react';

import { PlateEditor } from '@udecode/plate-common';
import classNames from 'classnames';

import { Button } from '@/components/Button';
import EditorPlateComponent from '@/components/Editor/EditorPlateComponent';
import SingleLineEditorComponent from '@/components/Editor/TitleEditorComponent';
import { Label } from '@/components/Label';
import { Separator } from '@/components/Separator';
import {
  useErrorHandler,
  useSuccessHandler,
  useWarningHandler,
} from '@/hooks/useToasterHandler';
import EmojiIcon, { IEmojiIcon } from '@/icons/emoji';
import TeamButton from '@/pages/issue/components/Properties/TeamButton';
import ProjectServicesContext from '@/providers/project/ProjectServicesContext';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { BasePriorities } from '@/services/issue/issue-utils';
import {
  PriorityType,
  useCreateProjectMutation,
} from '@/types/tanstack-query/generated';
import PlateUtils from '@/utils/plate-utils';

import ProjectPropertiesComponent from '../ProjectPropertiesComponent';

export const CreateProjectComponent = (props: {
  initTeamId?: string;
  onSuccess: () => void;
  onCancel: () => void;
}) => {
  const { teams, selected: selectedWorkspace } = useContext(WorkspaceContext);
  const { showErrorToast } = useErrorHandler();
  const { showSuccessToast } = useSuccessHandler();
  const { showWarningToast } = useWarningHandler();
  const editorTitleRef = useRef<PlateEditor | null>(null);
  const editorShortSummaryRef = useRef<PlateEditor | null>(null);
  const editorContentRef = useRef<PlateEditor | null>(null);
  const { isShowCreateProject, showCreateProjectPopup } = useContext(
    ProjectServicesContext,
  );
  const [selectedProjectStatusId, setSelectedProjectStatusId] =
    useState<string>();
  const [selectedTeamId, setSelectedTeamId] = useState<string | undefined>();
  const [selectedLeadId, onSelectedLeadId] = useState<string | undefined>();
  const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>([]);
  const [selectedPriority, setSelectedPriority] = useState<
    PriorityType | undefined
  >();
  const [selectedLabelIds, setSelectedLabelIds] = useState<string[]>([]);
  const [selectedStartAt, setSelectedStartAt] = useState<Date | undefined>();
  const [selectedEndAt, setSelectedEndAt] = useState<Date | undefined>();
  const createProject = useCreateProjectMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {
      showCreateProjectPopup({ isOpen: false });
      props.onSuccess();
      showSuccessToast({
        title: 'Project has been created',
      });
    },
    onSettled: () => {},
  });

  const onSubmitCreate = () => {
    if (selectedTeamId && selectedWorkspace) {
      const getTitle = PlateUtils.getTitleFromTElement(
        editorTitleRef.current?.children,
      );
      const getShortSummary = PlateUtils.getTitleFromTElement(
        editorShortSummaryRef.current?.children,
      );
      if (!getTitle) {
        showWarningToast({
          title: 'Title project is required',
          description: 'Please add a title before submitting.',
        });
        return;
      }
      const getContent = PlateUtils.getContentFromTElement(
        editorContentRef.current?.children,
      );
      const createProjectInput = {
        title: getTitle ?? '',
        description: getContent,
        workspace_id: selectedWorkspace?.id,
        icon: '',
        priority: selectedPriority,
        short_summary: getShortSummary,
        project_status_id: selectedProjectStatusId,
        label_ids: selectedLabelIds.join(','),
        leader_id: selectedLeadId,
        member_ids: selectedMemberIds.join(','),
        team_ids: [selectedTeamId].join(','),
        start_at: selectedStartAt?.toISOString(),
        end_at: selectedEndAt?.toISOString(),
      };
      createProject.mutate({
        createInput: createProjectInput,
      });
    }
  };

  const onReset = () => {
    setSelectedProjectStatusId(undefined);
    setSelectedPriority(BasePriorities[0]?.type);
  };

  const onInitForm = () => {};

  useEffect(() => {
    if (!isShowCreateProject) {
      onReset();
    } else {
      onInitForm();
    }
  }, [isShowCreateProject]);

  useEffect(() => {
    onReset();
    onInitForm();
  }, [selectedTeamId]);

  useEffect(() => {
    if (teams.length > 0 && !props.initTeamId) {
      setSelectedTeamId(teams[0]?.id);
    } else {
      setSelectedTeamId(props.initTeamId);
    }
  }, [props]);

  return (
    <>
      <div className="flex-none">
        <div className="flex items-center gap-2 p-[10px]">
          <TeamButton
            selected={selectedTeamId}
            onChange={setSelectedTeamId}
            renderItem={(value) => (
              <Button
                size={'sm'}
                className="h-[26px] max-w-[80px]"
                type="button"
              >
                <div className="flex flex-row items-center gap-2">
                  <EmojiIcon
                    tag={value?.icon as IEmojiIcon}
                    attrs={{
                      width: 16,
                      height: 16,
                      color: value?.color,
                    }}
                  />
                  <Label>{value?.identifier}</Label>
                </div>
              </Button>
            )}
          />
          <Label>›</Label>

          <Label>New project</Label>
        </div>
      </div>
      <div className="flex flex-none flex-col px-[10px]">
        <div className="mb-[10px] flex ">
          <SingleLineEditorComponent
            className="min-h-[10px] text-2xl"
            loading={false}
            editorRef={editorTitleRef}
            placeholder={'Project title'}
          />
        </div>
        <div className="mb-[10px] flex">
          <SingleLineEditorComponent
            className="h-auto"
            loading={false}
            editorRef={editorShortSummaryRef}
            placeholder={'Short summary'}
          />
        </div>
      </div>

      <div className="px-[10px]">
        <ProjectPropertiesComponent
          disabled={false}
          selectedStartAt={selectedStartAt}
          selectedEndAt={selectedEndAt}
          onSelectedStartAt={setSelectedStartAt}
          onSelectedEndAt={setSelectedEndAt}
          teamIds={selectedTeamId ? [selectedTeamId] : []}
          selectedProjectStatusId={selectedProjectStatusId}
          onSelectedProjectStatusId={setSelectedProjectStatusId}
          selectedPriority={selectedPriority}
          onSelectedPriority={setSelectedPriority}
          selectedLabelIds={selectedLabelIds}
          onSelectedLabelIds={setSelectedLabelIds}
          selectedLeadId={selectedLeadId}
          onSelectedLeadId={onSelectedLeadId}
          selectedMemberIds={selectedMemberIds}
          onSelectedMemberIds={setSelectedMemberIds}
          onCopyId={() => {}}
          onCopyLink={() => {}}
        />
      </div>

      <div
        className={classNames(
          'flex min-h-[200px] w-full grow flex-col overflow-auto px-[10px]',
        )}
      >
        <EditorPlateComponent
          loading={false}
          placeholder="Add description ..."
          editorRef={editorContentRef}
        />
      </div>
      <div className="flex-none">
        <div className="w-full flex-none">
          <Separator className="my-[10px]  w-full flex-none" />
          <div className="flex flex-none flex-row justify-end gap-2 p-[10px] pt-0">
            <Button
              type="button"
              size={'sm'}
              variant={'secondary'}
              onClick={() => onSubmitCreate()}
              disabled={createProject.isPending}
            >
              <Label>Create project</Label>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
