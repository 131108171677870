import { useContext, useEffect, useState } from 'react';

import orderBy from 'lodash/orderBy';

import {
  IDocumentFragment,
  PushEventType,
} from '@/types/tanstack-query/generated';

import SubscriptionContext from '../subscriptions/SubscriptionContext';

const useWorkspaceDocument = () => {
  const { pubsub } = useContext(SubscriptionContext);
  const [documents, setDocuments] = useState<IDocumentFragment[]>([]);

  const storeDocuments = async (value: IDocumentFragment[]) => {
    setDocuments(orderBy(value, ['created_at'], ['desc']));
  };

  const getDocumentsByTeam = (props: {
    team_id: string;
  }): IDocumentFragment[] => {
    const getDocuments = documents.filter(
      (item) => item.team_id === props.team_id && item.is_deleted === false,
    );

    return getDocuments;
  };

  const pushItem = async (props: { document: IDocumentFragment }) => {
    const clone = Object.assign([], documents);
    clone.push(props.document);
    storeDocuments(clone);
  };

  const updateItem = async (props: { document: IDocumentFragment }) => {
    const clone: IDocumentFragment[] = Object.assign([], documents);
    storeDocuments(
      clone.map((item) => {
        if (item.id === props.document.id) return { ...props.document };
        return { ...item };
      }),
    );
  };

  const popItem = async (props: { document_id: string }) => {
    const getDocument = documents.find((item) => item.id === props.document_id);
    const clone = { ...getDocument, is_deleted: true } as IDocumentFragment;
    updateItem({ document: clone });
  };

  useEffect(() => {
    if (pubsub && pubsub.id && pubsub.event) {
      switch (pubsub.event) {
        case PushEventType.DocumentAdded:
          pushItem({ document: pubsub.data as IDocumentFragment });
          break;
        case PushEventType.DocumentUpdated:
          updateItem({ document: pubsub.data as IDocumentFragment });
          break;
        case PushEventType.DocumentDeleted:
          popItem({ document_id: pubsub.id });
          break;
        default:
          break;
      }
    }
  }, [pubsub]);

  return {
    documents,
    event: {
      storeDocuments,
      getDocumentsByTeam,
    },
  };
};

export default useWorkspaceDocument;
