import { useContext, useEffect, useState } from 'react';

import { Button } from '@/components/Button';
import { InProgressIcon } from '@/icons/core/issue';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { WorkflowType } from '@/types/tanstack-query/generated';
import { IssueFragment } from '@/types/tanstack-query/generated';

export const ParentIssuesCircleButton = (props: { id: string }) => {
  const { issues, workflows } = useContext(WorkspaceContext);
  const [percent, setPercent] = useState<number>(0);
  const [subIssues, setSubIssues] = useState<IssueFragment[]>([]);
  const [doneIssues, setDoneIssues] = useState<IssueFragment[]>([]);

  const onCalculate = () => {
    const getDone = workflows
      .filter((item) => item.type === WorkflowType.Completed)
      .map((item) => item.id);
    const getSubIssues = issues.filter((item) => item.issue_id === props.id);
    const getDoneIssues = getSubIssues.filter(
      (item) => item.workflow_id && getDone.includes(item.workflow_id),
    );
    const getPercent = (getDoneIssues.length / getSubIssues.length) * 13;
    setSubIssues(getSubIssues);
    setDoneIssues(getDoneIssues);
    setPercent(getPercent);
  };
  useEffect(() => {
    onCalculate();
  }, []);
  useEffect(() => {
    onCalculate();
  }, [issues, workflows]);

  return subIssues.length > 0 ? (
    <Button
      size={'sm'}
      variant={'outline'}
      className="flex flex-row items-center gap-1 px-3 py-1"
    >
      <InProgressIcon
        attrs={{
          width: 14,
          height: 14,
          style: { color: percent === 13 ? 'green' : 'blue' },
        }}
        percent={percent}
      />
      <span>{`${doneIssues.length}/${subIssues.length}`}</span>
    </Button>
  ) : (
    <></>
  );
};
