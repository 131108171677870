import { useContext, useState } from 'react';

import TimeAgo from 'react-timeago';

import { Label } from '@/components/Label';
import { ScrollArea, ScrollBar } from '@/components/ScrollArea';
import { Separator } from '@/components/Separator';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { IGroupingIssue } from '@/types/tanstack-query/core/core';
import { IssueFragment } from '@/types/tanstack-query/generated';
import CustomDateTime from '@/utils/format-datetime';

import useIssuesActivity from './useIssuesActivity';
import FilterIssue from '../../components/FilterIssue/FilterIssue';
import IssueTableList from '../../components/IssueTableList/IssueTableList';
import MyIssueHeader from '../../components/MyIssueHeader/MyIssueHeader';
import { ConditionFilterList } from '../../constant/constant';

const DateSection = (props: { date: string; issues: IssueFragment[] }) => {
  return (
    <>
      <div className="flex h-[38px] flex-row items-center gap-4 bg-secondary bg-opacity-20 px-[10px]">
        <div className="flex grow flex-row items-center gap-2">
          <div className="flex w-full flex-none">
            <Label>
              <TimeAgo
                live={false}
                date={props.date}
                minPeriod={2}
                formatter={(value, unit, suffix) => {
                  if (unit === 'hour') {
                    return `Today`;
                  }
                  if (value === 1 && unit === 'day') {
                    return `Yesterday`;
                  }
                  return `${value} ${value > 0 ? `${unit}s` : unit} ${suffix}`;
                }}
              />
            </Label>
          </div>
        </div>
      </div>
      <Separator className="w-full" orientation="horizontal" />
      <IssueTableList data={props.issues} type={'issues'} />
    </>
  );
};

const IssuesActivityPage = () => {
  const { selected: selectedWorkspace } = useContext(WorkspaceContext);
  const [grouping, setGrouping] = useState<IGroupingIssue>('parent');
  const {
    issues,
    dates,
    event,
    workflows,
    labels,
    members,
    selectedStatuses,
    selectedConditionStatus,
    selectedPriorities,
    selectedConditionPriority,
    selectedLabels,
    selectedConditionLabel,
    selectedAssignees,
    selectedConditionAssignee,
  } = useIssuesActivity();
  return (
    <div className="flex w-full flex-col">
      <MyIssueHeader active={'activity'} />
      <FilterIssue
        selectedGrouping={grouping}
        onSelectedGrouping={setGrouping}
        onClearStatuses={() => {
          event.setSelectedStatuses([]);
          event.setSelectedConditionStatus(ConditionFilterList[0]!);
        }}
        onSelectedStatuses={event.setSelectedStatuses}
        selectedStatuses={selectedStatuses}
        selectedConditionStatus={selectedConditionStatus}
        onChangeConditionStatus={event.setSelectedConditionStatus}
        onClearPriorities={() => {
          event.setSelectedPriorities([]);
          event.setSelectedConditionPriority(ConditionFilterList[0]!);
        }}
        onSelectedPriorities={event.setSelectedPriorities}
        selectedPriorities={selectedPriorities}
        selectedConditionPriority={selectedConditionPriority}
        onChangeConditionPriority={event.setSelectedConditionPriority}
        onClearLabels={() => {
          event.setSelectedLabels([]);
          event.setSelectedConditionLabel(ConditionFilterList[0]!);
        }}
        onSelectedLabels={event.setSelectedLabels}
        selectedLabels={selectedLabels}
        selectedConditionLabel={selectedConditionLabel}
        onChangeConditionLabel={event.setSelectedConditionLabel}
        onClearAssignees={() => {
          event.setSelectedAssignees([]);
          event.setSelectedConditionAssignee(ConditionFilterList[0]!);
        }}
        onSelectedAssignees={event.setSelectedAssignees}
        selectedAssignees={selectedAssignees}
        selectedConditionAssignee={selectedConditionAssignee}
        onChangeConditionAssignee={event.setSelectedConditionAssignee}
        workflows={workflows}
        labels={labels}
        members={members}
      />
      {selectedWorkspace && (
        <ScrollArea type="auto" style={{ maxHeight: '100%' }}>
          {dates.map((item) => {
            const getIssues = issues.filter(
              (x) =>
                CustomDateTime.convertStringToDateTime(
                  x.updated_at,
                  'yyyy-MM-dd',
                ) === item,
            );
            return (
              <div key={item}>
                <DateSection date={item} issues={getIssues}></DateSection>
                <ScrollBar orientation="vertical" />
              </div>
            );
          })}
        </ScrollArea>
      )}
    </div>
  );
};

export default IssuesActivityPage;
