import * as Types from '../../types';

import { gql } from '@apollo/client';
import { IssueAttachmentFragmentDoc } from './fragments.graphql.generated';
import * as Apollo from '@apollo/client';
export type CreateIssueAttachmentMutationVariables = Types.Exact<{
  createInput: Types.CreateUploadIssueAttachmentInput;
}>;


export type CreateIssueAttachmentMutation = { __typename?: 'Mutation', createIssueAttachment: { __typename?: 'IssueAttachmentEntity', id: string, issue_id: string, workspace_id?: string | null, file_id: string, created_by: string, created_at?: string | null, file?: { __typename?: 'FileEntity', id: string, name: string, path: string, mime_type: string, size: number, workspace_id?: string | null, created_at?: string | null } | null, user?: { __typename?: 'UserEntity', email: string, username?: string | null, id: string, image?: string | null, full_name?: string | null, theme?: Types.ThemeType | null } | null } };


export const CreateIssueAttachmentDocument = gql`
    mutation createIssueAttachment($createInput: CreateUploadIssueAttachmentInput!) {
  createIssueAttachment(createInput: $createInput) {
    ...IssueAttachment
  }
}
    ${IssueAttachmentFragmentDoc}`;
export type CreateIssueAttachmentMutationFn = Apollo.MutationFunction<CreateIssueAttachmentMutation, CreateIssueAttachmentMutationVariables>;
export type CreateIssueAttachmentMutationResult = Apollo.MutationResult<CreateIssueAttachmentMutation>;
export type CreateIssueAttachmentMutationOptions = Apollo.BaseMutationOptions<CreateIssueAttachmentMutation, CreateIssueAttachmentMutationVariables>;