/* eslint-disable import/no-extraneous-dependencies */
import { useContext, useEffect } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import LoadingFullPage from '@/components/LoadingPage';
import {
  useGetDocumentsQuery,
  useGetIssuesQuery,
  useGetNotificationsQuery,
  useGetProjectsQuery,
  useGetWorkspaceByUrlQuery,
} from '@/types/tanstack-query/generated';

import useWorkspace from './useWorkspace';
import useWorkspaceDocument from './useWorkspaceDocument';
import useWorkspaceIssue from './useWorkspaceIssue';
import useWorkspaceNotification from './useWorkspaceNotification';
import useWorkspaceProject from './useWorkspaceProject';
import WorkspaceContext from './WorkspaceContext';
import SubscriptionContext from '../subscriptions/SubscriptionContext';

const WorkspaceWrapper = (props: { children?: React.ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const params = useParams();
  const {
    event,
    selected,
    workspaces,
    labels,
    teams,
    workflows,
    members,
    subscribers,
    projectStatuses,
    favorites,
    role,
    memberWorkspaces,
  } = useWorkspace();
  const { onConnect } = useContext(SubscriptionContext);

  const { event: eventIssue, issues } = useWorkspaceIssue();
  const { event: eventDocument, documents } = useWorkspaceDocument();
  const { event: eventProject, projects } = useWorkspaceProject();
  const { event: eventNotification, notifications } =
    useWorkspaceNotification();

  const getWorkspaceByUrl = useGetWorkspaceByUrlQuery({
    URL: params.workspace,
  });

  const getIssues = useGetIssuesQuery(
    {
      getInput: {
        workspace_id: getWorkspaceByUrl.data?.workspaceByURL?.workspace.id!,
      },
    },
    { enabled: !!getWorkspaceByUrl.data?.workspaceByURL?.workspace.id },
  );

  const getDocuments = useGetDocumentsQuery(
    {
      getInput: {
        workspace_id: getWorkspaceByUrl.data?.workspaceByURL?.workspace.id!,
      },
    },
    { enabled: !!getWorkspaceByUrl.data?.workspaceByURL?.workspace.id },
  );

  const getProjects = useGetProjectsQuery(
    {
      getInput: {
        workspace_id: getWorkspaceByUrl.data?.workspaceByURL?.workspace.id!,
      },
    },
    { enabled: !!getWorkspaceByUrl.data?.workspaceByURL?.workspace.id },
  );
  const getNotifications = useGetNotificationsQuery(
    {
      getInput: {
        workspace_id: getWorkspaceByUrl.data?.workspaceByURL?.workspace.id!,
      },
    },
    { enabled: !!getWorkspaceByUrl.data?.workspaceByURL?.workspace.id },
  );

  useEffect(() => {
    if (getWorkspaceByUrl.isLoading) return;
    if (!getWorkspaceByUrl.data?.workspaceByURL) {
      navigate(`/`);
      return;
    }
    if (!getWorkspaceByUrl.data?.workspaceByURL.workspace) {
      if (location.pathname === '/') {
        navigate(`/join`);
        return;
      }
    }
    onConnect(getWorkspaceByUrl.data?.workspaceByURL?.workspace.id);
    event.storeWorkspace(getWorkspaceByUrl.data?.workspaceByURL);
    if (location.pathname === '/') {
      navigate(
        `/${getWorkspaceByUrl.data?.workspaceByURL.workspace?.url}/my-issues/assigned`,
      );
    }
  }, [getWorkspaceByUrl.data?.workspaceByURL]);

  useEffect(() => {
    eventIssue.storeIssues(getIssues.data?.issues ?? []);
  }, [getIssues.data?.issues]);

  useEffect(() => {
    eventDocument.storeDocuments(getDocuments.data?.documents ?? []);
  }, [getDocuments.data?.documents]);

  useEffect(() => {
    eventNotification.storeNotifications(
      getNotifications.data?.notifications ?? [],
    );
  }, [getNotifications.data?.notifications]);

  useEffect(() => {
    eventProject.storeProjects(getProjects.data?.projects ?? []);
  }, [getProjects.data?.projects]);

  return (
    <WorkspaceContext.Provider
      value={{
        selected: selected,
        workspaces: workspaces,
        labels,
        teams,
        workflows,
        issues,
        documents,
        projects,
        projectStatuses,
        members,
        notifications: notifications,
        subscribers,
        favorites,
        memberWorkspaces,
        role,
        getDataByTeam: (value) => event.getDataByTeam(value),
        getIssuesByTeam: (value) => {
          const getTeam = teams.find(
            (item) =>
              item.id === value?.team_id ||
              item.identifier === value?.team_identifier,
          );
          if (getTeam) {
            return eventIssue.getIssuesByTeam({ team_id: getTeam.id });
          }
          return [];
        },
        getDocumentsByTeam: (value) => {
          const getTeam = teams.find(
            (item) =>
              item.id === value?.team_id ||
              item.identifier === value?.team_identifier,
          );
          if (getTeam) {
            return eventDocument.getDocumentsByTeam({ team_id: getTeam.id });
          }
          return [];
        },
        getProjectsByTeam: (value) => {
          const getTeam = teams.find(
            (item) =>
              item.id === value?.team_id ||
              item.identifier === value?.team_identifier,
          );
          if (getTeam) {
            return eventProject.getProjectsByTeam({ team_id: getTeam.id });
          }
          return [];
        },
        getIssuesByProject: (value) => {
          const getProject = projects.find(
            (item) =>
              item.id === value?.project_id ||
              item.code === value?.project_code,
          );
          if (getProject) {
            return eventIssue.getIssuesByProject({ project_id: getProject.id });
          }
          return [];
        },
      }}
    >
      {!getWorkspaceByUrl.isLoading &&
      !getIssues.isLoading &&
      !getNotifications.isLoading &&
      !getDocuments.isLoading &&
      !getProjects.isLoading ? (
        props.children
      ) : (
        <LoadingFullPage description="Setting your workspace" />
      )}
    </WorkspaceContext.Provider>
  );
};

export default WorkspaceWrapper;
