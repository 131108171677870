import * as Types from '../../types';

import { gql } from '@apollo/client';
export type UserFragment = { __typename?: 'UserEntity', email: string, username?: string | null, id: string, image?: string | null, full_name?: string | null, theme?: Types.ThemeType | null };

export const UserFragmentDoc = gql`
    fragment User on UserEntity {
  email
  username
  id
  image
  full_name
  theme
}
    `;