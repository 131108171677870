import { ReactNode } from 'react';

import { Label } from '@/components/Label';
import { ArrowRightIcon } from '@/icons/core';
import EmojiIcon, { IEmojiIcon } from '@/icons/emoji';
import { TopNavigationBody } from '@/providers/main/components/TopNavigationBody';
import { TeamFragment } from '@/types/tanstack-query/generated';

const TeamHeader = (props: {
  team: TeamFragment | undefined;
  name: string;
  isCreateDocument: boolean;
  right?: ReactNode;
}) => {
  return (
    <TopNavigationBody>
      <div className="flex w-full flex-row items-center gap-2">
        <div className="flex grow flex-row items-center gap-2">
          <div className="flex flex-row items-center gap-2">
            <EmojiIcon
              tag={props.team?.icon as IEmojiIcon}
              attrs={{
                width: 18,
                height: 18,
                color: props.team?.color,
              }}
            />
            <Label className="font-semibold">{props.team?.name}</Label>
          </div>
          <ArrowRightIcon className="flex-none" width={18} height={18} />
          <Label>{props.name}</Label>
        </div>
        {props.right}
      </div>
    </TopNavigationBody>
  );
};
export default TeamHeader;
