import { useContext, useEffect, useState } from 'react';

import uniqBy from 'lodash/uniqBy';

import useIssuesMultiFilters from '@/hooks/useIssuesMultiFilters';
import AuthenticatedUserContext from '@/providers/auth/AuthenticatedUserContext';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { SubscriberType } from '@/types/tanstack-query/generated';
import { WorkflowFragment, LabelFragment } from '@/types/tanstack-query/generated';

const useIssuesSubscribed = () => {
  const { user } = useContext(AuthenticatedUserContext);
  const {
    issues: cores,
    workflows: workflowsCore,
    labels: labelsCore,
    members,
    subscribers,
  } = useContext(WorkspaceContext);
  const [workflows, setWorkflows] = useState<WorkflowFragment[]>([]);
  const [labels, setLabels] = useState<LabelFragment[]>([]);

  const {
    issues,
    selectedStatuses,
    selectedConditionStatus,
    selectedPriorities,
    selectedConditionPriority,
    selectedLabels,
    selectedConditionLabel,
    selectedAssignees,
    selectedConditionAssignee,
    event: {
      setIssues,
      setSelectedStatuses,
      setSelectedConditionStatus,
      setSelectedPriorities,
      setSelectedConditionPriority,
      setSelectedLabels,
      setSelectedConditionLabel,
      setSelectedAssignees,
      setSelectedConditionAssignee,
    },
  } = useIssuesMultiFilters();

  useEffect(() => {
    const getIssueSubscriber = subscribers.filter(
      (item) => item.member_id === user?.id,
    );
    const getIssues = getIssueSubscriber.map(
      (item) =>
        cores.find(
          (x) =>
            x.id === item.map_id &&
            item.type === SubscriberType.Issue &&
            x.is_deleted === false,
        )!,
    );

    setIssues(getIssues);
  }, [cores]);

  useEffect(() => {
    const getList = uniqBy(
      workflowsCore,
      (item) => `${item.name} ${item.type}`,
    );
    setWorkflows(getList);
  }, [workflowsCore]);

  useEffect(() => {
    const getList = uniqBy(labelsCore, (item) => `${item.name}`);
    setLabels(getList);
  }, [labelsCore]);

  return {
    issues,
    workflows,
    labels,
    members,
    selectedStatuses,
    selectedConditionStatus,
    selectedPriorities,
    selectedConditionPriority,
    selectedLabels,
    selectedConditionLabel,
    selectedAssignees,
    selectedConditionAssignee,
    event: {
      setSelectedStatuses,
      setSelectedConditionStatus,
      setSelectedPriorities,
      setSelectedConditionPriority,
      setSelectedLabels,
      setSelectedConditionLabel,
      setSelectedAssignees,
      setSelectedConditionAssignee,
    },
  };
};

export default useIssuesSubscribed;
