import React, { useState } from 'react';

import clsx from 'clsx';
import { CalendarX2Icon, PackageIcon } from 'lucide-react';

import { Button } from '@/components/Button';
import { Label } from '@/components/Label';
import { UserAvatar } from '@/components/UserAvatar';
import { useErrorHandler } from '@/hooks/useToasterHandler';
import { LabelIcon, UserAssignIcon } from '@/icons/core/issue';
import { getNameByPriority } from '@/services/issue/issue-utils';
import {
  IssueFragment,
  PriorityType,
  useUpdateIssueMutation,
} from '@/types/tanstack-query/generated';
import CustomDateTime from '@/utils/format-datetime';

import { MemoizedAddToProjectButton } from './Properties/AddToProjectButton';
import MemoizedAssignToButton from './Properties/AssignToButton';
import MemoizedDueDateButton from './Properties/DueDateButton';
import {
  MemoizedLabelButton,
  MemoizedRenderLabelColor,
  MemoizedRenderLabelsColor,
} from './Properties/LabelButton';
import {
  MemoizedPriorityButton,
  MemoizedRenderPriorityIcon,
} from './Properties/PriorityButton';
import {
  MemoizedRenderStatusIcon,
  MemoizedStatusButton,
} from './Properties/StatusButton';

const onVisibleWhenNotEmpty = (props: {
  isDefaultVisible: boolean;
  isVisibleWhenNotEmpty: boolean;
  isValue: boolean;
}) => {
  if (!props.isDefaultVisible) {
    return false;
  }
  if (props.isVisibleWhenNotEmpty && !props.isValue) {
    return false;
  }
  return true;
};

const IssuePropertiesComponent = (props: {
  teamId: string;
  isVisibleWhenNotEmpty?: boolean;
  isHiddenWorkflow?: boolean;
  isHiddenPriority?: boolean;
  isHiddenAssignee?: boolean;
  isHiddenLabel?: boolean;
  isHiddenProject?: boolean;
  isHiddenDueDate?: boolean;
  selectedWorkflowId: string | undefined;
  onSelectedWorkflowId: (value: string | undefined) => void;
  selectedPriority: PriorityType | undefined;
  onSelectedPriority: (value: PriorityType | undefined) => void;
  selectedLabelIds: string[];
  onSelectedLabelIds: (value: string[]) => void;
  selectedMemberId: string | undefined;
  onSelectedMemberId: (value: string | undefined) => void;
  selectedProjectId: string | undefined;
  onSelectedProjectId: (value: string | undefined) => void;
  selectedDueDate: Date | undefined;
  onSelectedDueDate: (value: Date | undefined) => void;
  size?: 'sm' | 'default' | 'lg' | 'icon';
}) => {
  let height = 'h-7';
  let textSize = 'text-xs';
  if (props.size === 'default') {
    height = 'h-8';
    textSize = 'text-sm';
  }

  return (
    <div className="flex w-full flex-wrap items-start gap-2">
      {onVisibleWhenNotEmpty({
        isDefaultVisible: !props.isHiddenWorkflow,
        isVisibleWhenNotEmpty: props.isVisibleWhenNotEmpty ?? false,
        isValue: !!props.selectedWorkflowId,
      }) && (
        <MemoizedStatusButton
          teamId={props.teamId}
          selected={props.selectedWorkflowId}
          onSelected={props.onSelectedWorkflowId}
          renderItem={(value) => {
            if (!value && props.selectedWorkflowId) return <></>;
            return (
              <div className="flex-none">
                <Button
                  size={props.size ?? 'sm'}
                  className={clsx(
                    'flex flex-row items-center justify-start gap-1 text-left',
                    height,
                  )}
                  variant="outline"
                  type="button"
                >
                  <span className="flex w-[20px] flex-none">
                    <MemoizedRenderStatusIcon
                      type={value?.type}
                      color={value?.color}
                      processing={value?.processing}
                    />
                  </span>
                  {!props.isVisibleWhenNotEmpty && (
                    <Label className={clsx(textSize)}>{value?.name}</Label>
                  )}
                </Button>{' '}
              </div>
            );
          }}
        />
      )}

      {onVisibleWhenNotEmpty({
        isDefaultVisible: !props.isHiddenPriority,
        isVisibleWhenNotEmpty: props.isVisibleWhenNotEmpty ?? false,
        isValue:
          !!props.selectedPriority &&
          props.selectedPriority !== PriorityType.NoPriority,
      }) && (
        <MemoizedPriorityButton
          selected={props.selectedPriority}
          onSelected={props.onSelectedPriority}
          renderItem={(value) => {
            if (!value && props.selectedPriority) return <></>;
            return (
              <div className="flex-none">
                <Button
                  size={props.size ?? 'sm'}
                  className={clsx(
                    'flex flex-row items-center justify-start gap-1 text-left',
                    height,
                  )}
                  variant="outline"
                  type="button"
                >
                  <span className="flex">
                    <MemoizedRenderPriorityIcon type={value?.type} />
                  </span>
                  {!props.isVisibleWhenNotEmpty && (
                    <Label className={clsx(textSize)}>
                      {getNameByPriority(value?.type)}
                    </Label>
                  )}
                </Button>
              </div>
            );
          }}
        />
      )}

      {onVisibleWhenNotEmpty({
        isDefaultVisible: !props.isHiddenAssignee,
        isVisibleWhenNotEmpty: props.isVisibleWhenNotEmpty ?? false,
        isValue: !!props.selectedMemberId,
      }) && (
        <MemoizedAssignToButton
          teamIds={props.teamId ? [props.teamId] : []}
          selected={props.selectedMemberId}
          onSelected={props.onSelectedMemberId}
          renderItem={(value) => {
            if (!value && props.selectedMemberId) return <></>;
            return (
              <div className="flex-none">
                <Button
                  size={props.size ?? 'sm'}
                  className={clsx(
                    'flex flex-row items-center justify-start gap-1 text-left',
                    height,
                  )}
                  variant="outline"
                  type="button"
                >
                  {!props.selectedMemberId && (
                    <div className="flex flex-row items-center gap-1">
                      <UserAssignIcon width={22} height={22} />
                      <Label className={clsx(textSize)}>Assign</Label>
                    </div>
                  )}
                  {props.selectedMemberId && (
                    <div className="flex flex-row items-center gap-2">
                      <UserAvatar
                        size="sm"
                        url={value?.image}
                        fallback={value?.username}
                      />
                      <Label className={clsx(textSize)}>
                        {value?.username}
                      </Label>
                    </div>
                  )}
                </Button>
              </div>
            );
          }}
        />
      )}

      {onVisibleWhenNotEmpty({
        isDefaultVisible: !props.isHiddenLabel,
        isVisibleWhenNotEmpty: props.isVisibleWhenNotEmpty ?? false,
        isValue: props.selectedLabelIds.length > 0,
      }) && (
        <div className="flex flex-none flex-row gap-2">
          <MemoizedLabelButton
            teamId={props.teamId}
            selected={props.selectedLabelIds}
            onSelected={props.onSelectedLabelIds}
            placeholder="Search label..."
            renderItem={(value) => {
              if (props.isVisibleWhenNotEmpty) {
                return (
                  <>
                    {value.map((label) => (
                      <Button
                        size={props.size ?? 'sm'}
                        className={clsx(
                          'flex flex-row items-center justify-start gap-1 text-left',
                          height,
                        )}
                        variant="outline"
                        type="button"
                        key={label.id}
                      >
                        <div className="flex flex-row items-center gap-1">
                          <MemoizedRenderLabelColor label={label} />
                          <Label className={clsx(textSize)}>{label.name}</Label>
                        </div>
                      </Button>
                    ))}
                  </>
                );
              }
              return (
                <Button
                  size={props.size ?? 'sm'}
                  className={clsx(
                    'flex flex-row items-center justify-start gap-1 text-left',
                    height,
                  )}
                  variant="outline"
                  type="button"
                >
                  {value.length > 0 && (
                    <div className="flex flex-row items-center gap-1">
                      <MemoizedRenderLabelsColor labels={value} />
                      <Label className={clsx(textSize)}>
                        {value.length} labels
                      </Label>
                    </div>
                  )}
                  {value.length === 0 && <LabelIcon width={20} height={20} />}
                </Button>
              );
            }}
          />
        </div>
      )}

      {onVisibleWhenNotEmpty({
        isDefaultVisible: !props.isHiddenProject,
        isVisibleWhenNotEmpty: props.isVisibleWhenNotEmpty ?? false,
        isValue: !!props.selectedProjectId,
      }) && (
        <MemoizedAddToProjectButton
          teamId={props.teamId}
          selected={props.selectedProjectId}
          onSelected={props.onSelectedProjectId}
          renderItem={(value) => {
            if (!value && props.selectedProjectId) return <></>;
            return (
              <div className="flex-none">
                <Button
                  size={props.size ?? 'sm'}
                  className={clsx(
                    'flex flex-row items-center justify-start gap-1 text-left',
                    height,
                  )}
                  variant="outline"
                  type="button"
                >
                  {value && (
                    <div className="flex w-full flex-row items-center gap-1">
                      <PackageIcon width={20} height={20} />
                      <div className="line-clamp-1 grow text-ellipsis">
                        <Label className="text-ellipsis text-xs">
                          {value.title}
                        </Label>
                      </div>
                    </div>
                  )}
                  {!value && (
                    <div className="flex flex-row items-center gap-1">
                      <PackageIcon width={20} height={20} />
                      <Label className={clsx(textSize)}>Add to project</Label>
                    </div>
                  )}
                </Button>
              </div>
            );
          }}
        />
      )}

      {onVisibleWhenNotEmpty({
        isDefaultVisible: !props.isHiddenDueDate,
        isVisibleWhenNotEmpty: props.isVisibleWhenNotEmpty ?? false,
        isValue: !!props.selectedDueDate,
      }) && (
        <div className="flex-none">
          <MemoizedDueDateButton
            selected={
              props.selectedDueDate
                ? new Date(props.selectedDueDate)
                : undefined
            }
            onSelected={props.onSelectedDueDate}
            renderItem={(value) => (
              <Button
                variant={'outline'}
                size={props.size ?? 'sm'}
                className={clsx(
                  'flex flex-row items-center justify-start gap-1 text-left',
                  height,
                )}
              >
                <CalendarX2Icon width={18} height={18} color="red" />
                <Label className={clsx(textSize)}>
                  {value ? CustomDateTime.formatDate(value) : 'Pick a Due date'}
                </Label>
              </Button>
            )}
          />
        </div>
      )}
    </div>
  );
};

const IssuePropertiesByIssueIdComponent = (props: { issue: IssueFragment }) => {
  const { showErrorToast } = useErrorHandler();
  const [selectedWorkflowId, setSelectedWorkflowId] = useState<
    string | undefined
  >(props.issue.workflow_id ?? undefined);
  const [selectedMemberId, setSelectedMemberId] = useState<string | undefined>(
    props.issue.assignee_id ?? undefined,
  );
  const [selectedLabelIds, setSelectedLabelIds] = useState<string[]>(
    props.issue.labels?.map((item) => item.label_id) ?? [],
  );
  const [selectedPriority, setSelectedPriority] = useState<PriorityType>(
    props.issue.priority,
  );
  const [selectedDueDate, setSelectedDueDate] = useState<Date | undefined>(
    props.issue.due_date_at ? new Date(props.issue.due_date_at) : undefined,
  );

  const [selectedProjectId, setSelectedProjectId] = useState<
    string | undefined
  >(props.issue.project_id ?? undefined);
  const updateIssue = useUpdateIssueMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {},
    onSettled: () => {},
  });

  return (
    <IssuePropertiesComponent
      teamId={props.issue.team_id ?? ''}
      isHiddenWorkflow={true}
      isHiddenAssignee={true}
      isVisibleWhenNotEmpty={true}
      selectedWorkflowId={selectedWorkflowId}
      onSelectedWorkflowId={(value) => {
        setSelectedWorkflowId(value);
        updateIssue.mutate({
          updateInput: {
            id: props.issue.id,
            workflow_id: value,
          },
        });
      }}
      selectedMemberId={selectedMemberId}
      onSelectedMemberId={(value) => {
        setSelectedMemberId(value);
        updateIssue.mutate({
          updateInput: {
            id: props.issue.id,
            assignee_id: value,
          },
        });
      }}
      selectedLabelIds={selectedLabelIds}
      onSelectedLabelIds={(value) => {
        setSelectedLabelIds(value);
        updateIssue.mutate({
          updateInput: {
            id: props.issue.id,
            label_ids: value,
          },
        });
      }}
      selectedPriority={selectedPriority}
      onSelectedPriority={(value) => {
        setSelectedPriority(value ?? PriorityType.Low);
        updateIssue.mutate({
          updateInput: {
            id: props.issue.id,
            priority: value,
          },
        });
      }}
      selectedProjectId={selectedProjectId}
      onSelectedProjectId={(value) => {
        setSelectedProjectId(value);
        updateIssue.mutate({
          updateInput: {
            id: props.issue.id,
            project_id: value,
          },
        });
      }}
      selectedDueDate={selectedDueDate}
      onSelectedDueDate={(value) => {
        setSelectedDueDate(value);
        updateIssue.mutate({
          updateInput: {
            id: props.issue.id,
            due_date_at: value?.toISOString(),
          },
        });
      }}
    />
  );
};

const MemoizedIssuePropertiesComponent = React.memo(IssuePropertiesComponent);
const MemoizedIssuePropertiesByIssueComponent = React.memo(
  IssuePropertiesByIssueIdComponent,
);

export {
  MemoizedIssuePropertiesComponent,
  MemoizedIssuePropertiesByIssueComponent,
};
