import { useContext, useRef } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import * as yup from 'yup';

import { Card } from '@/components/Card';
import { Form, FormField } from '@/components/Form';
import { Input } from '@/components/Input';
import { Label } from '@/components/Label';
import { LabelError } from '@/components/LabelError';
import { Separator } from '@/components/Separator';
import { UserAvatar } from '@/components/UserAvatar';
import { useErrorHandler } from '@/hooks/useToasterHandler';
import AuthenticatedUserContext from '@/providers/auth/AuthenticatedUserContext';
import {
  ThemeType,
  UpdateUserInput,
  UserFragment,
  useUpdateUserMutation,
} from '@/types/tanstack-query/generated';

import HeaderSetting from '../../components/HeaderSetting';

export type IUpdateUser = {
  full_name: string;
  username: string;
  image?: string;
  mode?: ThemeType;
};

export const schemaUpdateUser = yup.object().shape({
  full_name: yup.string().required(),
  username: yup.string().required(),
  image: yup.string(),
  mode: yup.mixed<ThemeType>().oneOf(Object.values(ThemeType)),
});

const InformationSection = (props: { user: UserFragment }) => {
  const { showErrorToast } = useErrorHandler();
  const updateUser = useUpdateUserMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {},
    onSettled: () => {},
  });
  const formRef = useRef<HTMLFormElement>(null);
  const form = useForm<IUpdateUser>({
    resolver: yupResolver<IUpdateUser>(schemaUpdateUser),
    defaultValues: {
      full_name: props.user?.full_name ?? undefined,
      username: props.user?.username ?? undefined,
      image: props.user?.image ?? undefined,
    },
  });

  const onUpdate = (data: IUpdateUser) => {
    const getFields = omitBy(data, isNil) as UpdateUserInput;
    updateUser.mutate({
      updateInput: { ...getFields },
    });
  };

  const debounced = useDebounceCallback(
    () => formRef?.current?.requestSubmit(),
    500,
  );

  return (
    <Card className="p-0">
      <Form {...form}>
        <form ref={formRef} onSubmit={form.handleSubmit(onUpdate)}>
          <div className="flex flex-col gap-6 bg-secondary p-4">
            <Label className="font-semibold">Profile picture</Label>
            <div className={'flex flex-col items-center gap-4 text-center'}>
              <UserAvatar size={'2xl'} fallback={props.user?.full_name} />
              <Label className="opacity-50">
                Pick a image for your avatar. Recommended size is 256x256px
              </Label>
            </div>
          </div>
          <div className="flex flex-col gap-6 p-4">
            <div className="flex flex-row items-center gap-2">
              <Label className="grow">Full name</Label>
              <div className="w-[200px]">
                <FormField
                  control={form.control}
                  name="full_name"
                  render={({ field }) => (
                    <>
                      <Input
                        {...field}
                        onChange={(e) => {
                          debounced();
                          field.onChange(e);
                        }}
                        placeholder="Full name"
                      ></Input>
                      <LabelError>
                        {form.formState.errors.full_name?.message}
                      </LabelError>
                    </>
                  )}
                />
              </div>
            </div>
            <Separator />
            <div className="flex flex-row items-center gap-2">
              <div className="flex flex-col gap-2">
                <Label className="grow">Username</Label>
                <Label className="text-xs opacity-50">
                  Nickname or first name, however you want to be called in
                  Linear
                </Label>
              </div>
              <div className="w-[200px]">
                <FormField
                  control={form.control}
                  name="username"
                  render={({ field }) => (
                    <>
                      <Input
                        {...field}
                        onChange={(e) => {
                          debounced();
                          field.onChange(e);
                        }}
                        placeholder="Username"
                      ></Input>
                      <LabelError>
                        {form.formState.errors.username?.message}
                      </LabelError>
                    </>
                  )}
                />
              </div>
            </div>
          </div>
        </form>
      </Form>
    </Card>
  );
};

const SettingAccountProfilePage = () => {
  const { user } = useContext(AuthenticatedUserContext);

  return (
    <div>
      <HeaderSetting
        title={'Profile'}
        subtitle={'Manage your profile'}
      ></HeaderSetting>
      {user && (
        <div className="w-full">
          <InformationSection user={user} />
        </div>
      )}
    </div>
  );
};

export default SettingAccountProfilePage;
