import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogTitle,
} from '@/components/AlertDialog';
import { useErrorHandler } from '@/hooks/useToasterHandler';
import {
  useRemoveWorkflowMutation,
  WorkflowFragment,
} from '@/types/tanstack-query/generated';

const DeleteWorkflowDialog = (props: {
  workflow: WorkflowFragment | undefined;
  onCancel: () => void;
  onDeleteSuccess: (workflow: WorkflowFragment) => void;
}) => {
  const { showErrorToast } = useErrorHandler();

  const removeWorkflow = useRemoveWorkflowMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: (res) => {
      props.onDeleteSuccess(res.removeWorkflow);
    },
    onSettled: () => {},
  });

  return (
    <AlertDialog open={!!props.workflow}>
      <AlertDialogContent>
        <AlertDialogTitle className="text-xl">
          Are you sure you want to delete the "{props.workflow?.name}" workflow?
        </AlertDialogTitle>
        <AlertDialogDescription>
          The workflow will be removed. This might also affect issues and drafts
          you do not have access to. This action cannot be undone.
        </AlertDialogDescription>

        <div className="flex flex-row items-center justify-end gap-4">
          <AlertDialogCancel onClick={() => props.onCancel()}>
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction
            onClick={() =>
              props.workflow && removeWorkflow.mutate({ id: props.workflow.id })
            }
          >
            Delete
          </AlertDialogAction>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DeleteWorkflowDialog;
