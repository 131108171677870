import React, { useContext } from 'react';

import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { TeamFragment } from '@/types/tanstack-query/generated';

const RenderTeam = (props: {
  id: string | undefined;
  renderItem: (value: TeamFragment | undefined) => React.ReactNode;
}) => {
  if (!props.id) {
    return <>{props.renderItem(undefined)}</>;
  }
  const { teams } = useContext(WorkspaceContext);

  return <>{props.renderItem(teams.find((item) => item.id === props.id))}</>;
};
const MemoizedRenderTeam = React.memo(RenderTeam);

export { MemoizedRenderTeam };
