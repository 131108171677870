import { useMutation } from '@apollo/client';
import { useFilePicker } from 'use-file-picker';
import {
  FileAmountLimitValidator,
  FileSizeValidator,
} from 'use-file-picker/validators';

import { InvalidInputError } from '@/services/core/error-type';
import { CreateAttachmentDocument } from '@/types/apollo-client/file/graphql/mutations.graphql.generated';

import { useErrorHandler } from './useToasterHandler';

const useUploadAttachment = (props: {
  type: string;
  workspace_id: string;
  onUploadSuccess: (path: string) => void;
}) => {
  const { showErrorToast } = useErrorHandler();

  const [createAttachment, { loading }] = useMutation(CreateAttachmentDocument);

  const onAttachment = async (file: File) => {
    if (file) {
      const res = await createAttachment({
        variables: {
          createInput: {
            type: props.type,
            file: file,
            workspace_id: props.workspace_id,
          },
        },
      });

      if (res.data?.createAttachment) {
        props.onUploadSuccess(res.data.createAttachment.path);
      }
    }
  };

  const { openFilePicker } = useFilePicker({
    readAs: 'DataURL',
    multiple: false,
    validators: [
      new FileAmountLimitValidator({ max: 1 }),
      new FileSizeValidator({ maxFileSize: 50 * 1024 * 1024 /* 50 MB */ }),
    ],

    onFilesRejected: () => {
      showErrorToast({
        error: new InvalidInputError('Input size invalid'),
      });
    },
    onFilesSuccessfullySelected: ({ plainFiles }) => {
      if (plainFiles[0]) {
        onAttachment(plainFiles[0]);
      }
    },
  });

  const onUpload = () => {
    openFilePicker();
  };

  return { loading: loading, onUpload };
};
export default useUploadAttachment;
